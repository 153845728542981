import * as yup from 'yup'

import i18next from '../../../../../i18n.js'

const RD_MODEL = {
    project: 'name',
    responsibility: 'responsibility_id',
    start_date: 'start_date',
    end_date: 'end_date',
    status: 'is_active',
    applicant: 'applicant_id',
    team: 'team_id',
    days: 'days',
    base_price: 'rate',
    budget: 'budget',
    link_drive_operation: 'drive_url_operation',
    link_drive_management: 'drive_url_management',
}

const DEFAULT_RD_MOCK = {
    [RD_MODEL.project]: '',
    [RD_MODEL.responsibility]: null,
    [RD_MODEL.start_date]: null,
    [RD_MODEL.end_date]: null,
    [RD_MODEL.status]: null,
    [RD_MODEL.applicant]: null,
    [RD_MODEL.team]: null,
    [RD_MODEL.days]: null,
    [RD_MODEL.base_price]: null,
    [RD_MODEL.budget]: null,
    [RD_MODEL.link_drive_operation]: null,
    [RD_MODEL.link_drive_management]: null,
}
const RequestValidationSchema = yup.object().shape({
    [RD_MODEL.project]: yup
        .string()
        .required(i18next.t('validation:general_form.project_required')),
    [RD_MODEL.responsibility]: yup
        .object()
        .required(i18next.t('validation:general_form.responsibility_required')),
    [RD_MODEL.start_date]: yup
        .date()
        .required(i18next.t('validation:general_form.start_date_required')),
    [RD_MODEL.end_date]: yup
        .date()
        .required(i18next.t('validation:general_form.end_date_required')),
    [RD_MODEL.status]: yup
        .object()
        .required(i18next.t('validation:general_form.status')),
    [RD_MODEL.applicant]: yup
        .object()
        .required(i18next.t('validation:general_form.applicant_required')),
    [RD_MODEL.team]: yup
        .object()
        .required(i18next.t('validation:general_form.team_leader')),
    [RD_MODEL.days]: yup
        .string()
        .matches(/^[1-9]\d*$/, i18next.t('validation:general_form.rate_check'))
        .required(i18next.t('validation:general_form.days_required')),
    [RD_MODEL.base_price]: yup
        .string()
        .required(i18next.t('validation:general_form.base_price_required')),
    [RD_MODEL.budget]: yup
        .string()
        .nullable()
        .transform(value => (value === '' ? null : value)),
    [RD_MODEL.link_drive_operation]: yup
        .string()
        .matches(
            /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/,
            i18next.t('validation:general_form.invalid_url')
        )
        .nullable()
        .transform(value => (value === '' ? null : value)),
    [RD_MODEL.link_drive_management]: yup
        .string()
        .matches(
            /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/,
            i18next.t('validation:general_form.invalid_url')
        )
        .nullable()
        .transform(value => (value === '' ? null : value)),
})

export {
    RD_MODEL,
    DEFAULT_RD_MOCK,
    RequestValidationSchema as validationSchema,
}
