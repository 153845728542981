import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    // HistoryIcon, (MERP-551)
    TeamIcon,
} from '@/assets/icons/icons.jsx'
import Sidebar from '@/components/sidebar/Sidebar.jsx'
import Tabs from '@/components/tabs/Tabs.jsx'
import { setPanelId, selectPanelId } from '@/services/settings/settingsSlice'
import { LABELS_TABLES } from '@/utilities/constants/commons'

// import TeamHistorySidebar from './history/TeamHistorySidebar' (MERP-551)
import TeamSidebar from './team/TeamSidebar'

const TeamSidebarWrapper = ({ setOpenForm }) => {
    const dispatch = useDispatch()

    const panelId = useSelector(selectPanelId)
    const [tabValue, setTabValue] = useState(LABELS_TABLES.team)

    const sideBarTeamData = [
        {
            id: 1,
            value: LABELS_TABLES.team,
            label: LABELS_TABLES.team,
            icon: <TeamIcon />,
            component: <TeamSidebar setOpenForm={setOpenForm} />,
        },
        // TODO: comment until further notice (MERP-551)
        // {
        //     id: 2,
        //     value: LABELS_TABLES.history,
        //     label: LABELS_TABLES.history,
        //     icon: <HistoryIcon />,
        //     component: <TeamHistorySidebar />,
        // },
    ]

    return (
        <Sidebar
            openIcon={false}
            onClose={() => {
                dispatch(setPanelId(undefined))
                setTabValue(LABELS_TABLES.team)
            }}
            open={panelId !== undefined}
        >
            <Tabs
                defaultValue={tabValue}
                triggers={sideBarTeamData}
                isSidePanel={true}
                haveDivider
                onValueChange={e => setTabValue(e)}
            />
        </Sidebar>
    )
}

export default TeamSidebarWrapper

TeamSidebarWrapper.propTypes = {
    setOpenForm: PropTypes.func,
}
