import PropTypes from 'prop-types'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InfoIcon, PlusIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import Datepicker from '@/components/date-picker/DatePicker.jsx'
import Tooltip from '@/components/tooltip/Tooltip.jsx'
import { StyledContainerButton } from '@/pages/projects/detail/detail-final-balance/final-balance-sub-header/styles.js'
import { LABELS_TABLES } from '@/utilities/constants/index.js'

const FinalBalanceSubHeader = ({
    onClickHandler,
    selectedTab,
    setMonthSelected,
    notHavePhase,
    onClickToUpdate,
}) => {
    const { t } = useTranslation()
    const [datePickerValue, setDatePickerValue] = useState(null)

    const { control: formControl } = useForm()

    switch (selectedTab) {
        case LABELS_TABLES.activities:
            return null
        default:
            return (
                <StyledContainerButton notHavePhase={notHavePhase}>
                    {/* <Button
                        variant={'ghost'}
                        type={'button'}
                        size={'small'}
                        onClick={exportHandler}
                    >
                        <ExportIcon />
                        {t('activity.actions.export')}
                    </Button>*/}
                    {notHavePhase && (
                        <Tooltip
                            text={t('validation:general_form.log_activity')}
                            showPosition={'bottom'}
                        >
                            <Button
                                size={'small'}
                                variant={'ghost'}
                                onClick={onClickToUpdate}
                            >
                                <InfoIcon />
                                {t('general.update')}
                            </Button>
                        </Tooltip>
                    )}

                    {/*TODO: ADD DATEPICKER WHEN IS READY THE SCHEMA IS READY*/}
                    <Datepicker
                        name={'Single month'}
                        control={formControl}
                        showMonthYearPicker
                        placeholder={t(
                            'billing_project.fields.placeholder.date'
                        )}
                        pickerType={'months'}
                        dateFormat={'MMMM/yyyy'}
                        selected={datePickerValue}
                        onChange={date => {
                            setDatePickerValue(date)
                            setMonthSelected({
                                month: date.getMonth() + 1,
                                year: date.getFullYear(),
                                isFilter: true,
                            })
                        }}
                    />

                    <Button
                        type={'button'}
                        size={'small'}
                        onClick={() =>
                            onClickHandler({
                                isOpen: true,
                                typeForm: 'modifyMembers',
                                isEdit: true,
                            })
                        }
                    >
                        <PlusIcon />
                        {t('activity.actions.modify_members')}
                    </Button>
                </StyledContainerButton>
            )
    }
}

export default FinalBalanceSubHeader

FinalBalanceSubHeader.propTypes = {
    onClickHandler: PropTypes.func,
    selectedTab: PropTypes.string,
    setMonthSelected: PropTypes.func,
    notHavePhase: PropTypes.bool,
    onClickToUpdate: PropTypes.func,
}
