import * as Tabs from '@radix-ui/react-tabs'
import styled, { css } from 'styled-components'

export const StyledTabsRoot = styled(Tabs.Root)`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const StyledTabsList = styled(Tabs.List)`
    ${({ theme, sidepanel, tabs }) => css`
        flex-shrink: 0;
        display: flex;
        padding: ${sidepanel ? `${theme.spacing}px ${theme.spacing * 3}px` : 0};
        gap: ${sidepanel ? `${theme.spacing * 4}px` : 0};
        justify-content: ${sidepanel ? 'space-between' : 'flex-start'};
        ${sidepanel &&
        tabs === 2 &&
        `gap: ${theme.spacing * 8}px;
         justify-content: flex-start;`}
    `}
`
export const StyledDivTabs = styled.div`
    ${({ theme }) => css`
        border-bottom: 1px solid ${theme.palette.neutral.base[100]};
        width: 100%;
    `}
`

export const StyledTabsTrigger = styled(Tabs.Trigger)`
    ${({ theme, icon, sidepanel }) => css`
        padding: ${!sidepanel ? `0px ${theme.spacing * 2}px` : 0};
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: ${theme.spacing / 4}px;
        user-select: none;
        cursor: pointer;

        color: ${theme.palette.neutral.base[200]};

        &[data-state='active'] {
            p {
                ${sidepanel
                    ? theme.typography.paragraph.p12.bold
                    : theme.typography.paragraph.p14.medium}
            }

            color: ${theme.palette.neutral.base[500]};
            ${!icon &&
            css`
                border-bottom: 1px solid ${theme.palette.neutral.base[1000]};
            `}
            box-shadow: ${theme.shadows[60]};

            & svg {
                & path {
                    fill: ${theme.palette.neutral.base[500]} !important;
                }
            }
        }

        & p {
            ${sidepanel
                ? theme.typography.paragraph.p12.bold
                : theme.typography.paragraph.p14.regular}
            margin-bottom: ${sidepanel
                ? 0
                : `${theme.spacing + theme.spacing / 2}px`};
        }
        &:focus {
            position: relative;
            box-shadow: ${theme.shadows[60]};
        }

        ${sidepanel &&
        css`
            & p {
                ${theme.typography.paragraph.p12.bold};
            }
        `}
    `}
`

export const StyledTabsContent = styled(Tabs.Content)`
    ${({ theme, sidepanel }) => css`
        flex-grow: 1;
        ${sidepanel
            ? css`
                  padding: ${theme.spacing * 2}px ${theme.spacing * 3}px;
              `
            : css`
                  padding-top: ${theme.spacing * 3}px;
              `}
        background-color: ${theme.palette.neutral.base[0]};
        border-bottom-left-radius: ${theme.spacing}px;
        border-bottom-right-radius: ${theme.spacing}px;
        outline: none;
        overflow-y: auto;
        &:focus {
            box-shadow: none;
        }
        &[data-state='inactive'] {
            display: none;
        }
    `}
`

export const StyledTabsBody = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-flow: column;
        gap: ${theme.spacing}px;
    `}
`

export const StyledTabsSidePanelBody = styled.div`
    display: flex;
    flex-flow: column;
`
