import PropTypes from 'prop-types'

import { StyledBaseButton } from './styles'

const Button = ({
    children,
    variant = 'filled',
    size = 'medium',
    color = 'primary',
    gradient = 'base',
    type = 'button',
    collapsed = false,
    disabled = false,
    onClick = () => {},
    className,
    isUppercase,
    ...props
}) => {
    return (
        <StyledBaseButton
            variant={variant}
            size={size}
            color={color}
            gradient={gradient}
            type={type}
            collapsed={collapsed}
            disabled={disabled}
            onClick={onClick}
            className={className}
            isUppercase={isUppercase}
            {...props}
        >
            {children}
        </StyledBaseButton>
    )
}

export default Button

Button.propTypes = {
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOf(['small', 'medium', 'large', 'fullWidth']),
    variant: PropTypes.oneOf(['filled', 'ghost', 'borderless', 'underlined']), // TODO: fare delle costanti
    color: PropTypes.oneOf(['neutral', 'primary', 'secondary', 'danger']),
    gradient: PropTypes.oneOf(['base', 'dark', 'light']),
    type: PropTypes.oneOf(['button', 'submit']),
    collapsed: PropTypes.bool,
    disabled: PropTypes.any,
    onClick: PropTypes.func,
    className: PropTypes.string,
    isUppercase: PropTypes.bool,
}
