import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import DialogDelete from '@/components/dialog-delete/DialogDelete.jsx'
import DialogSafeClose from '@/components/dialog-safe-close/DialogSafeClose.jsx'
import SidePopover from '@/components/side-popover/SidePopover.jsx'
import Spinner from '@/components/spinner/Spinner.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import {
    DEFAULT_CONTRACT_MOCK,
    validationSchema,
} from '@/pages/settings/settings-form/contract-form/contract.schema.js'
import {
    useDeleteContractMutation,
    useGetContractByIdQuery,
} from '@/services/settings/settingApiSlice.js'
import { selectPanelId } from '@/services/settings/settingsSlice'
import { handleApiError } from '@/utilities/api/helpers.js'

import ContractForm from './ContractForm'

const ContractFormWrapper = ({
    onClose,
    isEdit,
    openDialogToConfirm,
    setOpenDialogToConfirm,
    closeModal,
    openForm,
    contractID,
}) => {
    const panelId = useSelector(selectPanelId)

    const [openDialogToDelete, setOpenDialogToDelete] = useState(false)
    const { t } = useTranslation()
    const { triggerAlert } = useAlert()
    const showError = useError()

    const { data, isFetching } = useGetContractByIdQuery(
        {
            ID: panelId,
            contractID,
        },
        { skip: !panelId || !contractID }
    )

    const [deleteContract] = useDeleteContractMutation()

    const deleteUser = async () => {
        try {
            await deleteContract({
                ID: panelId,
                contractID,
            }).unwrap()
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: t('commons:dialog.success'),
            })
            onClose()
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        } finally {
            setOpenDialogToDelete(false)
        }
    }

    const props = {
        onClose,
        isEdit,
        openDialogToConfirm,
        setOpenDialogToConfirm,
        closeModal,
        openForm,
        data,
        setOpenDialogToDelete,
    }

    const methods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'onChange',
        shouldFocusError: true,
        resolver: yupResolver(validationSchema),
        defaultValues: DEFAULT_CONTRACT_MOCK,
    })

    const {
        formState: { isDirty },
    } = methods

    if (isFetching) {
        return (
            <Spinner
                overlay={false}
                overlayFullscreen={false}
                className={undefined}
            />
        )
    }

    return (
        <>
            <DialogDelete
                open={openDialogToDelete}
                onClose={() => setOpenDialogToDelete(false)}
                onDelete={deleteUser}
            />
            {openDialogToConfirm && (
                <DialogSafeClose
                    goBack={() => {
                        onClose()
                    }}
                    onClose={() => setOpenDialogToConfirm(false)}
                    open={openDialogToConfirm}
                />
            )}
            <FormProvider {...methods}>
                <SidePopover
                    onClose={() => closeModal(isDirty)}
                    isDirty={isDirty}
                    title={t(
                        isEdit
                            ? 'settings.actions.edit_contract'
                            : 'settings.actions.create_contract'
                    )}
                >
                    <ContractForm {...props} />
                </SidePopover>
            </FormProvider>
        </>
    )
}

export default ContractFormWrapper

ContractFormWrapper.propTypes = {
    onClose: PropTypes.func,
    isEdit: PropTypes.bool,
    isLoading: PropTypes.bool,
    setIsLoading: PropTypes.func,
    openDialogToConfirm: PropTypes.bool,
    setOpenDialogToConfirm: PropTypes.func,
    closeModal: PropTypes.func,
    openForm: PropTypes.object,
    data: PropTypes.object,
    contractID: PropTypes.number,
    userID: PropTypes.number,
}
