import PropTypes from 'prop-types'

import {
    StyledTabsRoot,
    StyledTabsList,
    StyledTabsTrigger,
    StyledTabsContent,
    StyledTabsSidePanelBody,
    StyledTabsBody,
    StyledDivTabs,
} from './styles'

//sidepanel={isSidePanel ? 1 : 0} is used to resolve a warning

const Tabs = ({
    triggers = [],
    defaultValue,
    onValueChange,
    disabled,
    isSidePanel = false,
    haveDivider = false,
    action,
}) => (
    <StyledTabsRoot defaultValue={defaultValue} onValueChange={onValueChange}>
        <StyledTabsList
            sidepanel={isSidePanel ? 1 : 0}
            tabs={triggers?.length || 0}
        >
            {triggers.map(trigger => {
                return (
                    <StyledTabsTrigger
                        value={trigger.value}
                        disabled={disabled}
                        key={trigger.id}
                        icon={trigger.icon}
                        sidepanel={isSidePanel ? 1 : 0}
                        onClick={trigger.action}
                    >
                        {trigger.icon}
                        <p>{trigger.label}</p>
                    </StyledTabsTrigger>
                )
            })}
        </StyledTabsList>
        {haveDivider && <StyledDivTabs />}

        {!action &&
            triggers.map(trigger => {
                return (
                    <StyledTabsContent
                        value={trigger.value}
                        key={trigger.id}
                        sidepanel={isSidePanel ? 1 : 0}
                    >
                        {isSidePanel ? (
                            <StyledTabsSidePanelBody>
                                {trigger.component}
                            </StyledTabsSidePanelBody>
                        ) : (
                            <StyledTabsBody>{trigger.component}</StyledTabsBody>
                        )}
                    </StyledTabsContent>
                )
            })}
    </StyledTabsRoot>
)

export default Tabs

Tabs.propTypes = {
    triggers: PropTypes.array,
    defaultValue: PropTypes.string,
    onValueChange: PropTypes.func,
    disabled: PropTypes.bool,
    isSidePanel: PropTypes.bool,
    haveDivider: PropTypes.bool,
    action: PropTypes.func,
}
