import * as PropTypes from 'prop-types'
import { forwardRef } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Controller } from 'react-hook-form'

import ErrorMessage from '@/components/error-message/ErrorMessage.jsx'
import StatusInputIcon from '@/components/input/status-input/StatusInput.jsx'

import {
    StyledContainer,
    StyledIcon,
    StyledInputWrapper,
    StyledLabel,
} from './styles'

const InputCurrency = forwardRef(function InputCurrency(
    {
        label,
        name,
        control,
        placeholder,
        helpText,
        icon,
        iconPosition = 'left',
        iconCallback,
        statusIcon,
        statusIconCallback,
        touched = false,
        errors,
        rootClassName,
        inputProps,
        disabled,
        allowDecimals = false,
        allowNegativeValue = false,
        decimalsLimit = 2,
        decimalScale = 2,
        fixedDecimalLength = 0,
        prefix,
        suffix,
        decimalSeparator = ',',
        groupSeparator = '.',
        maxLength,
        step = 1,
        required,
        size,
        width,
        ...rest
    },
    ref
) {
    return (
        <StyledContainer
            ref={ref}
            size={size}
            width={width}
            className={rootClassName}
        >
            {label && (
                <StyledLabel htmlFor={name} disabled={disabled}>
                    {label}
                    {required && '*'}
                </StyledLabel>
            )}
            <StyledInputWrapper
                iconPosition={iconPosition}
                touched={touched}
                hasError={!!errors}
                isSuccess={!errors && touched}
                disabled={disabled}
                size={size}
            >
                {icon && (
                    <StyledIcon onClick={iconCallback} disabled={disabled}>
                        {icon}
                    </StyledIcon>
                )}
                <Controller
                    control={control}
                    name={name}
                    render={({ field: { onChange, onBlur, ref } }) => (
                        <CurrencyInput
                            placeholder={placeholder}
                            onValueChange={onChange}
                            onBlur={onBlur}
                            disabled={disabled}
                            ref={ref}
                            allowDecimals={allowDecimals}
                            allowNegativeValue={allowNegativeValue}
                            decimalsLimit={decimalsLimit}
                            decimalScale={decimalScale}
                            fixedDecimalLength={fixedDecimalLength}
                            prefix={prefix}
                            suffix={suffix}
                            decimalSeparator={decimalSeparator}
                            groupSeparator={groupSeparator}
                            maxLength={maxLength}
                            step={step}
                            {...inputProps}
                            {...rest}
                        />
                    )}
                />
                {/*icon status*/}
                <StatusInputIcon
                    statusIcon={statusIcon}
                    statusIconCallback={statusIconCallback}
                    touched={touched}
                    errors={errors}
                />
            </StyledInputWrapper>

            {/*help text*/}
            <ErrorMessage errors={errors} helpText={helpText} />
        </StyledContainer>
    )
})

export default InputCurrency

InputCurrency.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    control: PropTypes.object,
    placeholder: PropTypes.string,
    helpText: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    icon: PropTypes.element,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    rounded: PropTypes.bool,
    iconCallback: PropTypes.func,
    statusIcon: PropTypes.element,
    statusIconCallback: PropTypes.func,
    touched: PropTypes.bool,
    errors: PropTypes.object,
    rootClassName: PropTypes.string,
    inputProps: PropTypes.object,
    disabled: PropTypes.bool,
    allowDecimals: PropTypes.bool,
    allowNegativeValue: PropTypes.bool,
    decimalsLimit: PropTypes.number,
    decimalScale: PropTypes.number,
    fixedDecimalLength: PropTypes.number,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    decimalSeparator: PropTypes.string,
    groupSeparator: PropTypes.string,
    maxLength: PropTypes.number,
    step: PropTypes.number,
    required: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    width: PropTypes.string,
}
