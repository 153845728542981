import styled, { css } from 'styled-components'

export const StyledBody = styled.div`
    ${({ theme }) => css`
        z-index: 1;
        width: 100%;
        height: calc(100vh - (${theme.headerHeight}px));
        overflow-x: hidden;
    `}
`

export const StyledLayout = styled.div`
    display: flex;
    height: 100%;
`
