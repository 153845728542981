import i18next from 'i18next'

import { LABELS_TABLES } from '@/utilities/constants/commons'
import { dateFormat, formatCurrency } from '@/utilities/helpers'

export const redFields = data => {
    return {
        info: [
            {
                label: i18next.t('general.status'),
                value: data?.is_active
                    ? i18next.t('project.sidebar_left.labels.active')
                    : i18next.t('project.sidebar_left.labels.notActive'),
                width: 50,
            },
            {
                label: i18next.t('general.typology'),
                value: LABELS_TABLES.rd,
                width: 50,
            },
            {
                label: i18next.t('project.sidebar_left.labels.start'),
                value: dateFormat(data?.start_date),
                width: 50,
            },
            {
                label: i18next.t('project.sidebar_left.labels.end'),
                value: dateFormat(data?.end_date),
                width: 50,
            },
        ],
        reporting: [
            {
                label: i18next.t('project.sidebar_left.labels.dayjs'),
                value: data?.days?.toString(),
                width: 50,
            },
            {
                label: i18next.t('project.sidebar_left.labels.price'),
                value: formatCurrency(data?.rate),
                width: 50,
            },
            {
                label: i18next.t('project.sidebar_left.labels.budget'),
                value: formatCurrency(data?.budget),
                width: 50,
            },
        ],
        link: [
            {
                label: i18next.t('project.sidebar_left.labels.management'),
                value: data?.drive_url_management,
                width: 100,
                link: true,
            },
            {
                label: i18next.t('project.sidebar_left.labels.operation'),
                value: data?.drive_url_operation,
                width: 100,
                link: true,
            },
        ],
        team: [
            {
                label: i18next.t('general.team_lead'),
                value: data?.team?.team_lead?.full_name,
                width: 100,
            },
        ],
    }
}
