import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    ProjectIcon,
    CustomerIcon,
    // HistoryIcon, (MERP-551)
} from '@/assets/icons/icons.jsx'
import Sidebar from '@/components/sidebar/Sidebar.jsx'
import Tabs from '@/components/tabs/Tabs.jsx'
import ApplicantSidebar from '@/pages/projects/detail/sidebar-detail/project/ApplicantSidebar.jsx'
import { LABELS_TABLES } from '@/utilities/constants/commons'

import BalanceSidebar from './project/BalanceSidebar'
import CustomerSidebar from './project/CustomerSiedbar'
import ReDSidebar from './project/ReDSidebar'
import StandardSidebar from './project/StandardSidebar'
// import StandardHistorySidebar from './project/history/StandardHistorySidebar' (MERP-551)

//REPLACE TYPE WITH KEY IN API
const SidebarDetail = ({ setDataForm, projectData }) => {
    const { t } = useTranslation()

    const [tabValue, setTabValue] = useState(t('general.project'))

    const sideBarProjectData = [
        {
            id: 1,
            value: t('general.project'),
            label: t('general.project'),
            icon: <ProjectIcon />,
            component: (
                <>
                    {projectData &&
                        projectData?.type === LABELS_TABLES.standardProject && (
                            <StandardSidebar
                                data={projectData}
                                setDataForm={setDataForm}
                            />
                        )}
                    {projectData &&
                        projectData?.type === LABELS_TABLES.balanceProject && (
                            <BalanceSidebar
                                data={projectData}
                                setDataForm={setDataForm}
                            />
                        )}
                    {projectData &&
                        projectData?.type === LABELS_TABLES.redProject && (
                            <ReDSidebar
                                data={projectData}
                                setDataForm={setDataForm}
                            />
                        )}
                </>
            ),
        },
        {
            id: 2,
            value:
                projectData?.type !== LABELS_TABLES.redProject
                    ? t('project.customer')
                    : t('project.applicant'),
            label:
                projectData?.type !== LABELS_TABLES.redProject
                    ? t('project.customer')
                    : t('project.applicant'),
            icon: <CustomerIcon />,
            component: (
                <>
                    {projectData &&
                    projectData?.type !== LABELS_TABLES.redProject ? (
                        <CustomerSidebar
                            data={projectData}
                            setDataForm={setDataForm}
                        />
                    ) : (
                        <>
                            <ApplicantSidebar
                                data={projectData?.applicant}
                                setDataForm={setDataForm}
                            />
                        </>
                    )}
                </>
            ),
        },
        // TODO: comment until further notice (MERP-551)
        // {
        //     id: 3,
        //     value: t('general.history'),
        //     label: t('general.history'),
        //     icon: <HistoryIcon />,
        //     component: <StandardHistorySidebar panelId={projectData?.id} />,
        // },
    ]

    return (
        <Sidebar
            openIcon={true}
            onClose={() => {
                setTabValue(t('general.project'))
            }}
            open={true}
        >
            <Tabs
                defaultValue={tabValue}
                triggers={sideBarProjectData}
                isSidePanel={true}
                haveDivider
                onValueChange={e => setTabValue(e)}
            />
        </Sidebar>
    )
}

export default SidebarDetail

SidebarDetail.propTypes = {
    isLoading: PropTypes.bool,
    projectData: PropTypes.object,
    setDataForm: PropTypes.func,
    type: PropTypes.string,
}
