import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { TrashIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import Datepicker from '@/components/date-picker/DatePicker.jsx'
import Input from '@/components/input/Input.jsx'
import Select from '@/components/select/Select.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import { StyledContainerFormActions } from '@/pages/customers/detail/customer-form/billing-form/styles.js'
import { StyledContainerDelete } from '@/pages/customers/detail/customer-tables/table-projects/projects-form/styles.js'
import {
    CONTRACT_MODEL,
    DEFAULT_CONTRACT_MOCK,
} from '@/pages/settings/settings-form/contract-form/contract.schema.js'
import { StyledForm } from '@/pages/settings/settings-form/contract-form/styles.js'
import {
    useCreateContractMutation,
    useEditContractMutation,
} from '@/services/settings/settingApiSlice'
import { formatDateFromApi, handleApiError } from '@/utilities/api/helpers.js'
import { CONTRACT_TYPE } from '@/utilities/constants/index.js'
import { CONTRACT_TYPE_OPTIONS } from '@/utilities/constants/list.js'
import {
    iterateOverDirtyFields,
    translateOptions,
    filterEmptyKeys,
    formatDate,
} from '@/utilities/helpers.js'

const ContractForm = ({
    onClose,
    isEdit,
    closeModal,
    openForm,
    data,
    setOpenDialogToDelete,
}) => {
    const { t } = useTranslation()
    const { triggerAlert } = useAlert()
    const showError = useError()

    const [createContract] = useCreateContractMutation()
    const [editContract] = useEditContractMutation()

    const {
        register,
        control,
        handleSubmit,
        reset,
        setError,
        setValue,
        formState: {
            errors,
            isDirty,
            touchedFields,
            dirtyFields,
            isSubmitting,
        },
    } = useFormContext()

    const onSubmit = async values => {
        try {
            const remappedData = {
                ...values,
                [CONTRACT_MODEL.contract]:
                    values[CONTRACT_MODEL.contract].value,
                [CONTRACT_MODEL.start_date]: formatDate(
                    values[CONTRACT_MODEL.start_date]
                ),
                [CONTRACT_MODEL.end_date]: formatDate(
                    values[CONTRACT_MODEL.end_date]
                ),
            }

            if (isEdit) {
                await editContract({
                    ID: openForm?.data?.memberId,
                    contractID: openForm?.data?.id,
                    data: iterateOverDirtyFields(dirtyFields, remappedData),
                }).unwrap()
            } else {
                const filteredData = filterEmptyKeys(remappedData)
                await createContract({
                    ID: openForm?.data?.memberId,
                    data: filteredData,
                }).unwrap()
            }
            onClose()
            reset(DEFAULT_CONTRACT_MOCK)
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: isEdit
                    ? t('commons:toast.updated_success')
                    : t('commons:toast.created_success'),
            })
        } catch (error) {
            handleApiError({
                error,
                handleGeneralError: showError,
                handleFormError: setError,
            })
        }
    }

    useEffect(() => {
        if (isEdit && data) {
            reset({
                ...data,
                [CONTRACT_MODEL.contract]: translateOptions(
                    CONTRACT_TYPE_OPTIONS
                ).find(
                    option => option.value === data[CONTRACT_MODEL.contract]
                ),
                [CONTRACT_MODEL.start_date]: formatDateFromApi(
                    data[CONTRACT_MODEL.start_date]
                ),
                [CONTRACT_MODEL.end_date]: formatDateFromApi(
                    data[CONTRACT_MODEL.end_date]
                ),
            })
        }
    }, [isEdit, data])

    const startDate = useWatch({
        control,
        name: CONTRACT_MODEL.start_date,
    })

    const haveContract = useWatch({
        control,
        name: CONTRACT_MODEL.contract,
    })

    const haveIdentifier = useWatch({
        control,
        name: CONTRACT_MODEL.identifier,
    })

    const minEndDate = useMemo(() => {
        const date = new Date(startDate)
        date.setDate(date.getDate() + 1)
        return date
    }, [startDate])

    return (
        <StyledForm
            isEdit={isEdit}
            onSubmit={handleSubmit(onSubmit)}
            className="container-fluid p-0 d-grid"
        >
            <div className="row">
                <div className="col-12">
                    <Select
                        required
                        name={CONTRACT_MODEL.contract}
                        control={control}
                        touched={touchedFields[CONTRACT_MODEL.contract]}
                        errors={errors[CONTRACT_MODEL.contract]}
                        label={t(`general.typology`)}
                        placeholder={t(`settings.fields.placeholder.contract`)}
                        size="large"
                        options={translateOptions(CONTRACT_TYPE_OPTIONS)}
                        onChangeCallback={() => {
                            setValue(CONTRACT_MODEL.identifier, '')
                        }}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Input
                        label={t(
                            haveContract?.value === CONTRACT_TYPE.employee
                                ? `settings.fields.label.employee_number`
                                : 'general.vat_number'
                        )}
                        placeholder={t(
                            `settings.fields.placeholder.${
                                haveContract?.value === CONTRACT_TYPE.employee
                                    ? 'employee_number'
                                    : 'vat_number'
                            }`
                        )}
                        touched={
                            touchedFields[CONTRACT_MODEL.identifier] &&
                            haveIdentifier !== '' &&
                            haveIdentifier !== null
                        }
                        errors={errors[CONTRACT_MODEL.identifier]}
                        {...register(CONTRACT_MODEL.identifier, {
                            onChange: e => {
                                if (
                                    haveContract?.value ===
                                    CONTRACT_TYPE.employee
                                ) {
                                    setValue(
                                        CONTRACT_MODEL.identifier,
                                        e?.target?.value.replace(
                                            /[a-zA-Z]/g,
                                            ''
                                        )
                                    )
                                } else {
                                    setValue(
                                        CONTRACT_MODEL.identifier,
                                        e?.target?.value
                                    )
                                }
                            },
                        })}
                        size="large"
                        width={'100%'}
                        inputProps={{
                            maxLength:
                                haveContract?.value === CONTRACT_TYPE.employee
                                    ? 10
                                    : 20,
                        }}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <Datepicker
                        required
                        name={CONTRACT_MODEL.start_date}
                        label={t(`settings.fields.label.contract_start_date`)}
                        placeholder={t(
                            `settings.fields.placeholder.contract_start_date`
                        )}
                        control={control}
                        touched={touchedFields[CONTRACT_MODEL.start_date]}
                        errors={errors[CONTRACT_MODEL.start_date]}
                        showMonthDropdown
                        showYearDropdown
                        pickerType={'days'}
                        className="mb-2"
                        minDate={new Date(2013, 0, 1)}
                    />
                </div>
                <div className="col-6">
                    <Datepicker
                        name={CONTRACT_MODEL.end_date}
                        label={t(`settings.fields.label.contract_end_date`)}
                        placeholder={t(
                            `settings.fields.placeholder.contract_end_date`
                        )}
                        control={control}
                        touched={touchedFields[CONTRACT_MODEL.end_date]}
                        errors={errors[CONTRACT_MODEL.end_date]}
                        showMonthDropdown
                        showYearDropdown
                        pickerType={'days'}
                        className="mb-2"
                        minDate={minEndDate}
                        disabled={!isEdit && !startDate}
                    />
                </div>
            </div>

            {isEdit && (
                <div className="row">
                    <StyledContainerDelete className="col-12">
                        <TrashIcon />
                        <p
                            onClick={() => {
                                setOpenDialogToDelete(true)
                            }}
                        >
                            {t('settings.actions.delete_contract')}
                        </p>
                    </StyledContainerDelete>
                </div>
            )}

            <StyledContainerFormActions className="row d-flex">
                <div className="col-4">
                    <Button
                        size="large"
                        color={'neutral'}
                        isUppercase
                        type={'button'}
                        onClick={() => closeModal(isDirty)}
                    >
                        {t('commons:button.cancel')}
                    </Button>
                </div>
                <div className="col-8">
                    <Button
                        type="submit"
                        size="large"
                        isUppercase
                        disabled={(!isDirty && isEdit) || isSubmitting}
                    >
                        {isEdit
                            ? t('commons:button.save')
                            : t('commons:button.create')}
                    </Button>
                </div>
            </StyledContainerFormActions>
        </StyledForm>
    )
}

export default ContractForm

ContractForm.propTypes = {
    onClose: PropTypes.func,
    isEdit: PropTypes.bool,
    isLoading: PropTypes.bool,
    openDialogToConfirm: PropTypes.bool,
    setOpenDialogToConfirm: PropTypes.func,
    closeModal: PropTypes.func,
    openForm: PropTypes.object,
    data: PropTypes.object,
    setOpenDialogToDelete: PropTypes.func,
}
