import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import { StyledCalendarDay } from '../styles'

const TableHeader = ({ selectedMonth, selectedYear, days, currentData }) => {
    return days?.map(day => {
        const date = new Date(selectedYear, selectedMonth - 1, day)
        const dayName = new Intl.DateTimeFormat('it-IT', {
            weekday: 'narrow',
        }).format(date)

        const isDayAllocated = currentData?.map(item => {
            return Object.values(item.allocations).map(allocation => {
                return Object.entries(allocation).map(([key, value]) => {
                    if (value?.allocated_hours > 0) {
                        return +dayjs(key).format('D')
                    }
                })
            })
        })

        return (
            <StyledCalendarDay
                key={day}
                holiday={dayName === 'S' || dayName === 'D'}
                large={isDayAllocated?.flat(3)?.includes(day)}
            >
                <p>{dayName}</p>
                <p>{day}</p>
            </StyledCalendarDay>
        )
    })
}

export default TableHeader

TableHeader.propTypes = {
    selectedMonth: PropTypes.number,
    selectedYear: PropTypes.number,
    days: PropTypes.array,
    currentData: PropTypes.array,
}
