import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { TrashIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import Datepicker from '@/components/date-picker/DatePicker.jsx'
import Divider from '@/components/divider/Divider.jsx'
import Input from '@/components/input/Input.jsx'
import PhoneNumberInput from '@/components/phone-number-input/PhoneNumberInput.jsx'
import CreateSelect from '@/components/select/CreateSelect'
import Select from '@/components/select/Select.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import { StyledContainerFormActions } from '@/pages/customers/detail/customer-form/billing-form/styles.js'
import { StyledContainerDelete } from '@/pages/customers/detail/customer-tables/table-projects/projects-form/styles.js'
import {
    DEFAULT_MEMBER_MOCK,
    MEMBER_MODEL,
} from '@/pages/settings/settings-form/member-form/members.schema'
import { StyledForm } from '@/pages/settings/settings-form/member-form/styles.js'
import {
    useCreateMemberMutation,
    useCreateSpecificitiesMutation,
    useEditMemberMutation,
    useGetSpecificityByIdQuery,
} from '@/services/settings/settingApiSlice.js'
import {
    formatDateFromApi,
    handleApiError,
    remapSpecificitiesOptions,
} from '@/utilities/api/helpers.js'
import {
    ACCESS_OPTIONS,
    ROLE_OPTIONS,
    STATUS_OPTIONS,
    SUCCESS_MANAGER_OPTIONS,
} from '@/utilities/constants/list.js'
import {
    iterateOverDirtyFields,
    retrieveSingleValueForRs,
    translateOptions,
    filterEmptyKeys,
    legalAge,
    formatDate,
} from '@/utilities/helpers.js'

const MemberForm = ({
    onClose,
    isEdit,
    data,
    openForm,
    closeModal,
    responsibilities,
    setOpenDialogToDelete,
    scopes,
    canNotDelete,
}) => {
    const { t } = useTranslation()
    const { triggerAlert } = useAlert()
    const showError = useError()
    const [scopeSelected, setScopeSelected] = useState(null)

    const { data: specificities } = useGetSpecificityByIdQuery(
        {
            ID: scopeSelected,
            queryKey: [MEMBER_MODEL.specificity, scopeSelected],
        },
        { skip: !scopeSelected }
    )

    const [isLoading, setIsLoading] = useState(false)

    const [createMember] = useCreateMemberMutation()
    const [editMember] = useEditMemberMutation()
    const [createSpecificity] = useCreateSpecificitiesMutation({})

    const {
        register,
        control,
        handleSubmit,
        reset,
        setError,
        setValue,
        getValues,
        formState: {
            errors,
            isDirty,
            touchedFields,
            dirtyFields,
            isSubmitting,
        },
    } = useFormContext()

    const status = getValues(MEMBER_MODEL.status)
    const haveAccess = getValues(MEMBER_MODEL.can_access)
    const specificity = getValues(MEMBER_MODEL.specificity)
    const onSubmit = async data => {
        const remappedData = {
            ...data,
            [MEMBER_MODEL.can_access]:
                haveAccess?.value || data[MEMBER_MODEL.can_access]?.value,
            [MEMBER_MODEL.role]: data[MEMBER_MODEL.role]?.value || null,
            [MEMBER_MODEL.scope]: data[MEMBER_MODEL.scope]?.value || null,
            [MEMBER_MODEL.specificity]:
                specificity?.length > 0
                    ? data[MEMBER_MODEL.specificity]?.map(
                          specificity => specificity.value
                      )
                    : null,
            [MEMBER_MODEL.responsibility]:
                data[MEMBER_MODEL.responsibility]?.value || null,
            [MEMBER_MODEL.success_manager]:
                data[MEMBER_MODEL.success_manager]?.value,

            [MEMBER_MODEL.phone_number]: data[MEMBER_MODEL.phone_number]
                ? `+${data[MEMBER_MODEL.phone_number]}`
                : null,
            [MEMBER_MODEL.status]: data[MEMBER_MODEL.status]?.value,
            [MEMBER_MODEL.birth_date]: formatDate(
                data[MEMBER_MODEL.birth_date]
            ),
        }

        try {
            if (isEdit) {
                await editMember({
                    ID: openForm.id,
                    data: iterateOverDirtyFields(dirtyFields, remappedData),
                }).unwrap()
            } else {
                const filteredData = filterEmptyKeys(remappedData)
                await createMember({ data: filteredData }).unwrap()
            }
            onClose()
            reset(DEFAULT_MEMBER_MOCK)
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: isEdit
                    ? t('commons:toast.updated_success')
                    : t('commons:toast.created_success'),
            })
        } catch (error) {
            handleApiError({
                error,
                handleGeneralError: showError,
                handleFormError: setError,
            })
        }
    }

    useEffect(() => {
        if (isEdit && data) {
            reset({
                ...data,

                [MEMBER_MODEL.birth_date]: formatDateFromApi(
                    data[MEMBER_MODEL.birth_date]
                ),
                [MEMBER_MODEL.can_access]: retrieveSingleValueForRs(
                    translateOptions(ACCESS_OPTIONS),
                    data[MEMBER_MODEL.can_access]
                ),
                [MEMBER_MODEL.role]: retrieveSingleValueForRs(
                    translateOptions(ROLE_OPTIONS),
                    data[MEMBER_MODEL.role]
                ),
                [MEMBER_MODEL.scope]: retrieveSingleValueForRs(
                    scopes,
                    data?.scope?.id
                ),
                [MEMBER_MODEL.success_manager]: retrieveSingleValueForRs(
                    translateOptions(SUCCESS_MANAGER_OPTIONS),
                    data[MEMBER_MODEL.success_manager]
                ),
                [MEMBER_MODEL.responsibility]: retrieveSingleValueForRs(
                    responsibilities,
                    data?.responsibility?.id
                ),
                [MEMBER_MODEL.status]: retrieveSingleValueForRs(
                    translateOptions(STATUS_OPTIONS),
                    data[MEMBER_MODEL.status]
                ),
            })
        }
    }, [isEdit, data])

    const haveScope = getValues(MEMBER_MODEL.scope)

    const haveMail = useWatch({
        control,
        name: MEMBER_MODEL.personal_email,
    })

    const haveWorkMail = useWatch({
        control,
        name: MEMBER_MODEL.work_email,
    })

    const havePhone = useWatch({
        control,
        name: MEMBER_MODEL.phone_number,
    })

    useEffect(() => {
        if (!haveScope) {
            setValue(MEMBER_MODEL.specificity, [])
        }
    }, [haveScope])

    useEffect(() => {
        if (!!data?.scope && isEdit) {
            setScopeSelected(data?.scope?.id)
        }

        if (data?.specificities?.length > 0) {
            setValue(
                MEMBER_MODEL.specificity,
                data?.specificities?.map(
                    specificity => ({
                        label: specificity.key_name,
                        value: specificity.id,
                    }),
                    {
                        shouldDirty: true,
                    }
                )
            )
        }
    }, [data, isEdit])

    const handleCreate = async inputValue => {
        setIsLoading(true)
        try {
            const { data } = await createSpecificity({
                ID: scopeSelected,
                data: {
                    key_name: inputValue,
                },
            })
            setValue(
                MEMBER_MODEL.specificity,
                [
                    ...getValues(MEMBER_MODEL.specificity),
                    {
                        label: data.key_name,
                        value: data.id,
                    },
                ],
                {
                    shouldDirty: true,
                }
            )
        } catch (error) {
            handleApiError({
                error,
                handleGeneralError: showError,
            })
        } finally {
            setIsLoading(false)
        }
    }

    const onValueChange = e => {
        if (e?.value) {
            setScopeSelected(e?.value)
            setValue(MEMBER_MODEL.specificity, [])
        }
    }

    useEffect(() => {
        if (status?.value === false) {
            setValue(
                MEMBER_MODEL.can_access,
                translateOptions(ACCESS_OPTIONS)[1],
                {
                    shouldDirty: true,
                }
            )
        }
    }, [status])

    return (
        <StyledForm
            isEdit={isEdit}
            onSubmit={handleSubmit(onSubmit)}
            className="container-fluid p-0 d-grid"
        >
            <div className="row">
                <div className="col-6">
                    <Input
                        required
                        label={t(`general.name`)}
                        placeholder={t(`settings.fields.placeholder.name`)}
                        width="100%"
                        size={'large'}
                        touched={touchedFields[MEMBER_MODEL.name]}
                        errors={errors[MEMBER_MODEL.name]}
                        {...register(MEMBER_MODEL.name)}
                    />
                </div>

                <div className="col-6">
                    <Input
                        required
                        label={t(`general.surname`)}
                        placeholder={t(`settings.fields.placeholder.surname`)}
                        width="100%"
                        size={'large'}
                        touched={touchedFields[MEMBER_MODEL.surname]}
                        errors={errors[MEMBER_MODEL.surname]}
                        {...register(MEMBER_MODEL.surname)}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Datepicker
                        name={MEMBER_MODEL.birth_date}
                        label={t(`general.birth_date`)}
                        placeholder={t(
                            `settings.fields.placeholder.birth_date`
                        )}
                        control={control}
                        touched={touchedFields[MEMBER_MODEL.birth_date]}
                        errors={errors[MEMBER_MODEL.birth_date]}
                        showMonthDropdown
                        showYearDropdown
                        pickerType={'days'}
                        className="mb-2"
                        ref={{ ...register(MEMBER_MODEL.birth_date) }}
                        maxDate={legalAge(true)}
                        minDate={legalAge(false)}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <PhoneNumberInput
                        label={t(`settings.fields.label.phone_number`)}
                        placeholder={t(
                            `settings.fields.placeholder.phone_number`
                        )}
                        touched={
                            touchedFields[MEMBER_MODEL.phone_number] &&
                            havePhone !== '' &&
                            havePhone !== null
                        }
                        errors={errors[MEMBER_MODEL.phone_number]}
                        control={control}
                        name={MEMBER_MODEL.phone_number}
                    />
                </div>

                <div className="col-6">
                    <Input
                        label={t(`general.personal_email`)}
                        placeholder={t(
                            `settings.fields.placeholder.personal_email`
                        )}
                        width="100%"
                        size={'large'}
                        touched={
                            touchedFields[MEMBER_MODEL.personal_email] &&
                            haveMail !== '' &&
                            haveMail !== null
                        }
                        errors={errors[MEMBER_MODEL.personal_email]}
                        {...register(MEMBER_MODEL.personal_email)}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Divider
                        orientation={'horizontal'}
                        width={'100%'}
                        height={'1px'}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Select
                        required
                        name={MEMBER_MODEL.status}
                        label={t('general.status')}
                        placeholder={t('projects.fields.placeholder.select')}
                        control={control}
                        options={translateOptions(STATUS_OPTIONS)}
                        touched={touchedFields[MEMBER_MODEL.status]}
                        errors={errors[MEMBER_MODEL.status]}
                        size="large"
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <Select
                        required
                        name={MEMBER_MODEL.can_access}
                        label={t(`general.can_access`)}
                        placeholder={t(
                            `settings.fields.placeholder.can_access`
                        )}
                        control={control}
                        options={translateOptions(ACCESS_OPTIONS)}
                        touched={touchedFields[MEMBER_MODEL.can_access]}
                        errors={errors[MEMBER_MODEL.can_access]}
                        size="large"
                        disabled={status?.value === false}
                    />
                </div>
                <div className="col-6">
                    <Select
                        name={MEMBER_MODEL.role}
                        label={t(`general.role`)}
                        placeholder={t(`settings.fields.placeholder.role`)}
                        options={translateOptions(ROLE_OPTIONS)}
                        touched={touchedFields[MEMBER_MODEL.role]}
                        errors={errors[MEMBER_MODEL.role]}
                        control={control}
                        isClearable={!haveAccess?.value}
                        required={haveAccess?.value}
                        disabled={haveAccess?.value === false}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <Select
                        name={MEMBER_MODEL.success_manager}
                        label={t(`general.success_manager`)}
                        options={translateOptions(SUCCESS_MANAGER_OPTIONS)}
                        placeholder={t(`general.success_manager`)}
                        control={control}
                        touched={touchedFields[MEMBER_MODEL.success_manager]}
                        errors={errors[MEMBER_MODEL.success_manager]}
                        required
                    />
                </div>
                <div className="col-6">
                    <Select
                        name={MEMBER_MODEL.responsibility}
                        label={t('rd.fields.labels.responsibility')}
                        placeholder={t('projects.fields.placeholder.select')}
                        control={control}
                        options={responsibilities}
                        touched={touchedFields[MEMBER_MODEL.responsibility]}
                        errors={errors[MEMBER_MODEL.responsibility]}
                        size="large"
                        isClearable={!haveAccess?.value}
                        required={haveAccess?.value}
                        disabled={haveAccess?.value === false}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Divider
                        orientation={'horizontal'}
                        width={'100%'}
                        height={'1px'}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <Select
                        name={MEMBER_MODEL.scope}
                        label={t(`general.scope`)}
                        placeholder={t(`settings.fields.placeholder.scope`)}
                        control={control}
                        options={scopes}
                        touched={touchedFields[MEMBER_MODEL.scope]}
                        errors={errors[MEMBER_MODEL.scope]}
                        isClearable
                        onChangeCallback={e => onValueChange(e)}
                    />
                </div>

                <div className="col-6">
                    <CreateSelect
                        label={t(`general.specificity`)}
                        placeholder={t(
                            `settings.fields.placeholder.specificity`
                        )}
                        isMulti
                        options={remapSpecificitiesOptions(specificities)}
                        hideSelectedOptions={false}
                        disabled={!haveScope || isLoading}
                        isClearable
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        onCreateOption={handleCreate}
                        control={control}
                        name={MEMBER_MODEL.specificity}
                        touched={touchedFields[MEMBER_MODEL.specificity]}
                        errors={errors[MEMBER_MODEL.specificity]}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Input
                        label={t(`general.work_email`)}
                        placeholder={t(`general.work_email`)}
                        width="100%"
                        size={'large'}
                        touched={
                            touchedFields[MEMBER_MODEL.work_email] &&
                            haveWorkMail !== '' &&
                            haveWorkMail !== null
                        }
                        errors={errors[MEMBER_MODEL.work_email]}
                        {...register(MEMBER_MODEL.work_email)}
                    />
                </div>
            </div>

            {isEdit && (
                <div className="row">
                    {!canNotDelete && (
                        <StyledContainerDelete className="col-12">
                            <TrashIcon />
                            <p
                                onClick={() => {
                                    setOpenDialogToDelete(true)
                                }}
                            >
                                {t(`settings.actions.delete_member`)}
                            </p>
                        </StyledContainerDelete>
                    )}
                </div>
            )}

            <StyledContainerFormActions className="row d-flex ">
                <div className="col-4">
                    <Button
                        size="large"
                        color={'neutral'}
                        isUppercase
                        type={'button'}
                        onClick={() => closeModal(isDirty)}
                    >
                        {t('commons:button.cancel')}
                    </Button>
                </div>
                <div className="col-8">
                    <Button
                        type={'submit'}
                        size="large"
                        isUppercase
                        disabled={(!isDirty && isEdit) || isSubmitting}
                    >
                        {isEdit
                            ? t('commons:button.save')
                            : t('commons:button.create')}
                    </Button>
                </div>
            </StyledContainerFormActions>
        </StyledForm>
    )
}

export default MemberForm

MemberForm.propTypes = {
    onClose: PropTypes.func,
    isEdit: PropTypes.bool,
    data: PropTypes.object,
    openForm: PropTypes.object,
    openDialogToConfirm: PropTypes.bool,
    setOpenDialogToConfirm: PropTypes.func,
    closeModal: PropTypes.func,
    scopes: PropTypes.array,
    responsibilities: PropTypes.array,
    setOpenDialogToDelete: PropTypes.func,
    canNotDelete: PropTypes.bool,
}
