import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import DialogDelete from '@/components/dialog-delete/DialogDelete.jsx'
import DialogSafeClose from '@/components/dialog-safe-close/DialogSafeClose.jsx'
import Spinner from '@/components/spinner/Spinner.jsx'
import SubHeader from '@/components/subheader/SubHeader.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import ProjectsFilters from '@/pages/projects/list/projects-filters/ProjectsFilters.jsx'
import ProjectsForm from '@/pages/projects/list/projects-form/ProjectsForm.jsx'
import ProjectTabs from '@/pages/projects/list/projects-tabs/ProjectTabs.jsx'
import { StyledContainerFluid } from '@/pages/settings/styles.js'
import { useGetCustomersListQuery } from '@/services/customers/customersApiSlice.js'
import {
    useDeleteFinalBalanceProjectMutation,
    useGetFinalBalanceProjectsQuery,
} from '@/services/projects/finalBalanceApiSlice.js'
import {
    useDeleteProjectMutation,
    useGetProjectsQuery,
} from '@/services/projects/projectsApiSlice.js'
import {
    useDeleteRDProjectMutation,
    useGetRDProjectsQuery,
} from '@/services/projects/r&dApiSlice.js'
import {
    useGetMembersQuery,
    useGetResponsibilitiesQuery,
    useGetTeamsQuery,
} from '@/services/settings/settingApiSlice.js'
import {
    handleApiError,
    remapAPIForResponsibilities,
    remapSelectOptions,
} from '@/utilities/api/helpers.js'
import {
    ROUTE_FINAL_BALANCE,
    ROUTE_PROJECTS,
    ROUTE_RD,
    ROUTE_STANDARD_PROJECTS,
} from '@/utilities/constants/index.js'

const ProjectsList = () => {
    const { t } = useTranslation()
    const showError = useError()
    const { triggerAlert } = useAlert()
    const location = useLocation()
    const projectsRoute = `${ROUTE_PROJECTS}/${ROUTE_STANDARD_PROJECTS}`
    const finalBalanceRoute = `${ROUTE_PROJECTS}/${ROUTE_FINAL_BALANCE}`
    const rdRoute = `${ROUTE_PROJECTS}/${ROUTE_RD}`

    const [idSelected, setIdSelected] = useState(null)
    const [selectedTab, setSelectedTab] = useState(null)

    const [openDialogToDelete, setOpenDialogToDelete] = useState(false)
    const [openDialogToConfirm, setOpenDialogToConfirm] = useState(false)

    const [page, setPage] = useState(1)

    const [dataForm, setDataForm] = useState({
        isOpen: false,
        typeForm: null,
        id: null,
        isEdit: false,
        data: null,
    })

    const [filter, setFilter] = useState({
        is_active: null,
        team_id: null,
        success_manager_id: null,
    })

    const [deleteProject] = useDeleteProjectMutation()
    const [deleteFinalBalance] = useDeleteFinalBalanceProjectMutation()
    const [deleteRDProject] = useDeleteRDProjectMutation()

    const { data: successManagers } = useGetMembersQuery(
        { is_success_manager: true },
        { skip: location.pathname === rdRoute }
    )
    const { data: customers } = useGetCustomersListQuery()
    const { data: standardProjects, isFetching: isFetchingStandardProjects } =
        useGetProjectsQuery(
            { currentPage: page, ...filter },
            { skip: location.pathname !== projectsRoute }
        )
    const { data: finalBalance, isFetching: isFetchingFinalBalance } =
        useGetFinalBalanceProjectsQuery(
            { currentPage: page, ...filter },
            { skip: location.pathname !== finalBalanceRoute }
        )
    const { data: rdData, isFetching: isFetchingRd } = useGetRDProjectsQuery(
        { currentPage: page, ...filter },
        { skip: location.pathname !== rdRoute }
    )

    const { data: responsibilities } = useGetResponsibilitiesQuery({})
    const { data: teams } = useGetTeamsQuery()
    const { data: applicants } = useGetMembersQuery(
        {},
        { skip: location.pathname !== rdRoute }
    )

    useEffect(() => {
        setPage(1)
    }, [])

    const openDialogDelete = id => {
        setIdSelected(id)
        setOpenDialogToDelete(true)
    }
    const handleDeleteProject = async () => {
        try {
            switch (location.pathname) {
                case finalBalanceRoute:
                    await deleteFinalBalance({
                        ID: idSelected,
                    }).unwrap()
                    break
                case rdRoute:
                    await deleteRDProject({
                        id: idSelected,
                    }).unwrap()
                    break
                default:
                    await deleteProject({
                        ID: idSelected,
                    }).unwrap()
            }
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: t('commons:dialog.success'),
            })
            setDataForm({})
            setOpenDialogToDelete(false)
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        } finally {
            setOpenDialogToDelete(false)
        }
    }

    const isLoading =
        isFetchingStandardProjects || isFetchingFinalBalance || isFetchingRd

    return (
        <>
            {isLoading && <Spinner />}
            <StyledContainerFluid className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-4">
                        <SubHeader title={t('general.projects')}>
                            <ProjectsFilters
                                setDataForm={setDataForm}
                                selectedTab={selectedTab}
                                teamLead={remapSelectOptions(teams?.data)}
                                successManagers={remapSelectOptions(
                                    successManagers?.data
                                )}
                                applicants={remapSelectOptions(
                                    applicants?.data
                                )}
                                setFilter={setFilter}
                                filter={filter}
                            />
                        </SubHeader>
                    </div>

                    <div className="col-12">
                        <ProjectTabs
                            projects={standardProjects?.data}
                            finalBalance={finalBalance?.data}
                            rdData={rdData?.data}
                            setDataForm={setDataForm}
                            openDialogDelete={openDialogDelete}
                            setSelectedTab={setSelectedTab}
                        />
                    </div>
                </div>

                <DialogDelete
                    open={openDialogToDelete}
                    onClose={() => setOpenDialogToDelete(false)}
                    onDelete={handleDeleteProject}
                />

                {openDialogToConfirm && (
                    <DialogSafeClose
                        goBack={() => {
                            setDataForm({})
                            setOpenDialogToConfirm(false)
                        }}
                        onClose={() => setOpenDialogToConfirm(false)}
                        open={openDialogToConfirm}
                    />
                )}

                {dataForm.isOpen && (
                    <ProjectsForm
                        dataForm={dataForm}
                        openDialogDelete={openDialogDelete}
                        setOpenDialogToConfirm={setOpenDialogToConfirm}
                        onClose={() => setDataForm({})}
                        applicants={remapSelectOptions(applicants?.data)}
                        customers={remapSelectOptions(customers?.data)}
                        teams={teams?.data}
                        successManagers={remapSelectOptions(
                            successManagers?.data
                        )}
                        responsibilities={remapAPIForResponsibilities(
                            responsibilities
                        )}
                    />
                )}
            </StyledContainerFluid>
        </>
    )
}

export default ProjectsList
