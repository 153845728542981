import PropTypes from 'prop-types'

import AddMembersWrapper from '@/pages/customers/log/LogForm/AddMembers/AddMembersWrapper.jsx'
import AllocationFormWrapper from '@/pages/projects/detail/detail-final-balance/final-balance-form/allocation-form/AllocationFormWrapper.jsx'
import FinalBalanceWrapper from '@/pages/projects/list/projects-form/final-balance-form/FinalBalanceWrapper.jsx'
import { remapSelectOptions } from '@/utilities/api/helpers.js'
import { LABELS_TABLES } from '@/utilities/constants/index.js'

const RenderLogForm = ({
    dataForm,
    setDataForm,
    members,
    onClose,
    finalBalance,
    allocations,
    setOpenDialogToConfirm,
    customers,
    openDialogDelete,
    teams,
    userAllocated,
}) => {
    const closeModal = isDirty => {
        if (isDirty) {
            setOpenDialogToConfirm(true)
        } else {
            onClose()
        }
    }

    const successManagerOptions = members?.data?.filter(
        successManager => successManager?.is_success_manager
    )

    const props = {
        dataForm,
        onClose,
        setDataForm,
        finalBalance,
        allocations,
        openDialogDelete,
        members: members?.data,
        closeModal,
        customers,
        teams,
        successManagers: remapSelectOptions(successManagerOptions),
        canNotDelete: true,
        userAllocated,
    }

    switch (dataForm.typeForm) {
        case LABELS_TABLES.final_balances:
            return <FinalBalanceWrapper {...props} />
        case 'allocation':
            return <AllocationFormWrapper {...props} />
        case 'modifyMembers':
            return <AddMembersWrapper {...props} />
        default:
            return null
    }
}

export default RenderLogForm

RenderLogForm.propTypes = {
    dataForm: PropTypes.object,
    setDataForm: PropTypes.func,
    members: PropTypes.object,
    onClose: PropTypes.func,
    finalBalance: PropTypes.object,
    allocations: PropTypes.array,
    openDialogDelete: PropTypes.func,
    setOpenDialogToConfirm: PropTypes.func,
    customers: PropTypes.array,
    teams: PropTypes.array,
    successManagers: PropTypes.array,
    userAllocated: PropTypes.array,
}
