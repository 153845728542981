import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import SidePopover from '@/components/side-popover/SidePopover.jsx'
import RandDForm from '@/pages/projects/list/projects-form/r&d-form/R&DForm.jsx'
import {
    DEFAULT_RD_MOCK,
    validationSchema,
} from '@/pages/projects/list/projects-form/r&d-form/r&dForm.schema.js'

const RAndDWrapper = ({
    dataForm,
    openDialogDelete,
    closeModal,
    applicants,
    teams,
    responsibilities,
    canNotDelete,
}) => {
    const { t } = useTranslation()

    const methods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'onChange',
        nativeValidation: false,
        shouldFocusError: true,
        resolver: yupResolver(validationSchema),
        defaultValues: DEFAULT_RD_MOCK,
    })

    const {
        formState: { isDirty },
    } = methods

    return (
        <FormProvider {...methods}>
            <SidePopover
                onClose={() => closeModal(isDirty)}
                isDirty={isDirty}
                title={
                    dataForm?.isEdit ? t(`rd.edit_title`) : t(`rd.create_title`)
                }
            >
                <RandDForm
                    dataForm={dataForm}
                    isEdit={dataForm?.isEdit}
                    openDialogDelete={openDialogDelete}
                    teams={teams}
                    responsibilities={responsibilities}
                    closeModal={closeModal}
                    applicants={applicants}
                    canNotDelete={canNotDelete}
                />
            </SidePopover>
        </FormProvider>
    )
}

export default RAndDWrapper

RAndDWrapper.propTypes = {
    onClose: PropTypes.func,
    dataForm: PropTypes.object,
    setIsLoading: PropTypes.func,
    openDialogDelete: PropTypes.func,
    closeModal: PropTypes.func,
    applicants: PropTypes.array,
    teams: PropTypes.array,
    responsibilities: PropTypes.array,
    canNotDelete: PropTypes.bool,
}
