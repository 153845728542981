import i18next from 'i18next'
import { Link } from 'react-router-dom'

import { ActionsIcon, EditIcon, TrashIcon } from '@/assets/icons/icons.jsx'
import Chip from '@/components/chip/Chip.jsx'
import Dropdown from '@/components/dropdown/Dropdown.jsx'
import {
    COMMON_DATE_FORMAT,
    GENERAL_STATUSES,
    LABELS_TABLES,
    PROJECT_STATUS,
    PROJECT_TYPE,
    ROUTE_FINAL_BALANCE,
    ROUTE_RD,
    ROUTE_STANDARD_PROJECTS,
} from '@/utilities/constants/index.js'
import {
    getHumanReadableDate,
    formatCurrency,
    capitalize,
} from '@/utilities/helpers.js'

/* eslint-disable */

export const COLUMNS_PROJECTS_NAMES = {
    id: 'id',
    name: 'name',
    team: 'team',
    typology: 'typology',
    status: 'status',
    start_date: 'start_date',
    end_date: 'end_date',
    budget: 'budget',
    actions: 'actions',
}
export const columnsProjects = () => {
    return [
        {
            alignment: 'left',
            label: i18next.t('general.id'),
            name: COLUMNS_PROJECTS_NAMES.id,
            width: '5%',
            sortable: false,
            divider: false,
        },
        {
            alignment: 'left',
            label: i18next.t('general.project'),
            name: COLUMNS_PROJECTS_NAMES.name,
            sortable: false,
            width: '33%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.team'),
            name: COLUMNS_PROJECTS_NAMES.team,
            sortable: false,
            width: '14%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.typology'),
            name: COLUMNS_PROJECTS_NAMES.typology,
            sortable: false,
            width: '10%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.status'),
            name: COLUMNS_PROJECTS_NAMES.status,
            sortable: false,
            width: '6%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('customer.tables.projects.start_date'),
            name: COLUMNS_PROJECTS_NAMES.start_date,
            sortable: true,
            width: '10%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('customer.tables.projects.end_date'),
            name: COLUMNS_PROJECTS_NAMES.end_date,
            sortable: true,
            width: '10%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('customer.tables.projects.budget'),
            name: COLUMNS_PROJECTS_NAMES.budget,
            sortable: false,
            width: '10%',
            divider: true,
        },
        {
            alignment: 'space-evenly',
            label: ' ',
            name: COLUMNS_PROJECTS_NAMES.actions,
            sortable: false,
            width: '2%',
            divider: true,
        },
    ]
}

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersProjects = (
    openDialogDelete,
    openEditSidebar
) => ({
    [COLUMNS_PROJECTS_NAMES.id]: ({ global_project_id }) => (
        <p>#{global_project_id}</p>
    ),
    [COLUMNS_PROJECTS_NAMES.name]: data => {
        const renderTypeProject = () => {
            switch (data?.type) {
                case PROJECT_TYPE.standard:
                    return `/${ROUTE_STANDARD_PROJECTS}/${data?.global_project_id}`
                case PROJECT_TYPE.final_balance:
                    return `/${ROUTE_FINAL_BALANCE}/${data?.global_project_id}`
                default:
                    return `/${ROUTE_RD}/${data?.global_project_id}`
            }
        }
        return <Link to={renderTypeProject()}>{data?.name}</Link>
    },
    [COLUMNS_PROJECTS_NAMES.team]: ({ team }) => <p>{team?.name}</p>,
    [COLUMNS_PROJECTS_NAMES.typology]: ({ type }) => <p>{capitalize(type)}</p>,
    [COLUMNS_PROJECTS_NAMES.status]: ({ is_active }) => {
        return (
            <Chip
                text={!is_active ? PROJECT_STATUS.closed : PROJECT_STATUS.open}
                size={'medium'}
                variant={is_active ? GENERAL_STATUSES.success : ''}
            />
        )
    },
    [COLUMNS_PROJECTS_NAMES.start_date]: ({ start_date }) => {
        return <p>{getHumanReadableDate(start_date, COMMON_DATE_FORMAT)}</p>
    },
    [COLUMNS_PROJECTS_NAMES.end_date]: ({ end_date }) => (
        <p>{getHumanReadableDate(end_date, COMMON_DATE_FORMAT)}</p>
    ),
    [COLUMNS_PROJECTS_NAMES.budget]: ({ budget }) => (
        <p>{formatCurrency(budget)}</p>
    ),
    [COLUMNS_PROJECTS_NAMES.actions]: data => (
        <Dropdown
            trigger={<ActionsIcon />}
            items={[
                {
                    label: i18next.t('customer.actions.edit_project'),
                    onSelect: () => {
                        openEditSidebar(data?.id, LABELS_TABLES.projects, data)
                    },
                    icon: <EditIcon />,
                    isToDelete: false,
                },
                {
                    label: i18next.t('customer.actions.delete_project'),
                    onSelect: () => {
                        openDialogDelete(data?.id, LABELS_TABLES.projects, data)
                    },
                    icon: <TrashIcon />,
                    isToDelete: true,
                },
            ]}
        />
    ),
})
