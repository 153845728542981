import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CloseIconToast } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import Divider from '@/components/divider/Divider.jsx'
import Select from '@/components/select/Select.jsx'
import useError from '@/hooks/useError.jsx'
import { ADD_MEMBERS_MODEL } from '@/pages/customers/log/LogForm/AddMembers/addMembersForm.schema.js'
import {
    StyledContainer,
    StyledContainerFormActions,
    StyledContainerMembers,
    StyledContainerMembersName,
    StyledFormAddMembers,
} from '@/pages/customers/log/LogForm/AddMembers/styles.js'
import { useCreateFinalBalanceAllocationsMutation } from '@/services/projects/finalBalanceApiSlice.js'
import { handleApiError, remapSelectOptions } from '@/utilities/api/helpers.js'

const AddMembers = ({
    isEdit,
    closeModal,
    openDialogDelete,
    members,
    finalBalance,
    allocations,
    userAllocated,
}) => {
    const { t } = useTranslation()
    const showError = useError()

    const users = allocations?.map(({ user }) => user) || []
    const [addMember] = useCreateFinalBalanceAllocationsMutation()

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors, isDirty, touchedFields, isSubmitting },
    } = useFormContext()

    const onSubmit = async data => {
        try {
            await addMember({
                projectID: finalBalance.id,
                userID: data.member?.value,
            })
            closeModal()
        } catch (error) {
            handleApiError({
                error,
                handleGeneralError: showError,
                handleFormError: setError,
            })
        }
    }

    const membersOptions = members?.filter(
        member => !userAllocated?.some(user => user?.user.id === member.id)
    )

    return (
        <StyledFormAddMembers
            onSubmit={handleSubmit(onSubmit)}
            className="container-fluid d-grid p-0 gap-3"
        >
            <div className="row">
                <div className="col-12">
                    <Select
                        required
                        name={ADD_MEMBERS_MODEL.member}
                        label={t('general.add_member')}
                        placeholder={t('general.add_member')}
                        control={control}
                        touched={touchedFields[ADD_MEMBERS_MODEL.member]}
                        errors={errors[ADD_MEMBERS_MODEL.member]}
                        size="large"
                        options={remapSelectOptions(membersOptions)}
                        isOptionDisabled={options => !options?.data?.is_active}
                        isSearchable
                    />
                </div>
            </div>

            <StyledContainer>
                <Divider
                    orientation={'horizontal'}
                    width={'100%'}
                    height={'1px'}
                />
                {users?.map((user, index) => {
                    return (
                        <div className="row" key={index}>
                            <StyledContainerMembers className="col-12">
                                <StyledContainerMembersName>
                                    <p>
                                        {user?.first_name} {user?.last_name}
                                    </p>
                                    <div>
                                        <CloseIconToast
                                            width={'12px'}
                                            height={'12px'}
                                            onClick={() => {
                                                openDialogDelete({
                                                    isOpen: true,
                                                    id: user?.id,
                                                })
                                            }}
                                        />
                                    </div>
                                </StyledContainerMembersName>

                                <Divider
                                    orientation={'horizontal'}
                                    width={'100%'}
                                    height={'1px'}
                                />
                            </StyledContainerMembers>
                        </div>
                    )
                })}
            </StyledContainer>

            <StyledContainerFormActions className="row d-flex">
                <div className="col-md-4">
                    <Button
                        size="large"
                        color={'neutral'}
                        isUppercase
                        onClick={() => {
                            closeModal(isDirty)
                        }}
                    >
                        {t('commons:button.cancel')}
                    </Button>
                </div>
                <div className="col-md-8">
                    <Button
                        type="submit"
                        size="large"
                        isUppercase
                        disabled={(!isDirty && isEdit) || isSubmitting}
                    >
                        {isEdit
                            ? t('commons:button.save')
                            : t('commons:button.create')}
                    </Button>
                </div>
            </StyledContainerFormActions>
        </StyledFormAddMembers>
    )
}

export default AddMembers

AddMembers.propTypes = {
    isEdit: PropTypes.bool,
    closeModal: PropTypes.func,
    members: PropTypes.array,
    finalBalance: PropTypes.object,
    allocations: PropTypes.array,
    openDialogDelete: PropTypes.func,
    userAllocated: PropTypes.array,
}
