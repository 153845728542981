import i18next from 'i18next'

import {
    ActionsIcon,
    ContractIcon,
    EditIcon,
    TeamIcon,
    TrashIcon,
} from '@/assets/icons/icons.jsx'
import Dropdown from '@/components/dropdown/Dropdown.jsx'
import Tooltip from '@/components/tooltip/Tooltip.jsx'
import {
    StyledContainerDropdownItems,
    StyledLink,
} from '@/pages/settings/styles.js'
import { CONTRACT_TYPE, TYPE_USERS } from '@/utilities/constants'

/* eslint-disable */

export const COLUMNS_MEMBERS_NAMES = {
    id: 'id',
    name: 'name',
    work_email: 'work_email',
    status: 'status',
    can_access: 'can_access',
    role: 'role',
    typology: 'contract',
    actions: 'actions',
}
export const columnsMembers = [
    {
        alignment: 'left',
        label: i18next.t('general.id'),
        name: COLUMNS_MEMBERS_NAMES.id,
        width: '5%',
        sortable: false,
        divider: false,
    },
    {
        alignment: 'left',
        label: i18next.t('general.name'),
        name: COLUMNS_MEMBERS_NAMES.name,
        sortable: false,
        width: '26%',
        divider: true,
    },
    {
        alignment: 'left',
        label: i18next.t('general.work_email'),
        name: COLUMNS_MEMBERS_NAMES.work_email,
        sortable: false,
        width: '26%',
        divider: true,
    },
    {
        alignment: 'left',
        label: i18next.t('general.status'),
        name: COLUMNS_MEMBERS_NAMES.status,
        sortable: false,
        width: '11%',
        divider: true,
    },
    {
        alignment: 'left',
        label: i18next.t('general.can_access'),
        name: COLUMNS_MEMBERS_NAMES.can_access,
        sortable: false,
        width: '10%',
        divider: true,
    },
    {
        alignment: 'left',
        label: i18next.t('general.role'),
        name: COLUMNS_MEMBERS_NAMES.role,
        sortable: false,
        width: '10%',
        divider: true,
    },
    {
        alignment: 'left',
        label: i18next.t('general.typology'),
        name: COLUMNS_MEMBERS_NAMES.typology,
        sortable: false,
        width: '10%',
        divider: true,
    },
    {
        alignment: 'space-evenly',
        label: ' ',
        name: COLUMNS_MEMBERS_NAMES.actions,
        sortable: false,
        width: '2%',
        divider: true,
    },
]

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersMembers = (
    openPanel,
    openDeleteDialog,
    openEditDialog
) => ({
    [COLUMNS_MEMBERS_NAMES.id]: ({ id }) => <p>#{id}</p>,
    [COLUMNS_MEMBERS_NAMES.name]: ({
        first_name,
        last_name,
        id,
        contract,
        team,
    }) => {
        const contractExist = !!contract
        const teamExist = !!team

        const nothingAssociated = !contractExist || !teamExist

        return (
            <>
                {!nothingAssociated ? (
                    <StyledLink
                        nothingAssociated={nothingAssociated}
                        onClick={() => openPanel(id)}
                    >
                        {`${first_name} ${last_name}`}
                    </StyledLink>
                ) : (
                    <Tooltip
                        showPosition="bottom"
                        customContent={
                            <div>
                                {!contractExist && (
                                    <StyledContainerDropdownItems>
                                        <ContractIcon />
                                        <p>
                                            {i18next.t(
                                                'settings.missing_contract'
                                            )}
                                        </p>
                                    </StyledContainerDropdownItems>
                                )}
                                {!teamExist && (
                                    <StyledContainerDropdownItems>
                                        <TeamIcon />
                                        <p>
                                            {i18next.t('settings.missing_team')}
                                        </p>
                                    </StyledContainerDropdownItems>
                                )}
                            </div>
                        }
                        customcolor="red"
                    >
                        <StyledLink
                            nothingAssociated={nothingAssociated}
                            onClick={() => openPanel(id)}
                        >
                            {`${first_name} ${last_name}`}
                        </StyledLink>
                    </Tooltip>
                )}
            </>
        )
    },
    [COLUMNS_MEMBERS_NAMES.work_email]: ({ work_email }) => (
        <p>{work_email ? work_email : '-'}</p>
    ),
    [COLUMNS_MEMBERS_NAMES.status]: ({ is_active }) => {
        return (
            <p>
                {is_active
                    ? i18next.t(`commons:status.active`)
                    : i18next.t('commons:status.inactive')}
            </p>
        )
    },
    [COLUMNS_MEMBERS_NAMES.can_access]: ({ can_access }) => (
        <p>
            {can_access
                ? i18next.t('commons:access.yes')
                : i18next.t('commons:access.no')}
        </p>
    ),
    [COLUMNS_MEMBERS_NAMES.role]: ({ role }) => (
        <p>{role ? i18next.t(`commons:role.${role}`) : '-'}</p>
    ),
    [COLUMNS_MEMBERS_NAMES.typology]: ({ contract }) => {
        return (
            <p>
                {contract?.type === CONTRACT_TYPE.employee &&
                    i18next.t(`commons:contract.employee`)}

                {contract?.type === CONTRACT_TYPE.contractor &&
                    i18next.t('commons:contract.contractor')}

                {!contract?.type && '-'}
            </p>
        )
    },
    [COLUMNS_MEMBERS_NAMES.actions]: ({ id }) => (
        <Dropdown
            trigger={<ActionsIcon />}
            items={[
                {
                    label: i18next.t('settings.actions.edit_member'),
                    onSelect: () => {
                        openEditDialog({
                            isOpen: true,
                            typeForm: TYPE_USERS.members,
                            id: id,
                            isEdit: true,
                        })
                    },
                    icon: <EditIcon />,
                    isToDelete: false,
                },
                {
                    label: i18next.t('settings.actions.delete_member'),
                    onSelect: () => {
                        openDeleteDialog(id, TYPE_USERS.members)
                    },
                    icon: <TrashIcon />,
                    isToDelete: true,
                },
            ]}
        />
    ),
})
