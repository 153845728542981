import { API_RESPONSE_TYPES } from '@/services/axiosInstance.js'

import { apiSlice } from '../apiSlice.js'

// Injects these endpoints to main apiSlice
export const financialImportsApiSlice = apiSlice.injectEndpoints({
    addTagTypes: ['financialImport'],
    endpoints: builder => ({
        createFinancialImport: builder.mutation({
            query: (payload = {}) => {
                const { responseType, data, ...params } = payload
                return {
                    url: '/financial-imports',
                    method: 'post',
                    data,
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['financialImport'],
        }),

        getFinancialImportID: builder.query({
            query: (payload = {}) => {
                const { ID, responseType, ...params } = payload
                return {
                    url: `/financial-imports/${ID}`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
        }),

        deleteFinancialImport: builder.mutation({
            query: (payload = {}) => {
                const { ID, responseType, ...params } = payload
                return {
                    url: `/financial-imports/${ID}`,
                    method: 'delete',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['financialImport'],
        }),

        getFinancialImportDate: builder.query({
            query: (payload = {}) => {
                const { year, month, responseType, ...params } = payload
                return {
                    url: `/financial-imports/year/${year}/month/${month}`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['financialImport'],
        }),
    }),
})

export const {
    useCreateFinancialImportMutation,
    useGetFinancialImportIDQuery,
    useDeleteFinancialImportMutation,
    useGetFinancialImportDateQuery,
} = financialImportsApiSlice
