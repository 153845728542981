import i18next from 'i18next'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import {
    ArrowIcon,
    StatusIconSuccess,
    TrashIcon,
    ArrowOpenedIcon,
} from '@/assets/icons/icons.jsx'
import EditCellRow from '@/components/edit-cell-row/EditCellRow.jsx'
import {
    StyledSalContainer,
    StyledSalItem,
    StyledSalNumber,
} from '@/components/edit-cell-row/styles'
import MultiProgress from '@/components/multi-progress/MultiProgress'
import Spinner from '@/components/spinner/Spinner.jsx'
import { useUpdateStandardSalMutation } from '@/services/projects/projectsApiSlice.js'
import {
    handlExpandedRows,
    currentExpandedRows,
} from '@/services/projects/projectsSlice'
import { useGetTeamsQuery } from '@/services/settings/settingApiSlice.js'
import { formatDateToApi, remapSelectOptions } from '@/utilities/api/helpers'
import { EDIT_CELL_TYPE } from '@/utilities/constants/commons'

import ExpandedTable from './expanded-table/ExpandedTable'
import { StyledExpandedContainer } from './styles'
/* eslint-disable */

export const COLUMNS_ACTIVITY_NAMES = {
    open: '',
    sal: 'sal',
    budget: 'budget',
    hours: 'hours',
    start_date: 'start_date',
    end_date: 'end_date',
    team: 'team',
    actions: 'actions',
}

export const columnsActivity = () => {
    return [
        {
            alignment: 'left',
            label: (
                <div style={{ display: 'flex' }}>
                    <ArrowIcon />
                </div>
            ),
            name: COLUMNS_ACTIVITY_NAMES.open,
            width: '2%',
            sortable: false,
            divider: false,
        },
        {
            alignment: 'left',
            label: i18next.t('activity.table.label_columns.sal'),
            name: COLUMNS_ACTIVITY_NAMES.sal,
            sortable: false,
            width: '40%',
            divider: false,
        },
        {
            alignment: 'left',
            label: i18next.t('activity.table.label_columns.budget'),
            name: COLUMNS_ACTIVITY_NAMES.budget,
            sortable: false,
            width: '10%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('activity.table.label_columns.hours'),
            name: COLUMNS_ACTIVITY_NAMES.hours,
            sortable: false,
            width: '10%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('activity.table.label_columns.start_date'),
            name: COLUMNS_ACTIVITY_NAMES.start_date,
            sortable: true,
            width: '10%',
            divider: true,
            allowOverflow: true,
        },
        {
            alignment: 'left',
            label: i18next.t('activity.table.label_columns.end_date'),
            name: COLUMNS_ACTIVITY_NAMES.end_date,
            sortable: true,
            width: '10%',
            divider: true,
            allowOverflow: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.team'),
            name: COLUMNS_ACTIVITY_NAMES.team,
            sortable: false,
            width: '15%',
            divider: true,
            allowOverflow: true,
        },
        {
            alignment: 'space-evenly',
            label: ' ',
            name: COLUMNS_ACTIVITY_NAMES.actions,
            sortable: false,
            width: '3%',
            divider: true,
        },
    ]
}

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersActivity = openDialogDelete => ({
    [COLUMNS_ACTIVITY_NAMES.open]: ({ id: salID }, index) => {
        const expandedRows = useSelector(currentExpandedRows)

        const dispatch = useDispatch()
        const handleClick = () => {
            dispatch(handlExpandedRows(index))
        }

        const expandedIcon = index => {
            if (expandedRows.includes(index)) {
                return <ArrowOpenedIcon onClick={handleClick} />
            } else {
                return <ArrowIcon onClick={handleClick} fill={'#171428'} />
            }
        }

        return <div style={{ display: 'flex' }}>{expandedIcon(index)}</div>
    },
    [COLUMNS_ACTIVITY_NAMES.sal]: (data, index) => {
        const { name, is_completed, id, project, global_sal_id } = data
        const theme = useTheme()
        const [updateSalData, { isLoading, isSuccess, error: apiError }] =
            useUpdateStandardSalMutation()

        const handleBlur = e => {
            if (e.target.value !== name) {
                updateSalData({
                    ID: project?.id,
                    salID: id,
                    data: {
                        name: e.target.value,
                    },
                })
            }
        }

        return (
            <StyledSalContainer>
                <StatusIconSuccess
                    style={{ cursor: 'default' }}
                    fill={
                        !is_completed
                            ? theme.palette.neutral.base[0]
                            : theme.palette.success.base.background
                    }
                    fillPath={
                        !is_completed
                            ? theme.palette.neutral.base[1000]
                            : theme.palette.success.base.active
                    }
                />
                <StyledSalItem disabled={is_completed}>
                    <p>#{global_sal_id} - </p>
                    {isLoading ? (
                        <Spinner overlay={true} overlayFullscreen={true} />
                    ) : (
                        <EditCellRow
                            disabled={is_completed}
                            data={name}
                            handleBlur={handleBlur}
                            type={EDIT_CELL_TYPE.text}
                            index={index}
                            isSuccess={isSuccess}
                            errors={apiError}
                        />
                    )}
                </StyledSalItem>
            </StyledSalContainer>
        )
    },
    [COLUMNS_ACTIVITY_NAMES.budget]: ({
        budget,
        is_completed,
        id,
        project,
    }) => {
        const [updateSalData, { isLoading, isSuccess, error: apiError }] =
            useUpdateStandardSalMutation()
        const methods = useForm()
        const { control } = methods

        const handleBlur = e => {
            const hours = +e.target.value / (project?.rate / 8)
            if (+e.target.value !== budget) {
                updateSalData({
                    ID: project?.id,
                    salID: id,
                    data: {
                        budget: +e.target.value,
                        hours: hours,
                    },
                })
            }
        }

        return (
            <StyledSalNumber disabled={is_completed}>
                <p>€</p>
                {isLoading ? (
                    <Spinner overlay={true} overlayFullscreen={true} />
                ) : (
                    <EditCellRow
                        disabled={is_completed}
                        data={budget}
                        handleBlur={handleBlur}
                        control={control}
                        type={EDIT_CELL_TYPE.currency}
                        isSuccess={isSuccess}
                        errors={apiError}
                    />
                )}
            </StyledSalNumber>
        )
    },
    [COLUMNS_ACTIVITY_NAMES.hours]: data => {
        const { hours, id, project, is_completed } = data
        const [updateSalData, { isLoading, isSuccess, error: apiError }] =
            useUpdateStandardSalMutation()

        const methods = useForm()
        const { control } = methods

        const handleBlur = e => {
            const budget = +e.target.value * (project?.rate / 8)
            if (+e.target.value !== hours) {
                updateSalData({
                    ID: project?.id,
                    salID: id,
                    data: {
                        budget: budget,
                        hours: +e.target.value,
                    },
                })
            }
        }

        return (
            <StyledSalNumber disabled={is_completed}>
                {isLoading ? (
                    <Spinner overlay={true} overlayFullscreen={true} />
                ) : (
                    <EditCellRow
                        disabled={is_completed}
                        data={hours}
                        handleBlur={handleBlur}
                        control={control}
                        type={EDIT_CELL_TYPE.currency}
                        isSuccess={isSuccess}
                        errors={apiError}
                    />
                )}
            </StyledSalNumber>
        )
    },
    [COLUMNS_ACTIVITY_NAMES.start_date]: data => {
        const { start_date, id, project, end_date, is_completed } = data
        const [updateSalData, { isLoading, isSuccess, error: apiError }] =
            useUpdateStandardSalMutation()

        const methods = useForm()
        const { control } = methods

        const handleBlur = e => {
            if (formatDateToApi(e)) {
                updateSalData({
                    ID: project?.id,
                    salID: id,
                    data: {
                        start_date: formatDateToApi(e),
                    },
                })
            }
        }

        return (
            <StyledSalItem disabled={is_completed}>
                {isLoading ? (
                    <Spinner overlay={true} overlayFullscreen={true} />
                ) : (
                    <EditCellRow
                        disabled={is_completed}
                        date={start_date}
                        handleBlur={handleBlur}
                        control={control}
                        type={EDIT_CELL_TYPE.date}
                        isSuccess={isSuccess}
                        errors={apiError}
                    />
                )}
            </StyledSalItem>
        )
    },
    [COLUMNS_ACTIVITY_NAMES.end_date]: ({
        start_date,
        id,
        project,
        end_date,
        is_completed,
    }) => {
        const [updateSalData, { isLoading, isSuccess, error: apiError }] =
            useUpdateStandardSalMutation()

        const methods = useForm()
        const { control } = methods

        const handleBlur = e => {
            if (formatDateToApi(e)) {
                updateSalData({
                    ID: project?.id,
                    salID: id,
                    data: {
                        end_date: formatDateToApi(e),
                    },
                })
            }
        }

        return (
            <StyledSalItem disabled={is_completed}>
                {isLoading ? (
                    <Spinner overlay={true} overlayFullscreen={true} />
                ) : (
                    <EditCellRow
                        disabled={is_completed}
                        date={end_date}
                        handleBlur={handleBlur}
                        control={control}
                        type={EDIT_CELL_TYPE.date}
                        outOfDate={
                            !is_completed &&
                            new Date(project?.end_date) < new Date(end_date)
                        }
                        isSuccess={isSuccess}
                        errors={apiError}
                    />
                )}
            </StyledSalItem>
        )
    },
    [COLUMNS_ACTIVITY_NAMES.team]: ({ team, id, project, is_completed }) => {
        const [updateSalData, { isLoading, isSuccess, error: apiError }] =
            useUpdateStandardSalMutation()

        const handleBlur = e => {
            updateSalData({
                ID: project?.id,
                salID: id,
                data: {
                    team_id: e,
                },
            })
        }

        const { data: teams } = useGetTeamsQuery({})

        return (
            <>
                {isLoading ? (
                    <Spinner overlay={true} overlayFullscreen={true} />
                ) : (
                    <EditCellRow
                        disabled={is_completed}
                        optionsData={remapSelectOptions(teams?.data)}
                        handleBlur={handleBlur}
                        data={`#${team?.id} - ${team?.name}`}
                        type={EDIT_CELL_TYPE.select}
                        lastPage={teams?.pagination?.lastPage}
                        currentPage={teams?.pagination?.currentPage}
                        isSuccess={isSuccess}
                        errors={apiError}
                    />
                )}
            </>
        )
    },
    [COLUMNS_ACTIVITY_NAMES.actions]: ({ id, is_completed }) => {
        const theme = useTheme()
        return (
            <TrashIcon
                onClick={() => !is_completed && openDialogDelete(id)}
                width={24}
                height={24}
                style={{
                    cursor: !is_completed ? 'pointer' : 'default',
                }}
                fill={
                    !is_completed
                        ? theme.palette.neutral.base[400]
                        : theme.palette.neutral.base[200]
                }
            />
        )
    },

    expandedRow: data => {
        const { id, analytics } = data
        const {
            wired_expenses_hours,
            expected_expenses_hours,
            logged_time_hours,
            schedules_hours,
            available_hours,

            expected_expenses_amount,
            logged_time_amount,
            wired_expenses_amount,
            schedules_amount,
        } = analytics

        return (
            <StyledExpandedContainer>
                <MultiProgress
                    multiData={{
                        sustained: wired_expenses_hours,
                        planned: expected_expenses_hours,
                        consumed: logged_time_hours,
                        scheduled: schedules_hours,

                        sustained_amount: wired_expenses_amount,
                        planned_amount: expected_expenses_amount,
                        consumed_amount: logged_time_amount,
                        schedules_amount: schedules_amount,

                        remaining: available_hours,
                    }}
                />
                <ExpandedTable
                    openDialogDelete={openDialogDelete}
                    salID={id}
                    salData={data}
                />
            </StyledExpandedContainer>
        )
    },
})
