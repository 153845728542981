import styled, { css } from 'styled-components'

import Button from '@/components/button/Button.jsx'

export const StyledContainerDashboardBody = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: 290px 1fr;
        gap: ${theme.spacing * 3}px;
    `}
`
export const StyledContainerSelect = styled.div`
    width: 170px;
`

export const StyledContainerButton = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing * 2}px;
    `}
`

export const StyledContainerTables = styled.div`
    ${({ theme }) => css`
        padding: ${theme.spacing * 3}px;
        border-radius: ${theme.spacing - 2}px;
        border: 2px solid ${theme.palette.danger.base.hover};
        height: fit-content;
    `}
`

export const StyledContainerTitle = styled.div`
    ${({ theme }) => css`
        p {
            ${theme.typography.paragraph.p16.medium};
        }

        h1 {
            color: ${theme.palette.danger.base.active};
        }

        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        align-self: stretch;
        margin-bottom: ${theme.spacing * 2}px;
    `}
`

export const StyledButton = styled(Button)`
    ${({ theme, isActive }) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        padding: ${theme.spacing - 2}px ${theme.spacing * 2}px;
        color: ${theme.palette.neutral.base[1000]};

        & small {
            color: ${theme.palette.danger.base.active};
        }

        ${isActive &&
        css`
            transition: 0.3s;
            background-color: ${theme.palette.danger.base.active};
            color: ${theme.palette.neutral.base[0]};

            & small {
                color: ${theme.palette.neutral.base[0]};
            }
        `}

        &:hover {
            transition: 0.3s;
            background-color: ${theme.palette.danger.base.active};
            color: ${theme.palette.neutral.base[0]};

            & small {
                color: ${theme.palette.neutral.base[0]};
            }
        }

        &:disabled {
            cursor: not-allowed;
            border: 1px solid ${theme.palette.neutral.base[100]};
            background: ${theme.palette.neutral.base[0]};
            color: ${theme.palette.neutral.base[200]};

            & small {
                color: ${theme.palette.neutral.base[200]};
            }
        }
    `}
`

export const StyledBudget = styled.p`
    ${({ theme }) => css`
        color: ${theme.palette.danger.base.active};
        ${theme.typography.paragraph.p14.medium};
    `}
`
