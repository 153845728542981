import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import ErrorMessage from '@/components/error-message/ErrorMessage.jsx'
import StatusInputIcon from '@/components/input/status-input/StatusInput.jsx'
import {
    StyledContainer,
    StyledInput,
    StyledInputWrapper,
    StyledLabel,
} from '@/components/phone-number-input/styles.js'

const InputPhone = ({
    label,
    name,
    placeholder,
    control,
    touched = false,
    required = false,
    errors,
    helpText,
    inputProps,
    disabled,
    statusIcon,
    onlyCountries,
    countryCodeEditable = true,
    statusIconCallback,
    width = '100%',
    defaultCountry = 'it',
    preferredCountries = ['it', 'ua', 'il'],
    ...props
}) => {
    const { t } = useTranslation()

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                    <StyledContainer>
                        {label && (
                            <StyledLabel disabled={disabled} htmlFor={name}>
                                {label}
                                {required && '*'}
                            </StyledLabel>
                        )}

                        <StyledInputWrapper
                            touched={touched}
                            hasError={!!errors}
                            isSuccess={!errors && touched}
                            disabled={disabled}
                            width={width}
                        >
                            <StyledInput
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                ref={ref}
                                onlyCountries={onlyCountries}
                                touched={touched}
                                hasError={!!errors}
                                countryCodeEditable={countryCodeEditable}
                                isSuccess={!errors && touched}
                                disabled={disabled}
                                //input props
                                inputProps={inputProps}
                                placeholder={placeholder}
                                //phone number input props
                                country={defaultCountry}
                                enableSearch={true}
                                preferredCountries={preferredCountries}
                                searchPlaceholder={t(
                                    'settings.fields.placeholder.search_prefix'
                                )}
                                searchNotFound={t(
                                    'settings.fields.placeholder.search_no_result'
                                )}
                                {...props}
                            />

                            {/*icon status*/}
                            <StatusInputIcon
                                statusIcon={statusIcon}
                                statusIconCallback={statusIconCallback}
                                touched={touched}
                                errors={errors}
                            />
                        </StyledInputWrapper>
                        {/*help text*/}
                        <ErrorMessage errors={errors} helpText={helpText} />
                    </StyledContainer>
                )
            }}
        />
    )
}

export default InputPhone

InputPhone.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    control: PropTypes.object,
    touched: PropTypes.bool,
    required: PropTypes.bool,
    errors: PropTypes.object,
    helpText: PropTypes.string,
    inputProps: PropTypes.object,
    disabled: PropTypes.bool,
    countryCodeEditable: PropTypes.bool,
    statusIcon: PropTypes.string,
    statusIconCallback: PropTypes.func,
    width: PropTypes.string,
    onlyCountries: PropTypes.string,
    defaultCountry: PropTypes.string,
    preferredCountries: PropTypes.array,
}
