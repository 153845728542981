import PropTypes from 'prop-types'

import CustomerBillingFormWrapper from '@/pages/customers/detail/customer-form/billing-form/CustomerBillingFormWrapper.jsx'
import CustomerCostFormWrapper from '@/pages/customers/detail/customer-form/costs-form/CustomerCostFormWrapper.jsx'
import FinalInvoiceFormWrapper from '@/pages/customers/detail/customer-form/final-invoice-form/FinalInvoiceFormWrapper.jsx'
import CostsRDFormWrapper from '@/pages/projects/detail/detail-r&d/r&d-form/costs-rd-form/CostsRDFormWrapper.jsx'
import BillingFormWrapper from '@/pages/projects/detail/detail-standard-projects/project-form/billing-form/BillingFormWrapper.jsx'
import CostsFormWrapper from '@/pages/projects/detail/detail-standard-projects/project-form/costs-form/CostsFormWrapper.jsx'
import { COSTS_TYPE, LABELS_TABLES } from '@/utilities/constants/index.js'

const FinanceRenderForm = ({
    dataForm,
    isEdit,
    onClose,
    setOpenDialogToConfirm,
    openDialogDelete,
    sals,
    teams,
    applicants,
    customers,
    responsibilities,
    suppliers,
    successManagers,
}) => {
    const closeModal = isDirty => {
        if (isDirty) {
            setOpenDialogToConfirm(true)
        } else {
            onClose()
        }
    }

    const props = {
        dataForm,
        isEdit,
        onClose,
        closeModal,
        setOpenDialogToConfirm,
        openDialogDelete,
        sals,
        teams,
        applicants,
        customers,
        responsibilities,
        suppliers,
        successManagers,
        isFinance: true,
    }

    if (dataForm?.formType === LABELS_TABLES.billing) {
        switch (dataForm?.data?.type) {
            case COSTS_TYPE.project: //invoice
                return <BillingFormWrapper {...props} />
            case COSTS_TYPE.service: //invoice
                return <CustomerBillingFormWrapper {...props} />
            default:
                return <FinalInvoiceFormWrapper {...props} />
        }
    } else if (dataForm?.formType === LABELS_TABLES.costs) {
        switch (dataForm?.data?.type) {
            case COSTS_TYPE.project:
                if (dataForm?.data?.project?.type === 'standard') {
                    return <CostsFormWrapper {...props} />
                } else {
                    return <CostsRDFormWrapper {...props} />
                }
            case COSTS_TYPE.service:
                return <CustomerCostFormWrapper {...props} />
            default:
                return null
        }
    }
}

export default FinanceRenderForm

FinanceRenderForm.propTypes = {
    dataForm: PropTypes.object,
    isEdit: PropTypes.bool,
    onClose: PropTypes.func,
    setOpenDialogToConfirm: PropTypes.func,
    openDialogDelete: PropTypes.func,
    applicants: PropTypes.array,
    customers: PropTypes.array,
    teams: PropTypes.array,
    successManagers: PropTypes.array,
    responsibilities: PropTypes.array,
    sals: PropTypes.array,
    suppliers: PropTypes.array,
}
