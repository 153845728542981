import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import Datepicker from '@/components/date-picker/DatePicker.jsx'
import Search from '@/components/search/Search.jsx'
import { StyledContainerFinalBalanceFinance } from '@/pages/finance/finance-filters/styles.js'
import { setImportDate } from '@/services/financial/financialSlice.js'

const FinalBalancesFinanceFilters = ({ setFilters }) => {
    const { t } = useTranslation()
    const { control: formControl } = useForm()
    const dispatch = useDispatch()

    const [value, setValue] = useState(new Date(dayjs().startOf('month')))

    const getDate = e => {
        const date = new Date(e)
        setValue(date)
        dispatch(setImportDate(date.toString()))
        setFilters({
            year: e.getFullYear(),
            isFilter: true,
        })
    }
    return (
        <StyledContainerFinalBalanceFinance>
            <Datepicker
                name={'import-picker'}
                placeholder={t(`settings.fields.placeholder.end_date`)}
                control={formControl}
                selected={value}
                showYearPicker
                pickerType={'months'}
                className="mb-2"
                dateFormat="yyyy"
                onChange={getDate}
            />
            <Search
                placeholder={t('settings.filters.placeholder.search')}
                handleSearch={value => {
                    setFilters({ search: value })
                }}
            />
        </StyledContainerFinalBalanceFinance>
    )
}

export default FinalBalancesFinanceFilters

FinalBalancesFinanceFilters.propTypes = {
    setFilters: PropTypes.func,
}
