import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { NotFoundIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import {
    StyledContainerButtonNotFound,
    StyledContainerNotFound,
    StyledContainerP,
} from '@/pages/not-found/styles.js'
import * as routeNames from '@/utilities/constants'

const NotFound = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    return (
        <StyledContainerNotFound>
            <NotFoundIcon />
            <h1>{t('not_found.title')}</h1>

            <StyledContainerP>
                <p>{t('not_found.message')}</p>
            </StyledContainerP>

            <StyledContainerButtonNotFound>
                <Button
                    type="button"
                    size={'large'}
                    onClick={() => navigate(routeNames.ROUTE_DASHBOARD)}
                >
                    {t('not_found.button')}
                </Button>
            </StyledContainerButtonNotFound>
        </StyledContainerNotFound>
    )
}

export default NotFound
