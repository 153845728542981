import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { ProjectIcon } from '@/assets/icons/icons.jsx'
import Sidebar from '@/components/sidebar/Sidebar.jsx'
import Tabs from '@/components/tabs/Tabs.jsx'
import StandardSidebar from '@/pages/projects/detail/sidebar-detail/project/StandardSidebar.jsx'

const LogSidebar = ({ finalBalance, setDataForm }) => {
    const { t } = useTranslation()
    const sideBarLeftData = [
        {
            id: 1,
            value: t('general.project'),
            label: t('general.project'),
            icon: <ProjectIcon />,
            component: (
                <StandardSidebar
                    data={finalBalance}
                    setDataForm={setDataForm}
                />
            ),
        },
    ]
    return (
        <Sidebar openIcon={true}>
            <Tabs
                defaultValue={t('general.project')}
                triggers={sideBarLeftData}
                isSidePanel={true}
                haveDivider
            />
        </Sidebar>
    )
}

export default LogSidebar

LogSidebar.propTypes = {
    finalBalance: PropTypes.object,
    setDataForm: PropTypes.func,
}
