import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import Spinner from '@/components/spinner/Spinner.jsx'
import UploadContainer from '@/components/upload-container/UploadContainer.jsx'
import { StyledContainerFinancialImport } from '@/pages/finance/finance-tables/styles.js'
import { useGetFinancialImportDateQuery } from '@/services/financial/financialImportsApiSlice.js'
import { selectedDate } from '@/services/financial/financialSlice'

const initialState = Array.from({ length: 10 }, (_, i) => ({
    category: (i + 1).toString(),
}))

const FinancialImport = ({ filters }) => {
    const currentDate = useSelector(selectedDate)

    const date = new Date(currentDate)
    const year = date.getFullYear()
    const month = date.getMonth() + 1

    const { data: imports, isFetching } = useGetFinancialImportDateQuery({
        year: filters?.year ? filters?.year : year,
        month: filters?.month ? filters?.month : month,
    })

    const mergedArray = useMemo(() => {
        return initialState?.map(obj1 => {
            const matchingObj = imports?.find(
                obj2 => obj2?.category === obj1?.category
            )
            return matchingObj ? { ...obj1, ...matchingObj } : obj1
        })
    }, [initialState, imports])

    return (
        <>
            {isFetching && <Spinner />}
            <StyledContainerFinancialImport>
                {mergedArray?.length > 0 &&
                    mergedArray?.map(item => {
                        return (
                            <UploadContainer
                                key={item?.category}
                                item={item}
                                month={month}
                                year={year}
                            />
                        )
                    })}
            </StyledContainerFinancialImport>
        </>
    )
}

export default FinancialImport

FinancialImport.propTypes = {
    filters: PropTypes.object,
}
