import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
    CurrencyIcon,
    LinkIcon,
    PercentageIcon,
    TrashIcon,
} from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import Datepicker from '@/components/date-picker/DatePicker.jsx'
import Divider from '@/components/divider/Divider.jsx'
import Input from '@/components/input/Input.jsx'
import InputCurrency from '@/components/input-currency/InputCurrency.jsx'
import QuerySelect from '@/components/query-select/QuerySelect.jsx'
import Select from '@/components/select/Select.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import { StyledContainerFormActions } from '@/pages/customers/detail/customer-form/billing-form/styles.js'
import { StyledContainerDelete } from '@/pages/customers/detail/customer-tables/table-projects/projects-form/styles.js'
import CustomersModalWrapper from '@/pages/customers/list/customers-modal/CustomersModalWrapper.jsx'
import {
    DEFAULT_FINAL_BALANCE_MOCK,
    FINAL_BALANCE_MODEL,
} from '@/pages/projects/list/projects-form/final-balance-form/finalBalance.schema.js'
import { StyledForm } from '@/pages/projects/list/projects-form/final-balance-form/styles.js'
import {
    useCreateFinalBalanceProjectMutation,
    useGetFinalBalanceIDQuery,
    useGetFinalBalanceProjectsQuery,
    useUpdateFinalBalanceProjectMutation,
} from '@/services/projects/finalBalanceApiSlice.js'
import { handleApiError, remapSelectOptions } from '@/utilities/api/helpers.js'
import { STATUS_OPTIONS_PROJECTS } from '@/utilities/constants/list.js'
import {
    filterEmptyKeys,
    iterateOverDirtyFields,
    retrieveSingleValueForRs,
    translateOptions,
} from '@/utilities/helpers.js'

const FinalBalanceForm = ({
    dataForm,
    isEdit,
    openDialogDelete,
    closeModal,
    teams,
    successManagers,
    customers,
    canNotDelete,
}) => {
    const { t } = useTranslation()
    const showError = useError()
    const { triggerAlert } = useAlert()
    const [openFormCustomer, setOpenFormCustomer] = useState(false)

    const { data } = useGetFinalBalanceIDQuery(
        { ID: dataForm.id },
        { skip: !isEdit }
    )

    const [addFinalBalanceProject] = useCreateFinalBalanceProjectMutation()
    const [editFinalBalanceProject] = useUpdateFinalBalanceProjectMutation()

    const {
        register,
        control,
        handleSubmit,
        reset,
        setError,
        setValue,
        watch,
        getValues,
        formState: {
            errors,
            isDirty,
            touchedFields,
            dirtyFields,
            isSubmitting,
        },
    } = useFormContext()

    const onSubmit = async data => {
        try {
            const basePriceValue = parseFloat(
                data[FINAL_BALANCE_MODEL.base_price].replace(',', '.')
            )

            const remappedData = {
                ...data,
                [FINAL_BALANCE_MODEL.customer]:
                    data[FINAL_BALANCE_MODEL.customer].value,
                [FINAL_BALANCE_MODEL.status]:
                    data[FINAL_BALANCE_MODEL.status].value,
                [FINAL_BALANCE_MODEL.success_manager]:
                    data[FINAL_BALANCE_MODEL.success_manager].value,
                [FINAL_BALANCE_MODEL.team]:
                    data[FINAL_BALANCE_MODEL.team].value,
                [FINAL_BALANCE_MODEL.base_price]: basePriceValue,
                [FINAL_BALANCE_MODEL.markup]: +data[FINAL_BALANCE_MODEL.markup],
            }
            if (isEdit) {
                await editFinalBalanceProject({
                    ID: dataForm.id,
                    data: iterateOverDirtyFields(dirtyFields, remappedData),
                }).unwrap()
            } else {
                const filteredData = filterEmptyKeys(remappedData)
                await addFinalBalanceProject({
                    data: filteredData,
                }).unwrap()
            }
            closeModal()
            reset(DEFAULT_FINAL_BALANCE_MOCK)
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: isEdit
                    ? t('commons:toast.updated_success')
                    : t('commons:toast.created_success'),
            })
        } catch (error) {
            handleApiError({
                error,
                handleGeneralError: showError,
                handleFormError: setError,
            })
        }
    }

    const haveSelectedTeam = useWatch({
        control,
        name: FINAL_BALANCE_MODEL.team,
    })

    const rateOfTeam = useMemo(() => {
        if (haveSelectedTeam) {
            return teams.find(team => team.id === haveSelectedTeam.value)?.rate
        }
        return 0
    }, [haveSelectedTeam])

    useEffect(() => {
        if (isEdit) {
            const rate =
                data?.rate === data?.team?.rate ? data?.team?.rate : data?.rate
            setValue(FINAL_BALANCE_MODEL.base_price, rate)
        } else {
            if (rateOfTeam) {
                setValue(FINAL_BALANCE_MODEL.base_price, rateOfTeam)
            }
        }
    }, [rateOfTeam])

    useEffect(() => {
        if (isEdit && data) {
            reset({
                ...data,
                [FINAL_BALANCE_MODEL.start_date]: new Date(
                    data[FINAL_BALANCE_MODEL.start_date]
                ),
                [FINAL_BALANCE_MODEL.end_date]: new Date(
                    data[FINAL_BALANCE_MODEL.end_date]
                ),
                [FINAL_BALANCE_MODEL.status]: retrieveSingleValueForRs(
                    translateOptions(STATUS_OPTIONS_PROJECTS),
                    data[FINAL_BALANCE_MODEL.status]
                ),
                [FINAL_BALANCE_MODEL.success_manager]: retrieveSingleValueForRs(
                    successManagers,
                    data?.success_manager?.id
                ),
                [FINAL_BALANCE_MODEL.team]: retrieveSingleValueForRs(
                    remapSelectOptions(teams),
                    data?.team?.id
                ),
                [FINAL_BALANCE_MODEL.customer]: retrieveSingleValueForRs(
                    customers,
                    data?.customer?.id
                ),
            })
        }
    }, [data, isEdit])

    const startDate = getValues(FINAL_BALANCE_MODEL.start_date)

    const minEndDate = useMemo(() => {
        const date = new Date(startDate)
        date.setDate(date.getDate() + 1)
        return date
    }, [startDate])

    const projectName = useWatch({
        control,
        name: FINAL_BALANCE_MODEL.project,
    })

    const { data: finalBalanceProject } = useGetFinalBalanceProjectsQuery({
        queryKey: [FINAL_BALANCE_MODEL.project, projectName],
        name: projectName,
    })

    useEffect(() => {
        if (
            finalBalanceProject?.data?.length > 0 &&
            !!dirtyFields[FINAL_BALANCE_MODEL.project]
        ) {
            setError(FINAL_BALANCE_MODEL.project, {
                type: 'manual',
                message: t('validation:general_form.project_name_exists'),
            })
        }
    }, [finalBalanceProject])

    const successManagersFiltered = successManagers?.filter(
        successManager => successManager?.data?.is_success_manager
    )

    return (
        <>
            <div
                style={{
                    zIndex: 1,
                }}
            >
                {openFormCustomer && (
                    <CustomersModalWrapper
                        onClose={() => setOpenFormCustomer(false)}
                        isEdit={false}
                        successManagers={successManagers}
                    />
                )}
            </div>

            <StyledForm
                style={{
                    zIndex: 0,
                }}
                isEdit={isEdit}
                onSubmit={handleSubmit(onSubmit)}
                className="container-fluid p-0 d-grid"
            >
                <div className="row">
                    <div className="col-6">
                        <Input
                            label={t('general.project')}
                            placeholder={t(
                                'projects.fields.placeholder.project'
                            )}
                            required
                            width="100%"
                            size={'large'}
                            touched={touchedFields[FINAL_BALANCE_MODEL.project]}
                            errors={errors[FINAL_BALANCE_MODEL.project]}
                            {...register(FINAL_BALANCE_MODEL.project)}
                        />
                    </div>

                    <div className="col-6">
                        <QuerySelect
                            required
                            name={FINAL_BALANCE_MODEL.customer}
                            label={t('projects.fields.labels.customer')}
                            placeholder={t(
                                'projects.fields.placeholder.customer'
                            )}
                            control={control}
                            options={customers}
                            touched={
                                touchedFields[FINAL_BALANCE_MODEL.customer]
                            }
                            errors={errors[FINAL_BALANCE_MODEL.customer]}
                            size="large"
                            onClickHandler={() => setOpenFormCustomer(true)}
                            cta={t('projects.actions.add_customer')}
                            isOptionDisabled={customers =>
                                !customers?.data?.is_active
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <Divider
                            orientation={'horizontal'}
                            width={'100%'}
                            height={'1px'}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <Datepicker
                            required
                            name={FINAL_BALANCE_MODEL.start_date}
                            label={t('projects.fields.labels.start_date')}
                            placeholder={t(
                                'projects.fields.placeholder.start_date'
                            )}
                            control={control}
                            touched={
                                touchedFields[FINAL_BALANCE_MODEL.start_date]
                            }
                            errors={errors[FINAL_BALANCE_MODEL.start_date]}
                            showMonthDropdown
                            showYearDropdown
                            pickerType={'days'}
                            className="mb-2"
                        />
                    </div>

                    <div className="col-6">
                        <Datepicker
                            required
                            name={FINAL_BALANCE_MODEL.end_date}
                            label={t('projects.fields.labels.end_date')}
                            placeholder={t(
                                'projects.fields.placeholder.end_date'
                            )}
                            control={control}
                            touched={
                                touchedFields[FINAL_BALANCE_MODEL.end_date]
                            }
                            errors={errors[FINAL_BALANCE_MODEL.end_date]}
                            showMonthDropdown
                            showYearDropdown
                            pickerType={'days'}
                            className="mb-2"
                            minDate={minEndDate}
                            disabled={!startDate}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <Select
                            required
                            name={FINAL_BALANCE_MODEL.status}
                            label={t('general.status')}
                            placeholder={t(
                                'projects.fields.placeholder.select'
                            )}
                            control={control}
                            options={translateOptions(STATUS_OPTIONS_PROJECTS)}
                            touched={touchedFields[FINAL_BALANCE_MODEL.status]}
                            errors={errors[FINAL_BALANCE_MODEL.status]}
                            size="large"
                        />
                    </div>

                    <div className="col-6">
                        <Select
                            required
                            name={FINAL_BALANCE_MODEL.success_manager}
                            label={t('general.success_manager')}
                            placeholder={t(
                                'projects.fields.placeholder.select'
                            )}
                            control={control}
                            options={successManagersFiltered}
                            touched={
                                touchedFields[
                                    FINAL_BALANCE_MODEL.success_manager
                                ]
                            }
                            errors={errors[FINAL_BALANCE_MODEL.success_manager]}
                            size="large"
                            isSearchable
                            isOptionDisabled={options =>
                                !options?.data?.is_active
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <Select
                            required
                            name={FINAL_BALANCE_MODEL.team}
                            label={t('general.team')}
                            placeholder={t(
                                'projects.fields.placeholder.select'
                            )}
                            control={control}
                            options={remapSelectOptions(teams)}
                            touched={touchedFields[FINAL_BALANCE_MODEL.team]}
                            errors={errors[FINAL_BALANCE_MODEL.team]}
                            size="large"
                            isSearchable
                            isOptionDisabled={teams => !teams?.data?.is_active}
                        />
                    </div>

                    <div className="col-6">
                        <InputCurrency
                            label={t('projects.fields.labels.rate')}
                            placeholder={t('projects.fields.placeholder.rate')}
                            required
                            width="100%"
                            size={'large'}
                            touched={
                                touchedFields[FINAL_BALANCE_MODEL.base_price]
                            }
                            errors={errors[FINAL_BALANCE_MODEL.base_price]}
                            name={FINAL_BALANCE_MODEL.base_price}
                            control={control}
                            icon={<CurrencyIcon />}
                            iconPosition={'right'}
                            allowDecimals
                            allowNegativeValue={false}
                            inputProps={{
                                value: watch(FINAL_BALANCE_MODEL.base_price),
                            }}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <Input
                            required
                            type={'number'}
                            label={t('projects.fields.labels.markup')}
                            placeholder={t('projects.fields.placeholder.rate')}
                            width="100%"
                            size={'large'}
                            touched={touchedFields[FINAL_BALANCE_MODEL.markup]}
                            errors={errors[FINAL_BALANCE_MODEL.markup]}
                            {...register(FINAL_BALANCE_MODEL.markup)}
                            icon={<PercentageIcon />}
                            iconPosition={'right'}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <Divider
                            orientation={'horizontal'}
                            width={'100%'}
                            height={'1px'}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <Input
                            icon={<LinkIcon />}
                            iconPosition={'right'}
                            label={t(
                                'project.fields.label.link_drive_operation'
                            )}
                            placeholder={t(
                                'project.fields.placeholder.link_drive_operation'
                            )}
                            width="100%"
                            size={'large'}
                            errors={
                                errors[FINAL_BALANCE_MODEL.link_drive_operation]
                            }
                            {...register(
                                FINAL_BALANCE_MODEL.link_drive_operation
                            )}
                        />
                    </div>

                    <div className="col-6">
                        <Input
                            icon={<LinkIcon />}
                            iconPosition={'right'}
                            label={t(
                                'project.fields.label.link_drive_management'
                            )}
                            placeholder={t(
                                'project.fields.placeholder.link_drive_management'
                            )}
                            width="100%"
                            size={'large'}
                            errors={
                                errors[
                                    FINAL_BALANCE_MODEL.link_drive_management
                                ]
                            }
                            {...register(
                                FINAL_BALANCE_MODEL.link_drive_management
                            )}
                        />
                    </div>
                </div>

                {isEdit && (
                    <div className="row">
                        {!canNotDelete && (
                            <StyledContainerDelete className="col-12">
                                <TrashIcon />
                                <p
                                    onClick={() => {
                                        openDialogDelete(dataForm?.id)
                                    }}
                                >
                                    {t(`final_balance.actions.delete`)}
                                </p>
                            </StyledContainerDelete>
                        )}
                    </div>
                )}

                <StyledContainerFormActions className="row d-flex">
                    <div className="col-4">
                        <Button
                            size="large"
                            color={'neutral'}
                            isUppercase
                            type={'button'}
                            onClick={() => closeModal(isDirty)}
                        >
                            {t('commons:dialog.error.actions.cancel')}
                        </Button>
                    </div>
                    <div className="col-8">
                        <Button
                            type="submit"
                            size="large"
                            isUppercase
                            disabled={(!isDirty && isEdit) || isSubmitting}
                        >
                            {isEdit
                                ? t(`commons:button.save`)
                                : t('commons:button.create')}
                        </Button>
                    </div>
                </StyledContainerFormActions>
            </StyledForm>
        </>
    )
}

export default FinalBalanceForm

FinalBalanceForm.propTypes = {
    dataForm: PropTypes.object,
    isEdit: PropTypes.bool,
    openDialogDelete: PropTypes.func,
    closeModal: PropTypes.func,
    teams: PropTypes.array,
    successManagers: PropTypes.array,
    customers: PropTypes.array,
    canNotDelete: PropTypes.bool,
}
