import i18next from 'i18next'
import PropTypes from 'prop-types'
import { Fragment, useEffect, useState } from 'react'

import {
    ArrowAccordionIcon,
    EditIcon,
    InfoIconOutlined,
} from '@/assets/icons/icons.jsx'
import Chip from '@/components/chip/Chip.jsx'
import Dropdown from '@/components/dropdown/Dropdown.jsx'
import {
    StyledArrowForTable,
    StyledCell,
    StyledHeader,
    StyledRow,
    StyledYearHeaderCell,
    StyledHeaderCellMonth,
    StyledContainerChildRow,
    StyledOpenSubRowContainer,
    StyledSecondRow,
} from '@/components/final-balance-table/styles.js'
import {
    FINAL_BALANCE_STATUS,
    LABELS_TABLES,
} from '@/utilities/constants/index.js'
import { capitalize } from '@/utilities/helpers.js'

const FinalBalanceTable = ({ parentRows, setDataForm, setYear, filters }) => {
    const [openRows, setOpenRows] = useState([])
    const monthsPerPage = 8

    const isCurrentYear = year => {
        const date = new Date()
        return date.getFullYear() === year
    }

    const years = Array.from({ length: 70 }, (_, i) => {
        const date = new Date()
        return {
            id: i,
            name: date.getFullYear() - i,
            key_name: `year_${i}`,
        }
    })

    const lastYear = years[years.length - 1]

    const months = Array.from({ length: 12 }, (_, i) => {
        const date = new Date(0, i)
        return {
            id: i,
            name: date.toLocaleString('ita', { month: 'long' }),
            key_name: `month_${i}`,
        }
    })

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const currentMonthIndex = new Date().getMonth()
    const desiredThirdMonthIndex = (currentMonthIndex + 2) % 12
    const [startMonthIndex, setStartMonthIndex] = useState(
        (desiredThirdMonthIndex + 12 - monthsPerPage + 4) % 12
    )

    useEffect(() => {
        setYear(selectedYear)
    }, [selectedYear])

    const endMonthIndex = (startMonthIndex + monthsPerPage - 1) % 12
    const visibleMonths =
        startMonthIndex <= endMonthIndex
            ? months.slice(startMonthIndex, endMonthIndex + 1)
            : [
                  ...months.slice(startMonthIndex),
                  ...months.slice(0, endMonthIndex + 1),
              ]

    useEffect(() => {
        parentRows?.length <= 1 && setOpenRows([0])
    }, [parentRows])

    const handleYearChange = increment => {
        const index = years.findIndex(year => year.name === selectedYear)
        if (index !== -1) {
            const newIndex = index + increment
            if (newIndex >= 0 && newIndex < years.length) {
                setSelectedYear(years[newIndex].name)
            }
        }
        if (startMonthIndex === 0 && increment === -1) {
            setStartMonthIndex(months.length - monthsPerPage)
            setSelectedYear(selectedYear - 1)
        }
    }

    const handleMonthChange = increment => {
        const newStartIndex = (startMonthIndex + increment + 12) % 12
        setStartMonthIndex(newStartIndex)

        if (increment === -1) {
            if (visibleMonths[0].id === 0) {
                setSelectedYear(selectedYear - 1)
            }
        } else {
            if (
                visibleMonths[visibleMonths.length - 1].id === 11 &&
                !isCurrentYear(selectedYear)
            ) {
                setSelectedYear(selectedYear + 1)
            }
        }
    }

    const isTheCurrentMonth = month => {
        const date = new Date()
        const currentMonth = months.find(month => month.id === date.getMonth())
        return currentMonth.name === month
    }

    const handleOpenSubRow = parentIndex => {
        if (parentRows?.length <= 1) return

        const updatedOpenRows = openRows.includes(parentIndex)
            ? openRows.filter(index => index !== parentIndex)
            : [...openRows, parentIndex]

        setOpenRows(updatedOpenRows)
    }

    const returnVariant = matchingMonth => {
        switch (matchingMonth?.type) {
            case FINAL_BALANCE_STATUS.confirmed:
                return 'success'
            case FINAL_BALANCE_STATUS.partial_confirmed:
                return 'warning'
            default:
                return
        }
    }

    const renderCell = (item, column, itemToReturn) => {
        const monthIndex = column?.id

        if (item && item.length > 0) {
            const matchingSummary = item.find(summary => {
                const summaryMonth = new Date(summary.date).getMonth()
                return summaryMonth === monthIndex
            })
            if (matchingSummary) {
                if (itemToReturn === 'total_amount') {
                    return (
                        <Chip
                            text={`€ ${matchingSummary[itemToReturn]}`}
                            fullWidth
                            variant={returnVariant(matchingSummary)}
                        />
                    )
                } else {
                    const invoices = matchingSummary?.invoices
                    const allPaidOrUtt = invoices?.every(
                        invoice =>
                            invoice.status === 'paid' ||
                            invoice.status === 'utt'
                    )

                    return (
                        <StyledSecondRow allPaidOrUtt={allPaidOrUtt}>
                            € <p>{matchingSummary[itemToReturn]}</p>
                            {invoices && invoices.length > 0 && (
                                <div>
                                    <Dropdown
                                        title={i18next.t('general.invoices')}
                                        trigger={<InfoIconOutlined />}
                                        items={invoices?.map(invoice => {
                                            return {
                                                label: `#${invoice.id} | €${invoice.amount}`,
                                                icon:
                                                    invoice.status !== 'paid' &&
                                                    invoice?.status !==
                                                        'utt' ? (
                                                        <EditIcon
                                                            onClick={() => {
                                                                setDataForm({
                                                                    open: true,
                                                                    formType:
                                                                        LABELS_TABLES.final_invoice,
                                                                    id: invoice.id,
                                                                    isEdit: true,
                                                                    data: invoice,
                                                                })
                                                            }}
                                                        />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                width: 9,
                                                                height: 10,
                                                            }}
                                                        />
                                                    ),
                                                isToDelete: false,
                                            }
                                        })}
                                    />
                                </div>
                            )}
                        </StyledSecondRow>
                    )
                }
            }
        }

        return ''
    }

    const renderCellSubRow = (item, column) => {
        const monthKey = `month_${column.id}`
        if (item.months) {
            const matchingMonth = item.months.find(
                month => month.key_name === monthKey
            )
            if (matchingMonth) {
                return (
                    <Chip
                        text={`h ${matchingMonth.hours} | € ${matchingMonth.budget}`}
                        fullWidth
                        variant={returnVariant(matchingMonth)}
                    />
                )
            }
        }
        return ''
    }

    return (
        <>
            <div>
                <StyledHeader numMonths={visibleMonths?.length}>
                    {/* Year Header */}
                    <StyledYearHeaderCell>
                        <StyledArrowForTable
                            canRotate={false}
                            isCurrentYear={lastYear === selectedYear}
                            onClick={() =>
                                lastYear !== selectedYear && handleYearChange(1)
                            }
                        />
                        {filters?.year ? filters?.year : selectedYear}
                        <StyledArrowForTable
                            canRotate={true}
                            isCurrentYear={isCurrentYear(selectedYear)}
                            onClick={() =>
                                !isCurrentYear(selectedYear) &&
                                handleYearChange(-1)
                            }
                        />
                    </StyledYearHeaderCell>

                    {/* Previous Month Arrow */}
                    <StyledArrowForTable
                        onClick={() => handleMonthChange(-1)}
                    />

                    {/* Month Labels */}
                    {visibleMonths?.map(month => (
                        <StyledHeaderCellMonth
                            key={month.id}
                            isTheCurrentMonth={isTheCurrentMonth(month.name)}
                        >
                            {capitalize(month.name.slice(0, 3))}
                        </StyledHeaderCellMonth>
                    ))}

                    {/* Next Month Arrow */}
                    <StyledArrowForTable
                        canRotate={true}
                        onClick={() => handleMonthChange(1)}
                    />
                </StyledHeader>

                {/* Parent Rows - body*/}
                {parentRows?.map((item, parentIndex) => (
                    <div key={item?.id}>
                        {/*first row with entire budget*/}
                        <StyledRow
                            isOpen={openRows.includes(parentIndex)}
                            key={parentIndex}
                            numMonths={visibleMonths?.length}
                            lastCellIndex={visibleMonths.length - 1}
                        >
                            <StyledCell
                                isFirst={true}
                                onClick={() => handleOpenSubRow(parentIndex)}
                            >
                                {item?.name}
                                {parentRows?.length > 1 && item?.children && (
                                    <ArrowAccordionIcon
                                        width={10}
                                        height={10}
                                    />
                                )}
                            </StyledCell>

                            {visibleMonths?.map(column => {
                                return (
                                    <StyledCell key={column?.id}>
                                        {renderCell(
                                            item?.summaries,
                                            column,
                                            'total_amount'
                                        )}
                                    </StyledCell>
                                )
                            })}
                        </StyledRow>

                        {/* SubRows - body*/}
                        {openRows.includes(parentIndex) && item?.children && (
                            <StyledOpenSubRowContainer>
                                {/*second row*/}
                                <StyledContainerChildRow
                                    isOpen={openRows.includes(parentIndex)}
                                    key={parentIndex}
                                    numMonths={visibleMonths?.length}
                                    lastCellIndex={visibleMonths.length - 1}
                                >
                                    <StyledCell
                                        isFirst={true}
                                        isSubRow={true}
                                    />

                                    {visibleMonths?.map(column => (
                                        <StyledCell
                                            key={column?.id}
                                            isSecondRow={true}
                                        >
                                            {renderCell(
                                                item?.summaries,
                                                column,
                                                'wallet_balance'
                                            )}
                                        </StyledCell>
                                    ))}
                                </StyledContainerChildRow>

                                {/*all projects rows*/}
                                {item?.children?.map((child, childIndex) => (
                                    <Fragment key={childIndex}>
                                        <StyledContainerChildRow
                                            numMonths={visibleMonths?.length}
                                            lastCellIndex={
                                                visibleMonths.length - 1
                                            }
                                        >
                                            <StyledCell
                                                isFirst={true}
                                                isSubRow={true}
                                            >
                                                {child?.name}
                                            </StyledCell>

                                            {visibleMonths?.map(column => (
                                                <StyledCell
                                                    key={column?.id}
                                                    isSubRow={true}
                                                >
                                                    {renderCellSubRow(
                                                        child,
                                                        column
                                                    )}
                                                </StyledCell>
                                            ))}
                                        </StyledContainerChildRow>
                                    </Fragment>
                                ))}
                            </StyledOpenSubRowContainer>
                        )}
                    </div>
                ))}
            </div>
        </>
    )
}

export default FinalBalanceTable
FinalBalanceTable.propTypes = {
    parentRows: PropTypes.array,
    setDataForm: PropTypes.func,
    setYear: PropTypes.func,
    filters: PropTypes.object,
}
