import PropTypes from 'prop-types'
import { useOutletContext } from 'react-router-dom'

import Table from '@/components/table/index.jsx'
import { columnsRenderersStandardProjects } from '@/pages/projects/list/projects-tables/projects-table/columnsStandardProjects.jsx'
import { tableHeight } from '@/utilities/helpers'

const StandardProjectsTables = () => {
    const { props } = useOutletContext()

    const {
        projects,
        tableColumns,
        openDialogDelete,
        openEditSidebar,
        goToDetails,
        goToCustomer,
    } = props

    return (
        <Table
            columns={tableColumns.projects}
            columnsRenderers={columnsRenderersStandardProjects(
                openDialogDelete,
                openEditSidebar,
                goToDetails,
                goToCustomer
            )}
            data={projects}
            isPaginated
            bodyheight={tableHeight(projects)}
        />
    )
}

export default StandardProjectsTables

StandardProjectsTables.propTypes = {
    projects: PropTypes.array,
    tableColumns: PropTypes.object,
    openDialogDelete: PropTypes.func,
    openEditSidebar: PropTypes.func,
    goToDetails: PropTypes.func,
    goToCustomer: PropTypes.func,
}
