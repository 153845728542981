import { Link } from 'react-router-dom'

import { StyledBudget } from '@/pages/dashboard/styles.js'

import i18next from '../../../../i18n.js'

/* eslint-disable */

export const COLUMNS_DASHBOARD_FOURTH = {
    project: 'project',
    sal: 'sal',
    expiration_date: 'expiration_date',
}
export const columnsFourth = [
    {
        alignment: 'left',
        label: i18next.t('general.project'),
        name: COLUMNS_DASHBOARD_FOURTH.project,
        width: '50%',
        sortable: false,
        divider: false,
    },
    {
        alignment: 'left',
        label: i18next.t('activity.table.label_columns.sal'),
        name: COLUMNS_DASHBOARD_FOURTH.sal,
        width: '25%',
        sortable: false,
        divider: true,
    },
    {
        alignment: 'left',
        label: i18next.t('dashboard.table.deadline'),
        name: COLUMNS_DASHBOARD_FOURTH.expiration_date,
        width: '25%',
        sortable: false,
        divider: true,
    },
]

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersFourth = () => ({
    [COLUMNS_DASHBOARD_FOURTH.project]: ({ id, project }) => (
        <p>{`#${id} - ${project}`}</p>
    ),
    [COLUMNS_DASHBOARD_FOURTH.sal]: ({ sal }) => <Link>{`#${sal}`}</Link>,
    [COLUMNS_DASHBOARD_FOURTH.expiration_date]: ({ expiration_date }) => (
        <StyledBudget>{`${expiration_date}`}</StyledBudget>
    ),
})
