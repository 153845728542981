import i18next from 'i18next'
import { Link } from 'react-router-dom'

import { ActionsIcon, EditIcon, TrashIcon } from '@/assets/icons/icons.jsx'
import Dropdown from '@/components/dropdown/Dropdown.jsx'
import { TYPE_USERS } from '@/utilities/constants'
import { formatCurrency } from '@/utilities/helpers'

/* eslint-disable */

export const COLUMNS_TEAMS_NAMES = {
    id: 'id',
    team: 'name',
    team_lead: 'team_lead',
    rate: 'rate',
    status: 'status',
    product_line: 'product_line',
    people: 'people',
    actions: 'actions',
}

export const columnsTeams = () => {
    return [
        {
            alignment: 'left',
            label: i18next.t('general.id'),
            name: COLUMNS_TEAMS_NAMES.id,
            width: '5%',
            sortable: false,
            divider: false,
        },
        {
            alignment: 'left',
            label: i18next.t('general.team_name'),
            name: COLUMNS_TEAMS_NAMES.team,
            width: '25%',
            sortable: false,
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.team_lead'),
            name: COLUMNS_TEAMS_NAMES.team_lead,
            sortable: false,
            width: '25%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.rate'),
            name: COLUMNS_TEAMS_NAMES.rate,
            sortable: false,
            width: '15%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.status'),
            name: COLUMNS_TEAMS_NAMES.status,
            sortable: false,
            width: '7%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.product_line'),
            name: COLUMNS_TEAMS_NAMES.product_line,
            sortable: false,
            width: '15%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('settings.tables.teams.people'),
            name: COLUMNS_TEAMS_NAMES.people,
            sortable: false,
            width: '6%',
            divider: true,
        },
        {
            alignment: 'space-evenly',
            label: ' ',
            name: COLUMNS_TEAMS_NAMES.actions,
            sortable: false,
            width: '2%',
            divider: true,
        },
    ]
}
//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error
export const columnsRenderersTeams = (
    openPanel,
    openDeleteDialog,
    openEditDialog
) => ({
    [COLUMNS_TEAMS_NAMES.id]: ({ id }) => <p>#{id}</p>,
    [COLUMNS_TEAMS_NAMES.team]: ({ name, id }) => (
        <Link onClick={() => openPanel(id)}>{name}</Link>
    ),
    [COLUMNS_TEAMS_NAMES.team_lead]: ({ team_lead }) => {
        return <p>{team_lead ? team_lead?.full_name : '-'}</p>
    },
    [COLUMNS_TEAMS_NAMES.rate]: ({ rate }) => {
        return <p>{formatCurrency(rate)}</p>
    },
    [COLUMNS_TEAMS_NAMES.status]: ({ is_active }) => {
        return (
            <p>
                {is_active
                    ? i18next.t(`commons:status.active`)
                    : i18next.t('commons:status.inactive')}
            </p>
        )
    },
    [COLUMNS_TEAMS_NAMES.product_line]: ({ product_line }) => (
        <p>{i18next.t(`commons:product_line.${product_line}`)}</p>
    ),
    [COLUMNS_TEAMS_NAMES.people]: ({ users }) => {
        const usersLenght = users?.length || 0
        return <p>{usersLenght}</p>
    },
    [COLUMNS_TEAMS_NAMES.actions]: ({ id }) => (
        <Dropdown
            trigger={<ActionsIcon />}
            items={[
                {
                    label: i18next.t('settings.actions.edit_team'),
                    onSelect: () => {
                        openEditDialog({
                            isOpen: true,
                            typeForm: TYPE_USERS.teams,
                            id: id,
                            isEdit: true,
                        })
                    },
                    icon: <EditIcon />,
                    isToDelete: false,
                },
                {
                    label: i18next.t('settings.actions.delete_team'),
                    onSelect: () => {
                        openDeleteDialog(id, TYPE_USERS.teams)
                    },
                    icon: <TrashIcon />,
                    isToDelete: true,
                },
            ]}
        />
    ),
})
