import { API_RESPONSE_TYPES } from '@/services/axiosInstance.js'

import { apiSlice } from '../apiSlice.js'

// Injects these endpoints to main apiSlice
export const rAndDProjectsApiSlice = apiSlice.injectEndpoints({
    addTagTypes: ['rdProjects', 'rdProjectsSals', 'RDCosts'],
    endpoints: builder => ({
        //rdProjects
        addRDProject: builder.mutation({
            query: (payload = {}) => {
                const { data, ...params } = payload
                const url = '/internal-projects'
                return {
                    url,
                    method: 'post',
                    data,
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['rdProjects', 'projectsCustomer'],
        }),

        getRDProjects: builder.query({
            query: (payload = {}) => {
                const { ...params } = payload
                const url = '/internal-projects'
                return {
                    url,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['rdProjects'],
        }),

        getRDProjectByID: builder.query({
            query: (payload = {}) => {
                const { id, ...params } = payload
                const url = `/internal-projects/${id}`
                return {
                    url,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['rdProjectByID'],
        }),

        updateRDProject: builder.mutation({
            query: (payload = {}) => {
                const { id, data, ...params } = payload
                const url = `/internal-projects/${id}`
                return {
                    url,
                    method: 'patch',
                    data,
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['rdProjects', 'projectsCustomer'],
        }),

        deleteRDProject: builder.mutation({
            query: (payload = {}) => {
                const { id, ...params } = payload
                const url = `/internal-projects/${id}`
                return {
                    url,
                    method: 'delete',
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['rdProjects', 'projectsCustomer'],
        }),

        //rdProject history

        getRDProjectHistory: builder.query({
            query: (payload = {}) => {
                const { id, ...params } = payload
                const url = `/internal-projects/${id}/actions-history`
                return {
                    url,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
        }),

        getRDProjectHistoryType: builder.query({
            query: (payload = {}) => {
                const { id, ...params } = payload
                const url = `/internal-projects/${id}/history/types`
                return {
                    url,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
        }),

        //RD SALS

        createRDProjectSAL: builder.mutation({
            //sal create
            query: (payload = {}) => {
                const { id, data, ...params } = payload
                const url = `/internal-projects/${id}/sals`
                return {
                    url,
                    method: 'post',
                    data,
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['rdProjectsSals'],
        }),

        getRDProjectSALs: builder.query({
            //index sal
            query: (payload = {}) => {
                const { id, ...params } = payload
                const url = `/internal-projects/${id}/sals`
                return {
                    url,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['rdProjectsSals'],
        }),

        getRDProjectSALByID: builder.query({
            //show unique sal
            query: (payload = {}) => {
                const { id, salID, ...params } = payload
                const url = `/internal-projects/${id}/sals/${salID}`
                return {
                    url,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
        }),

        updateRDProjectSAL: builder.mutation({
            //update unique sal
            query: (payload = {}) => {
                const { id, salID, data, ...params } = payload
                const url = `/internal-projects/${id}/sals/${salID}`
                return {
                    url,
                    method: 'patch',
                    data,
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['rdProjectsSals', 'Activities'],
        }),

        deleteRDProjectSAL: builder.mutation({
            //delete unique sal
            query: (payload = {}) => {
                const { id, salID, ...params } = payload
                const url = `/internal-projects/${id}/sals/${salID}`
                return {
                    url,
                    method: 'delete',
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['rdProjectsSals'],
        }),

        //RD SALS activities

        createRDProjectSALActivity: builder.mutation({
            //sal activity create
            query: (payload = {}) => {
                const { projectID, salID, data, ...params } = payload
                const url = `/internal-projects/${projectID}/sals/${salID}/activities`
                return {
                    url,
                    method: 'post',
                    data,
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Activities', 'rdProjectsSals'],
        }),

        getRDProjectSALActivities: builder.query({
            //sal activity index
            query: (payload = {}) => {
                const { projectID, salID, ...params } = payload
                const url = `/internal-projects/${projectID}/sals/${salID}/activities`
                return {
                    url,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['Activities'],
        }),

        getRDProjectSALActivityByID: builder.query({
            //sal activity show
            query: (payload = {}) => {
                const { projectID, salID, activityID, ...params } = payload
                const url = `/internal-projects/${projectID}/sals/${salID}/activities/${activityID}`
                return {
                    url,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
        }),

        updateRDProjectSALActivity: builder.mutation({
            //sal activity update
            query: (payload = {}) => {
                const { projectID, salID, activityID, data, ...params } =
                    payload
                const url = `/internal-projects/${projectID}/sals/${salID}/activities/${activityID}`
                return {
                    url,
                    method: 'patch',
                    data,
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Activities'],
        }),

        deleteRDProjectSALActivity: builder.mutation({
            //sal activity delete
            query: (payload = {}) => {
                const { projectID, salID, activityID, ...params } = payload
                const url = `/internal-projects/${projectID}/sals/${salID}/activities/${activityID}`
                return {
                    url,
                    method: 'delete',
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Activities'],
        }),

        //COSTS
        getRDProjectCosts: builder.query({
            query: (payload = {}) => {
                const { projectID, ...params } = payload
                const url = `/internal-projects/${projectID}/expenses`
                return {
                    url,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['RDCosts'],
        }),

        getRDProjectCostByID: builder.query({
            query: (payload = {}) => {
                const { projectID, salsID, expenseID, ...params } = payload
                const url = `/internal-projects/${projectID}/sals/${salsID}/expenses/${expenseID}`
                return {
                    url,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
        }),

        createRDProjectCost: builder.mutation({
            query: (payload = {}) => {
                const { projectID, salsID, data, ...params } = payload
                const url = `/internal-projects/${projectID}/sals/${salsID}/expenses`
                return {
                    url,
                    method: 'post',
                    data,
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['RDCosts'],
        }),

        editRDProjectCost: builder.mutation({
            query: (payload = {}) => {
                const { projectID, salsID, expenseID, data, ...params } =
                    payload
                const url = `/internal-projects/${projectID}/sals/${salsID}/expenses/${expenseID}`
                return {
                    url,
                    method: 'patch',
                    data,
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['RDCosts'],
        }),

        deleteRDProjectCost: builder.mutation({
            query: (payload = {}) => {
                const { projectID, salsID, expenseID, ...params } = payload
                const url = `/internal-projects/${projectID}/sals/${salsID}/expenses/${expenseID}`
                return {
                    url,
                    method: 'delete',
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['RDCosts'],
        }),
    }),
})

export const {
    useAddRDProjectMutation,
    useGetRDProjectsQuery,
    useGetRDProjectByIDQuery,
    useUpdateRDProjectMutation,
    useDeleteRDProjectMutation,

    useGetRDProjectHistoryQuery,
    useGetRDProjectHistoryTypeQuery,

    useCreateRDProjectSALMutation,
    useGetRDProjectSALsQuery,
    useGetRDProjectSALByIDQuery,
    useUpdateRDProjectSALMutation,
    useDeleteRDProjectSALMutation,

    useCreateRDProjectSALActivityMutation,
    useGetRDProjectSALActivitiesQuery,
    useGetRDProjectSALActivityByIDQuery,
    useUpdateRDProjectSALActivityMutation,
    useDeleteRDProjectSALActivityMutation,

    useGetRDProjectCostsQuery,
    useGetRDProjectCostByIDQuery,
    useCreateRDProjectCostMutation,
    useEditRDProjectCostMutation,
    useDeleteRDProjectCostMutation,
} = rAndDProjectsApiSlice
