import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import Datepicker from '@/components/date-picker/DatePicker.jsx'
import { StyledContainerFinancialImportFinance } from '@/pages/finance/finance-filters/styles.js'
import { setImportDate } from '@/services/financial/financialSlice'

const FinancialImportFilters = ({ setFilters }) => {
    const { t } = useTranslation()
    const { control } = useForm()
    const dispatch = useDispatch()

    const [value, setValue] = useState(new Date(dayjs().startOf('month')))

    const getDate = e => {
        const date = new Date(e)
        setValue(date)
        dispatch(setImportDate(date.toString()))
        setFilters({
            year: e.getFullYear(),
            month: e.getMonth() + 1,
        })
    }

    return (
        <StyledContainerFinancialImportFinance>
            <Datepicker
                name={'import-picker'}
                placeholder={t(`settings.fields.placeholder.end_date`)}
                control={control}
                selected={value}
                showMonthYearPicker
                pickerType={'months'}
                className="mb-2"
                dateFormat="MM/yyyy"
                onChange={getDate}
            />
        </StyledContainerFinancialImportFinance>
    )
}

export default FinancialImportFilters

FinancialImportFilters.propTypes = {
    setFilters: PropTypes.func,
}
