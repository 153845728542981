import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CurrencyIcon, TrashIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import Input from '@/components/input/Input.jsx'
import InputCurrency from '@/components/input-currency/InputCurrency.jsx'
import Select from '@/components/select/Select.jsx'
import Spinner from '@/components/spinner/Spinner'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import { StyledContainerFormActions } from '@/pages/customers/detail/customer-form/billing-form/styles.js'
import { StyledContainerDelete } from '@/pages/customers/detail/customer-tables/table-projects/projects-form/styles.js'
import { StyledForm } from '@/pages/settings/settings-form/team-form/styles.js'
import {
    TEAM_MODEL,
    DEFAULT_TEAM_MOCK,
} from '@/pages/settings/settings-form/team-form/teams.schema.js'
import {
    useCreateTeamMutation,
    useEditTeamMutation,
    useGetTeamByIdQuery,
    useGetTeamsSearchQuery,
} from '@/services/settings/settingApiSlice'
import { handleApiError, remapSelectOptions } from '@/utilities/api/helpers.js'
import {
    PRODUCT_LINE_OPTIONS,
    STATUS_OPTIONS,
} from '@/utilities/constants/list.js'
import {
    iterateOverDirtyFields,
    retrieveSingleValueForRs,
    translateOptions,
    filterEmptyKeys,
} from '@/utilities/helpers.js'

import { PaginatedSelect } from '../../members-select/PaginatedSelect'

const TeamForm = ({
    onClose,
    isEdit,
    closeModal,
    openForm,
    teamLead,
    setOpenDialogToDelete,
}) => {
    const { t } = useTranslation()
    const { triggerAlert } = useAlert()
    const showError = useError()

    const { data, isFetching } = useGetTeamByIdQuery(
        {
            ID: openForm.id,
        },
        { skip: !openForm.id }
    )

    const {
        register,
        control,
        handleSubmit,
        reset,
        setError,
        watch,
        formState: {
            errors,
            isDirty,
            touchedFields,
            dirtyFields,
            isSubmitting,
        },
    } = useFormContext()

    const teamName = useWatch({
        control,
        name: TEAM_MODEL.name,
    })

    const [createTeam] = useCreateTeamMutation()
    const [editTeam] = useEditTeamMutation()
    const { data: teams } = useGetTeamsSearchQuery({
        queryKey: teamName,
        name: teamName,
    })

    useEffect(() => {
        if (
            teams?.data?.filter(i => i?.name === teamName).length > 0 &&
            !!dirtyFields[TEAM_MODEL.name]
        ) {
            setError(TEAM_MODEL.name, {
                type: 'manual',
                message: t('validation:general_form.team_name_exists'),
            })
        }
    }, [teamName, teams])

    const onSubmit = async data => {
        try {
            const remappedData = {
                ...data,
                [TEAM_MODEL.status]: data[TEAM_MODEL.status]?.value,
                [TEAM_MODEL.team_leader]: data[TEAM_MODEL.team_leader]?.value,
                [TEAM_MODEL.product_line]: data[TEAM_MODEL.product_line]?.value,
                [TEAM_MODEL.rate]: Number(
                    data[TEAM_MODEL.rate]?.replace(',', '.')
                ),
            }

            if (isEdit) {
                await editTeam({
                    ID: openForm?.id,
                    data: iterateOverDirtyFields(dirtyFields, remappedData),
                }).unwrap()
            } else {
                const filteredData = filterEmptyKeys(remappedData)
                await createTeam({
                    data: filteredData,
                }).unwrap()
            }
            onClose()
            reset(DEFAULT_TEAM_MOCK)
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: isEdit
                    ? t('commons:toast.updated_success')
                    : t('commons:toast.created_success'),
            })
        } catch (error) {
            handleApiError({
                error,
                handleGeneralError: showError,
                handleFormError: setError,
            })
        }
    }

    useEffect(() => {
        if (isEdit && data) {
            reset({
                ...data,
                [TEAM_MODEL.team_leader]: retrieveSingleValueForRs(
                    remapSelectOptions(teamLead, false),
                    data?.team_lead?.id
                ),
                [TEAM_MODEL.status]: retrieveSingleValueForRs(
                    translateOptions(STATUS_OPTIONS),
                    data[TEAM_MODEL.status]
                ),
                [TEAM_MODEL.product_line]: retrieveSingleValueForRs(
                    translateOptions(PRODUCT_LINE_OPTIONS),
                    data[TEAM_MODEL.product_line]
                ),
            })
        }
    }, [isEdit, data])

    if (isFetching) {
        return (
            <Spinner
                overlay={false}
                overlayFullscreen={false}
                className={undefined}
            />
        )
    }

    return (
        <StyledForm
            isEdit={isEdit}
            onSubmit={handleSubmit(onSubmit)}
            className="container-fluid p-0 d-grid"
        >
            <div className="row">
                <div className="col-6">
                    <Input
                        required
                        label={t(`general.team_name`)}
                        placeholder={t(`general.team_name`)}
                        touched={touchedFields[TEAM_MODEL.name]}
                        errors={errors[TEAM_MODEL.name]}
                        {...register(TEAM_MODEL.name)}
                        size="large"
                        width={'100%'}
                    />
                </div>
                <div className="col-6">
                    <PaginatedSelect
                        required
                        name={TEAM_MODEL.team_leader}
                        label={t(`general.team_lead`)}
                        placeholder={t(
                            `settings.fields.placeholder.team_leader`
                        )}
                        isTeamLead={false}
                        control={control}
                        errors={errors[TEAM_MODEL.team_leader]}
                        isSearchable
                        isOptionDisabled={options => !options?.data?.is_active}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <InputCurrency
                        required
                        label={t(`general.rate`)}
                        placeholder={t(`settings.fields.placeholder.rate`)}
                        touched={touchedFields[TEAM_MODEL.rate]}
                        errors={errors[TEAM_MODEL.rate]}
                        name={TEAM_MODEL.rate}
                        control={control}
                        size="large"
                        width={'100%'}
                        icon={<CurrencyIcon />}
                        iconPosition={'right'}
                        allowDecimals
                        allowNegativeValue={false}
                        inputProps={{
                            value: watch(TEAM_MODEL.rate),
                        }}
                    />
                </div>

                <div className="col-6">
                    <Select
                        required
                        name={TEAM_MODEL.product_line}
                        label={t(`general.product_line`)}
                        placeholder={t(
                            `settings.fields.placeholder.product_line`
                        )}
                        options={translateOptions(PRODUCT_LINE_OPTIONS)}
                        touched={touchedFields[TEAM_MODEL.product_line]}
                        errors={errors[TEAM_MODEL.product_line]}
                        control={control}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Select
                        required
                        name={TEAM_MODEL.status}
                        label={t(`general.status`)}
                        options={translateOptions(STATUS_OPTIONS)}
                        placeholder={t(`settings.fields.placeholder.status`)}
                        touched={touchedFields[TEAM_MODEL.status]}
                        errors={errors[TEAM_MODEL.status]}
                        control={control}
                    />
                </div>
            </div>

            {isEdit && (
                <div className="row">
                    <StyledContainerDelete className="col-12">
                        <TrashIcon />
                        <p
                            onClick={() => {
                                setOpenDialogToDelete(true)
                            }}
                        >
                            {t(`settings.actions.delete_team`)}
                        </p>
                    </StyledContainerDelete>
                </div>
            )}

            <StyledContainerFormActions className="row d-flex">
                <div className="col-4">
                    <Button
                        size="large"
                        color={'neutral'}
                        isUppercase
                        type={'button'}
                        onClick={() => closeModal(isDirty)}
                    >
                        {t('commons:button.cancel')}
                    </Button>
                </div>
                <div className="col-8">
                    <Button
                        type="submit"
                        size="large"
                        isUppercase
                        disabled={(!isDirty && isEdit) || isSubmitting}
                    >
                        {isEdit
                            ? t('commons:button.save')
                            : t('commons:button.create')}
                    </Button>
                </div>
            </StyledContainerFormActions>
        </StyledForm>
    )
}

export default TeamForm

TeamForm.propTypes = {
    onClose: PropTypes.func,
    isEdit: PropTypes.bool,
    isLoading: PropTypes.bool,
    setIsLoading: PropTypes.func,
    openDialogToConfirm: PropTypes.bool,
    setOpenDialogToConfirm: PropTypes.func,
    closeModal: PropTypes.func,
    openForm: PropTypes.object,
    teamLead: PropTypes.array,
    data: PropTypes.object,
    setOpenDialogToDelete: PropTypes.func,
}
