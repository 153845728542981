import {
    ROLE,
    STATUS,
    CONTRACT_TYPE,
    PRODUCT_LINE,
    ACCESS,
    COSTS_STATUS,
    COSTS_TYPE,
    INVOICE_STATUS,
} from './enum'

export const ROLE_OPTIONS = [
    { value: ROLE.admin, label: 'commons:role.admin' },
    { value: ROLE.user, label: 'commons:role.user' },
    { value: ROLE.accounting, label: 'commons:role.accounting' },
]
export const STATUS_OPTIONS = [
    { value: STATUS.active, label: 'commons:status.active' },
    { value: STATUS.inactive, label: 'commons:status.inactive' },
]

export const ACCESS_OPTIONS = [
    { value: ACCESS.yes, label: 'commons:access.yes' },
    { value: ACCESS.no, label: 'commons:access.no' },
]

export const SUCCESS_MANAGER_OPTIONS = [
    { value: ACCESS.yes, label: 'commons:access.yes' },
    { value: ACCESS.no, label: 'commons:access.no' },
]

export const CONTRACT_TYPE_OPTIONS = [
    { value: CONTRACT_TYPE.employee, label: 'commons:contract.employee' },
    { value: CONTRACT_TYPE.contractor, label: 'commons:contract.contractor' },
]

export const PRODUCT_LINE_OPTIONS = [
    { value: PRODUCT_LINE.webapp, label: 'commons:product_line.web-app' },
    { value: PRODUCT_LINE.mobileapp, label: 'commons:product_line.mobile-app' },
    { value: PRODUCT_LINE.wordpress, label: 'commons:product_line.wordpress' },
]

export const COSTS_STATUS_OPTIONS = [
    { value: COSTS_STATUS.expected, label: 'commons:costs_status.expected' },
    { value: COSTS_STATUS.received, label: 'commons:costs_status.received' },
    { value: COSTS_STATUS.paid, label: 'commons:costs_status.paid' },
    { value: COSTS_STATUS.cancelled, label: 'commons:costs_status.cancelled' },
]

export const COSTS_TYPE_OPTIONS = [
    { value: COSTS_TYPE.project, label: 'commons:costs_type.project' },
    { value: COSTS_TYPE.service, label: 'commons:costs_type.service' },
]

export const INVOICE_STATUS_OPTIONS = [
    {
        value: INVOICE_STATUS.expected,
        label: 'commons:invoice_status.expected',
    },
    { value: INVOICE_STATUS.issued, label: 'commons:invoice_status.issued' },
    { value: INVOICE_STATUS.paid, label: 'commons:invoice_status.paid' },
    { value: INVOICE_STATUS.utt, label: 'commons:invoice_status.utt' },
    {
        value: INVOICE_STATUS.cancelled,
        label: 'commons:invoice_status.cancelled',
    },
]

export const STATUS_OPTIONS_PROJECTS = [
    { value: STATUS.active, label: 'commons:project_status.open' },
    { value: STATUS.inactive, label: 'commons:project_status.closed' },
]

export const PROJECT_COST_TYPE_OPTIONS = [
    { value: COSTS_TYPE.project, label: 'commons:costs_type.project' },
    { value: COSTS_TYPE.service, label: 'commons:costs_type.service' },
    {
        value: COSTS_TYPE.final_balance,
        label: 'commons:costs_type.final_balance',
    },
]
