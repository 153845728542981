import * as AlertDialog from '@radix-ui/react-alert-dialog'
import styled, { css } from 'styled-components'

export const StyledAlertDialogOverlay = styled(AlertDialog.Overlay)`
    ${({ theme }) => css`
        background-color: ${theme.palette.neutral.base[100]};
        opacity: 0.5;
        position: fixed;
        inset: 0;
        animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    `}
`

export const StyledAlertDialogContent = styled(AlertDialog.Content)`
    ${({ theme }) => css`
        background-color: ${theme.palette.neutral.base[0]};
        border-radius: ${theme.spacing / 2}px;
        box-shadow: ${theme.shadows[40]};
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 356px;
        padding: ${theme.spacing * 3}px;
        animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
        z-index: ${theme.zIndex.modal};

        &:focus {
            outline: none;
        }
    `}
`

export const StyledAlertDialogTitle = styled(AlertDialog.Title)`
    ${({ theme }) => css`
        color: ${theme.palette.neutral.base[1000]};
        ${theme.typography.paragraph.p16.medium};
        display: flex;
        justify-content: space-between;
        align-items: center;
        & svg {
            cursor: pointer;
        }
        & button {
            display: flex;
            align-items: center;
        }
    `}
`

export const StyledAlertDialogDescription = styled.div`
    ${({ theme }) => css`
        ${theme.typography.paragraph.p14.regular};
        color: ${theme.palette.neutral.base[300]};
        margin: ${theme.spacing}px 0 ${theme.spacing * 3}px 0;
    `}
`

export const StyledActionsContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        gap: ${theme.spacing}px;
        align-items: center;
        justify-content: center;
    `}
`
