import PropTypes from 'prop-types'
import { useEffect } from 'react'

import {
    AlertIcon,
    CloseIconToast,
    InfoIcon,
    SuccessIcon,
} from '@/assets/icons/icons'

import {
    StyledCloseIconWrapper,
    StyledContainer,
    StyledContent,
    StyledIcon,
    StyledMessage,
    StyledRoot,
} from './styles'

export const TOAST_STATUSES = {
    SUCCESS: 'success',
    FAILURE: 'fail',
    WARNING: 'warning',
}
const Toast = ({
    visible,
    message,
    actionCallback,
    dismissTimeout,
    canDismiss = true,
    dismissCallback,
    variant = TOAST_STATUSES.FAILURE,
    closeIcon = <CloseIconToast />,
    position,
}) => {
    const getVariantStyle = variant => {
        switch (variant) {
            case TOAST_STATUSES.SUCCESS:
                return {
                    status: 'success',
                    icon: <SuccessIcon />,
                }
            case TOAST_STATUSES.FAILURE:
                return {
                    status: 'danger',
                    icon: <AlertIcon />,
                }
            default:
                return {
                    status: 'warning',
                    icon: <InfoIcon />,
                }
        }
    }
    const variantStyle = getVariantStyle(variant)

    useEffect(() => {
        if (dismissTimeout && visible) {
            const timeoutId = setTimeout(() => {
                closeHandler()
            }, +dismissTimeout)
            return () => {
                clearTimeout(timeoutId)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])

    const closeHandler = () => {
        dismissCallback && dismissCallback()
    }

    return (
        <>
            <StyledRoot
                visible={visible}
                variantStyle={variantStyle}
                position={position}
            >
                <StyledContainer>
                    <StyledIcon>{variantStyle.icon}</StyledIcon>
                    <StyledContent>
                        {message && <StyledMessage>{message}</StyledMessage>}
                        {actionCallback && <span onClick={actionCallback} />}
                    </StyledContent>
                    {canDismiss && (
                        <StyledCloseIconWrapper
                            variantStyle={variantStyle}
                            onClick={closeHandler}
                        >
                            {closeIcon}
                        </StyledCloseIconWrapper>
                    )}
                </StyledContainer>
            </StyledRoot>
        </>
    )
}

export default Toast

Toast.propTypes = {
    visible: PropTypes.bool,
    message: PropTypes.string,
    actionCallback: PropTypes.func,
    dismissTimeout: PropTypes.func,
    canDismiss: PropTypes.bool,
    dismissCallback: PropTypes.func,
    variant: PropTypes.oneOf(['fail', 'warning', 'success']),
    closeIcon: PropTypes.element,
    position: PropTypes.oneOf([
        'top-left',
        'top-right',
        'bottom-left',
        'bottom-right',
    ]),
}
