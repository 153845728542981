import * as yup from 'yup'

import i18next from '../../../../i18n.js'

const MEMBER_MODEL = {
    name: 'first_name',
    surname: 'last_name',
    birth_date: 'birth_date',
    phone_number: 'phone_number',
    personal_email: 'personal_email',
    can_access: 'can_access',
    role: 'role',
    scope: 'scope_id',
    specificity: 'specificities_ids',
    status: 'is_active',
    responsibility: 'responsibility_id',
    work_email: 'work_email',
    success_manager: 'is_success_manager',
}

const DEFAULT_MEMBER_MOCK = {
    [MEMBER_MODEL.name]: null,
    [MEMBER_MODEL.surname]: null,
    [MEMBER_MODEL.birth_date]: null,
    [MEMBER_MODEL.phone_number]: null,
    [MEMBER_MODEL.personal_email]: null,
    [MEMBER_MODEL.work_email]: null,
    [MEMBER_MODEL.can_access]: null,
    [MEMBER_MODEL.scope]: null,
    [MEMBER_MODEL.specificity]: null,
    [MEMBER_MODEL.status]: null,
    [MEMBER_MODEL.responsibility]: null,
    [MEMBER_MODEL.role]: null,
    [MEMBER_MODEL.success_manager]: null,
}
const RequestValidationSchema = yup.object().shape({
    [MEMBER_MODEL.name]: yup
        .string()
        .required(i18next.t('validation:general_form.name_required')),
    [MEMBER_MODEL.surname]: yup
        .string()
        .required(i18next.t('validation:general_form.lastname_required')),
    [MEMBER_MODEL.birth_date]: yup.date().nullable(),
    [MEMBER_MODEL.phone_number]: yup
        .string()
        .min(10, i18next.t('validation:general_form.invalid_phone'))
        .nullable()
        .transform(value => (value === '' ? null : value)),
    [MEMBER_MODEL.personal_email]: yup
        .string()
        .email(i18next.t('validation:general_form.invalid_email'))
        .nullable()
        .transform(value => (value === '' ? null : value)),
    [MEMBER_MODEL.can_access]: yup
        .object()
        .required(i18next.t('validation:general_form.access_required')),
    [MEMBER_MODEL.role]: yup
        .object()
        .when([MEMBER_MODEL.can_access], (canAccess, schema) => {
            return canAccess[0]?.value
                ? schema.required(
                      i18next.t('validation:general_form.role_required')
                  )
                : schema.nullable()
        }),
    [MEMBER_MODEL.scope]: yup.object().nullable(),
    [MEMBER_MODEL.specificity]: yup.mixed().nullable(),
    [MEMBER_MODEL.responsibility]: yup
        .object()
        .when([MEMBER_MODEL.can_access], (canAccess, schema) => {
            return canAccess[0]?.value
                ? schema.required(
                      i18next.t(
                          'validation:general_form.responsibility_required'
                      )
                  )
                : schema.nullable()
        }),
    [MEMBER_MODEL.status]: yup
        .object()
        .required(i18next.t('validation:general_form.status')),
    [MEMBER_MODEL.work_email]: yup
        .string()
        .email(i18next.t('validation:general_form.invalid_email'))
        .nullable()
        .transform(value => (value === '' ? null : value)),
    [MEMBER_MODEL.success_manager]: yup
        .object()
        .required(
            i18next.t('validation:general_form.success_manager_required')
        ),
})

export {
    MEMBER_MODEL,
    DEFAULT_MEMBER_MOCK,
    RequestValidationSchema as validationSchema,
}
