import { Navigate, Outlet } from 'react-router-dom'

import useUser from '@/hooks/useUser.jsx'
import { ROUTE_LOGIN } from '@/utilities/constants'

const PrivateRoute = () => {
    const { accessToken } = useUser()
    return accessToken ? <Outlet /> : <Navigate to={ROUTE_LOGIN} />
}

export default PrivateRoute
