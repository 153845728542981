import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import Header from '@/components/header/Header.jsx'
import Toast from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import { useWindowSize } from '@/hooks/useWindowSize.jsx'

import { StyledBody, StyledLayout } from './styles'

const PrivateLayout = ({ withHeader }) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(width <= 448)
    const { isAlertOpen, alertData, closeAlert } = useAlert()

    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    return (
        <>
            <Toast
                visible={isAlertOpen}
                message={alertData.message}
                variant={alertData.variant}
                dismissCallback={closeAlert}
                position={'top-right'}
            />
            {withHeader && <Header isMobile={isMobile} />}
            <StyledBody withHeader={withHeader}>
                <StyledLayout>
                    <Outlet />
                </StyledLayout>
            </StyledBody>
        </>
    )
}

export default PrivateLayout

PrivateLayout.propTypes = {
    withHeader: PropTypes.bool,
}
