import styled, { css } from 'styled-components'

import { StyledTHead } from '@/components/table/desktop/header/style'
export const StyledExpandedContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-flow: column;
        gap: ${theme.spacing}px;
        ${StyledTHead} {
            background: ${theme.palette.neutral.base[0]};
        }
    `}
`

export const StyledContainerButtonAddActivity = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: 5% 1fr;
        align-items: center;
        margin-bottom: ${theme.spacing}px;
    `}
`
