import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { PlusIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import Search from '@/components/search/Search.jsx'
import Select from '@/components/select/Select.jsx'
import ToggleSwitch from '@/components/toggle-switch/ToggleSwitch.jsx'
import useError from '@/hooks/useError.jsx'
import {
    StyledContainerPayrollFinance,
    StyledContainerToggle,
} from '@/pages/finance/finance-filters/styles.js'
import { useCreatePayrollMutation } from '@/services/financial/financeApiSlice.js'
import { handleApiError } from '@/utilities/api/helpers.js'
import { CONTRACT_TYPE_OPTIONS } from '@/utilities/constants/list.js'
import { translateOptions } from '@/utilities/helpers.js'

const PayrollFilters = ({ setFilters, filters }) => {
    const { t } = useTranslation()
    const showError = useError()
    const [addPayroll] = useCreatePayrollMutation()

    const handleCsvFileChange = event => {
        const file = event.target.files[0]
        if (file) {
            const reader = new FileReader()
            reader.onloadend = () => {
                const base64Csv = reader.result
                uploadCsvFile(base64Csv)
            }
            reader.readAsDataURL(file)
        }
    }

    const uploadCsvFile = async base64Csv => {
        const base64Data = base64Csv.replace(/^data:text\/csv;base64,/, '')
        const data = {
            csv: base64Data,
        }
        try {
            await addPayroll({
                data: data,
            }).unwrap()
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        }
    }

    const handleAddCsvClick = () => {
        const csvFileInput = document.getElementById('csvFileInput')
        if (csvFileInput) {
            csvFileInput.click()
        }
    }

    return (
        <StyledContainerPayrollFinance>
            <StyledContainerToggle>
                <p> {t('finance.filters.no_costs')}</p>
                <ToggleSwitch
                    onChange={e =>
                        setFilters({
                            ...filters,
                            withCost: e.target.checked,
                        })
                    }
                    isSmall
                />
            </StyledContainerToggle>

            <Select
                isControlled={false}
                size={'medium'}
                placeholder={t('general.typology')}
                options={translateOptions(CONTRACT_TYPE_OPTIONS)}
                onChange={value =>
                    setFilters({
                        ...filters,
                        contractType: value?.value,
                    })
                }
            />
            <Search
                placeholder={t('settings.filters.placeholder.search')}
                handleSearch={value => {
                    setFilters({ search: value })
                }}
            />

            <input
                type="file"
                id="csvFileInput"
                accept=".csv,.pdf"
                style={{ display: 'none' }}
                onChange={handleCsvFileChange}
            />
            <Button type="button" size="small" onClick={handleAddCsvClick}>
                <PlusIcon />
                {t('finance.filters.add_csv')}
            </Button>
        </StyledContainerPayrollFinance>
    )
}

export default PayrollFilters

PayrollFilters.propTypes = {
    setFilters: PropTypes.func,
    filters: PropTypes.object,
}
