import { Navigate, Outlet } from 'react-router-dom'

import useUser from '@/hooks/useUser.jsx'
import { ROUTE_DASHBOARD } from '@/utilities/constants'

const PublicRoute = () => {
    const { accessToken } = useUser()
    return accessToken ? <Navigate to={ROUTE_DASHBOARD} /> : <Outlet />
}

export default PublicRoute
