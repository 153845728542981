import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import SubHeader from '@/components/subheader/SubHeader'
import SettingsRenderForm from '@/pages/settings/settings-form/SettingsRenderForm.jsx'
import MemberSidebarWrapper from '@/pages/settings/sidebar-settings/MemberSidebarWrapper.jsx'
import TeamSidebarWrapper from '@/pages/settings/sidebar-settings/TeamSidebarWrapper.jsx'
import { StyledContainerFluid } from '@/pages/settings/styles.js'
import SubHeaderFilters from '@/pages/settings/subheader-filters/SubHeaderFilters.jsx'
import TablesSettings from '@/pages/settings/tables-settings/TablesSettings.jsx'
import {
    ROUTE_SETTINGS,
    ROUTE_SETTINGS_MEMBERS,
    TYPE_USERS,
} from '@/utilities/constants/index.js'

const Settings = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()

    const [openForm, setOpenForm] = useState({
        isOpen: false,
        typeForm: null,
        id: null,
        isEdit: false,
        data: null,
    })
    const [filter, setFilter] = useState({
        type: null,
        search: '',
    })

    const [teamLeadFilter, setTeamLeadFilter] = useState({
        team_lead_id: null,
    })

    const isMembers =
        location.pathname === `${ROUTE_SETTINGS}/${ROUTE_SETTINGS_MEMBERS}`

    useEffect(() => {
        if (location.pathname === ROUTE_SETTINGS) {
            navigate(`${ROUTE_SETTINGS}/${ROUTE_SETTINGS_MEMBERS}`, {
                state: TYPE_USERS.members,
            })
        }
    }, [location, navigate])

    return (
        <>
            {isMembers ? (
                <MemberSidebarWrapper setOpenForm={setOpenForm} />
            ) : (
                <TeamSidebarWrapper setOpenForm={setOpenForm} />
            )}

            <StyledContainerFluid className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-4">
                        <SubHeader title={t('general.settings')}>
                            <SubHeaderFilters
                                setOpenForm={setOpenForm}
                                setFilter={setFilter}
                                setTeamLeadFilter={setTeamLeadFilter}
                                isMembers={isMembers}
                            />
                        </SubHeader>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <TablesSettings
                            setOpenForm={setOpenForm}
                            setFilter={setFilter}
                            setTeamLeadFilter={setTeamLeadFilter}
                            filter={filter}
                            teamLeadFilter={teamLeadFilter}
                            isMembers={isMembers}
                        />
                    </div>
                </div>

                {openForm.isOpen && (
                    <SettingsRenderForm
                        isEdit={!!openForm.isEdit}
                        openForm={openForm}
                        onClose={() =>
                            setOpenForm({
                                isOpen: false,
                                typeForm: null,
                                id: null,
                                isEdit: false,
                                data: null,
                            })
                        }
                        filter={filter}
                    />
                )}
            </StyledContainerFluid>
        </>
    )
}

export default Settings
