import { Popover } from '@radix-ui/react-popover'
import PropTypes from 'prop-types'

import { StyledBody, StyledOption } from './style'

const SorterModal = ({ onClose, options, sortColumnCb }) => {
    const onSetSorter = option => {
        sortColumnCb(option.value, 'desc')
        onClose()
    }

    return (
        <>
            <Popover
                onClose={onClose}
                title="Sort by"
                hasCloseIcon
                width="400px"
            >
                <StyledBody>
                    {options.map((option, index) => (
                        <StyledOption
                            key={index}
                            onClick={() => onSetSorter(option)}
                        >
                            {option.label}
                        </StyledOption>
                    ))}
                </StyledBody>
            </Popover>
        </>
    )
}

export default SorterModal

SorterModal.propTypes = {
    onClose: PropTypes.func,
    options: PropTypes.array,
    sortColumnCb: PropTypes.func,
}
