import styled, { css } from 'styled-components'

import { getTHAlignment } from '@/utilities/helpers.js'

export const StyledTRow = styled.div`
    ${({
        theme: { palette, spacing },
        alternatingRowColors,
        clickableRowPath,
        selectedRow,
    }) => css`
        display: flex;
        align-items: center;
        cursor: initial;
        flex-wrap: wrap;
        padding: 0 ${spacing}px 0 ${spacing}px;
        border-bottom: 1px solid ${palette.neutral.base[100]};
        background: ${selectedRow
            ? palette.primary.base.background
            : palette.neutral.base[0]};

        ${clickableRowPath &&
        css`
            transition: ease-in-out 400ms;
            cursor: pointer;

            &:hover {
                background: ${palette.neutral.base[100]};
            }
        `}
        &:nth-child(even) {
            background: ${alternatingRowColors
                ? palette.neutral.base[50]
                : 'unset'};
        }
    `}
`

export const StyledTCell = styled.div`
    ${({ theme: { spacing }, havePadding, alignment, width, haveIcon }) => {
        return css`
            display: flex;
            justify-content: ${getTHAlignment(alignment)};
            align-items: center;
            min-height: 56px;
            width: ${width};
            gap: ${haveIcon ? spacing + 6 : spacing}px;
            padding: ${havePadding ? `0 ${spacing}px 0 ${spacing}px` : '0'};
        `
    }}
`

export const StyledTCellText = styled.span`
    ${({ theme: { palette, typography }, allowOverflow }) => css`
        color: ${palette.neutral.base[500]};
        ${typography.paragraph.p14.regular};
        overflow: ${allowOverflow ? 'visible' : 'hidden'};
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        & svg {
            cursor: pointer;
        }
    `}
`

export const StyledExpandedRow = styled.div`
    width: 100%;
    min-height: 50px;
`

export const StyledSubRow = styled.div`
    ${({ theme: { palette } }) => css`
        width: 100%;
        color: ${palette.neutral.base[500]};
    `}
`
