import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { InfoIcon, PlusIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import DialogDelete from '@/components/dialog-delete/DialogDelete.jsx'
import DialogSafeClose from '@/components/dialog-safe-close/DialogSafeClose.jsx'
import ProgressCard from '@/components/progress-card/ProgressCard.jsx'
import Spinner from '@/components/spinner/Spinner.jsx'
import SubHeader from '@/components/subheader/SubHeader.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import Tooltip from '@/components/tooltip/Tooltip.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import RDDetailsForm from '@/pages/projects/detail/detail-r&d/r&d-form/R&DDetailsForm.jsx'
import RAndDTabs from '@/pages/projects/detail/detail-r&d/r&d-tabs/R&DTabs.jsx'
import { StyledContainerButtonCosts } from '@/pages/projects/detail/detail-r&d/styles.js'
import { StyledContainerFluid } from '@/pages/settings/styles.js'
import axios from '@/services/axiosInstance.js'
import {
    useGetSuppliersQuery,
    useGlobalProjectIDQuery,
} from '@/services/projects/projectsApiSlice.js'
import {
    useDeleteRDProjectCostMutation,
    useDeleteRDProjectSALMutation,
    useGetRDProjectByIDQuery,
    useGetRDProjectSALsQuery,
} from '@/services/projects/r&dApiSlice.js'
import {
    useGetMembersQuery,
    useGetResponsibilitiesQuery,
    useGetTeamsQuery,
} from '@/services/settings/settingApiSlice.js'
import {
    handleApiError,
    remapAPIForResponsibilities,
    remapSelectOptions,
} from '@/utilities/api/helpers.js'
import { remapRespForThreeCards } from '@/utilities/api/remapResponse.js'
import { LABELS_TABLES } from '@/utilities/constants/index.js'

import SidebarDetail from '../sidebar-detail/SidebarDetail'

const RDDetail = () => {
    const { id: globalSalID } = useParams()
    //take id from global sal id
    const { data: data } = useGlobalProjectIDQuery({ globalSalID: globalSalID })
    const id = data?.id

    const { t } = useTranslation()
    const showError = useError()
    const { triggerAlert } = useAlert()
    const location = useLocation()

    const [selectedTab, setSelectedTab] = useState(null)
    const [dataForm, setDataForm] = useState({
        isOpen: false,
        typeForm: null,
        id: null,
        isEdit: false,
        data: null,
    })

    const [idSelected, setIdSelected] = useState(null)
    const [openDialogToConfirm, setOpenDialogToConfirm] = useState(false)
    const [openDialogToDelete, setOpenDialogToDelete] = useState(false)

    const [deleteSAL] = useDeleteRDProjectSALMutation()
    const [deleteRDCosts] = useDeleteRDProjectCostMutation()
    const { data: project, isFetching } = useGetRDProjectByIDQuery(
        { id: id },
        { skip: !id }
    )
    const { data: sals } = useGetRDProjectSALsQuery({ id: id }, { skip: !id })
    const { data: suppliers } = useGetSuppliersQuery()
    const { data: teams } = useGetTeamsQuery()
    const { data: applicants } = useGetMembersQuery()
    const { data: responsibilities } = useGetResponsibilitiesQuery()

    const handleDelete = async () => {
        try {
            switch (selectedTab) {
                case LABELS_TABLES.costs:
                    await deleteRDCosts({
                        projectID: id,
                        salsID: idSelected.salID,
                        expenseID: idSelected.id,
                    }).unwrap()
                    break
                default:
                    await deleteSAL({
                        id: id,
                        salID: idSelected.id,
                    }).unwrap()
                    break
            }
            setOpenDialogToDelete(false)
            setDataForm({})
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: t('commons:dialog.success'),
            })
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        } finally {
            setOpenDialogToDelete(false)
        }
    }

    const openFormAddCost = () => {
        setDataForm({
            isOpen: true,
            typeForm: LABELS_TABLES.costs,
            isEdit: false,
        })
    }

    const openDialogDelete = (id, typeForm, salID) => {
        setIdSelected({
            id: id,
            salID: salID,
        })
        setOpenDialogToDelete(true)
    }

    const openEditSidebar = (id, type, data) => {
        setDataForm({
            isOpen: true,
            typeForm: type,
            id: id,
            isEdit: true,
            data: data,
        })
    }

    const handleClick = async () => {
        try {
            await axios.get(`/floatSync/projects/${globalSalID}/sync`)
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        }
    }

    const dataAnalytics =
        data?.analytics && remapRespForThreeCards(data?.analytics, data)

    const notHavePhase = data?.has_logged_time_without_phase
    return (
        <>
            {isFetching && <Spinner />}
            <SidebarDetail
                projectData={project}
                setDataForm={setDataForm}
                isLoading={isFetching}
            />
            <StyledContainerFluid className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-4">
                        <SubHeader title={project?.name}>
                            <StyledContainerButtonCosts
                                condition={
                                    notHavePhase &&
                                    selectedTab === LABELS_TABLES.costs
                                }
                            >
                                {notHavePhase && (
                                    <Tooltip
                                        text={t(
                                            'validation:general_form.log_activity'
                                        )}
                                        showPosition={'bottom'}
                                    >
                                        <Button
                                            size={'small'}
                                            variant={'ghost'}
                                            onClick={handleClick}
                                        >
                                            <InfoIcon />
                                            {t('general.update')}
                                        </Button>
                                    </Tooltip>
                                )}
                                {selectedTab === LABELS_TABLES.costs && (
                                    <Button
                                        type={'button'}
                                        size={'small'}
                                        onClick={openFormAddCost}
                                    >
                                        <PlusIcon />
                                        {t('projects.actions.add_cost')}
                                    </Button>
                                )}
                            </StyledContainerButtonCosts>
                        </SubHeader>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <RAndDTabs
                            id={id}
                            setSelectedTab={setSelectedTab}
                            isLoading={isFetching}
                            openDialogDelete={openDialogDelete}
                            openEditSidebar={openEditSidebar}
                        />
                    </div>
                </div>

                <div className="row">
                    {/*TODO: remove this condition after refactor route*/}
                    {location.search === '?section=Attivit%C3%A0' && (
                        <div className={`col-5`}>
                            <ProgressCard data={dataAnalytics} />
                        </div>
                    )}
                </div>

                {dataForm.isOpen && (
                    <RDDetailsForm
                        selectedTab={selectedTab}
                        dataForm={dataForm}
                        onClose={() => setDataForm({})}
                        setOpenDialogToConfirm={setOpenDialogToConfirm}
                        openDialogDelete={openDialogDelete}
                        isEdit={dataForm.isEdit}
                        sals={remapSelectOptions(sals)}
                        suppliers={suppliers}
                        teams={teams?.data}
                        applicants={remapSelectOptions(applicants?.data)}
                        responsibilities={remapAPIForResponsibilities(
                            responsibilities
                        )}
                    />
                )}

                <DialogDelete
                    open={openDialogToDelete}
                    onClose={() => setOpenDialogToDelete(false)}
                    onDelete={handleDelete}
                />

                {openDialogToConfirm && (
                    <DialogSafeClose
                        goBack={() => {
                            setDataForm({})
                            setOpenDialogToConfirm(false)
                        }}
                        onClose={() => setOpenDialogToConfirm(false)}
                        open={openDialogToConfirm}
                    />
                )}
            </StyledContainerFluid>
        </>
    )
}

export default RDDetail
