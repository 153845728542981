import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { CurrencyIcon, TrashIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import Datepicker from '@/components/date-picker/DatePicker.jsx'
import InputCurrency from '@/components/input-currency/InputCurrency.jsx'
import CreateSelect from '@/components/select/CreateSelect.jsx'
import Select from '@/components/select/Select.jsx'
import TextArea from '@/components/text-area/TextArea.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import { StyledContainerFormActions } from '@/pages/customers/detail/customer-form/billing-form/styles.js'
import {
    COSTS_CUSTOMER_MODEL,
    DEFAULT_COSTS_CUSTOMER_MOCK,
} from '@/pages/customers/detail/customer-form/costs-form/customerCostsForm.schema.js'
import { StyledFormCustomerCost } from '@/pages/customers/detail/customer-form/costs-form/styles.js'
import { StyledContainerDelete } from '@/pages/customers/detail/customer-tables/table-projects/projects-form/styles.js'
import {
    useCreateCustomerCostMutation,
    useGetCustomerCostByIDQuery,
    useUpdateCustomerCostMutation,
} from '@/services/customers/customersApiSlice.js'
import { useCreateSupplierMutation } from '@/services/projects/projectsApiSlice.js'
import {
    formatDateFromApi,
    formatDateToApi,
    handleApiError,
    remapSpecificitiesOptions,
} from '@/utilities/api/helpers.js'
import { COSTS_STATUS_OPTIONS } from '@/utilities/constants/list.js'
import {
    filterEmptyKeys,
    iterateOverDirtyFields,
    retrieveSingleValueForRs,
    translateOptions,
} from '@/utilities/helpers.js'

const CustomerCostForm = ({
    isEdit,
    closeModal,
    dataForm,
    openDialogDelete,
    suppliers,
}) => {
    const { t } = useTranslation()
    const showError = useError()
    const { triggerAlert } = useAlert()
    const { id } = useParams()
    const [isSupplierCreating, setIsSupplierCreating] = useState(false)
    const customerID = dataForm?.data?.customer?.id || id
    const expenseID = dataForm?.id || dataForm?.data?.id

    const [addCost] = useCreateCustomerCostMutation()
    const [updateCost] = useUpdateCustomerCostMutation()
    const [createSuppliers] = useCreateSupplierMutation()
    const { data: cost } = useGetCustomerCostByIDQuery(
        { customerID: customerID, expenseID: expenseID },
        { skip: !isEdit }
    )

    const {
        register,
        control,
        handleSubmit,
        reset,
        setError,
        setValue,
        watch,
        formState: {
            errors,
            isDirty,
            touchedFields,
            dirtyFields,
            isSubmitting,
        },
    } = useFormContext()

    const onSubmit = async data => {
        try {
            const remappedData = {
                ...data,
                [COSTS_CUSTOMER_MODEL.date]: formatDateToApi(
                    data[COSTS_CUSTOMER_MODEL.date]
                ),
                [COSTS_CUSTOMER_MODEL.status]:
                    data[COSTS_CUSTOMER_MODEL.status]?.value,
                [COSTS_CUSTOMER_MODEL.taxable]: parseFloat(
                    data[COSTS_CUSTOMER_MODEL.taxable]
                ),
                [COSTS_CUSTOMER_MODEL.supplier]:
                    data[COSTS_CUSTOMER_MODEL.supplier].value,
            }
            if (isEdit) {
                await updateCost({
                    customerID: customerID,
                    expenseID: expenseID,
                    data: iterateOverDirtyFields(dirtyFields, remappedData),
                }).unwrap()
            } else {
                const filteredData = filterEmptyKeys(remappedData)
                await addCost({
                    customerID: customerID,
                    data: filteredData,
                }).unwrap()
            }
            closeModal()
            reset(DEFAULT_COSTS_CUSTOMER_MOCK)
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: isEdit
                    ? t('commons:toast.updated_success')
                    : t('commons:toast.created_success'),
            })
        } catch (error) {
            handleApiError({
                error,
                handleGeneralError: showError,
                handleFormError: setError,
            })
        }
    }

    useEffect(() => {
        if (isEdit && cost) {
            reset({
                ...cost,
                [COSTS_CUSTOMER_MODEL.date]: formatDateFromApi(cost.date),
                [COSTS_CUSTOMER_MODEL.status]: retrieveSingleValueForRs(
                    translateOptions(COSTS_STATUS_OPTIONS),
                    cost[COSTS_CUSTOMER_MODEL.status]
                ),
                [COSTS_CUSTOMER_MODEL.supplier]: retrieveSingleValueForRs(
                    remapSpecificitiesOptions(suppliers),
                    cost.supplier_id
                ),
            })
        }
    }, [cost, isEdit])

    const handleCreate = async inputValue => {
        setIsSupplierCreating(true)
        try {
            const data = await createSuppliers({
                data: {
                    business_name: inputValue,
                },
            }).unwrap()
            setValue(COSTS_CUSTOMER_MODEL.supplier, {
                label: data.business_name,
                value: data.id,
            })
        } catch (error) {
            handleApiError({
                error,
                handleGeneralError: showError,
                handleFormError: setError,
            })
        } finally {
            setIsSupplierCreating(false)
        }
    }

    const idToDelete = {
        salID: cost?.sal?.id,
        expensesID: expenseID,
    }

    return (
        <StyledFormCustomerCost
            isEdit={isEdit}
            onSubmit={handleSubmit(onSubmit)}
            className="container-fluid d-grid p-0 gap-3"
        >
            <div className="row">
                <div className="col-6">
                    <Datepicker
                        required
                        label={t('projects.fields.labels.date')}
                        placeholder={t(
                            'billing_project.fields.placeholder.date'
                        )}
                        control={control}
                        name={COSTS_CUSTOMER_MODEL.date}
                        touched={touchedFields[COSTS_CUSTOMER_MODEL.date]}
                        errors={errors[COSTS_CUSTOMER_MODEL.date]}
                        showMonthDropdown
                        showYearDropdown
                        pickerType={'days'}
                    />
                </div>

                <div className="col-6">
                    <Select
                        required
                        name={COSTS_CUSTOMER_MODEL.status}
                        label={t('general.status')}
                        placeholder={t('projects.fields.placeholder.status')}
                        control={control}
                        touched={touchedFields[COSTS_CUSTOMER_MODEL.status]}
                        errors={errors[COSTS_CUSTOMER_MODEL.status]}
                        size="large"
                        options={translateOptions(COSTS_STATUS_OPTIONS)}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <InputCurrency
                        required
                        label={t('projects.fields.labels.taxable')}
                        placeholder={t('projects.fields.placeholder.taxable')}
                        width="100%"
                        size={'large'}
                        icon={<CurrencyIcon />}
                        iconPosition={'right'}
                        control={control}
                        errors={errors[COSTS_CUSTOMER_MODEL.taxable]}
                        name={COSTS_CUSTOMER_MODEL.taxable}
                        inputProps={{
                            value: watch(COSTS_CUSTOMER_MODEL.taxable),
                        }}
                    />
                </div>
                <div className="col-6">
                    <CreateSelect
                        label={t('costs_project.fields.labels.supplier')}
                        placeholder={t(
                            'costs_project.fields.placeholder.supplier'
                        )}
                        options={remapSpecificitiesOptions(suppliers)}
                        hideSelectedOptions={false}
                        isClearable
                        onCreateOption={handleCreate}
                        control={control}
                        name={COSTS_CUSTOMER_MODEL.supplier}
                        touched={touchedFields[COSTS_CUSTOMER_MODEL.supplier]}
                        errors={errors[COSTS_CUSTOMER_MODEL.supplier]}
                        isLoading={isSupplierCreating}
                        disabled={isSupplierCreating}
                        haveDropdownIcon={false}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <TextArea
                        required
                        label={t('projects.fields.labels.note')}
                        placeholder={t('projects.fields.placeholder.note')}
                        width="100%"
                        size={'large'}
                        errors={errors[COSTS_CUSTOMER_MODEL.note]}
                        {...register(COSTS_CUSTOMER_MODEL.note)}
                        resize={'vertical'}
                    />
                </div>
            </div>

            {isEdit && (
                <div className="row">
                    <StyledContainerDelete className="col-12">
                        <TrashIcon />
                        <p
                            onClick={() => {
                                openDialogDelete(expenseID, idToDelete)
                            }}
                        >
                            {t('costs_project.actions.delete_cost')}
                        </p>
                    </StyledContainerDelete>
                </div>
            )}

            <StyledContainerFormActions className="row d-flex">
                <div className="col-md-4">
                    <Button
                        size="large"
                        color={'neutral'}
                        isUppercase
                        onClick={() => {
                            closeModal(isDirty)
                        }}
                    >
                        {t('commons:button.cancel')}
                    </Button>
                </div>
                <div className="col-md-8">
                    <Button
                        type="submit"
                        size="large"
                        isUppercase
                        disabled={(!isDirty && isEdit) || isSubmitting}
                    >
                        {isEdit
                            ? t('commons:button.save')
                            : t('commons:button.create')}
                    </Button>
                </div>
            </StyledContainerFormActions>
        </StyledFormCustomerCost>
    )
}

export default CustomerCostForm

CustomerCostForm.propTypes = {
    isEdit: PropTypes.bool,
    closeModal: PropTypes.func,
    dataForm: PropTypes.object,
    openDialogDelete: PropTypes.func,
    suppliers: PropTypes.array,
}
