import * as yup from 'yup'

import i18next from '../../../../i18n.js'

const CONTRACT_MODEL = {
    contract: 'type',
    identifier: 'identifier',
    start_date: 'start_date',
    end_date: 'end_date',
}

const DEFAULT_CONTRACT_MOCK = {
    [CONTRACT_MODEL.contract]: null,
    [CONTRACT_MODEL.identifier]: null,
    [CONTRACT_MODEL.start_date]: null,
    [CONTRACT_MODEL.end_date]: null,
}

const RequestValidationSchema = yup.object().shape({
    [CONTRACT_MODEL.contract]: yup
        .object()
        .required(i18next.t('validation:general_form.typology_required')),
    [CONTRACT_MODEL.identifier]: yup
        .string()
        .nullable()
        .transform(value => (value === '' ? null : value)),
    [CONTRACT_MODEL.start_date]: yup
        .date()
        .required(i18next.t('validation:general_form.start_date_required')),
    [CONTRACT_MODEL.end_date]: yup.date().nullable(),
})

export {
    CONTRACT_MODEL,
    DEFAULT_CONTRACT_MOCK,
    RequestValidationSchema as validationSchema,
}
