import styled, { css } from 'styled-components'

import { TABLE_HEIGHT } from '@/utilities/constants/commons'

export const StyledCalendarDayContainer = styled.div`
    overflow-x: scroll;
    width: 100%;
    /* Nascondi la scroll bar standard */

    ::-webkit-scrollbar {
        display: none; /* Chrome, Safari e Opera */
    }

    -ms-overflow-style: none; /* IE e Edge */
    scrollbar-width: none; /* Firefox */
`

export const StyledCalendarDayHeadContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        position: sticky;
        top: 0;
        background: ${theme.palette.neutral.base[0]};
    `}
`
export const StyledCalendarDayBodyContainer = styled.div`
    display: flex;
    flex-flow: column;
    max-height: ${TABLE_HEIGHT};
`

export const StyledCalendarSelectContainer = styled.div`
    ${({ theme, holiday }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 50px;
        background: ${theme.palette.neutral.base[0]};
        text-align: center;
        ${theme.typography.paragraph.p16.bold};
        color: ${theme.palette.neutral.base[holiday ? 200 : 1000]};

        & input {
            width: 64px;
        }
    `}
`

export const StyledCalendarSelectDateContainer = styled.div`
    ${({ theme, status }) => css`
        display: flex;
        align-items: center;
        position: sticky;
        left: 0;
        justify-content: space-between;
        min-height: 50px;
        min-width: 209px;
        max-width: 209px;
        padding: ${theme.spacing}px ${theme.spacing * 3}px;
        gap: ${theme.spacing}px;
        background: ${theme.palette.neutral.base[0]};
        text-align: center;
        ${theme.typography.paragraph.p16.bold};

        & .date_container {
            display: flex;
            align-items: center;
            gap: ${theme.spacing}px;

            & .status_ {
                display: block;
                width: ${theme.spacing}px;
                height: ${theme.spacing}px;
                border-radius: 50%;
                background: ${status === 'active'
                    ? theme.palette.success.base.active
                    : theme.palette.warning.base.active};
            }
        }

        & button {
            cursor: pointer;
        }
    `}
`

export const StyledCalendarDay = styled.div`
    ${({ theme, holiday, large }) => css`
        min-height: 50px;
        width: 100%;
        max-width: ${large ? '58px' : '29px'};
        min-width: ${large ? '58px' : '29px'};
        background: ${theme.palette.neutral.base[0]};
        text-align: center;
        ${theme.typography.paragraph.p16.regular};
        color: ${theme.palette.neutral.base[holiday ? 200 : 1000]};
    `}
`

export const StyledCalendarUserContainer = styled.div`
    ${({ theme, status, isAnActivity }) => css`
        padding: ${theme.spacing}px;
        min-width: 209px;
        max-width: 209px;
        flex-flow: column;
        display: flex;
        ${!isAnActivity &&
        css`
            border-top: 1px solid ${theme.palette.neutral.base[200]};
            border-bottom: 1px solid ${theme.palette.neutral.base[200]};
            background: ${theme.palette.neutral.base[0]};
        `}
        ${isAnActivity &&
        css`
            background: ${theme.palette.secondary.base.background};
        `}

        position: sticky;
        left: 0;

        & .name_container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: ${theme.spacing}px;
            min-height: 25px;

            & .name_ {
                ${theme.typography.paragraph.p14.regular};
                color: ${theme.palette.neutral.base[1000]};
            }
        }

        & .time_container {
            display: flex;
            align-items: center;
            gap: ${theme.spacing}px;
            ${theme.typography.paragraph.p12.bold};

            & .status_ {
                width: ${theme.spacing}px;
                height: ${theme.spacing}px;
                border-radius: 50%;
                background: ${status
                    ? theme.palette.success.base.active
                    : theme.palette.warning.base.active};
            }
        }
    `}
`

export const StyledCalendarCellContainer = styled.div`
    ${({ theme, large }) => css`
        padding: 0px ${theme.spacing}px;
        border-left: 1px solid ${theme.palette.neutral.base[200]};
        width: 100%;
        max-width: ${large ? '58px' : '29px'};
        min-width: ${large ? '58px' : '29px'};
        z-index: -1;
    `}
`

export const StyledCalendarDataContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        margin-bottom: ${theme.spacing}px;

        & .float_container {
            padding: ${theme.spacing}px 0px;
            min-height: 52px;
            text-align: center;
            border-top: 1px solid ${theme.palette.neutral.base[200]};
            border-bottom: 1px solid ${theme.palette.neutral.base[200]};

            & input {
                width: 100%;
                min-width: 42px;
                max-width: 44px;
                height: 24px;
                border-radius: 5px;
                padding: 0px ${theme.spacing / 2}px;
                text-align: center;

                &::placeholder {
                    color: ${theme.palette.neutral.base[200]};
                }

                &.confirmed_ {
                    margin-bottom: 4px;
                    background: ${theme.palette.success.base.active};
                    color: ${theme.palette.neutral.base[0]};

                    &:focus {
                        color: ${theme.palette.neutral.base[0]};
                    }
                }

                &.sell_ {
                    margin-bottom: 4px;
                    background: ${theme.palette.neutral.base[0]};
                    color: ${theme.palette.neutral.base[200]};
                    border: 1px solid ${theme.palette.neutral.base[200]};
                }

                &.worked_ {
                    background: ${theme.palette.neutral.base[0]};
                    color: ${theme.palette.neutral.base[200]};
                    text-align: center;
                }

                &:focus {
                    box-shadow: ${theme.shadows[20]};
                    border: 1px solid ${theme.palette.primary.base.text};
                    color: ${theme.palette.primary.base.text};
                }
            }

            input[type='number']::-webkit-inner-spin-button,
            input[type='number']::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        & .empty_container {
            padding: ${theme.spacing}px 0px;
            border-top: 1px solid ${theme.palette.neutral.base[200]};
            border-bottom: 1px solid ${theme.palette.neutral.base[200]};

            & .bg_ {
                margin: 0 auto;
                min-height: 52px;
                background: ${theme.palette.secondary.base.background};
            }
        }
    `}
`

export const StyledRightClickMenu = styled.div`
    ${({ theme }) => css`
        cursor: pointer;
        display: flex;
        justify-content: start;
        align-items: center;
        gap: ${theme.spacing}px;
        padding: 0px ${theme.spacing}px;

        &.icon-container,
        svg {
            height: 10px;
            width: 9px;
        }

        & span {
            ${theme.typography.paragraph.p12.regular};
            color: ${theme.palette.neutral.base[1000]};
        }
    `}
`
