import * as yup from 'yup'

import i18next from '../../../../../i18n.js'

const COSTS_CUSTOMER_MODEL = {
    date: 'date',
    status: 'status',
    taxable: 'amount',
    supplier: 'supplier_id',
    note: 'description',
}

const DEFAULT_COSTS_CUSTOMER_MOCK = {
    [COSTS_CUSTOMER_MODEL.date]: null,
    [COSTS_CUSTOMER_MODEL.status]: null,
    [COSTS_CUSTOMER_MODEL.taxable]: null,
    [COSTS_CUSTOMER_MODEL.supplier]: null,
    [COSTS_CUSTOMER_MODEL.note]: '',
}

const RequestValidationSchema = yup.object().shape({
    [COSTS_CUSTOMER_MODEL.date]: yup
        .date()
        .required(i18next.t('validation:general_form.date')),
    [COSTS_CUSTOMER_MODEL.status]: yup
        .object()
        .required(i18next.t('validation:general_form.status')),
    [COSTS_CUSTOMER_MODEL.supplier]: yup
        .object()
        .required(i18next.t('validation:general_form.supplier')),
    [COSTS_CUSTOMER_MODEL.taxable]: yup
        .string()
        .required(i18next.t('validation:general_form.taxable')),
    [COSTS_CUSTOMER_MODEL.note]: yup
        .string()
        .nullable()
        .transform(value => (value === '' ? null : value)),
})

export {
    COSTS_CUSTOMER_MODEL,
    DEFAULT_COSTS_CUSTOMER_MOCK,
    RequestValidationSchema as validationSchema,
}
