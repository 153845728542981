import styled, { css } from 'styled-components'

export const StyledForm = styled.form`
    ${({ theme, isEdit }) => css`
        grid-template-rows: ${isEdit
            ? `repeat(9, max-content)`
            : `repeat(8, max-content)`};
        grid-gap: ${theme.spacing * 2}px;
        height: 100%;
    `}
`
