import { createApi } from '@reduxjs/toolkit/query/react'

import axios from './axiosInstance.js'

// This use the axios config instead of fetch API
const axiosBaseQuery =
    () =>
    async ({ url, method, data, params }) => {
        try {
            const result = await axios({
                url,
                method,
                data,
                params,
            })
            return { data: result.data }
        } catch (axiosError) {
            let err = axiosError
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            }
        }
    }

// Define our single API slice object, endpoints are injected in separately
export const apiSlice = createApi({
    baseQuery: axiosBaseQuery(),
    endpoints: () => ({}), // this line is required even if empty
})
