import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { StyleSheetManager, ThemeProvider } from 'styled-components'

import AlertProvider from '@/context/AlertProvider.jsx'
import UserProvider from '@/context/UserProvider.jsx'
import { getAppTheme, ResetStyles, GlobalStyles } from '@/theme'

import router from './router.jsx'
import store from './store/index.js'

function App() {
    const themeName = 'light'

    return (
        <Provider store={store}>
            <ThemeProvider theme={getAppTheme({ name: themeName })}>
                <StyleSheetManager>
                    <AlertProvider>
                        <UserProvider>
                            <ResetStyles />
                            <GlobalStyles />
                            <RouterProvider router={router} />
                        </UserProvider>
                    </AlertProvider>
                </StyleSheetManager>
            </ThemeProvider>
        </Provider>
    )
}

export default App
