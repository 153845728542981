import { Link } from 'react-router-dom'

import { StyledBudget } from '@/pages/dashboard/styles.js'

import i18next from '../../../../i18n.js'

/* eslint-disable */

export const COLUMNS_DASHBOARD_NINTH = {
    project: 'project',
    to_expire: 'to_expire',
}
export const columnsNinth = [
    {
        alignment: 'left',
        label: i18next.t('general.project'),
        name: COLUMNS_DASHBOARD_NINTH.project,
        width: '80%',
        sortable: false,
        divider: false,
    },
    {
        alignment: 'left',
        label: i18next.t('dashboard.table.to_expire'),
        name: COLUMNS_DASHBOARD_NINTH.to_expire,
        width: '20%',
        sortable: false,
        divider: true,
    },
]

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersNinth = () => ({
    [COLUMNS_DASHBOARD_NINTH.project]: ({ id, project }) => (
        <Link>{`#${id} - ${project}`}</Link>
    ),
    [COLUMNS_DASHBOARD_NINTH.to_expire]: ({ to_expire }) => (
        <StyledBudget>{`€ ${to_expire}`}</StyledBudget>
    ),
})
