import styled, { css } from 'styled-components'

export const StyledSubHeaderRoot = styled.div`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        background-color: ${theme.palette.neutral.base[0]};
        width: 100%;
    `}
`

export const StyledSubHeaderTitle = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        gap: ${theme.spacing * 2}px;
        & h2 {
            ${theme.typography.paragraph.heading2}
            color: ${theme.palette.neutral.base[1000]};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 290px;
        }
    `}
`
export const StyledSubHeaderChildren = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        gap: ${theme.spacing}px;
    `}
`
