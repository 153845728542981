export const ROLE = {
    admin: 'admin',
    user: 'user',
    accounting: 'accounting',
}

export const STATUS = {
    active: true,
    inactive: false,
}

export const ACCESS = {
    yes: true,
    no: false,
}

export const CONTRACT_TYPE = {
    employee: 'employee',
    contractor: 'contractor',
}

export const PRODUCT_LINE = {
    webapp: 'web-app',
    mobileapp: 'mobile-app',
    wordpress: 'wordpress',
}

export const COSTS_STATUS = {
    expected: 'expected',
    paid: 'paid',
    received: 'received',
    cancelled: 'cancelled',
}

export const COSTS_TYPE = {
    project: 'project',
    service: 'service',
    final_balance: 'final_balance',
}

export const INVOICE_STATUS = {
    paid: 'paid',
    issued: 'issued',
    utt: 'utt',
    expected: 'expected',
    cancelled: 'cancelled',
}

export const FINAL_BALANCE_STATUS = {
    confirmed: 'confirmed',
    partial_confirmed: 'partial_confirmed',
    schedule: 'schedule',
}
