import dayjs from 'dayjs'
import i18next from 'i18next'

export const handleApiError = ({
    error,
    handleGeneralError,
    handleFormError,
}) => {
    if (error) {
        let { response: { data } = {} } = error
        data = data ?? error.data

        Object.keys(data).forEach(errorFieldName => {
            const rawErrorValue = data[errorFieldName]
            if (handleGeneralError && errorFieldName === 'message') {
                let message
                if (i18next.exists(`validation:errors.${rawErrorValue}`)) {
                    message = i18next.t(`validation:errors.${rawErrorValue}`)
                } else {
                    message = rawErrorValue
                }
                // Generic error
                handleGeneralError(message)
            }
            if (handleFormError) {
                // Form field error

                let message
                if (Array.isArray(rawErrorValue)) {
                    message = rawErrorValue.map(item => {
                        if (i18next.exists(`validation:errors.${item}`)) {
                            return i18next.t(`validation:errors.${item}`)
                        }
                        return item
                    })
                } else {
                    if (i18next.exists(`validation:errors.${rawErrorValue}`)) {
                        message = i18next.t(
                            `validation:errors.${rawErrorValue}`
                        )
                    } else {
                        message = rawErrorValue
                    }
                }

                handleFormError(errorFieldName, { message })
            }
        })
    }
}

export const formatDateFromApi = date => {
    return date ? new Date(date) : null
}

export const formatDateToApi = date => {
    return date ? dayjs(date).toISOString() : null
}

export const remapSelectOptions = (array, hasIdIntoLabel = true) => {
    return array?.map(item => {
        const fullName = item?.full_name ? item?.full_name : item?.name
        return {
            value: item.id,
            label: hasIdIntoLabel ? `#${item.id} - ${fullName}` : fullName,
            data: {
                ...item,
            },
        }
    })
}

export const remapSpecificitiesOptions = array => {
    return array?.map(item => {
        return {
            value: item.id,
            label: item?.key_name ? item?.key_name : item?.business_name,
            data: {
                ...item,
            },
        }
    })
}

export const remapAPIForScopes = array => {
    return array.map(item => {
        return {
            value: item.id,
            label:
                item?.key_name.charAt(0).toUpperCase() +
                item?.key_name.slice(1),
        }
    })
}

export const capitalize = (input = '') => {
    return input.length ? input.charAt(0).toUpperCase() + input.slice(1) : input
}

export const remapAPIForResponsibilities = array => {
    return array?.map(item => {
        return {
            value: item.id,
            label: capitalize(item?.key_name),
        }
    })
}

export const remapActionType = type => {
    switch (type) {
        case 'contract_created':
            return i18next.t(
                'pages:settings.sidebar_left.action_type.contract_created'
            )
        case 'contract_updated':
            return i18next.t(
                'pages:settings.sidebar_left.action_type.contract_updated'
            )
        case 'contract_deleted':
            return i18next.t(
                'pages:settings.sidebar_left.action_type.contract_deleted'
            )
        case 'team_activated':
            return i18next.t(
                'pages:settings.sidebar_left.action_type.team_activated'
            )
        case 'team_deactivated':
            return i18next.t(
                'pages:settings.sidebar_left.action_type.team_deactivated'
            )
        case 'user_created':
            return i18next.t(
                'pages:settings.sidebar_left.action_type.user_created'
            )
        case 'user_deleted':
            return i18next.t(
                'pages:settings.sidebar_left.action_type.user_deleted'
            )
        case 'user_activated':
            return i18next.t(
                'pages:settings.sidebar_left.action_type.user_actived'
            )
        case 'user_deactivated':
            return i18next.t(
                'pages:settings.sidebar_left.action_type.user_deactived'
            )
        case 'team_created':
            return i18next.t(
                'pages:settings.sidebar_left.action_type.team_created'
            )
        case 'team_deleted':
            return i18next.t(
                'pages:settings.sidebar_left.action_type.team_deleted'
            )
        default:
            return i18next.t('pages:settings.sidebar_left.action_type.default')
    }
}

export const remapProjectActionType = type => {
    switch (type) {
        case 'standard_project_created':
            return i18next.t(
                'pages:project.sidebar_left.action_type.standard_project_created'
            )
        case 'standard_project_modified':
            return i18next.t(
                'pages:project.sidebar_left.action_type.standard_project_modified'
            )
        case 'standard_project_activated':
            return i18next.t(
                'pages:project.sidebar_left.action_type.standard_project_activated'
            )
        case 'standard_project_deactivated':
            return i18next.t(
                'pages:project.sidebar_left.action_type.standard_project_deactivated'
            )
        case 'standard_project_sal_created':
            return i18next.t(
                'pages:project.sidebar_left.action_type.standard_project_sal_created'
            )
        case 'standard_project_sal_modified':
            return i18next.t(
                'pages:project.sidebar_left.action_type.standard_project_sal_modified'
            )
        case 'standard_project_sal_deleted':
            return i18next.t(
                'pages:project.sidebar_left.action_type.standard_project_sal_deleted'
            )
        case 'standard_project_sal_completed':
            return i18next.t(
                'pages:project.sidebar_left.action_type.standard_project_sal_completed'
            )
        case 'standard_project_sal_not_completed':
            return i18next.t(
                'pages:project.sidebar_left.action_type.standard_project_sal_not_completed'
            )
        default:
            return i18next.t('pages:project.sidebar_left.action_type.default')
    }
}
