import { Link } from 'react-router-dom'

import { StyledBudget } from '@/pages/dashboard/styles.js'

import i18next from '../../../../i18n.js'

/* eslint-disable */

export const COLUMNS_DASHBOARD_ELEVENTH = {
    date: 'date',
    code: 'code',
    name: 'name',
    team: 'team',
    typology: 'typology',
    cost: 'cost',
}
export const columnsEleventh = [
    {
        alignment: 'left',
        label: i18next.t('customer.tables.billing.date'),
        name: COLUMNS_DASHBOARD_ELEVENTH.date,
        width: '10%',
        sortable: false,
        divider: false,
    },
    {
        alignment: 'left',
        label: i18next.t('dashboard.table.code'),
        name: COLUMNS_DASHBOARD_ELEVENTH.code,
        width: '8%',
        sortable: false,
        divider: true,
    },
    {
        alignment: 'left',
        label: i18next.t('settings.tables.name'),
        name: COLUMNS_DASHBOARD_ELEVENTH.name,
        width: '22%',
        sortable: false,
        divider: true,
    },
    {
        alignment: 'left',
        label: i18next.t('general.team'),
        name: COLUMNS_DASHBOARD_ELEVENTH.team,
        width: '30%',
        sortable: false,
        divider: true,
    },
    {
        alignment: 'left',
        label: i18next.t('general.typology'),
        name: COLUMNS_DASHBOARD_ELEVENTH.typology,
        width: '20%',
        sortable: false,
        divider: true,
    },
    {
        alignment: 'left',
        label: i18next.t('finance.table.cost'),
        name: COLUMNS_DASHBOARD_ELEVENTH.cost,
        width: '10%',
        sortable: false,
        divider: true,
    },
]

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersEleventh = () => ({
    [COLUMNS_DASHBOARD_ELEVENTH.date]: ({ payroll }) => <p>{payroll?.date}</p>,
    [COLUMNS_DASHBOARD_ELEVENTH.code]: ({ payroll }) => (
        <p>{`#${payroll?.code}`}</p>
    ),
    [COLUMNS_DASHBOARD_ELEVENTH.name]: ({ payroll }) => (
        <Link>{payroll?.name}</Link>
    ),
    [COLUMNS_DASHBOARD_ELEVENTH.team]: ({ payroll }) => <p>{payroll?.team}</p>,
    [COLUMNS_DASHBOARD_ELEVENTH.typology]: ({ payroll }) => {
        return <p>{payroll?.typology}</p>
    },
    [COLUMNS_DASHBOARD_ELEVENTH.cost]: ({ payroll }) => (
        <StyledBudget>{`€ ${payroll?.cost}`}</StyledBudget>
    ),
})
