import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { CurrencyIcon, TrashIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import Datepicker from '@/components/date-picker/DatePicker.jsx'
import InputCurrency from '@/components/input-currency/InputCurrency.jsx'
import CreateSelect from '@/components/select/CreateSelect.jsx'
import Select from '@/components/select/Select.jsx'
import TextArea from '@/components/text-area/TextArea.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import { StyledContainerFormActions } from '@/pages/customers/detail/customer-form/billing-form/styles.js'
import { StyledContainerDelete } from '@/pages/customers/detail/customer-tables/table-projects/projects-form/styles.js'
import { DEFAULT_COSTS_PROJECTS_MOCK } from '@/pages/projects/detail/detail-r&d/r&d-form/costs-rd-form/costsRDForm.schema.js'
import { COSTS_PROJECTS_MODEL } from '@/pages/projects/detail/detail-standard-projects/project-form/costs-form/costsForm.schema.js'
import { StyledForm } from '@/pages/projects/detail/detail-standard-projects/project-form/costs-form/styles.js'
import {
    useAddCostMutation,
    useCreateSupplierMutation,
    useGetCostsQuery,
    useGlobalProjectIDQuery,
    useUpdateCostMutation,
} from '@/services/projects/projectsApiSlice.js'
import {
    formatDateFromApi,
    formatDateToApi,
    handleApiError,
    remapSpecificitiesOptions,
} from '@/utilities/api/helpers.js'
import { COSTS_STATUS_OPTIONS } from '@/utilities/constants/list.js'
import {
    filterEmptyKeys,
    iterateOverDirtyFields,
    retrieveSingleValueForRs,
    translateOptions,
} from '@/utilities/helpers.js'

const CostsForm = ({
    isEdit,
    closeModal,
    dataForm,
    openDialogDelete,
    sals,
    suppliers,
    isFinance,
    isCustomer,
}) => {
    const { t } = useTranslation()
    const showError = useError()
    const { triggerAlert } = useAlert()
    const { id: globalSalID } = useParams()
    //take id from global sal id
    const { data: data } = useGlobalProjectIDQuery(
        {
            globalSalID: globalSalID
                ? globalSalID
                : dataForm?.data?.global_project_id,
        },
        {
            skip: isCustomer,
        }
    )

    const id = data?.id
    const [isSupplierCreating, setIsSupplierCreating] = useState(false)

    const [addCost] = useAddCostMutation()
    const [updateCost] = useUpdateCostMutation()
    const [createSuppliers] = useCreateSupplierMutation()

    const { data: costsIndex } = useGetCostsQuery({
        projectID: isFinance ? dataForm?.id : id,
    })
    const cost = isFinance
        ? dataForm?.data
        : costsIndex?.find(cost => cost.id === dataForm?.id)

    const {
        register,
        control,
        handleSubmit,
        reset,
        setError,
        setValue,
        watch,
        formState: {
            errors,
            isDirty,
            touchedFields,
            dirtyFields,
            isSubmitting,
        },
    } = useFormContext()

    const onSubmit = async data => {
        try {
            const remappedData = {
                ...data,
                [COSTS_PROJECTS_MODEL.date]: formatDateToApi(
                    data[COSTS_PROJECTS_MODEL.date]
                ),
                [COSTS_PROJECTS_MODEL.sal]:
                    data[COSTS_PROJECTS_MODEL.sal]?.value,
                [COSTS_PROJECTS_MODEL.status]:
                    data[COSTS_PROJECTS_MODEL.status]?.value,
                [COSTS_PROJECTS_MODEL.taxable]: parseFloat(
                    data[COSTS_PROJECTS_MODEL.taxable]
                ),
                [COSTS_PROJECTS_MODEL.supplier]:
                    data[COSTS_PROJECTS_MODEL.supplier].value,
            }
            if (isEdit) {
                await updateCost({
                    projectID: id,
                    salID: data[COSTS_PROJECTS_MODEL.sal]?.value,
                    expensesID: dataForm?.id,
                    data: iterateOverDirtyFields(dirtyFields, remappedData),
                }).unwrap()
            } else {
                const filteredData = filterEmptyKeys(remappedData)
                await addCost({
                    projectID: id,
                    salID: data[COSTS_PROJECTS_MODEL.sal]?.value,
                    data: filteredData,
                }).unwrap()
            }
            closeModal()
            reset(DEFAULT_COSTS_PROJECTS_MOCK)
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: isEdit
                    ? t('commons:toast.updated_success')
                    : t('commons:toast.created_success'),
            })
        } catch (error) {
            handleApiError({
                error,
                handleGeneralError: showError,
                handleFormError: setError,
            })
        }
    }

    useEffect(() => {
        if (isEdit && cost) {
            reset({
                ...cost,
                [COSTS_PROJECTS_MODEL.date]: formatDateFromApi(cost.date),
                [COSTS_PROJECTS_MODEL.status]: retrieveSingleValueForRs(
                    translateOptions(COSTS_STATUS_OPTIONS),
                    cost[COSTS_PROJECTS_MODEL.status]
                ),
                [COSTS_PROJECTS_MODEL.sal]: retrieveSingleValueForRs(
                    sals,
                    cost[COSTS_PROJECTS_MODEL.sal]?.id
                ),
                [COSTS_PROJECTS_MODEL.supplier]: retrieveSingleValueForRs(
                    remapSpecificitiesOptions(suppliers),
                    cost.supplier_id
                ),
            })
        }
    }, [cost, isEdit])

    const handleCreate = async inputValue => {
        setIsSupplierCreating(true)
        try {
            const data = await createSuppliers({
                data: {
                    business_name: inputValue,
                },
            }).unwrap()
            setValue(COSTS_PROJECTS_MODEL.supplier, {
                label: data.business_name,
                value: data.id,
            })
        } catch (error) {
            handleApiError({
                error,
                handleGeneralError: showError,
                handleFormError: setError,
            })
        } finally {
            setIsSupplierCreating(false)
        }
    }

    const idToDelete = {
        salID: cost?.sal?.id,
        expensesID: dataForm?.id,
    }

    const onlySalCompleted = sals?.filter(
        sal => sal.data?.is_completed === false
    )

    return (
        <StyledForm
            isEdit={isEdit}
            onSubmit={handleSubmit(onSubmit)}
            className="container-fluid d-grid p-0 gap-3"
        >
            <div className="row">
                <div className="col-6">
                    <Datepicker
                        required
                        label={t('projects.fields.labels.date')}
                        placeholder={t(
                            'billing_project.fields.placeholder.date'
                        )}
                        control={control}
                        name={COSTS_PROJECTS_MODEL.date}
                        touched={touchedFields[COSTS_PROJECTS_MODEL.date]}
                        errors={errors[COSTS_PROJECTS_MODEL.date]}
                        showMonthDropdown
                        showYearDropdown
                        pickerType={'days'}
                    />
                </div>

                <div className="col-6">
                    <Select
                        required
                        name={COSTS_PROJECTS_MODEL.status}
                        label={t('general.status')}
                        placeholder={t('projects.fields.placeholder.status')}
                        control={control}
                        touched={touchedFields[COSTS_PROJECTS_MODEL.status]}
                        errors={errors[COSTS_PROJECTS_MODEL.status]}
                        size="large"
                        options={translateOptions(COSTS_STATUS_OPTIONS)}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <InputCurrency
                        required
                        label={t('projects.fields.labels.taxable')}
                        placeholder={t('projects.fields.placeholder.taxable')}
                        width="100%"
                        size={'large'}
                        icon={<CurrencyIcon />}
                        iconPosition={'right'}
                        control={control}
                        errors={errors[COSTS_PROJECTS_MODEL.taxable]}
                        name={COSTS_PROJECTS_MODEL.taxable}
                        inputProps={{
                            value: watch(COSTS_PROJECTS_MODEL.taxable),
                        }}
                    />
                </div>
                <div className="col-6">
                    <Select
                        required
                        name={COSTS_PROJECTS_MODEL.sal}
                        label={t('billing_project.fields.labels.sal')}
                        placeholder={t(
                            'billing_project.fields.placeholder.sal'
                        )}
                        control={control}
                        touched={touchedFields[COSTS_PROJECTS_MODEL.sal]}
                        errors={errors[COSTS_PROJECTS_MODEL.sal]}
                        size="large"
                        options={onlySalCompleted}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <CreateSelect
                        label={t('costs_project.fields.labels.supplier')}
                        placeholder={t(
                            'costs_project.fields.placeholder.supplier'
                        )}
                        options={remapSpecificitiesOptions(suppliers)}
                        hideSelectedOptions={false}
                        isClearable
                        onCreateOption={handleCreate}
                        control={control}
                        name={COSTS_PROJECTS_MODEL.supplier}
                        touched={touchedFields[COSTS_PROJECTS_MODEL.supplier]}
                        errors={errors[COSTS_PROJECTS_MODEL.supplier]}
                        isLoading={isSupplierCreating}
                        disabled={isSupplierCreating}
                        haveDropdownIcon={false}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <TextArea
                        required
                        label={t('projects.fields.labels.note')}
                        placeholder={t('projects.fields.placeholder.note')}
                        width="100%"
                        size={'large'}
                        errors={errors[COSTS_PROJECTS_MODEL.note]}
                        {...register(COSTS_PROJECTS_MODEL.note)}
                        resize={'vertical'}
                    />
                </div>
            </div>

            {isEdit && (
                <div className="row">
                    <StyledContainerDelete className="col-12">
                        <TrashIcon />
                        <p
                            onClick={() => {
                                openDialogDelete(dataForm?.id, idToDelete)
                            }}
                        >
                            {t('costs_project.actions.delete_cost')}
                        </p>
                    </StyledContainerDelete>
                </div>
            )}

            <StyledContainerFormActions className="row d-flex">
                <div className="col-md-4">
                    <Button
                        size="large"
                        color={'neutral'}
                        isUppercase
                        onClick={() => {
                            closeModal(isDirty)
                        }}
                    >
                        {t('commons:button.cancel')}
                    </Button>
                </div>
                <div className="col-md-8">
                    <Button
                        type="submit"
                        size="large"
                        isUppercase
                        disabled={(!isDirty && isEdit) || isSubmitting}
                    >
                        {isEdit
                            ? t('commons:button.save')
                            : t('commons:button.create')}
                    </Button>
                </div>
            </StyledContainerFormActions>
        </StyledForm>
    )
}

export default CostsForm

CostsForm.propTypes = {
    isEdit: PropTypes.bool,
    closeModal: PropTypes.func,
    dataForm: PropTypes.object,
    openDialogDelete: PropTypes.func,
    sals: PropTypes.array,
    suppliers: PropTypes.array,
    isFinance: PropTypes.bool,
    isCustomer: PropTypes.bool,
}
