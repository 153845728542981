import ReactDOM from 'react-dom/client'

import { asyncInitGoogleClient } from '@/services/social-login/google-identity-service.js'

import App from './App.jsx'

import '@/theme/bootstrap-grid.min.css'
import '@/theme/bootstrap-utilities.min.css'

import './i18n'

const startApp = () => {
    ReactDOM.createRoot(document.getElementById('root')).render(<App />)
}

//Initialize auth services before starting the app

const initAuthServices = async () => {
    const authServices = [asyncInitGoogleClient()]
    try {
        await Promise.allSettled(authServices)
    } catch (err) {
        console.log('Cannot load auth services', err)
    } finally {
        startApp()
    }
}

initAuthServices()
