import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { LinkIcon, TrashIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import DialogDelete from '@/components/dialog-delete/DialogDelete.jsx'
import DialogSafeClose from '@/components/dialog-safe-close/DialogSafeClose.jsx'
import Divider from '@/components/divider/Divider.jsx'
import Input from '@/components/input/Input.jsx'
import PhoneNumberInput from '@/components/phone-number-input/PhoneNumberInput.jsx'
import Select from '@/components/select/Select.jsx'
import SidePopover from '@/components/side-popover/SidePopover.jsx'
import Spinner from '@/components/spinner/Spinner.jsx'
import TextArea from '@/components/text-area/TextArea.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import { StyledContainerDelete } from '@/pages/customers/detail/customer-tables/table-projects/projects-form/styles.js'
import {
    CUSTOMERS_MODEL,
    DEFAULT_CUSTOMERS_MOCK,
    validationSchema,
} from '@/pages/customers/list/customers-modal/customers.schema.js'
import { StyledFormCustomer } from '@/pages/customers/list/styles.js'
import {
    useAddCustomerMutation,
    useUpdateCustomerMutation,
} from '@/services/customers/customersApiSlice.js'
import { handleApiError } from '@/utilities/api/helpers.js'
import { STATUS_OPTIONS } from '@/utilities/constants/list.js'
import {
    filterEmptyKeys,
    iterateOverDirtyFields,
    retrieveSingleValueForRs,
    translateOptions,
} from '@/utilities/helpers.js'

const CustomersModalWrapper = ({
    onClose,
    userSelected,
    deleteUser,
    isEdit,
    openDialogToDelete,
    setOpenDialogToDelete,
    successManagers,
    canNotDelete,
}) => {
    const { t } = useTranslation()
    const showError = useError()
    const [isLoading, setIsLoading] = useState(false)
    const { triggerAlert } = useAlert()
    const [openDialogToConfirm, setOpenDialogToConfirm] = useState(false)

    const [addCustomer] = useAddCustomerMutation()
    const [updateCustomer] = useUpdateCustomerMutation()

    const methods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'onChange',
        nativeValidation: false,
        shouldFocusError: true,
        resolver: yupResolver(validationSchema),
        defaultValues: DEFAULT_CUSTOMERS_MOCK,
    })

    const {
        register,
        control,
        handleSubmit,
        reset,
        setError,
        setValue,
        formState: {
            errors,
            isDirty,
            dirtyFields,
            touchedFields,
            isSubmitting,
        },
    } = methods

    const closeModal = isDirty => {
        if (isDirty) {
            setOpenDialogToConfirm(true)
        } else {
            onClose()
        }
    }

    const onSubmit = async data => {
        setIsLoading(true)

        const remappedData = {
            ...data,
            [CUSTOMERS_MODEL.success_manager]:
                data[CUSTOMERS_MODEL.success_manager].value,
            [CUSTOMERS_MODEL.link_drive]: data[CUSTOMERS_MODEL.link_drive]
                ? data[CUSTOMERS_MODEL.link_drive]
                : null,
            [CUSTOMERS_MODEL.status]: data[CUSTOMERS_MODEL.status].value,
        }
        try {
            if (isEdit) {
                await updateCustomer({
                    ID: userSelected.id,
                    data: iterateOverDirtyFields(dirtyFields, remappedData),
                }).unwrap()
            } else {
                const filteredData = filterEmptyKeys(remappedData)
                await addCustomer({
                    data: filteredData,
                }).unwrap()
            }
            onClose()
            reset(DEFAULT_CUSTOMERS_MOCK)
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: t('commons:toast.created_success'),
            })
        } catch (error) {
            handleApiError({
                error,
                handleGeneralError: showError,
                handleFormError: setError,
            })
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (isEdit && userSelected) {
            reset({
                ...userSelected,
                [CUSTOMERS_MODEL.vat]: userSelected.vat,
                [CUSTOMERS_MODEL.success_manager]: {
                    value: userSelected.success_manager?.id,
                    label: userSelected.success_manager?.full_name,
                },
                [CUSTOMERS_MODEL.status]: retrieveSingleValueForRs(
                    translateOptions(STATUS_OPTIONS),
                    userSelected[CUSTOMERS_MODEL.status]
                ),
            })
        }
    }, [userSelected, isEdit])

    const [haveTouchedVat, setHaveTouchedVat] = useState(false)
    const validateInput = value => {
        setHaveTouchedVat(true)
        const regex = /^(?=[A-Z\d]{0,13}$)([A-Z]{0,2}[0-9]*)$/
        if (!regex.test(value)) {
            setValue(CUSTOMERS_MODEL.vat, value.slice(0, 13).toUpperCase())
            setError(CUSTOMERS_MODEL.vat, {
                type: 'manual',
                message: t('validation:general_form.vat_check'),
            })
        }
        setValue(CUSTOMERS_MODEL.vat, value.toUpperCase())
    }

    const havePhone = useWatch({
        control,
        name: CUSTOMERS_MODEL.phone_number,
    })

    const successManagerOptions = successManagers?.filter(
        successManager => successManager.data?.is_success_manager
    )
    return (
        <>
            {isLoading && <Spinner />}
            <DialogDelete
                open={openDialogToDelete}
                onClose={() => setOpenDialogToDelete(false)}
                onDelete={deleteUser}
            />
            {openDialogToConfirm && (
                <DialogSafeClose
                    goBack={() => {
                        onClose()
                    }}
                    onClose={() => setOpenDialogToConfirm(false)}
                    open={openDialogToConfirm}
                />
            )}
            <SidePopover
                onClose={onClose}
                title={
                    isEdit
                        ? t('customers.actions.edit_customer')
                        : t('customers.actions.create_customer')
                }
            >
                <StyledFormCustomer
                    onSubmit={handleSubmit(onSubmit)}
                    className="container-fluid d-grid p-0 gap-3"
                    isEdit={isEdit}
                >
                    <div className="row">
                        <div className="col-6">
                            <Input
                                required
                                label={t(
                                    'customers.fields.label.business_name'
                                )}
                                placeholder={t(
                                    'customers.fields.placeholder.business_name'
                                )}
                                width="100%"
                                size={'large'}
                                errors={errors[CUSTOMERS_MODEL.business_name]}
                                {...register(CUSTOMERS_MODEL.business_name)}
                            />
                        </div>
                        <div className="col-6">
                            <Input
                                inputProps={{
                                    minLength: 12,
                                    maxLength: 13,
                                }}
                                required
                                label={t('general.vat_number')}
                                placeholder={t(
                                    'customers.fields.placeholder.vat'
                                )}
                                width="100%"
                                size={'large'}
                                errors={errors[CUSTOMERS_MODEL.vat]}
                                {...register(CUSTOMERS_MODEL.vat)}
                                onChange={e => validateInput(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <Divider
                                orientation={'horizontal'}
                                width={'100%'}
                                height={'1px'}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <Select
                                required
                                label={t('general.success_manager')}
                                placeholder={t('general.success_manager')}
                                width="100%"
                                size={'large'}
                                errors={errors[CUSTOMERS_MODEL.success_manager]}
                                name={CUSTOMERS_MODEL.success_manager}
                                control={control}
                                options={successManagerOptions}
                                isSearchable
                                isOptionDisabled={options =>
                                    !options?.data?.is_active
                                }
                            />
                        </div>

                        <div className="col-6">
                            <Input
                                required
                                label={t('customers.fields.label.email')}
                                placeholder={t(
                                    'customers.fields.placeholder.email'
                                )}
                                width="100%"
                                size={'large'}
                                errors={errors[CUSTOMERS_MODEL.email]}
                                {...register(CUSTOMERS_MODEL.email)}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <Select
                                required
                                name={CUSTOMERS_MODEL.status}
                                label={t('general.status')}
                                placeholder={t(
                                    'projects.fields.placeholder.select'
                                )}
                                control={control}
                                options={translateOptions(STATUS_OPTIONS)}
                                touched={touchedFields[CUSTOMERS_MODEL.status]}
                                errors={errors[CUSTOMERS_MODEL.status]}
                                size="large"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <Input
                                inputProps={{
                                    minLength: 6,
                                    maxLength: 7,
                                }}
                                label={t('customers.fields.label.sdi')}
                                placeholder={t(
                                    'customers.fields.placeholder.sdi'
                                )}
                                width="100%"
                                size={'large'}
                                errors={errors[CUSTOMERS_MODEL.sdi]}
                                {...register(CUSTOMERS_MODEL.sdi)}
                            />
                        </div>

                        <div className="col-6">
                            <Input
                                label={t('customers.fields.label.pec')}
                                placeholder={t(
                                    'customers.fields.placeholder.pec'
                                )}
                                width="100%"
                                size={'large'}
                                errors={errors[CUSTOMERS_MODEL.pec]}
                                {...register(CUSTOMERS_MODEL.pec)}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <Divider
                                orientation={'horizontal'}
                                width={'100%'}
                                height={'1px'}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <Input
                                label={t('customers.fields.label.link_drive')}
                                placeholder={t(
                                    'customers.fields.placeholder.link_drive'
                                )}
                                width="100%"
                                size={'large'}
                                errors={errors[CUSTOMERS_MODEL.link_drive]}
                                {...register(CUSTOMERS_MODEL.link_drive)}
                                icon={<LinkIcon />}
                                iconPosition={'right'}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <Divider
                                orientation={'horizontal'}
                                width={'100%'}
                                height={'1px'}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <Input
                                label={t(
                                    'customers.fields.label.billing_address'
                                )}
                                placeholder={t(
                                    'customers.fields.placeholder.billing_address'
                                )}
                                width="100%"
                                size={'large'}
                                errors={errors[CUSTOMERS_MODEL.billing_address]}
                                {...register(CUSTOMERS_MODEL.billing_address)}
                            />
                        </div>

                        <div className="col-6">
                            <Input
                                label={t('customers.fields.label.city')}
                                placeholder={t(
                                    'customers.fields.placeholder.city'
                                )}
                                width="100%"
                                size={'large'}
                                errors={errors[CUSTOMERS_MODEL.city]}
                                {...register(CUSTOMERS_MODEL.city)}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <Input
                                inputProps={{
                                    maxLength: 5,
                                    minLength: 5,
                                    onInput: e => {
                                        e.target.value = e.target.value
                                            .replace(/[^0-9]/g, '')
                                            .trim()
                                    },
                                }}
                                label={t('customers.fields.label.cap')}
                                placeholder={t(
                                    'customers.fields.placeholder.cap'
                                )}
                                width="100%"
                                size={'large'}
                                errors={errors[CUSTOMERS_MODEL.cap]}
                                {...register(CUSTOMERS_MODEL.cap)}
                            />
                        </div>

                        <div className="col-6">
                            <PhoneNumberInput
                                label={t(`settings.fields.label.phone_number`)}
                                placeholder={t(
                                    `settings.fields.placeholder.phone_number`
                                )}
                                touched={
                                    touchedFields[
                                        CUSTOMERS_MODEL.phone_number
                                    ] &&
                                    havePhone !== '' &&
                                    havePhone !== null
                                }
                                errors={errors[CUSTOMERS_MODEL.phone_number]}
                                control={control}
                                name={CUSTOMERS_MODEL.phone_number}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <Divider
                                orientation={'horizontal'}
                                width={'100%'}
                                height={'1px'}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <TextArea
                                label={t('customers.fields.label.note')}
                                placeholder={t(
                                    'customers.fields.placeholder.note'
                                )}
                                width="100%"
                                size={'large'}
                                errors={errors[CUSTOMERS_MODEL.note]}
                                {...register(CUSTOMERS_MODEL.note)}
                                resize={'vertical'}
                            />
                        </div>
                    </div>

                    {isEdit && (
                        <div className="row">
                            {!canNotDelete && (
                                <StyledContainerDelete
                                    className="col-12"
                                    onClick={() => {
                                        setOpenDialogToDelete(true)
                                    }}
                                >
                                    <TrashIcon />
                                    <p>
                                        {t('customers.actions.delete_customer')}
                                    </p>
                                </StyledContainerDelete>
                            )}
                        </div>
                    )}

                    <div className="row d-flex mt-auto">
                        <div className="col-md-4">
                            <Button
                                size="large"
                                color={'neutral'}
                                isUppercase
                                onClick={() => closeModal(isDirty)}
                            >
                                {t('commons:button.cancel')}
                            </Button>
                        </div>
                        <div className="col-md-8">
                            <Button
                                type="submit"
                                size="large"
                                isUppercase
                                disabled={
                                    (!isDirty && !haveTouchedVat && isEdit) ||
                                    isSubmitting
                                }
                            >
                                {isEdit
                                    ? t('commons:button.save')
                                    : t('commons:button.create')}
                            </Button>
                        </div>
                    </div>
                </StyledFormCustomer>
            </SidePopover>
        </>
    )
}

export default CustomersModalWrapper

CustomersModalWrapper.propTypes = {
    onClose: PropTypes.func.isRequired,
    userSelected: PropTypes.any,
    openDialogToDelete: PropTypes.bool,
    deleteUser: PropTypes.func,
    isEdit: PropTypes.bool,
    setOpenDialogToDelete: PropTypes.func,
    successManagers: PropTypes.array,
    canNotDelete: PropTypes.bool,
}
