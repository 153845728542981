import { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import DialogDelete from '@/components/dialog-delete/DialogDelete.jsx'
import DialogSafeClose from '@/components/dialog-safe-close/DialogSafeClose.jsx'
import SubHeader from '@/components/subheader/SubHeader'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import FinanceFiltersRender from '@/pages/finance/finance-filters/FinanceFiltersRender.jsx'
import FinanceRenderForm from '@/pages/finance/finance-form/FinanceRenderForm.jsx'
import FinanceTables from '@/pages/finance/finance-tables/FinanceTabs.jsx'
import { StyledContainerFluid } from '@/pages/settings/styles.js'
import { useGetCustomersListQuery } from '@/services/customers/customersApiSlice.js'
import {
    useGetSalsQuery,
    useGetSuppliersQuery,
} from '@/services/projects/projectsApiSlice.js'
import {
    useGetMembersQuery,
    useGetResponsibilitiesQuery,
    useGetTeamsQuery,
} from '@/services/settings/settingApiSlice.js'
import {
    handleApiError,
    remapAPIForResponsibilities,
    remapSelectOptions,
} from '@/utilities/api/helpers.js'

const Finance = () => {
    const { t } = useTranslation()
    const { triggerAlert } = useAlert()
    const showError = useError()
    const [currentTab, setCurrentTab] = useState(t('finance.tabs.payroll'))
    const [openDialogToConfirm, setOpenDialogToConfirm] = useState(false)
    const [openDialogToDelete, setOpenDialogToDelete] = useState(false)
    const [dataForm, setDataForm] = useState({
        isOpen: false,
        typeForm: null,
        id: null,
        isEdit: false,
        data: null,
    })

    const [filters, setFilters] = useState({})

    const { data: teams } = useGetTeamsQuery()
    const { data: applicants } = useGetMembersQuery()
    const { data: successManagers } = useGetMembersQuery({
        is_success_manager: true,
    })
    const { data: responsibilities } = useGetResponsibilitiesQuery({})
    const { data: customers } = useGetCustomersListQuery()
    const { data: sals } = useGetSalsQuery(
        { ID: dataForm?.id },
        { skip: !dataForm?.id }
    )
    const { data: suppliers } = useGetSuppliersQuery()

    const openEditSidebar = (id, type, data) => {
        setDataForm({
            isOpen: true,
            formType: type,
            id: id,
            isEdit: true,
            data: data,
        })
    }

    const openDialogDelete = () => {
        setOpenDialogToDelete(true)
    }

    const handleDelete = async () => {
        try {
            setOpenDialogToDelete(false)
            setDataForm({})
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: t('commons:dialog.success'),
            })
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        }
    }

    const { search } = useLocation()

    let redirectToCorrectTab = useMemo(
        () => new URLSearchParams(search),
        [search]
    )

    useEffect(() => {
        if (redirectToCorrectTab.get('section')) {
            setCurrentTab(redirectToCorrectTab.get('section'))
        } else {
            setCurrentTab(t('finance.tabs.payroll'))
        }
    }, [redirectToCorrectTab])

    return (
        <>
            <StyledContainerFluid className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-4">
                        {currentTab && (
                            <SubHeader title={t('general.finance')}>
                                <FinanceFiltersRender
                                    currentTab={currentTab}
                                    setFilters={setFilters}
                                    filters={filters}
                                />
                            </SubHeader>
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        {currentTab && (
                            <FinanceTables
                                setCurrentTab={setCurrentTab}
                                currentTab={currentTab}
                                setDataForm={setDataForm}
                                openEditSidebar={openEditSidebar}
                                openDialogDelete={openDialogDelete}
                                filters={filters}
                                setFilters={setFilters}
                            />
                        )}
                    </div>
                </div>

                <DialogDelete
                    open={openDialogToDelete}
                    onClose={() => setOpenDialogToDelete(false)}
                    onDelete={handleDelete}
                />

                {openDialogToConfirm && (
                    <DialogSafeClose
                        goBack={() => {
                            setDataForm({})
                            setOpenDialogToConfirm(false)
                        }}
                        onClose={() => setOpenDialogToConfirm(false)}
                        open={openDialogToConfirm}
                    />
                )}

                {dataForm?.isOpen && (
                    <FinanceRenderForm
                        dataForm={dataForm}
                        isEdit={!!dataForm.isEdit}
                        onClose={() => setDataForm({})}
                        setOpenDialogToConfirm={setOpenDialogToConfirm}
                        openDialogDelete={openDialogDelete}
                        applicants={remapSelectOptions(applicants?.data)}
                        customers={remapSelectOptions(customers?.data)}
                        teams={teams?.data}
                        successManagers={remapSelectOptions(
                            successManagers?.data
                        )}
                        responsibilities={remapAPIForResponsibilities(
                            responsibilities
                        )}
                        sals={remapSelectOptions(sals)}
                        suppliers={suppliers}
                    />
                )}
            </StyledContainerFluid>
        </>
    )
}

export default Finance
