import dayjs from 'dayjs'

export const remappedDataFinalBalance = dataArray => {
    const remappedData = []

    dataArray?.forEach(data => {
        const customer = data.customer

        const remappedCustomerData = {
            id: customer?.id,
            name: customer?.name,
            children: [],
            summaries: [],
        }

        data?.summaries?.forEach(summary => {
            return remappedCustomerData.summaries.push({
                wallet_balance: summary?.wallet,
                total_amount: summary?.total_to_invoice,
                type: summary?.type,
                date: summary?.month,
                invoices: summary?.invoices,
                key_name: `month_${dayjs(summary?.month).get('month') + 1}`,
            })
        })

        data.projects?.forEach(project => {
            const projectData = {
                id: project?.project_id,
                name: project?.name,
                months: [],
            }

            project.dates?.forEach(date => {
                return projectData.months.push({
                    budget: date?.amount,
                    hours: date?.confirmed_hours,
                    type: date?.type,
                    key_name: `month_${dayjs(date?.month).get('month')}`,
                })
            })

            remappedCustomerData.children.push(projectData)
        })

        remappedData.push(remappedCustomerData)
    })

    return remappedData
}
