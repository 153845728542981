import styled, { css } from 'styled-components'

export const StyledContainerFinancialImport = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: ${theme.spacing * 4}px;
        grid-column-gap: ${theme.spacing * 3}px;
        margin-bottom: ${theme.spacing}px;
    `}
`
