import PropTypes from 'prop-types'

import {
    StyledSubHeaderRoot,
    StyledSubHeaderTitle,
    StyledSubHeaderChildren,
} from './styles'

const SubHeader = ({ title, status, children }) => {
    return (
        <StyledSubHeaderRoot>
            <StyledSubHeaderTitle>
                <h2>{title}</h2>
                {status}
            </StyledSubHeaderTitle>
            {children && (
                <StyledSubHeaderChildren>{children}</StyledSubHeaderChildren>
            )}
        </StyledSubHeaderRoot>
    )
}

export default SubHeader

SubHeader.propTypes = {
    title: PropTypes.string,
    status: PropTypes.element,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
}
