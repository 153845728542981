import PropTypes from 'prop-types'
import { useState } from 'react'

import Table from '@/components/table/index.jsx'
import { tableHeight } from '@/utilities/helpers'

const initialData = [
    //TODO: put into commons and delete mock when api is ready
    {
        id: 1,
        project: 'ciao',
        budget_to_plan: 1000,
        sal: '618 - Stage 2',
        expiration_date: '22/04/2023',
        available_budget: 10,
        downloaded_budget: 9000,
        total: '120% (538 di 448h)',
        total_percentage: '20',
        month: 'Maggio',
        time_to_be_confirmed: '24 di 50h',
        to_expire: '9.264,00',
        customer_name: 'Ciao',
        typology: 'Ciao',
        date: '22/04/2023',
        taxable: '10',
        stage: {
            id: 1,
            name: 'Ciao',
            status: 'Prevista',
        },
        status: 'Prevista',

        payroll: {
            date: '22/04/2023',
            code: '001',
            name: 'Ciao',
            team: 'Team Ciao',
            typology: 'Dipendente',
            cost: '10',
        },
    },
]

const DashboardTables = ({ buttonSelected }) => {
    const [isLoading, setIsLoading] = useState(false)

    console.log('setIsLoading', setIsLoading)
    return (
        <Table
            columns={buttonSelected.columns}
            columnsRenderers={buttonSelected.columnsRenderers}
            data={initialData}
            isLoading={isLoading}
            isPaginated
            bodyheight={tableHeight([initialData])}
        />
    )
}

export default DashboardTables

DashboardTables.propTypes = {
    buttonSelected: PropTypes.object,
}
