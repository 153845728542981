import PropTypes from 'prop-types'

import {
    StatusIconError,
    StatusIconSuccess,
    StatusIconWarning,
} from '@/assets/icons/icons.jsx'
import { StyledContainerStageStatus } from '@/components/stage-status/styles.js'
import { GENERAL_STATUSES } from '@/utilities/constants/index.js'

const StageStatus = ({ status, text, onClick }) => {
    const renderIcons = () => {
        switch (status) {
            case GENERAL_STATUSES.danger:
                return <StatusIconError />
            case GENERAL_STATUSES.success:
                return <StatusIconSuccess />
            case GENERAL_STATUSES.warning:
                return <StatusIconWarning />
            default:
                return
        }
    }
    return (
        <StyledContainerStageStatus onClick={onClick}>
            {renderIcons()}
            <span>{text ? text : '-'}</span>
        </StyledContainerStageStatus>
    )
}

export default StageStatus

StageStatus.propTypes = {
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    text: PropTypes.string,
    onClick: PropTypes.func,
}
