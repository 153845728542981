import styled, { css } from 'styled-components'

export const StyledActionButtonDialog = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: 1fr 173px;
        grid-gap: ${theme.spacing}px;
        width: 100%;
    `}
`
