import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ClockIcon, CloseIconToast } from '@/assets/icons/icons.jsx'
import Datepicker from '@/components/date-picker/DatePicker.jsx'
import Divider from '@/components/divider/Divider.jsx'
import Input from '@/components/input/Input.jsx'
import { ALLOCATION_MODEL } from '@/pages/projects/detail/detail-final-balance/final-balance-form/allocation-form/allocationForm.schema.js'
import {
    StyledContainerCloseIcon,
    StyledLi,
} from '@/pages/projects/detail/detail-final-balance/final-balance-form/allocation-form/styles.js'

const AllocationList = ({
    fields,
    index,
    control,
    onInputFocus,
    removeAllocation,
}) => {
    const { t } = useTranslation()
    const {
        formState: { errors, touchedFields },
        register,
    } = useFormContext()

    const startDateRef = useRef(null)
    const endDateRef = useRef(null)

    return (
        <>
            <StyledLi key={fields?.id}>
                <div style={{ display: 'none' }}>
                    <Input
                        type="number"
                        name={`${ALLOCATION_MODEL.allocation}[${index}].id`}
                        value={fields.id}
                        {...register(
                            `${ALLOCATION_MODEL.allocation}[${index}].id`
                        )}
                    />
                </div>
                <Datepicker
                    required
                    name={`${ALLOCATION_MODEL.allocation}[${index}].start_date`}
                    label={t('projects.fields.labels.start_date')}
                    placeholder={t('projects.fields.placeholder.start_date')}
                    control={control}
                    touched={
                        touchedFields[
                            `${ALLOCATION_MODEL.allocation}[${index}].start_date`
                        ]
                    }
                    errors={
                        errors[
                            `${ALLOCATION_MODEL.allocation}[${index}].start_date`
                        ]
                    }
                    showMonthDropdown
                    showYearDropdown
                    pickerType={'days'}
                    className="mb-2"
                    onFocus={() => onInputFocus(index)}
                    ref={startDateRef}
                />
                <Datepicker
                    required
                    name={`${ALLOCATION_MODEL.allocation}[${index}].end_date`}
                    label={t('projects.fields.labels.end_date')}
                    placeholder={t('projects.fields.placeholder.end_date')}
                    control={control}
                    touched={
                        touchedFields[
                            `${ALLOCATION_MODEL.allocation}[${index}].end_date`
                        ]
                    }
                    errors={
                        errors[
                            `${ALLOCATION_MODEL.allocation}[${index}].end_date`
                        ]
                    }
                    showMonthDropdown
                    showYearDropdown
                    pickerType={'days'}
                    className="mb-2"
                    onFocus={() => onInputFocus(index)}
                    ref={endDateRef}
                />

                <Input
                    icon={<ClockIcon />}
                    iconPosition={'right'}
                    label={t('activity.table.label_columns.hours')}
                    placeholder={t('projects.fields.placeholder.days')}
                    width="100%"
                    size={'large'}
                    errors={
                        errors[`${ALLOCATION_MODEL.allocation}[${index}].hours`]
                    }
                    {...register(
                        `${ALLOCATION_MODEL.allocation}[${index}].hours`
                    )}
                    onFocus={() => onInputFocus(index)}
                    type={'number'}
                    min={0}
                />

                <StyledContainerCloseIcon>
                    <CloseIconToast
                        width={'12px'}
                        height={'12px'}
                        onClick={() => {
                            removeAllocation(index)
                        }}
                    />
                </StyledContainerCloseIcon>
            </StyledLi>

            {index !== fields?.length - 1 && (
                <Divider
                    orientation={'horizontal'}
                    width={'100%'}
                    height={'1px'}
                />
            )}
        </>
    )
}
export default AllocationList

AllocationList.propTypes = {
    fields: PropTypes.object,
    index: PropTypes.number,
    control: PropTypes.any,
    onInputFocus: PropTypes.func,
    removeAllocation: PropTypes.func,
}
