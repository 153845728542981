import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import SectionBlock from '@/components/sidebar-tab-item/section-block/SectionBlock.jsx'
import SectionTitle from '@/components/sidebar-tab-item/section-title/SectionTitle.jsx'
import {
    StyledHistoryItem,
    StyledNoOptionsMessage,
    StyledSingleItem,
} from '@/components/sidebar-tab-item/styles.js'
import { TYPE_USERS } from '@/utilities/constants/index.js'
import { translatedEnum } from '@/utilities/helpers.js'

const ContractItems = ({
    items,
    setOpenForm,
    panelId,
    sectionTitle,
    editDisabled,
}) => {
    const { t } = useTranslation()

    return (
        <>
            <SectionTitle title={sectionTitle} />

            {items?.length > 0 ? (
                items?.map((contract, index) => {
                    const title = `${translatedEnum(
                        contract.type,
                        'contract'
                    )} | ${contract.identifier ? contract.identifier : '-'}`
                    return (
                        <StyledHistoryItem
                            key={contract.id}
                            noDivider={index === items.length - 1}
                        >
                            <SectionBlock
                                startDate={contract.start_date}
                                endDate={contract.end_date}
                                id={`#${contract.id}`}
                                title={title}
                                isActive={true}
                                editDisabled={editDisabled}
                                onEdit={() => {
                                    setOpenForm({
                                        isOpen: true,
                                        typeForm: TYPE_USERS.contract,
                                        data: {
                                            ...contract,
                                            memberId: panelId,
                                        },
                                        isEdit: true,
                                    })
                                }}
                            />
                        </StyledHistoryItem>
                    )
                })
            ) : (
                <StyledSingleItem className="value_">
                    <StyledNoOptionsMessage>
                        {t('settings.sidebar_left.subtitles.no_contracts')}
                    </StyledNoOptionsMessage>
                </StyledSingleItem>
            )}
        </>
    )
}

export default ContractItems

ContractItems.propTypes = {
    items: PropTypes.array,
    setOpenForm: PropTypes.func,
    panelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    sectionTitle: PropTypes.string,
    editDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}
