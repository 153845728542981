import PropTypes from 'prop-types'

import Divider from '@/components/divider/Divider.jsx'
import Progress from '@/components/progress/Progress.jsx'
import {
    StyledContainerHours,
    StyledContainerItem,
    StyledContainerPara,
    StyledContainerProgress,
    StyledValue,
} from '@/components/progress-card/styles.js'
import { formatCurrency } from '@/utilities/helpers.js'

const ItemProgressCard = ({ item, lastItem }) => {
    return (
        <>
            <StyledContainerItem>
                <StyledContainerPara>
                    <small>{item?.title}</small>
                    <StyledValue>{formatCurrency(item.value)}</StyledValue>
                </StyledContainerPara>

                {item?.haveProgress && (
                    <div>
                        <StyledContainerProgress>
                            <>
                                <Progress
                                    progressValue={item.progress ?? 0}
                                    width={180}
                                    height={6}
                                    color={item.color}
                                />
                                <p>{item.progress}%</p>
                            </>
                        </StyledContainerProgress>
                        <StyledContainerHours>
                            {item.remaining ? (
                                <p>
                                    {formatCurrency(item.remaining)} rimanenti
                                </p>
                            ) : (
                                <>
                                    {item.hours ? (
                                        <p>{item.hours}h</p>
                                    ) : (
                                        <p>0h</p>
                                    )}
                                    <p>({item.hoursOf ?? 0}h)</p>
                                </>
                            )}
                        </StyledContainerHours>
                    </div>
                )}
            </StyledContainerItem>

            {lastItem !== item.id && <Divider height={126} />}
        </>
    )
}

export default ItemProgressCard

ItemProgressCard.propTypes = {
    item: PropTypes.object,
    lastItem: PropTypes.number,
}
