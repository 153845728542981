import { StyledTRow } from '../row/style'

const Loader = () => {
    return Array.from(Array(3).keys()).map((row, index) => (
        <StyledTRow key={index}>
            {/*<ContentLoader height='100%' />*/}
        </StyledTRow>
    ))
}

export default Loader
