import styled, { css } from 'styled-components'

export const StyledButtonGoogle = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: auto 1fr;
        gap: ${theme.spacing * 4}px;
        width: 250px;
        height: 64px;
        background-color: transparent;
        justify-content: center;
        align-items: center;
        color: ${theme.palette.neutral.base[1000]};
        border: 1px solid ${theme.palette.neutral.base[200]};
        border-radius: ${theme.spacing / 2}px;
        padding: 0 ${theme.spacing * 2}px;
        cursor: pointer;
        ${theme.typography.buttonText};
    `}
`
