import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { PlusIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import ScrollSelect from '@/components/scroll-select/ScrollSelect'
import Select from '@/components/select/Select.jsx'
import { StyledContainerProjectsFilters } from '@/pages/projects/list/projects-filters/styles.js'
import { LABELS_TABLES } from '@/utilities/constants/index.js'
import { STATUS_OPTIONS_PROJECTS } from '@/utilities/constants/list.js'
import { translateOptions } from '@/utilities/helpers.js'

const ProjectsFilters = ({
    setDataForm,
    selectedTab,
    teamLead,
    successManagers,
    applicants,
    setFilter,
    filter,
}) => {
    const { t } = useTranslation()

    const handleChangeSelect = (value, type) => {
        setFilter({
            ...filter,
            [type]: value?.value,
        })
    }

    const successManagerOptions = successManagers?.filter(
        successManager => successManager.data?.is_success_manager
    )

    return (
        <StyledContainerProjectsFilters
            widht={
                selectedTab === LABELS_TABLES.final_balances
                    ? '180px'
                    : selectedTab === LABELS_TABLES.rd
                    ? '140px'
                    : '160px'
            }
        >
            <Select
                isClearable
                isControlled={false}
                size={'medium'}
                options={translateOptions(STATUS_OPTIONS_PROJECTS)}
                placeholder={t('projects.fields.placeholder.select_status')}
                onChange={value =>
                    setFilter({
                        ...filter,
                        is_active: value?.value,
                    })
                }
            />
            <ScrollSelect
                isClearable
                isControlled={false}
                size={'medium'}
                options={teamLead}
                placeholder={t('general.team')}
                lastPage={1}
                currentPage={1}
                onChange={value =>
                    setFilter({
                        ...filter,
                        team_id: value?.value,
                    })
                }
                isOptionDisabled={options => !options?.data?.is_active}
            />
            <Select
                isClearable
                isSearchable
                isControlled={false}
                size={'medium'}
                options={
                    selectedTab === LABELS_TABLES.rd
                        ? applicants
                        : successManagerOptions
                }
                placeholder={t(
                    selectedTab === LABELS_TABLES.rd
                        ? 'project.applicant'
                        : 'general.success_manager'
                )}
                onChange={value =>
                    handleChangeSelect(
                        value,
                        selectedTab === LABELS_TABLES.rd
                            ? 'applicant_id'
                            : 'success_manager_id'
                    )
                }
                isOptionDisabled={options => !options?.data?.is_active}
            />

            <Button
                type={'button'}
                size={'small'}
                onClick={() => {
                    setDataForm({
                        isOpen: true,
                        typeForm: selectedTab
                            ? selectedTab
                            : LABELS_TABLES.projects,
                        id: null,
                        isEdit: false,
                        data: null,
                    })
                }}
            >
                <PlusIcon />
                {t(
                    `projects.actions.add_${
                        selectedTab ? selectedTab : LABELS_TABLES.projects
                    }`
                )}
            </Button>
        </StyledContainerProjectsFilters>
    )
}

export default ProjectsFilters

ProjectsFilters.propTypes = {
    setDataForm: PropTypes.func,
    selectedTab: PropTypes.string,
    teamLead: PropTypes.array,
    successManagers: PropTypes.array,
    setFilter: PropTypes.func,
    filter: PropTypes.object,
    applicants: PropTypes.array,
}
