import { API_RESPONSE_TYPES } from '@/services/axiosInstance.js'
import { mergeInfiniteQuery } from '@/utilities/helpers.js'

import { apiSlice } from '../apiSlice.js'

// Injects these endpoints to main apiSlice
export const settingsApiSlice = apiSlice.injectEndpoints({
    addTagTypes: [
        'Members',
        'Teams',
        'AssignTeams',
        'Contracts',
        'UserTeamHistory',
        'Specificities',
        'ContractByID',
    ],
    endpoints: builder => ({
        // MEMBERS
        getMembers: builder.query({
            query: (payload = {}) => {
                const { responseType, ...params } = payload
                return {
                    url: `/users`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            //TODO PAGINATION ISSUE, RTK QUERY HAS INFINITE SCROLL IN WORK IN PROGRESS
            //https://redux-toolkit.js.org/rtk-query/comparison#comparing-feature-sets
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            // Always merge incoming data to the cache entry
            merge: (currentCache, newItems) => {
                mergeInfiniteQuery(currentCache, newItems)
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                if (currentArg?.currentPage) {
                    return currentArg !== previousArg
                }
                return
            },
            providesTags: ['Members'],
        }),
        getMemberById: builder.query({
            query: (payload = {}) => {
                const { responseType, ID: userID, ...params } = payload
                return {
                    url: `/users/${userID}`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['Members'],
        }),
        createMember: builder.mutation({
            query: (payload = {}) => {
                const { responseType, data } = payload
                return {
                    url: `/users`,
                    method: 'post',
                    data,
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Members'],
        }),
        editMember: builder.mutation({
            query: (payload = {}) => {
                const { responseType, ID: userID, data } = payload
                return {
                    url: `/users/${userID}`,
                    method: 'patch',
                    data,
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Members', 'rdProjectByID'],
        }),
        deleteMember: builder.mutation({
            query: (payload = {}) => {
                const { responseType, ID: userID } = payload
                return {
                    url: `/users/${userID}`,
                    method: 'delete',
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Members'],
        }),
        getHistoryById: builder.query({
            query: (payload = {}) => {
                const { responseType, ID: userID, ...params } = payload
                return {
                    url: `/users/${userID}/history`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: [
                'History',
                'Members',
                'Contracts',
                'UserTeamHistory',
            ],
        }),
        // TEAMS
        createTeam: builder.mutation({
            query: (payload = {}) => {
                const { responseType, data } = payload
                const url = `/teams`
                return {
                    url,
                    method: 'post',
                    data,
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Teams', 'Members'],
        }),
        getTeams: builder.query({
            query: (payload = {}) => {
                const { responseType, ...params } = payload
                const url = `/teams`
                return {
                    url,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },

            //TODO PAGINATION ISSUE, RTK QUERY HAS INFINITE SCROLL IN WORK IN PROGRESS
            //https://redux-toolkit.js.org/rtk-query/comparison#comparing-feature-sets
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            // Always merge incoming data to the cache entry
            merge: (currentCache, newItems) => {
                mergeInfiniteQuery(currentCache, newItems)
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                if (currentArg?.currentPage) {
                    return currentArg !== previousArg
                }
                return
            },
            providesTags: ['Teams'],
        }),
        getTeamsSearch: builder.query({
            query: (payload = {}) => {
                const { responseType, ...params } = payload
                const url = `/teams`
                return {
                    url,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['TeamsSearch'],
        }),
        getTeamHistory: builder.query({
            query: (payload = {}) => {
                const { responseType, ID, ...params } = payload
                const url = `/teams/${ID}/history`
                return {
                    url,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['Teams'],
        }),
        getTeamById: builder.query({
            query: (payload = {}) => {
                const { responseType, ID: teamID, ...params } = payload
                const url = `/teams/${teamID}`
                return {
                    url,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['Teams'],
        }),
        editTeam: builder.mutation({
            query: (payload = {}) => {
                const { responseType, ID: teamID, data } = payload
                const url = `/teams/${teamID}`
                return {
                    url,
                    method: 'patch',
                    data,
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Teams', 'Members'],
        }),
        deleteTeam: builder.mutation({
            query: (payload = {}) => {
                const { responseType, ID: teamID } = payload
                const url = `/teams/${teamID}`
                return {
                    url,
                    method: 'delete',
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Teams', 'Members'],
        }),
        // CONTRACTS
        createContract: builder.mutation({
            query: (payload = {}) => {
                const { responseType, ID: userID, data } = payload
                const url = `/users/${userID}/contracts`
                return {
                    url,
                    method: 'post',
                    data,
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Contracts', 'Members'],
        }),
        getContracts: builder.query({
            query: (payload = {}) => {
                const { responseType, ID: userID, ...params } = payload
                const url = `/users/${userID}/contracts`
                return {
                    url,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['Contracts'],
        }),
        getContractById: builder.query({
            query: (payload = {}) => {
                const {
                    responseType,
                    ID: userID,
                    contractID,
                    ...params
                } = payload
                const url = `/users/${userID}/contracts/${contractID}`
                return {
                    url,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['ContractByID'],
        }),
        editContract: builder.mutation({
            query: (payload = {}) => {
                const { responseType, ID: userID, contractID, data } = payload
                const url = `/users/${userID}/contracts/${contractID}`
                return {
                    url,
                    method: 'patch',
                    data,
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Contracts', 'Members', 'ContractByID'],
        }),
        deleteContract: builder.mutation({
            query: (payload = {}) => {
                const { responseType, ID: userID, contractID } = payload
                const url = `/users/${userID}/contracts/${contractID}`
                return {
                    url,
                    method: 'delete',
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Contracts', 'Members'],
        }),
        // USER-TEAM
        assignTeam: builder.mutation({
            query: (payload = {}) => {
                const { responseType, ID: userID, data } = payload
                const url = `/users/${userID}/user-team-history`
                return {
                    url,
                    method: 'post',
                    data,
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['UserTeamHistory', 'Members', 'Teams'],
        }),
        getUserTeamHistoryById: builder.query({
            query: (payload = {}) => {
                const { responseType, ID: userID, ...params } = payload
                const url = `/users/${userID}/user-team-history`
                return {
                    url,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['UserTeamHistory'],
        }),
        getAssignTeamById: builder.query({
            query: (payload = {}) => {
                const {
                    responseType,
                    ID: userID,
                    userTeamHistoryID,
                    ...params
                } = payload
                const url = `/users/${userID}/user-team-history/${userTeamHistoryID}`
                return {
                    url,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['AssignTeams'],
        }),
        editAssignedTeam: builder.mutation({
            query: (payload = {}) => {
                const {
                    responseType,
                    ID: userID,
                    historyTeamID,
                    data,
                } = payload
                const url = `/users/${userID}/user-team-history/${historyTeamID}`
                return {
                    url,
                    method: 'patch',
                    data,
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: [
                'UserTeamHistory',
                'Members',
                'Teams',
                'AssignTeams',
            ],
        }),
        deleteAssignedTeam: builder.mutation({
            query: (payload = {}) => {
                const { responseType, ID: userID, historyTeamID } = payload
                const url = `/users/${userID}/user-team-history/${historyTeamID}`
                return {
                    url,
                    method: 'delete',
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['UserTeamHistory', 'Members', 'Teams'],
        }),

        //scopes and specificities
        getScopes: builder.query({
            query: (payload = {}) => {
                const { responseType, ...params } = payload
                return {
                    url: `/scopes`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
        }),

        getSpecificityById: builder.query({
            query: (payload = {}) => {
                const { responseType, ID: ID, ...params } = payload
                return {
                    url: `/scopes/${ID}/specificities`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['Specificities'],
        }),

        createSpecificities: builder.mutation({
            query: (payload = {}) => {
                const { responseType, ID, data } = payload
                return {
                    url: `/scopes/${ID}/specificities`,
                    method: 'post',
                    data,
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Specificities'],
        }),

        //responsibilities
        getResponsibilities: builder.query({
            query: (payload = {}) => {
                const { responseType, ...params } = payload
                return {
                    url: '/responsibilities',
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
        }),
    }),
})

export const {
    useGetMembersQuery,
    useGetMemberByIdQuery,
    useCreateMemberMutation,
    useEditMemberMutation,
    useDeleteMemberMutation,
    useGetHistoryByIdQuery,
    useCreateTeamMutation,
    useGetTeamsQuery,
    useGetTeamsSearchQuery,
    useGetTeamHistoryQuery,
    useGetTeamByIdQuery,
    useEditTeamMutation,
    useDeleteTeamMutation,
    useCreateContractMutation,
    useGetContractByIdQuery,
    useGetContractsQuery,
    useEditContractMutation,
    useDeleteContractMutation,
    useAssignTeamMutation,
    useGetUserTeamHistoryByIdQuery,
    useGetAssignTeamByIdQuery,
    useEditAssignedTeamMutation,
    useDeleteAssignedTeamMutation,
    useGetSpecificityByIdQuery,
    useGetScopesQuery,
    useCreateSpecificitiesMutation,

    useGetResponsibilitiesQuery,
} = settingsApiSlice

//TODO: is this needed?
// Calling `someEndpoint.select(someArg)` generates a new selector that will return
// the query result object for a query with those parameters.
// To generate a selector for a specific query argument, call `select(theQueryArg)`.
// In this case, the users query has no params, so we don't pass anything to select()
export const selectMembersResult =
    settingsApiSlice.endpoints.getMembers.select()
// Check: https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced#selecting-users-data
// and https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced#selecting-users-data
/*
const emptyUsers = []

export const selectAllUsers = createSelector(
    selectUsersResult,
    usersResult => usersResult?.data ?? emptyUsers
)

export const selectUserById = createSelector(
    selectAllUsers,
    (state, userId) => userId,
    (users, userId) => users.find(user => user.id === userId)
)*/
