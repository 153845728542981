import it from './it'

/*
 *
 * To add new language:
 * 1. Create language directory - "it"
 * 2. Clone index.js from another language directory inside the language directory
 * 3. Add <namespace>.json files inside the language directory - "validation|commons|pages.json"
 * 4. Import the new language here in "resources"
 *
 */

const resources = {
    it: it,
}

export default resources
