import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AddIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import {
    StyledSliderSectionContainer,
    StyledSidebarSubHeader,
    StyledTabCtaContainer,
} from '@/components/sidebar-tab-item/styles'
import Spinner from '@/components/spinner/Spinner'
import Tooltip from '@/components/tooltip/Tooltip.jsx'
import ContractItems from '@/pages/settings/sidebar-settings/contracts/ContractItems.jsx'
import {
    useGetContractsQuery,
    useGetMemberByIdQuery,
} from '@/services/settings/settingApiSlice'
import { TYPE_USERS } from '@/utilities/constants'
import { LABELS_TABLES } from '@/utilities/constants/commons'

const ContractsSidebar = ({ setOpenForm, panelId }) => {
    const { t } = useTranslation()
    const currentDate = new Date()

    const { data: fetchedContracts, isFetching: isFetchingContract } =
        useGetContractsQuery({ ID: panelId }, { skip: !panelId })

    const { data: memberData, isFetching: isFetchingMember } =
        useGetMemberByIdQuery({ ID: panelId }, { skip: !panelId })

    const contracts = fetchedContracts?.data

    const userInactive = useMemo(() => {
        return memberData?.is_active === false
    }, [memberData])

    const canAddContract = useMemo(() => {
        return contracts?.find(
            item => (item && item.end_date === null) || !item?.user?.is_active
        )
    }, [contracts])

    const futureContracts = useMemo(() => {
        //future contracts are the contracts that have a start date after the current date
        return contracts?.filter(item => {
            const contractStartDate = new Date(item.start_date)
            return contractStartDate > currentDate
        })
    }, [contracts])

    const actualContract = useMemo(() => {
        return contracts?.filter(item => {
            const contractStartDate = new Date(item.start_date)
            const contractEndDate = item.end_date
                ? new Date(item.end_date)
                : null
            return (
                (contractStartDate <= currentDate && !contractEndDate) ||
                (contractStartDate <= currentDate &&
                    (contractEndDate === null ||
                        contractEndDate.toDateString() ===
                            currentDate.toDateString() ||
                        contractEndDate > currentDate))
            )
        })
    }, [contracts])

    const pastContracts = useMemo(() => {
        return contracts?.filter(item => {
            if (!item.end_date) return false
            const contractEndDate = new Date(item.end_date)
            const isContractEndingToday =
                contractEndDate.toDateString() === currentDate.toDateString()
            if (isContractEndingToday) return false

            return contractEndDate < currentDate
        })
    }, [contracts, currentDate])

    if (isFetchingContract || isFetchingMember) {
        return <Spinner overlay={false} overlayFullscreen={false} />
    }

    return (
        <>
            <StyledSidebarSubHeader>
                {<h3>{LABELS_TABLES.contracts}</h3>}
                <StyledTabCtaContainer>
                    <Tooltip
                        visibilityCondition={
                            canAddContract !== undefined || userInactive
                        }
                        text={t(
                            'settings.sidebar_left.subtitles.check_contract'
                        )}
                        showPosition="bottom"
                    >
                        <Button
                            onClick={() =>
                                setOpenForm({
                                    isOpen: true,
                                    typeForm: TYPE_USERS.contract,
                                    data: {
                                        memberId: panelId,
                                    },
                                    isEdit: false,
                                })
                            }
                            disabled={
                                canAddContract ||
                                userInactive ||
                                actualContract?.length > 0
                            }
                            collapsed
                        >
                            <AddIcon />
                        </Button>
                    </Tooltip>
                </StyledTabCtaContainer>
            </StyledSidebarSubHeader>

            <StyledSliderSectionContainer>
                <ContractItems
                    items={futureContracts}
                    setOpenForm={setOpenForm}
                    panelId={panelId}
                    sectionTitle={t(
                        'settings.sidebar_left.subtitles.future_contracts'
                    )}
                    editDisabled={userInactive}
                />
            </StyledSliderSectionContainer>

            <StyledSliderSectionContainer>
                <ContractItems
                    items={actualContract}
                    setOpenForm={setOpenForm}
                    panelId={panelId}
                    sectionTitle={t(
                        'settings.sidebar_left.subtitles.actual_contract'
                    )}
                    editDisabled={userInactive}
                />
            </StyledSliderSectionContainer>

            <div>
                <ContractItems
                    items={pastContracts}
                    setOpenForm={setOpenForm}
                    panelId={panelId}
                    sectionTitle={t(
                        'settings.sidebar_left.subtitles.past_contracts'
                    )}
                    editDisabled={userInactive}
                />
            </div>
        </>
    )
}

export default ContractsSidebar

ContractsSidebar.propTypes = {
    data: PropTypes.array,
    setOpenForm: PropTypes.func,
    panelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    memberData: PropTypes.object,
}
