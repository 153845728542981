import PropTypes from 'prop-types'
import { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import DialogDelete from '@/components/dialog-delete/DialogDelete.jsx'
import Tabs from '@/components/tabs/Tabs.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import {
    useDeleteMemberMutation,
    useDeleteTeamMutation,
} from '@/services/settings/settingApiSlice.js'
import { setPanelId, selectPanelId } from '@/services/settings/settingsSlice'
import { handleApiError } from '@/utilities/api/helpers.js'
import {
    TYPE_USERS,
    ROUTE_SETTINGS,
    ROUTE_SETTINGS_MEMBERS,
    ROUTE_SETTINGS_TEAMS,
} from '@/utilities/constants'

const TablesSettings = ({ setOpenForm, filter, teamLeadFilter }) => {
    const { t } = useTranslation()
    const showError = useError()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const panelId = useSelector(selectPanelId)
    const [openDialogToDelete, setOpenDialogToDelete] = useState(false)
    const location = useLocation()

    const [usersToDelete, setUsersToDelete] = useState(null)
    const [tabName, setTabName] = useState(true)
    const { triggerAlert } = useAlert()

    const [deleteMember] = useDeleteMemberMutation()
    const [deleteTeam] = useDeleteTeamMutation()

    const params = new URLSearchParams(location.search)
    const paramsId = params.get('id')

    useEffect(() => {
        if (paramsId !== null) {
            dispatch(setPanelId(+paramsId))
            setTabName(false)
            setTimeout(() => {
                setTabName(true)
            }, 0)
        }
        if (location.pathname.includes('members')) {
            setTabName(false)
            setTimeout(() => {
                setTabName(true)
            }, 0)
        }
        //TODO RADIX CHANGE TAB VALUE LOGIC
    }, [paramsId, location])

    const onSectionClick = section => {
        setOpenForm({})
        dispatch(setPanelId(undefined))

        if (section === TYPE_USERS.members) {
            navigate(`${ROUTE_SETTINGS}/${ROUTE_SETTINGS_MEMBERS}`, {
                state: 'members',
            })
        } else {
            navigate(`${ROUTE_SETTINGS}/${ROUTE_SETTINGS_TEAMS}`, {
                state: 'teams',
            })
        }
    }

    // eslint-disable-next-line
    const openPanel = id => {
        //close sidebar if click twice on same id
        if (panelId === id) {
            dispatch(setPanelId(undefined))
        } else {
            dispatch(setPanelId(id))
        }
    }

    const openDeleteDialog = (id, userType) => {
        setOpenDialogToDelete(true)
        setUsersToDelete({
            id,
            userType,
        })
    }

    const deleteUser = async () => {
        try {
            if (usersToDelete.userType === TYPE_USERS.members) {
                await deleteMember({ ID: usersToDelete.id }).unwrap()
            } else {
                await deleteTeam({ ID: usersToDelete.id }).unwrap()
            }
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: t('commons:dialog.success'),
            })
            dispatch(setPanelId(undefined))
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        } finally {
            setOpenDialogToDelete(false)
        }
    }

    const dataProps = {
        openPanel,
        openDeleteDialog,
        setOpenForm,
        filter,
        teamLeadFilter,
    }

    const settingsTab = [
        {
            id: 1,
            label: t('general.member'),
            value: TYPE_USERS.members,
        },
        {
            id: 2,
            label: t('general.team'),
            value: TYPE_USERS.teams,
        },
    ]

    const isMembersTab = useMemo(
        () =>
            location.pathname.includes('members')
                ? TYPE_USERS.members
                : TYPE_USERS.teams,
        [location.pathname]
    )

    return (
        <>
            <DialogDelete
                open={openDialogToDelete}
                onClose={() => setOpenDialogToDelete(false)}
                onDelete={deleteUser}
            />
            {tabName && (
                <Tabs
                    onValueChange={e => onSectionClick(e)}
                    triggers={settingsTab}
                    haveDivider
                    defaultValue={isMembersTab}
                />
            )}
            <Outlet context={{ dataProps }} />
        </>
    )
}

export default TablesSettings

TablesSettings.propTypes = {
    setSelectedTab: PropTypes.func,
    setOpenForm: PropTypes.func,
    setFilter: PropTypes.func,
    setTeamLeadFilter: PropTypes.func,
    filter: PropTypes.object,
    teamLeadFilter: PropTypes.object,
}
