import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useTranslation } from 'react-i18next'

import { EditIcon } from '@/assets/icons/icons.jsx'
import {
    StyledCalendarCellContainer,
    StyledRightClickMenu,
} from '@/components/calendar-day/styles.js'
import ContextMenu from '@/components/context-menu/ContextMenu.jsx'
import useError from '@/hooks/useError.jsx'
import { useConfirmAllocationsMutation } from '@/services/projects/finalBalanceApiSlice.js'
import { handleApiError } from '@/utilities/api/helpers.js'

const AllocationCell = ({
    cell,
    currentDate,
    finalBalance,
    userId,
    isLarge,
}) => {
    const { t } = useTranslation()
    const inputRefs = useRef([])
    const showError = useError()
    const [confirmAllocation] = useConfirmAllocationsMutation()

    const hours = cell ? cell?.allocated_hours : 0
    const idCell = cell ? cell?.allocated?.id : 0
    const confirmedHours = cell?.allocated_hours ? cell?.confirmed_hours : 0
    const loggedHours = cell?.logged_hours ? cell?.logged_hours : 0

    const [value, setValue] = useState(null)

    const handleChangeTime = async e => {
        let value = e.target.value
        if (+value < 0) {
            value = 0
        } else if (Number.isNaN(Number(value))) {
            value = 0
        } else {
            value = e.target.value.replace('h', '')
        }

        setValue(value)
    }

    const handleChangeStatus = async e => {
        switch (e.keyCode) {
            case 9: // Tab
            case 13: // Enter
                e.preventDefault()
                await handleConfirm()
                e.target.blur()
                break
            case 8: // Backspace
                e.target.blur()
                break
        }
    }

    const handleConfirm = async () => {
        try {
            await confirmAllocation({
                projectID: finalBalance?.id,
                data: [
                    {
                        user_id: userId,
                        allocations: [
                            {
                                date: currentDate,
                                hours: +value || value === 0 ? +value : hours,
                            },
                        ],
                    },
                ],
            })
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        }
    }

    if (cell?.allocated_hours) {
        return (
            <div
                className="float_container"
                tabIndex={idCell}
                key={`${idCell}-${idCell}-${currentDate}`}
            >
                <StyledCalendarCellContainer large={true}>
                    <ContextMenu
                        trigger={
                            <CurrencyInput
                                maxLength={2}
                                decimalsLimit={2}
                                className={`${
                                    cell?.confirmed_hours !== null
                                        ? 'confirmed_'
                                        : 'sell_'
                                }`}
                                suffix={'h'}
                                value={value || confirmedHours}
                                onKeyDown={handleChangeStatus}
                                onChange={handleChangeTime}
                                onClick={e => {
                                    e.target.select()
                                }}
                                ref={input =>
                                    (inputRefs.current[
                                        `${idCell}-${idCell}-${currentDate}`
                                    ] = input)
                                }
                                placeholder={
                                    !confirmedHours ? `${hours}h` : undefined
                                }
                            />
                        }
                        item={
                            <StyledRightClickMenu onClick={handleConfirm}>
                                <div className="icon-container">
                                    <EditIcon />
                                </div>
                                <span>{t('logs.table.row_action')}</span>
                            </StyledRightClickMenu>
                        }
                    />

                    <div className="worked_">
                        {loggedHours}/{hours}h
                    </div>
                </StyledCalendarCellContainer>
            </div>
        )
    } else {
        return (
            <div className="empty_container" key={`${idCell}-${currentDate}`}>
                <StyledCalendarCellContainer large={isLarge}>
                    <div className="bg_"></div>
                </StyledCalendarCellContainer>
            </div>
        )
    }
}

export default AllocationCell

AllocationCell.propTypes = {
    cell: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    currentDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    finalBalance: PropTypes.object,
    userId: PropTypes.number,
    isLarge: PropTypes.bool,
}
