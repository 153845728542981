import * as RadixTooltip from '@radix-ui/react-tooltip'
import PropTypes from 'prop-types'

import {
    StyledContent,
    StyledTooltipContainer,
    StyledTooltipContent,
} from './styles'

const Tooltip = ({
    children,
    customChildren,
    customContent,
    customcolor,
    text,
    onOpenChange,
    showPosition,
    haveArrow = false,
    margincontent,
    icon,
    visibilityCondition = true,
}) => {
    return (
        <RadixTooltip.Provider delayDuration={100}>
            <RadixTooltip.Root onOpenChange={onOpenChange}>
                <StyledTooltipContainer asChild>
                    {customChildren ? (
                        customChildren
                    ) : (
                        <button>{children}</button>
                    )}
                </StyledTooltipContainer>
                <RadixTooltip.Portal>
                    {visibilityCondition && (
                        <StyledTooltipContent
                            align={'right'}
                            side={showPosition}
                            sideOffset={5}
                            margincontent={margincontent}
                            customcolor={customcolor}
                        >
                            <StyledContent>
                                {icon}
                                {text}
                            </StyledContent>
                            {customContent}
                            {haveArrow && <RadixTooltip.Arrow />}
                        </StyledTooltipContent>
                    )}
                </RadixTooltip.Portal>
            </RadixTooltip.Root>
        </RadixTooltip.Provider>
    )
}

export default Tooltip
Tooltip.propTypes = {
    children: PropTypes.node,
    text: PropTypes.string,
    onOpenChange: PropTypes.func,
    showPosition: PropTypes.oneOf(['left', 'right', 'bottom', 'top']),
    haveArrow: PropTypes.bool,
    margincontent: PropTypes.string,
    icon: PropTypes.node,
    customChildren: PropTypes.node,
    customContent: PropTypes.node,
    customcolor: PropTypes.string,
    visibilityCondition: PropTypes.bool,
}
