import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
    ClockIcon,
    CurrencyIcon,
    LinkIcon,
    TrashIcon,
} from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import Datepicker from '@/components/date-picker/DatePicker.jsx'
import Divider from '@/components/divider/Divider.jsx'
import Input from '@/components/input/Input.jsx'
import InputCurrency from '@/components/input-currency/InputCurrency.jsx'
import Select from '@/components/select/Select.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import { StyledContainerFormActions } from '@/pages/customers/detail/customer-form/billing-form/styles.js'
import { StyledContainerDelete } from '@/pages/customers/detail/customer-tables/table-projects/projects-form/styles.js'
import { StyledForm } from '@/pages/projects/list/projects-form/final-balance-form/styles.js'
import {
    DEFAULT_RD_MOCK,
    RD_MODEL,
} from '@/pages/projects/list/projects-form/r&d-form/r&dForm.schema.js'
import { STANDARD_PROJECTS_MODEL } from '@/pages/projects/list/projects-form/standard-projects-form/standardProjects.schema.js'
import {
    useAddRDProjectMutation,
    useGetRDProjectByIDQuery,
    useGetRDProjectsQuery,
    useUpdateRDProjectMutation,
} from '@/services/projects/r&dApiSlice.js'
import { handleApiError, remapSelectOptions } from '@/utilities/api/helpers.js'
import { STATUS_OPTIONS_PROJECTS } from '@/utilities/constants/list.js'
import {
    filterEmptyKeys,
    iterateOverDirtyFields,
    retrieveSingleValueForRs,
    translateOptions,
} from '@/utilities/helpers.js'

const RandDForm = ({
    dataForm,
    isEdit,
    openDialogDelete,
    closeModal,
    teams,
    responsibilities,
    applicants,
    canNotDelete,
}) => {
    const { t } = useTranslation()
    const { triggerAlert } = useAlert()
    const showError = useError()
    const [addRDProject] = useAddRDProjectMutation()
    const [updateRD] = useUpdateRDProjectMutation()

    const { data } = useGetRDProjectByIDQuery(
        { id: dataForm.id },
        { skip: !isEdit }
    )

    const {
        register,
        control,
        handleSubmit,
        reset,
        setError,
        setValue,
        watch,
        getValues,
        formState: {
            errors,
            isDirty,
            touchedFields,
            dirtyFields,
            isSubmitting,
        },
    } = useFormContext()

    const days = useWatch({
        control,
        name: RD_MODEL.days,
    })

    const basePrice = useWatch({
        control,
        name: RD_MODEL.base_price,
    })

    const haveSelectedTeam = useWatch({
        control,
        name: RD_MODEL.team,
    })

    const rateOfTeam = useMemo(() => {
        if (haveSelectedTeam) {
            return teams.find(team => team.id === haveSelectedTeam.value)?.rate
        }
        return 0
    }, [haveSelectedTeam])

    useEffect(() => {
        if (isEdit) {
            setValue(STANDARD_PROJECTS_MODEL.base_price, data?.team?.rate)
        } else {
            if (rateOfTeam) {
                setValue(STANDARD_PROJECTS_MODEL.base_price, rateOfTeam)
            }
        }
    }, [rateOfTeam])

    const onSubmit = async data => {
        try {
            const remappedData = {
                ...data,
                [RD_MODEL.applicant]: data[RD_MODEL.applicant].value,
                [RD_MODEL.responsibility]: data[RD_MODEL.responsibility].value,
                [RD_MODEL.status]: data[RD_MODEL.status].value,
                [RD_MODEL.team]: data[RD_MODEL.team].value,
                [RD_MODEL.budget]: +parseFloat(data[RD_MODEL.budget]),
                [RD_MODEL.base_price]: +parseFloat(data[RD_MODEL.base_price]),
                [RD_MODEL.days]: +data[RD_MODEL.days],
            }
            if (isEdit) {
                await updateRD({
                    id: dataForm.id,
                    data: iterateOverDirtyFields(dirtyFields, remappedData),
                }).unwrap()
            } else {
                const filteredData = filterEmptyKeys(remappedData)
                await addRDProject({
                    data: filteredData,
                }).unwrap()
            }

            closeModal()
            reset(DEFAULT_RD_MOCK)
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: isEdit
                    ? t('commons:toast.updated_success')
                    : t('commons:toast.created_success'),
            })
        } catch (error) {
            handleApiError({
                error,
                handleGeneralError: showError,
                handleFormError: setError,
            })
        }
    }

    useEffect(() => {
        if (isEdit && data) {
            reset({
                ...data,
                [RD_MODEL.start_date]: new Date(data.start_date),
                [RD_MODEL.end_date]: new Date(data.end_date),
                [RD_MODEL.status]: retrieveSingleValueForRs(
                    translateOptions(STATUS_OPTIONS_PROJECTS),
                    data[RD_MODEL.status]
                ),
                [RD_MODEL.team]: retrieveSingleValueForRs(
                    remapSelectOptions(teams),
                    data?.team?.id
                ),
                [RD_MODEL.responsibility]: retrieveSingleValueForRs(
                    responsibilities,
                    data.responsibility?.id
                ),
                [RD_MODEL.applicant]: retrieveSingleValueForRs(
                    applicants,
                    data.applicant?.id
                ),
            })
        }
    }, [data, isEdit])

    useEffect(() => {
        if (days && basePrice) {
            const budget = parseFloat(days) * parseFloat(basePrice)
            setValue(RD_MODEL.budget, Math.round(budget * 100) / 100)
        }
    }, [days, basePrice])

    const startDate = getValues(RD_MODEL.start_date)

    const minEndDate = useMemo(() => {
        const date = new Date(startDate)
        date.setDate(date.getDate() + 1)
        return date
    }, [startDate])

    const projectName = useWatch({
        control,
        name: RD_MODEL.project,
    })

    const { data: projects } = useGetRDProjectsQuery({
        queryKey: [RD_MODEL.project, projectName],
        name: projectName,
    })

    useEffect(() => {
        if (projects?.data?.length > 0 && !!dirtyFields[RD_MODEL.project]) {
            setError(RD_MODEL.project, {
                type: 'manual',
                message: t('validation:general_form.project_name_exists'),
            })
        }
    }, [projects])

    return (
        <StyledForm
            isEdit={isEdit}
            onSubmit={handleSubmit(onSubmit)}
            className="container-fluid p-0 d-grid"
        >
            <div className="row">
                <div className="col-6">
                    <Input
                        label={t('general.project')}
                        placeholder={t('projects.fields.placeholder.project')}
                        required
                        width="100%"
                        size={'large'}
                        touched={touchedFields[RD_MODEL.project]}
                        errors={errors[RD_MODEL.project]}
                        {...register(RD_MODEL.project)}
                    />
                </div>

                <div className="col-6">
                    <Select
                        required
                        name={RD_MODEL.responsibility}
                        label={t('rd.fields.labels.responsibility')}
                        placeholder={t('projects.fields.placeholder.select')}
                        control={control}
                        options={responsibilities}
                        touched={touchedFields[RD_MODEL.responsibility]}
                        errors={errors[RD_MODEL.responsibility]}
                        size="large"
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Divider
                        orientation={'horizontal'}
                        width={'100%'}
                        height={'1px'}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <Datepicker
                        required
                        name={RD_MODEL.start_date}
                        label={t('projects.fields.labels.start_date')}
                        placeholder={t(
                            'projects.fields.placeholder.start_date'
                        )}
                        control={control}
                        touched={touchedFields[RD_MODEL.start_date]}
                        errors={errors[RD_MODEL.start_date]}
                        showMonthDropdown
                        showYearDropdown
                        pickerType={'days'}
                        className="mb-2"
                    />
                </div>

                <div className="col-6">
                    <Datepicker
                        required
                        name={RD_MODEL.end_date}
                        label={t('projects.fields.labels.end_date')}
                        placeholder={t('projects.fields.placeholder.end_date')}
                        control={control}
                        touched={touchedFields[RD_MODEL.end_date]}
                        errors={errors[RD_MODEL.end_date]}
                        showMonthDropdown
                        showYearDropdown
                        pickerType={'days'}
                        className="mb-2"
                        minDate={minEndDate}
                        disabled={!startDate}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <Select
                        required
                        name={RD_MODEL.status}
                        label={t('general.status')}
                        placeholder={t('projects.fields.placeholder.select')}
                        control={control}
                        options={translateOptions(STATUS_OPTIONS_PROJECTS)}
                        touched={touchedFields[RD_MODEL.status]}
                        errors={errors[RD_MODEL.status]}
                        size="large"
                    />
                </div>
                <div className="col-6">
                    <Select
                        required
                        name={RD_MODEL.applicant}
                        label={t('rd.fields.labels.applicant')}
                        placeholder={t('rd.fields.placeholder.applicant')}
                        control={control}
                        options={applicants}
                        touched={touchedFields[RD_MODEL.applicant]}
                        errors={errors[RD_MODEL.applicant]}
                        size="large"
                        isSearchable
                        isOptionDisabled={options => !options?.data?.is_active}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <Select
                        required
                        name={RD_MODEL.team}
                        label={t('general.team')}
                        placeholder={t('projects.fields.placeholder.select')}
                        control={control}
                        options={remapSelectOptions(teams)}
                        touched={touchedFields[RD_MODEL.team]}
                        errors={errors[RD_MODEL.team]}
                        size="large"
                        isSearchable
                        isOptionDisabled={teams => !teams?.data?.is_active}
                    />
                </div>

                <div className="col-6">
                    <InputCurrency
                        label={t('projects.fields.labels.rate')}
                        placeholder={t('projects.fields.placeholder.rate')}
                        required
                        width="100%"
                        size={'large'}
                        touched={touchedFields[RD_MODEL.base_price]}
                        errors={errors[RD_MODEL.base_price]}
                        name={RD_MODEL.base_price}
                        control={control}
                        icon={<CurrencyIcon />}
                        iconPosition={'right'}
                        allowDecimals
                        allowNegativeValue={false}
                        inputProps={{
                            value: watch(RD_MODEL.base_price),
                        }}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <Input
                        type={'number'}
                        required
                        label={t('rd.fields.labels.days')}
                        placeholder={t('rd.fields.placeholder.days')}
                        width="100%"
                        size={'large'}
                        touched={touchedFields[RD_MODEL.days]}
                        errors={errors[RD_MODEL.days]}
                        {...register(RD_MODEL.days)}
                        icon={<ClockIcon />}
                        iconPosition={'right'}
                    />
                </div>

                <div className="col-6">
                    <Input
                        type={'number'}
                        icon={<CurrencyIcon />}
                        iconPosition={'right'}
                        label={t('rd.fields.labels.budget')}
                        placeholder={t('rd.fields.placeholder.rate')}
                        width="100%"
                        size={'large'}
                        errors={errors[RD_MODEL.budget]}
                        {...register(RD_MODEL.budget)}
                        disabled
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Divider
                        orientation={'horizontal'}
                        width={'100%'}
                        height={'1px'}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <Input
                        icon={<LinkIcon />}
                        iconPosition={'right'}
                        label={t('project.fields.label.link_drive_operation')}
                        placeholder={t(
                            'project.fields.placeholder.link_drive_operation'
                        )}
                        width="100%"
                        size={'large'}
                        errors={errors[RD_MODEL.link_drive_operation]}
                        {...register(RD_MODEL.link_drive_operation)}
                    />
                </div>

                <div className="col-6">
                    <Input
                        icon={<LinkIcon />}
                        iconPosition={'right'}
                        label={t('project.fields.label.link_drive_management')}
                        placeholder={t(
                            'project.fields.placeholder.link_drive_management'
                        )}
                        width="100%"
                        size={'large'}
                        errors={errors[RD_MODEL.link_drive_management]}
                        {...register(RD_MODEL.link_drive_management)}
                    />
                </div>
            </div>

            {isEdit && (
                <div className="row">
                    {!canNotDelete && (
                        <StyledContainerDelete className="col-12">
                            <TrashIcon />
                            <p
                                onClick={() => {
                                    openDialogDelete(dataForm?.id)
                                }}
                            >
                                {t(`rd.actions.delete`)}
                            </p>
                        </StyledContainerDelete>
                    )}
                </div>
            )}

            <StyledContainerFormActions className="row d-flex">
                <div className="col-4">
                    <Button
                        size="large"
                        color={'neutral'}
                        isUppercase
                        type={'button'}
                        onClick={() => closeModal(isDirty)}
                    >
                        {t('commons:dialog.error.actions.cancel')}
                    </Button>
                </div>
                <div className="col-8">
                    <Button
                        type="submit"
                        size="large"
                        isUppercase
                        disabled={(!isDirty && isEdit) || isSubmitting}
                    >
                        {isEdit
                            ? t(`commons:button.save`)
                            : t('commons:button.create')}
                    </Button>
                </div>
            </StyledContainerFormActions>
        </StyledForm>
    )
}

export default RandDForm

RandDForm.propTypes = {
    dataForm: PropTypes.object,
    isEdit: PropTypes.bool,
    openDialogDelete: PropTypes.func,
    closeModal: PropTypes.func,
    teams: PropTypes.array,
    responsibilities: PropTypes.array,
    applicants: PropTypes.array,
    canNotDelete: PropTypes.bool,
}
