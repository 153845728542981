import i18next from 'i18next'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import { EditIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import SectionItem from '@/components/sidebar-tab-item/section-item/SectionItem'
import SectionTitle from '@/components/sidebar-tab-item/section-title/SectionTitle'
import {
    StyledSliderSectionContainer,
    StyledSidebarSubHeader,
    StyledTabCtaContainer,
    StyledTeamItemContainer,
    StyledTabItemContainer,
    StyledTabNavLink,
    StyledUsersInfoContainer,
    StyledSingleItem,
    StyledNoOptionsMessage,
} from '@/components/sidebar-tab-item/styles'
import Spinner from '@/components/spinner/Spinner'
import { useGetTeamByIdQuery } from '@/services/settings/settingApiSlice'
import { setPanelId, selectPanelId } from '@/services/settings/settingsSlice'
import {
    TYPE_USERS,
    ROUTE_SETTINGS,
    ROUTE_SETTINGS_MEMBERS,
} from '@/utilities/constants'
import { formatCurrency, translatedEnum } from '@/utilities/helpers'

const TeamSidebar = ({ setOpenForm }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const dispatch = useDispatch()

    const panelId = useSelector(selectPanelId)

    const { data, isFetching } = useGetTeamByIdQuery(
        { ID: panelId },
        { skip: !panelId }
    )

    if (isFetching) {
        return <Spinner overlay={false} overlayFullscreen={false} />
    }

    return (
        <>
            <StyledSidebarSubHeader>
                {<h3>{data?.name}</h3>}
                <StyledTabCtaContainer>
                    <Button
                        onClick={() =>
                            setOpenForm({
                                isOpen: true,
                                typeForm: TYPE_USERS.teams,
                                id: data?.id,
                                isEdit: true,
                            })
                        }
                        collapsed
                    >
                        <EditIcon
                            width={10}
                            height={10}
                            fill={theme.palette.neutral.base[0]}
                        />
                    </Button>
                </StyledTabCtaContainer>
            </StyledSidebarSubHeader>

            <StyledSliderSectionContainer>
                <StyledTeamItemContainer>
                    <SectionItem
                        label={t('general.status')}
                        value={
                            data?.is_active
                                ? i18next.t('commons:status.active')
                                : i18next.t('commons:status.inactive')
                        }
                        width={100}
                    />
                </StyledTeamItemContainer>

                <StyledTeamItemContainer>
                    <StyledTabItemContainer width={100}>
                        <p className="label_">{t('general.team_lead')}</p>
                        <StyledTabNavLink
                            key={data?.team_lead.full_name}
                            to={`${ROUTE_SETTINGS}/${ROUTE_SETTINGS_MEMBERS}?id=${data?.team_lead.id}`}
                            onClick={() => dispatch(setPanelId(undefined))}
                        >
                            {data?.team_lead.full_name}
                        </StyledTabNavLink>
                    </StyledTabItemContainer>
                </StyledTeamItemContainer>

                <StyledTeamItemContainer>
                    <SectionItem
                        label={t('settings.sidebar_left.labels.rate')}
                        value={formatCurrency(data?.rate)}
                        width={100}
                    />
                </StyledTeamItemContainer>

                <div>
                    <SectionItem
                        label={t('general.product_line')}
                        value={translatedEnum(
                            data?.product_line,
                            'product_line'
                        )}
                        width={100}
                    />
                </div>
            </StyledSliderSectionContainer>

            <div>
                <SectionTitle title={t('general.member')} />
                <StyledUsersInfoContainer>
                    {data?.users.length > 0 ? (
                        data?.users?.map(user => {
                            return (
                                <StyledTabNavLink
                                    key={user.id}
                                    to={`${ROUTE_SETTINGS}/${ROUTE_SETTINGS_MEMBERS}?id=${user.id}`}
                                    onClick={() =>
                                        dispatch(setPanelId(undefined))
                                    }
                                >
                                    {user.full_name}
                                </StyledTabNavLink>
                            )
                        })
                    ) : (
                        <StyledSingleItem className="value_">
                            <StyledNoOptionsMessage>
                                {t(
                                    'settings.sidebar_left.subtitles.no_members'
                                )}
                            </StyledNoOptionsMessage>
                        </StyledSingleItem>
                    )}
                </StyledUsersInfoContainer>
            </div>
        </>
    )
}

export default TeamSidebar

TeamSidebar.propTypes = {
    data: PropTypes.object,
    setOpenForm: PropTypes.func,
}
