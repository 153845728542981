import styled, { css } from 'styled-components'
export const StyledContainerPayrollFinance = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: 114px 130px 174px 116px;
        grid-gap: ${theme.spacing}px;
        grid-template-rows: 1fr;
        color: ${theme.palette.primary[0]};
    `}
`

export const StyledContainerActiveScheduleFinance = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: 130px 150px 174px;
        grid-gap: ${theme.spacing}px;
        grid-template-rows: 1fr;
        color: ${theme.palette.primary[0]};
    `}
`

export const StyledContainerPassiveScheduleFinance = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: 130px 174px;
        grid-gap: ${theme.spacing}px;
        grid-template-rows: 1fr;
        color: ${theme.palette.primary[0]};
    `}
`

export const StyledContainerFinalBalanceFinance = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: 150px 174px;
        grid-gap: ${theme.spacing}px;
        grid-template-rows: 1fr;
        color: ${theme.palette.primary[0]};
    `}
`

export const StyledContainerFinancialImportFinance = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: 150px;
        grid-gap: ${theme.spacing}px;
        grid-template-rows: 1fr;
        color: ${theme.palette.primary[0]};
        & .react-datepicker-popper {
            width: 100% !important;
        }
    `}
`

export const StyledContainerToggle = styled.div`
    ${({ theme }) => css`
        display: flex;
        gap: ${theme.spacing}px;
        align-items: center;
        & > p {
            ${theme.typography.paragraph.p14.regular};
        }
    `}
`
