import PropTypes from 'prop-types'

import CostsRDFormWrapper from '@/pages/projects/detail/detail-r&d/r&d-form/costs-rd-form/CostsRDFormWrapper.jsx'
import RAndDWrapper from '@/pages/projects/list/projects-form/r&d-form/R&DWrapper.jsx'
import MemberFormWrapper from '@/pages/settings/settings-form/member-form/MemberFormWrapper.jsx'
import { LABELS_TABLES } from '@/utilities/constants/index.js'

const RDDetailsForm = ({
    dataForm,
    onClose,
    setOpenDialogToConfirm,
    openDialogDelete,
    isEdit,
    suppliers,
    sals,
    teams,
    applicants,
    responsibilities,
}) => {
    const closeModal = isDirty => {
        if (isDirty) {
            setOpenDialogToConfirm(true)
        } else {
            onClose()
        }
    }
    const props = {
        dataForm,
        closeModal,
        openDialogDelete,
        isEdit,
        suppliers,
        sals,
        teams,
        applicants,
        responsibilities,
    }

    switch (dataForm?.typeForm) {
        case LABELS_TABLES.costs:
            return <CostsRDFormWrapper {...props} />
        case LABELS_TABLES.projects:
            return <RAndDWrapper canNotDelete={true} {...props} />
        case LABELS_TABLES.member:
            return (
                <MemberFormWrapper
                    canNotDelete={true}
                    onClose={closeModal}
                    openForm={dataForm}
                    {...props}
                />
            )
        default:
            return null
    }
}

export default RDDetailsForm

RDDetailsForm.propTypes = {
    dataForm: PropTypes.object,
    onClose: PropTypes.func,
    setOpenDialogToConfirm: PropTypes.func,
    openDialogDelete: PropTypes.func,
    isEdit: PropTypes.bool,
    suppliers: PropTypes.array,
    sals: PropTypes.array,
    teams: PropTypes.array,
    applicants: PropTypes.array,
    responsibilities: PropTypes.array,
}
