import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { Logo, ExitIcon } from '@/assets/icons/icons'
import Spinner from '@/components/spinner/Spinner.jsx'
import UserInitialsAvatar from '@/components/user-initials-avatar/UserInitialsAvatar.jsx'
import useError from '@/hooks/useError.jsx'
import useUser from '@/hooks/useUser.jsx'
import AuthAPI from '@/services/auth/auth.http.js'
import { setPanelId } from '@/services/settings/settingsSlice'
import { handleApiError } from '@/utilities/api/helpers.js'
import * as routeNames from '@/utilities/constants'

import {
    StyledHeaderRoot,
    StyledHeaderLogo,
    StyledHeaderInfo,
    StyledHeaderRoutes,
    StyledHeaderNav,
    StyledHeaderDivider,
    StyledHeaderUserInfo,
} from './styles'

const Header = () => {
    const { t } = useTranslation()
    const { user, resetUser } = useUser()
    const dispatch = useDispatch()
    const showError = useError()
    const [isLoading, setIsLoading] = useState(false)

    const routerItems = [
        { to: routeNames.ROUTE_DASHBOARD, title: t('header.dashboard') },
        {
            to: `${routeNames.ROUTE_PROJECTS}/${routeNames.ROUTE_STANDARD_PROJECTS}`,
            title: t('general.projects'),
        },
        { to: routeNames.ROUTE_CUSTOMERS, title: t('header.customers') },
        { to: routeNames.ROUTE_FINANCE, title: t('general.finance') },
    ]

    const logout = async () => {
        setIsLoading(true)
        try {
            await AuthAPI.logout.send()
            resetUser()
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            {isLoading && <Spinner />}
            <StyledHeaderRoot>
                <StyledHeaderLogo>
                    <Link to={routeNames.ROUTE_DASHBOARD}>
                        <Logo />
                    </Link>
                </StyledHeaderLogo>
                <StyledHeaderInfo>
                    <StyledHeaderRoutes>
                        {routerItems.map((item, index) => (
                            <StyledHeaderNav to={item.to} key={index}>
                                {item.title}
                            </StyledHeaderNav>
                        ))}
                        <StyledHeaderNav
                            to={`${routeNames.ROUTE_SETTINGS}/${routeNames.ROUTE_SETTINGS_MEMBERS}`}
                            onClick={() => dispatch(setPanelId(undefined))}
                        >
                            {t('general.settings')}
                        </StyledHeaderNav>
                    </StyledHeaderRoutes>
                    <StyledHeaderDivider />
                    <StyledHeaderUserInfo>
                        <UserInitialsAvatar
                            size={24}
                            id={user?.id}
                            username={`${user?.first_name} ${user?.last_name}`}
                        />
                        <ExitIcon onClick={logout} />
                    </StyledHeaderUserInfo>
                </StyledHeaderInfo>
            </StyledHeaderRoot>
        </>
    )
}

export default Header

Header.propTypes = {
    isMobile: PropTypes.bool,
}
