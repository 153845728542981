import styled, { css } from 'styled-components'

export const StyledContainerButton = styled.div`
    ${({ theme, notHavePhase }) => css`
        display: grid;
        grid-template-columns: ${notHavePhase
            ? '150px 150px 150px;'
            : '150px 150px'};
        grid-gap: ${theme.spacing}px;
    `}
`
