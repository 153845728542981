import styled, { css } from 'styled-components'

export const StyledErrorMessage = styled.div`
    ${({ theme: { spacing, colors, styledTypography } }) => css`
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: ${spacing * 2}px;
        padding: ${spacing * 2}px;

        & h4 {
            ${styledTypography.heading1};
            color: ${colors.red};
        }
    `}
`
