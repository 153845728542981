import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import FinalBalanceTable from '@/components/final-balance-table/FinalBalanceTable.jsx'
import Table from '@/components/table/index.jsx'
import Tabs from '@/components/tabs/Tabs.jsx'
import { remappedDataFinalBalance } from '@/pages/customers/detail/remappedDataFinalBalance.js'
import FinancialImport from '@/pages/finance/finance-tables/FinancialImport.jsx'
import {
    columnsActiveSchedule,
    columnsRenderersActiveSchedule,
} from '@/pages/finance/finance-tables/columnsActiveSchedule.jsx'
import {
    columnsPassiveSchedule,
    columnsRenderersPassiveSchedule,
} from '@/pages/finance/finance-tables/columnsPassiveSchedule.jsx'
import {
    columnsPayrolls,
    columnsRenderersPayrolls,
} from '@/pages/finance/finance-tables/columnsPayrolls.jsx'
import { useGetFinalBalanceCustomerQuery } from '@/services/customers/customersApiSlice.js'
import {
    useGetActiveScheduleQuery,
    useGetPassiveScheduleQuery,
    useGetPayrollsQuery,
} from '@/services/financial/financeApiSlice.js'
import { LABELS_TABLES, TABLE_HEIGHT } from '@/utilities/constants'
import { tableHeight, handleSortFilter } from '@/utilities/helpers'

const FinanceTables = ({
    currentTab,
    setCurrentTab,
    openDialogDelete,
    openEditSidebar,
    filters,
    setFilters,
    setDataForm,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [sortDirections, setSortDirections] = useState({
        active: 'asc',
        passive: 'asc',
    })

    const { data: payrolls, isFetching } = useGetPayrollsQuery(
        {
            ...filters,
        },
        {
            skip: currentTab !== LABELS_TABLES.payroll,
        }
    )
    const { data: activeInvoices, isFetching: fetchingActiveInvoices } =
        useGetActiveScheduleQuery(
            {
                sortDirection: sortDirections?.active,
                ...filters,
            },
            {
                skip: currentTab !== LABELS_TABLES.active_schedule,
            }
        )
    const { data: passiveExpenses, isFetching: fetchingPassiveExpenses } =
        useGetPassiveScheduleQuery(
            {
                sortDirection: sortDirections.passive,
                ...filters,
            },
            {
                skip: currentTab !== LABELS_TABLES.passive_schedule,
            }
        )

    const onSectionClick = section => {
        const searchParams = new URLSearchParams(location.search)
        searchParams.set('section', section)
        setCurrentTab(section)
        navigate(`?${searchParams.toString()}`)
        setFilters({})
    }

    const tableColumns = {
        payrolls: columnsPayrolls(),
        activeSchedule: columnsActiveSchedule(),
        passiveSchedule: columnsPassiveSchedule(),
    }

    const [year, setYear] = useState(new Date().getFullYear())

    const { data } = useGetFinalBalanceCustomerQuery({
        year: filters?.isFilter ? filters?.year : year,
        ...filters,
    })

    const finalBalanceData = remappedDataFinalBalance(data)

    const tableSections = [
        {
            id: 1,
            value: t('finance.tabs.payroll'),
            label: t('finance.tabs.payroll'),
            component: (
                <>
                    <Table
                        columns={tableColumns.payrolls}
                        columnsRenderers={columnsRenderersPayrolls()}
                        data={payrolls}
                        isLoading={isFetching}
                        isPaginated
                        bodyheight={TABLE_HEIGHT}
                    />
                </>
            ),
        },
        {
            id: 2,
            value: t('finance.tabs.active_schedule'),
            label: t('finance.tabs.active_schedule'),
            component: (
                <>
                    <Table
                        columns={tableColumns.activeSchedule}
                        columnsRenderers={columnsRenderersActiveSchedule(
                            openDialogDelete,
                            openEditSidebar
                        )}
                        data={activeInvoices}
                        isLoading={fetchingActiveInvoices}
                        isPaginated
                        bodyheight={tableHeight(activeInvoices)}
                        sortColumnCb={() =>
                            handleSortFilter('active', setSortDirections)
                        }
                    />
                </>
            ),
        },
        {
            id: 3,
            value: t('finance.tabs.passive_schedule'),
            label: t('finance.tabs.passive_schedule'),
            component: (
                <>
                    <Table
                        columns={tableColumns.passiveSchedule}
                        columnsRenderers={columnsRenderersPassiveSchedule(
                            openDialogDelete,
                            openEditSidebar
                        )}
                        data={passiveExpenses}
                        isLoading={fetchingPassiveExpenses}
                        isPaginated
                        bodyheight={tableHeight(passiveExpenses)}
                        sortColumnCb={() =>
                            handleSortFilter('passive', setSortDirections)
                        }
                    />
                </>
            ),
        },
        {
            id: 4,
            value: t('finance.tabs.final_balance'),
            label: t('finance.tabs.final_balance'),
            component: (
                <FinalBalanceTable
                    setYear={setYear}
                    parentRows={finalBalanceData}
                    setDataForm={setDataForm}
                    filters={filters}
                />
            ),
        },
        {
            id: 5,
            value: t('finance.tabs.financial_import'),
            label: t('finance.tabs.financial_import'),
            component: <FinancialImport filters={filters} />,
        },
    ]

    return (
        <>
            <Tabs
                onValueChange={e => onSectionClick(e)}
                defaultValue={currentTab}
                triggers={tableSections}
                haveDivider
            />
        </>
    )
}

export default FinanceTables

FinanceTables.propTypes = {
    currentTab: PropTypes.string,
    setCurrentTab: PropTypes.func,
    setDataForm: PropTypes.func,
    openDialogDelete: PropTypes.func,
    openEditSidebar: PropTypes.func,
    filters: PropTypes.object,
    setFilters: PropTypes.func,
}
