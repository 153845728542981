import {
    columnsEighth,
    columnsRenderersEighth,
} from '@/pages/dashboard/dashboard-body/dashboard-tables/columnsDashboardEighth.jsx'
import {
    columnsEleventh,
    columnsRenderersEleventh,
} from '@/pages/dashboard/dashboard-body/dashboard-tables/columnsDashboardEleventh.jsx'
import {
    columnsFifth,
    columnsRenderersFifth,
} from '@/pages/dashboard/dashboard-body/dashboard-tables/columnsDashboardFifth.jsx'
import {
    columnsFirst,
    columnsRenderersFirst,
} from '@/pages/dashboard/dashboard-body/dashboard-tables/columnsDashboardFirst.jsx'
import {
    columnsFourth,
    columnsRenderersFourth,
} from '@/pages/dashboard/dashboard-body/dashboard-tables/columnsDashboardFourth.jsx'
import {
    columnsNinth,
    columnsRenderersNinth,
} from '@/pages/dashboard/dashboard-body/dashboard-tables/columnsDashboardNinth.jsx'
import {
    columnsRenderersSecond,
    columnsSecond,
} from '@/pages/dashboard/dashboard-body/dashboard-tables/columnsDashboardSecond.jsx'
import {
    columnsRenderersSeventh,
    columnsSeventh,
} from '@/pages/dashboard/dashboard-body/dashboard-tables/columnsDashboardSeventh.jsx'
import {
    columnsRenderersSixth,
    columnsSixth,
} from '@/pages/dashboard/dashboard-body/dashboard-tables/columnsDashboardSixth.jsx'
import {
    columnsRenderersTenth,
    columnsTenth,
} from '@/pages/dashboard/dashboard-body/dashboard-tables/columnsDashboardTenth.jsx'
import {
    columnsRenderersThird,
    columnsThird,
} from '@/pages/dashboard/dashboard-body/dashboard-tables/columnsDashboardThird.jsx'

export default ({ t }) => {
    return [
        {
            id: 1,
            label: t('dashboard.button.not_assigned_budget'),
            length: 2,
            columns: columnsFirst,
            columnsRenderers: columnsRenderersFirst(),
        },
        {
            id: 2,
            label: t('dashboard.button.expired_sal'),
            length: 2,
            columns: columnsSecond,
            columnsRenderers: columnsRenderersSecond(),
        },
        {
            id: 3,
            label: t('dashboard.button.loss_sal'),
            length: 2,
            columns: columnsThird,
            columnsRenderers: columnsRenderersThird(),
        },
        {
            id: 4,
            label: t('dashboard.button.deadline_too_meet'),
            length: 2,
            columns: columnsFourth,
            columnsRenderers: columnsRenderersFourth(),
        },
        {
            id: 5,
            label: t('dashboard.button.total_hours_on_budget'),
            length: 2,
            columns: columnsFifth,
            columnsRenderers: columnsRenderersFifth(),
        },
        {
            id: 6,
            label: t('dashboard.button.planned_budget_below'),
            length: 2,
            columns: columnsSixth,
            columnsRenderers: columnsRenderersSixth(),
        },
        {
            id: 7,
            label: t('dashboard.button.delivery_activity_under'),
            length: 2,
            columns: columnsSeventh,
            columnsRenderers: columnsRenderersSeventh(),
        },
        {
            id: 8,
            label: t('dashboard.button.unconfirmed_finals'),
            length: 2,
            columns: columnsEighth,
            columnsRenderers: columnsRenderersEighth(),
        },
        {
            id: 9,
            label: t('dashboard.button.deadline_not_set'),
            length: 2,
            columns: columnsNinth,
            columnsRenderers: columnsRenderersNinth(),
        },
        {
            id: 10,
            label: t('dashboard.button.invoices_not_issued'),
            length: 0,
            columns: columnsTenth,
            columnsRenderers: columnsRenderersTenth(),
        },
        {
            id: 11,
            label: t('dashboard.button.incomplete_payroll'),
            length: 2,
            columns: columnsEleventh,
            columnsRenderers: columnsRenderersEleventh(),
        },
    ]
}
