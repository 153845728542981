import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { CurrencyIcon, TrashIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import Datepicker from '@/components/date-picker/DatePicker.jsx'
import InputCurrency from '@/components/input-currency/InputCurrency.jsx'
import Select from '@/components/select/Select.jsx'
import TextArea from '@/components/text-area/TextArea.jsx'
import useError from '@/hooks/useError.jsx'
import {
    StyledContainerFormActions,
    StyledFormBilling,
} from '@/pages/customers/detail/customer-form/billing-form/styles.js'
import { FINAl_INVOICE_MODEL } from '@/pages/customers/detail/customer-form/final-invoice-form/finalInvoice.schema.js'
import { StyledContainerDelete } from '@/pages/customers/detail/customer-tables/table-projects/projects-form/styles.js'
import {
    useCreateInvoiceFinalBalanceMutation,
    useGetInvoiceByIDFinalBalanceQuery,
    useUpdateInvoiceFinalBalanceMutation,
} from '@/services/projects/finalBalanceApiSlice.js'
import { formatDateFromApi, handleApiError } from '@/utilities/api/helpers.js'
import { INVOICE_STATUS_OPTIONS } from '@/utilities/constants/list.js'
import {
    iterateOverDirtyFields,
    retrieveSingleValueForRs,
    translateOptions,
} from '@/utilities/helpers.js'

const FinalInvoiceForm = ({
    isEdit,
    closeModal,
    dataForm,
    openDialogDelete,
}) => {
    const { t } = useTranslation()
    const showError = useError()
    const { id } = useParams()

    const [createInvoice] = useCreateInvoiceFinalBalanceMutation()
    const [updateInvoice] = useUpdateInvoiceFinalBalanceMutation()
    const { data: invoiceData } = useGetInvoiceByIDFinalBalanceQuery(
        { customerID: id, invoiceID: dataForm?.id },
        { skip: !isEdit }
    )

    const {
        register,
        control,
        handleSubmit,
        reset,
        setError,
        watch,
        formState: {
            errors,
            isDirty,
            touchedFields,
            dirtyFields,
            isSubmitting,
        },
    } = useFormContext()

    const onSubmit = async data => {
        try {
            const remappedData = {
                ...data,
                [FINAl_INVOICE_MODEL.taxable]: parseFloat(
                    data[FINAl_INVOICE_MODEL.taxable]
                ),
                [FINAl_INVOICE_MODEL.status]:
                    data[FINAl_INVOICE_MODEL.status]?.value,
            }

            if (isEdit) {
                await updateInvoice({
                    customerID: id,
                    invoiceID: dataForm?.id,
                    data: iterateOverDirtyFields(dirtyFields, remappedData),
                }).unwrap()
            } else {
                await createInvoice({
                    customerID: id,
                    invoiceID: dataForm?.id,
                    data: remappedData,
                }).unwrap()
            }
            closeModal()
        } catch (error) {
            handleApiError({
                error,
                handleGeneralError: showError,
                handleFormError: setError,
            })
        }
    }

    useEffect(() => {
        if (isEdit && invoiceData) {
            reset({
                ...invoiceData,
                [FINAl_INVOICE_MODEL.date]: formatDateFromApi(
                    invoiceData[FINAl_INVOICE_MODEL.date]
                ),
                [FINAl_INVOICE_MODEL.status]: retrieveSingleValueForRs(
                    translateOptions(INVOICE_STATUS_OPTIONS),
                    invoiceData[FINAl_INVOICE_MODEL.status]
                ),
            })
        }
    }, [invoiceData, isEdit])

    return (
        <>
            <StyledFormBilling
                isEdit={isEdit}
                onSubmit={handleSubmit(onSubmit)}
                className="container-fluid d-grid p-0 gap-3"
            >
                <div className="row">
                    <div className="col-6">
                        <Datepicker
                            required
                            label={t('projects.fields.labels.date')}
                            placeholder={t(
                                'projects.fields.placeholder.start_date'
                            )}
                            control={control}
                            name={FINAl_INVOICE_MODEL.date}
                            touched={touchedFields[FINAl_INVOICE_MODEL.date]}
                            errors={errors[FINAl_INVOICE_MODEL.date]}
                            showMonthDropdown
                            showYearDropdown
                            pickerType={'days'}
                        />
                    </div>

                    <div className="col-6">
                        <Select
                            required
                            name={FINAl_INVOICE_MODEL.status}
                            label={t('general.status')}
                            placeholder={t(
                                'projects.fields.placeholder.status'
                            )}
                            control={control}
                            touched={touchedFields[FINAl_INVOICE_MODEL.status]}
                            errors={errors[FINAl_INVOICE_MODEL.status]}
                            size="large"
                            options={translateOptions(INVOICE_STATUS_OPTIONS)}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <InputCurrency
                            required
                            label={t('projects.fields.labels.taxable')}
                            placeholder={t(
                                'projects.fields.placeholder.taxable'
                            )}
                            width="100%"
                            size={'large'}
                            icon={<CurrencyIcon />}
                            iconPosition={'right'}
                            control={control}
                            errors={errors[FINAl_INVOICE_MODEL.taxable]}
                            name={FINAl_INVOICE_MODEL.taxable}
                            inputProps={{
                                value: watch(FINAl_INVOICE_MODEL.taxable),
                            }}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <TextArea
                            required
                            label={t('projects.fields.labels.note')}
                            placeholder={t('projects.fields.placeholder.note')}
                            width="100%"
                            size={'large'}
                            errors={errors[FINAl_INVOICE_MODEL.note]}
                            {...register(FINAl_INVOICE_MODEL.note)}
                            resize={'vertical'}
                        />
                    </div>
                </div>

                {isEdit && (
                    <div className="row">
                        <StyledContainerDelete className="col-12">
                            <TrashIcon />
                            <p
                                onClick={() => {
                                    openDialogDelete(
                                        dataForm?.id,
                                        dataForm?.data
                                    )
                                }}
                            >
                                {t('final_balance.actions.delete')}
                            </p>
                        </StyledContainerDelete>
                    </div>
                )}

                <StyledContainerFormActions className="row d-flex">
                    <div className="col-md-4">
                        <Button
                            size="large"
                            color={'neutral'}
                            isUppercase
                            onClick={() => {
                                closeModal(isDirty)
                            }}
                        >
                            {t('commons:button.cancel')}
                        </Button>
                    </div>
                    <div className="col-md-8">
                        <Button
                            type="submit"
                            size="large"
                            isUppercase
                            disabled={(!isDirty && isEdit) || isSubmitting}
                        >
                            {isEdit
                                ? t('commons:button.save')
                                : t('commons:button.create')}
                        </Button>
                    </div>
                </StyledContainerFormActions>
            </StyledFormBilling>
        </>
    )
}

export default FinalInvoiceForm

FinalInvoiceForm.propTypes = {
    isEdit: PropTypes.bool,
    closeModal: PropTypes.func,
    dataForm: PropTypes.object,
    openDialogDelete: PropTypes.func,
}
