import * as yup from 'yup'

import i18next from '../../../../../i18n.js'

const STANDARD_PROJECTS_MODEL = {
    project: 'name',
    customer: 'customer_id',
    start_date: 'start_date',
    end_date: 'end_date',
    status: 'is_active',
    team: 'team_id',
    success_manager: 'success_manager_id',
    days: 'days',
    base_price: 'rate',
    budget: 'markup_budget',
    markup: 'markup',
    link_drive_operation: 'drive_url_operation',
    link_drive_management: 'drive_url_management',
}

const DEFAULT_STANDARD_PROJECTS_MOCK = {
    [STANDARD_PROJECTS_MODEL.project]: '',
    [STANDARD_PROJECTS_MODEL.customer]: null,
    [STANDARD_PROJECTS_MODEL.start_date]: null,
    [STANDARD_PROJECTS_MODEL.end_date]: null,
    [STANDARD_PROJECTS_MODEL.status]: null,
    [STANDARD_PROJECTS_MODEL.team]: null,
    [STANDARD_PROJECTS_MODEL.success_manager]: null,
    [STANDARD_PROJECTS_MODEL.days]: '',
    [STANDARD_PROJECTS_MODEL.base_price]: null,
    [STANDARD_PROJECTS_MODEL.budget]: null,
    [STANDARD_PROJECTS_MODEL.markup]: null,
    [STANDARD_PROJECTS_MODEL.link_drive_operation]: '',
    [STANDARD_PROJECTS_MODEL.link_drive_management]: null,
}
const RequestValidationSchema = yup.object().shape({
    [STANDARD_PROJECTS_MODEL.project]: yup
        .string()
        .required(i18next.t('validation:general_form.project_required')),
    [STANDARD_PROJECTS_MODEL.customer]: yup
        .object()
        .required(i18next.t('validation:general_form.customer_required')),
    [STANDARD_PROJECTS_MODEL.start_date]: yup
        .date()
        .required(i18next.t('validation:general_form.start_date_required')),
    [STANDARD_PROJECTS_MODEL.end_date]: yup
        .date()
        .required(i18next.t('validation:general_form.end_date_required')),
    [STANDARD_PROJECTS_MODEL.status]: yup
        .object()
        .required(i18next.t('validation:general_form.status')),
    [STANDARD_PROJECTS_MODEL.team]: yup
        .object()
        .required(i18next.t('validation:general_form.team_leader_required')),
    [STANDARD_PROJECTS_MODEL.success_manager]: yup
        .object()
        .required(
            i18next.t('validation:general_form.success_manager_required')
        ),
    [STANDARD_PROJECTS_MODEL.days]: yup
        .string()
        .matches(/^[1-9]\d*$/, i18next.t('validation:general_form.rate_check'))
        .required(i18next.t('validation:general_form.days_required')),
    [STANDARD_PROJECTS_MODEL.base_price]: yup
        .string()
        .required(i18next.t('validation:general_form.base_price_required'))
        .transform(value => (value === '' ? null : value)),
    [STANDARD_PROJECTS_MODEL.budget]: yup
        .string()
        .required(i18next.t('validation:general_form.rate_required')),
    [STANDARD_PROJECTS_MODEL.markup]: yup
        .number()
        .nullable()
        .transform(value => (value === '' ? null : value)),
    [STANDARD_PROJECTS_MODEL.link_drive_operation]: yup
        .string()
        .matches(
            /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/,
            i18next.t('validation:general_form.invalid_url')
        )
        .nullable()
        .transform(value => (value === '' ? null : value)),
    [STANDARD_PROJECTS_MODEL.link_drive_management]: yup
        .string()
        .matches(
            /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/,
            i18next.t('validation:general_form.invalid_url')
        )
        .nullable()
        .transform(value => (value === '' ? null : value)),
})

export {
    STANDARD_PROJECTS_MODEL,
    DEFAULT_STANDARD_PROJECTS_MOCK,
    RequestValidationSchema as validationSchema,
}
