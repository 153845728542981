import PropTypes from 'prop-types'

import ActiveScheduleFilters from '@/pages/finance/finance-filters/filters/ActiveScheduleFilters.jsx'
import FinalBalancesFinanceFilters from '@/pages/finance/finance-filters/filters/FinalBalancesFinanceFilters.jsx'
import FinancialImportFilters from '@/pages/finance/finance-filters/filters/FinancialImportFilters.jsx'
import PassiveSchedule from '@/pages/finance/finance-filters/filters/PassiveSchedule.jsx'
import PayrollFilters from '@/pages/finance/finance-filters/filters/PayrollFilters.jsx'
import { LABELS_TABLES } from '@/utilities/constants/index.js'

const FinanceFiltersRender = ({ currentTab, setFilters, filters }) => {
    const props = {
        setFilters,
        filters,
    }
    switch (currentTab) {
        case LABELS_TABLES.active_schedule:
            return <ActiveScheduleFilters {...props} />
        case LABELS_TABLES.passive_schedule:
            return <PassiveSchedule {...props} />
        case LABELS_TABLES.final_balances:
            return <FinalBalancesFinanceFilters {...props} />
        case LABELS_TABLES.financial_import:
            return <FinancialImportFilters {...props} />
        default:
            return <PayrollFilters {...props} />
    }
}

export default FinanceFiltersRender

FinanceFiltersRender.propTypes = {
    currentTab: PropTypes.string,
    setFilters: PropTypes.func,
    filters: PropTypes.object,
}
