import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { TrashIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import Datepicker from '@/components/date-picker/DatePicker.jsx'
import Select from '@/components/select/Select.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import { StyledContainerFormActions } from '@/pages/customers/detail/customer-form/billing-form/styles.js'
import { StyledContainerDelete } from '@/pages/customers/detail/customer-tables/table-projects/projects-form/styles.js'
import {
    ASSIGN_TEAM_MODEL,
    DEFAULT_ASSIGN_TEAM_MOCK,
} from '@/pages/settings/settings-form/assign-teams-form/assignTeam.schema.js'
import { StyledForm } from '@/pages/settings/settings-form/assign-teams-form/styles.js'
import {
    useAssignTeamMutation,
    useEditAssignedTeamMutation,
} from '@/services/settings/settingApiSlice'
import { handleApiError } from '@/utilities/api/helpers.js'
import {
    iterateOverDirtyFields,
    retrieveSingleValueForRs,
    filterEmptyKeys,
    formatDate,
} from '@/utilities/helpers.js'

const AssignTeamForm = ({
    onClose,
    isEdit,
    closeModal,
    openForm,
    data,
    teamsOptions,
    setOpenDialogToDelete,
}) => {
    const { t } = useTranslation()
    const { triggerAlert } = useAlert()
    const showError = useError()

    const {
        control,
        handleSubmit,
        reset,
        setError,
        getValues,
        formState: {
            errors,
            isDirty,
            touchedFields,
            dirtyFields,
            isSubmitting,
        },
    } = useFormContext()

    const [assignTeam] = useAssignTeamMutation()
    const [editAssignedTeam] = useEditAssignedTeamMutation()

    const onSubmit = async values => {
        try {
            const remappedValues = {
                ...values,
                [ASSIGN_TEAM_MODEL.team]: values[ASSIGN_TEAM_MODEL.team].value,
                [ASSIGN_TEAM_MODEL.start_date]: formatDate(
                    values[ASSIGN_TEAM_MODEL.start_date]
                ),
                [ASSIGN_TEAM_MODEL.end_date]: formatDate(
                    values[ASSIGN_TEAM_MODEL.end_date]
                ),
            }

            if (isEdit) {
                await editAssignedTeam({
                    ID: openForm?.data?.userID,
                    historyTeamID: openForm?.data?.id,
                    data: iterateOverDirtyFields(dirtyFields, remappedValues),
                }).unwrap()
            } else {
                const filteredValues = filterEmptyKeys(remappedValues)
                await assignTeam({
                    ID: openForm?.data?.userID,
                    data: filteredValues,
                }).unwrap()
            }
            onClose()
            reset(DEFAULT_ASSIGN_TEAM_MOCK)
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: isEdit
                    ? t('commons:toast.updated_success')
                    : t('commons:toast.created_success'),
            })
        } catch (error) {
            handleApiError({
                error,
                handleGeneralError: showError,
                handleFormError: setError,
            })
        }
    }

    useEffect(() => {
        if (isEdit && data) {
            reset({
                ...data,
                [ASSIGN_TEAM_MODEL.team]: retrieveSingleValueForRs(
                    teamsOptions,
                    data?.team?.id
                ),
                [ASSIGN_TEAM_MODEL.start_date]: data?.joined_at
                    ? new Date(data?.joined_at)
                    : null,
                [ASSIGN_TEAM_MODEL.end_date]: data?.leaved_at
                    ? new Date(data?.leaved_at)
                    : null,
            })
        }
    }, [isEdit, data])

    const startDate = getValues(ASSIGN_TEAM_MODEL.start_date)

    const minEndDate = useMemo(() => {
        const date = new Date(startDate)
        date.setDate(date.getDate() + 1)
        return date
    }, [startDate])

    return (
        <StyledForm
            isEdit={isEdit}
            onSubmit={handleSubmit(onSubmit)}
            className="container-fluid p-0 d-grid"
        >
            <div className="row">
                <div className="col-12">
                    <Select
                        required
                        name={ASSIGN_TEAM_MODEL.team}
                        control={control}
                        touched={touchedFields[ASSIGN_TEAM_MODEL.team]}
                        errors={errors[ASSIGN_TEAM_MODEL.team]}
                        label={t(`settings.fields.label.select_team`)}
                        placeholder={t(
                            `settings.fields.placeholder.select_team`
                        )}
                        size="large"
                        options={teamsOptions}
                        isSearchable
                        isOptionDisabled={teamsOptions =>
                            !teamsOptions?.data?.is_active
                        }
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <Datepicker
                        required
                        name={ASSIGN_TEAM_MODEL.start_date}
                        label={t(`settings.fields.label.start_date`)}
                        placeholder={t(
                            `settings.fields.placeholder.start_date`
                        )}
                        control={control}
                        touched={touchedFields[ASSIGN_TEAM_MODEL.start_date]}
                        errors={errors[ASSIGN_TEAM_MODEL.start_date]}
                        showMonthDropdown
                        showYearDropdown
                        pickerType={'days'}
                        className="mb-2"
                    />
                </div>
                <div className="col-6">
                    <Datepicker
                        name={ASSIGN_TEAM_MODEL.end_date}
                        label={t(`settings.fields.label.end_date`)}
                        placeholder={t(`settings.fields.placeholder.end_date`)}
                        control={control}
                        touched={touchedFields[ASSIGN_TEAM_MODEL.end_date]}
                        errors={errors[ASSIGN_TEAM_MODEL.end_date]}
                        showMonthDropdown
                        showYearDropdown
                        pickerType={'days'}
                        className="mb-2"
                        minDate={minEndDate}
                        disabled={!startDate}
                    />
                </div>
            </div>

            {isEdit && (
                <div className="row">
                    <StyledContainerDelete className="col-12">
                        <TrashIcon />
                        <p
                            onClick={() => {
                                setOpenDialogToDelete(true)
                            }}
                        >
                            {t(`settings.actions.delete_assign_team`)}
                        </p>
                    </StyledContainerDelete>
                </div>
            )}

            <StyledContainerFormActions className="row d-flex">
                <div className="col-4">
                    <Button
                        size="large"
                        color={'neutral'}
                        isUppercase
                        type={'button'}
                        onClick={() => closeModal(isDirty)}
                    >
                        {t('commons:button.cancel')}
                    </Button>
                </div>
                <div className="col-8">
                    <Button
                        type="submit"
                        size="large"
                        isUppercase
                        disabled={(!isDirty && isEdit) || isSubmitting}
                    >
                        {isEdit
                            ? t('commons:button.save')
                            : t(`settings.actions.confirm_button`)}
                    </Button>
                </div>
            </StyledContainerFormActions>
        </StyledForm>
    )
}

export default AssignTeamForm

AssignTeamForm.propTypes = {
    onClose: PropTypes.func,
    isEdit: PropTypes.bool,
    isLoading: PropTypes.bool,
    openDialogToConfirm: PropTypes.bool,
    setOpenDialogToConfirm: PropTypes.func,
    closeModal: PropTypes.func,
    openForm: PropTypes.object,
    data: PropTypes.object,
    teamsOptions: PropTypes.array,
    setOpenDialogToDelete: PropTypes.func,
}
