import i18next from 'i18next'
import { Link } from 'react-router-dom'

import { ActionsIcon, EditIcon, TrashIcon } from '@/assets/icons/icons.jsx'
import Chip from '@/components/chip/Chip.jsx'
import Dropdown from '@/components/dropdown/Dropdown.jsx'
import {
    COMMON_DATE_FORMAT,
    GENERAL_STATUSES,
    LABELS_TABLES,
    MODAL_TYPE,
    PROJECT_STATUS,
} from '@/utilities/constants/index.js'
import {
    getHumanReadableDate,
    truncateText,
    formatCurrency,
} from '@/utilities/helpers.js'
/* eslint-disable */

export const COLUMNS_STANDARD_PROJECTS_NAMES = {
    id: 'id',
    name: 'name',
    customer: 'customer',
    team_lead: 'team_lead',
    status: 'status',
    start_date: 'start_date',
    end_date: 'end_date',
    budget: 'budget',
    actions: 'actions',
}
export const columnsStandardProjects = () => {
    return [
        {
            alignment: 'left',
            label: i18next.t('general.id'),
            name: COLUMNS_STANDARD_PROJECTS_NAMES.id,
            width: '5%',
            sortable: false,
            divider: false,
        },
        {
            alignment: 'left',
            label: i18next.t('general.project'),
            name: COLUMNS_STANDARD_PROJECTS_NAMES.name,
            sortable: false,
            width: '25%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('projects.tables.projects.customer'),
            name: COLUMNS_STANDARD_PROJECTS_NAMES.customer,
            sortable: false,
            width: '16%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.team'),
            name: COLUMNS_STANDARD_PROJECTS_NAMES.team_lead,
            sortable: false,
            width: '16%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.status'),
            name: COLUMNS_STANDARD_PROJECTS_NAMES.status,
            sortable: false,
            width: '6%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('customer.tables.projects.start_date'),
            name: COLUMNS_STANDARD_PROJECTS_NAMES.start_date,
            sortable: true,
            width: '10%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('customer.tables.projects.end_date'),
            name: COLUMNS_STANDARD_PROJECTS_NAMES.end_date,
            sortable: true,
            width: '10%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('customer.tables.projects.budget'),
            name: COLUMNS_STANDARD_PROJECTS_NAMES.budget,
            sortable: false,
            width: '10%',
            divider: true,
        },
        {
            alignment: 'space-evenly',
            label: ' ',
            name: COLUMNS_STANDARD_PROJECTS_NAMES.actions,
            sortable: false,
            width: '2%',
            divider: true,
        },
    ]
}

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersStandardProjects = (
    openDialogDelete,
    openEditSidebar,
    goToDetails,
    goToCustomer
) => ({
    [COLUMNS_STANDARD_PROJECTS_NAMES.id]: data => {
        return <p>#{data?.global_project_id}</p>
    },
    [COLUMNS_STANDARD_PROJECTS_NAMES.name]: ({
        name,
        id,
        global_project_id,
    }) => (
        <Link onClick={() => goToDetails(id, global_project_id)}>{name}</Link>
    ),
    [COLUMNS_STANDARD_PROJECTS_NAMES.customer]: ({ customer }) => (
        <Link onClick={() => goToCustomer(customer?.id)}>
            {truncateText(customer?.name, 25)}
        </Link>
    ),
    [COLUMNS_STANDARD_PROJECTS_NAMES.team_lead]: ({ team }) => (
        <p>{truncateText(team?.name, 20)}</p>
    ),
    [COLUMNS_STANDARD_PROJECTS_NAMES.status]: ({ is_active }) => {
        return (
            <Chip
                text={is_active ? PROJECT_STATUS.open : PROJECT_STATUS.closed}
                size={'medium'}
                variant={is_active ? GENERAL_STATUSES.success : ''}
            />
        )
    },
    [COLUMNS_STANDARD_PROJECTS_NAMES.start_date]: ({ start_date }) => {
        return <p>{getHumanReadableDate(start_date, COMMON_DATE_FORMAT)}</p>
    },
    [COLUMNS_STANDARD_PROJECTS_NAMES.end_date]: ({ end_date }) => (
        <p>{getHumanReadableDate(end_date, COMMON_DATE_FORMAT)}</p>
    ),
    [COLUMNS_STANDARD_PROJECTS_NAMES.budget]: ({ markup_budget }) => {
        return <p>{formatCurrency(markup_budget)}</p>
    },
    [COLUMNS_STANDARD_PROJECTS_NAMES.actions]: ({ id }) => (
        <Dropdown
            trigger={<ActionsIcon />}
            items={[
                {
                    label: i18next.t('projects.tables.projects.edit'),
                    onSelect: () => {
                        openEditSidebar(
                            id,
                            MODAL_TYPE.edit,
                            LABELS_TABLES.projects
                        )
                    },
                    icon: <EditIcon />,
                    isToDelete: false,
                },
                {
                    label: i18next.t('projects.tables.projects.delete'),
                    onSelect: () => {
                        openDialogDelete(id, LABELS_TABLES.projects)
                    },
                    icon: <TrashIcon />,
                    isToDelete: true,
                },
            ]}
        />
    ),
})
