// used also in i18n
export const MODAL_TYPE = {
    create: 'create',
    edit: 'edit',
    delete: 'delete',
}

export const TABLE_ALIGNMENTS = {
    left: 'left',
    center: 'center',
    right: 'right',
    spaceEvenly: 'space-evenly',
}

export const TYPE_USERS = {
    members: 'members',
    teams: 'teams',
    contract: 'contract',
    assignTeam: 'assignTeam',
}
export const TABLE_HEIGHT = 'calc(100vh - 300px)'

export const TABLE_HEIGHT_WITH_WIDGET = 'calc(100vh - 500px)'

export const COMMON_DATE_FORMAT = 'DD/MM/YYYY'

export const PROJECT_STATUS = {
    open: 'Aperto',
    closed: 'Chiuso',
}

export const BILLING_STATUS = {
    paid: 'Pagata',
    expected: 'Prevista',
    issued: 'Emessa',
    cancelled: 'Annullata',
}

export const GENERAL_STATUSES = {
    success: 'success',
    warning: 'warning',
    danger: 'danger',
}

export const LABELS_TABLES = {
    projects: 'Progetti',
    project: 'Progetto',
    final_balances: 'Consuntivi',
    final_balance: 'Consuntivato',
    standard: 'Standard',
    rd: 'R&D',
    billing: 'Fatturazione',
    costs: 'Costi',
    activities: 'Attività',
    member: 'Membri',
    contracts: 'Contratti',
    history: 'Storico',
    team: 'Team',
    service: 'Servizio',
    payroll: 'Payroll',
    active_schedule: 'Scadenziario attivo',
    passive_schedule: 'Scadenziario passivo',
    financial_import: 'Import Finanziario',
    customer: 'Cliente',
    standardProject: 'standard',
    balanceProject: 'final-balance',
    redProject: 'internal',
    final_invoice: 'final_invoice',
}

export const STAGE_STATUS = {
    completed: 'completed',
    not_completed: 'not_completed',
    new: 'new',
}

export const EDIT_CELL_TYPE = {
    text: 'text',
    currency: 'currency',
    date: 'date',
    select: 'select',
    delivery_button: 'delivery_button',
}

export const PROJECT_TYPE = {
    standard: 'standard',
    final_balance: 'final-balance',
    internal: 'internal',
}
