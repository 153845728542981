import PropTypes from 'prop-types'

import CustomerBillingFormWrapper from '@/pages/customers/detail/customer-form/billing-form/CustomerBillingFormWrapper.jsx'
import CustomerCostFormWrapper from '@/pages/customers/detail/customer-form/costs-form/CustomerCostFormWrapper.jsx'
import FinalInvoiceFormWrapper from '@/pages/customers/detail/customer-form/final-invoice-form/FinalInvoiceFormWrapper.jsx'
import CustomersModalWrapper from '@/pages/customers/list/customers-modal/CustomersModalWrapper.jsx'
import CostsFormWrapper from '@/pages/projects/detail/detail-standard-projects/project-form/costs-form/CostsFormWrapper.jsx'
import FinalBalanceWrapper from '@/pages/projects/list/projects-form/final-balance-form/FinalBalanceWrapper.jsx'
import RDWrapper from '@/pages/projects/list/projects-form/r&d-form/R&DWrapper.jsx'
import StandardProjectWrapper from '@/pages/projects/list/projects-form/standard-projects-form/StandardProjectWrapper.jsx'
import { LABELS_TABLES, PROJECT_TYPE } from '@/utilities/constants/index.js'

const CustomerDetailsForm = ({
    onClose,
    isEdit,
    dataForm,
    setOpenDialogToConfirm,
    suppliers,
    openDialogDelete,
    customers,
    teams,
    successManagers,
}) => {
    const closeModal = isDirty => {
        if (isDirty) {
            setOpenDialogToConfirm(true)
        } else {
            onClose()
        }
    }
    const props = {
        onClose,
        isEdit,
        dataForm,
        closeModal,
        suppliers,
        openDialogDelete,
        customers,
        teams,
        successManagers,
    }

    switch (dataForm.formType) {
        case LABELS_TABLES.billing:
            return <CustomerBillingFormWrapper {...props} />
        case LABELS_TABLES.costs:
            switch (dataForm?.data?.type) {
                case 'project':
                    return <CostsFormWrapper {...props} isCustomer={true} />
                default:
                    return <CustomerCostFormWrapper {...props} />
            }
        case LABELS_TABLES.projects:
            switch (dataForm.data.type) {
                case PROJECT_TYPE.standard:
                    return <StandardProjectWrapper {...props} />
                case PROJECT_TYPE.final_balance:
                    return <FinalBalanceWrapper {...props} />
                default:
                    return <RDWrapper {...props} />
            }
        case LABELS_TABLES.customer:
            return (
                <CustomersModalWrapper
                    {...props}
                    userSelected={dataForm?.data}
                />
            )
        case LABELS_TABLES.final_invoice:
            return <FinalInvoiceFormWrapper {...props} />
    }
}

export default CustomerDetailsForm

CustomerDetailsForm.propTypes = {
    selectedTab: PropTypes.string,
    onClose: PropTypes.func,
    isEdit: PropTypes.bool,
    dataForm: PropTypes.object,
    setOpenDialogToConfirm: PropTypes.func,
    suppliers: PropTypes.array,
    openDialogDelete: PropTypes.func,
    customers: PropTypes.array,
    teams: PropTypes.array,
    successManagers: PropTypes.array,
}
