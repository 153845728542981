import * as yup from 'yup'

import i18next from '../../../../../i18n.js'

const BILLING_MODEL = {
    date: 'date',
    status: 'status',
    taxable: 'amount',
    associate_cost: 'expense_id',
    note: 'description',
}

const DEFAULT_BILLING_MOCK = {
    [BILLING_MODEL.date]: null,
    [BILLING_MODEL.status]: null,
    [BILLING_MODEL.taxable]: null,
    [BILLING_MODEL.associate_cost]: null,
    [BILLING_MODEL.note]: '',
}

const RequestValidationSchema = yup.object().shape({
    [BILLING_MODEL.date]: yup
        .date()
        .required(i18next.t('validation:general_form.date')),
    [BILLING_MODEL.status]: yup
        .object()
        .required(i18next.t('validation:general_form.status')),
    [BILLING_MODEL.associate_cost]: yup.object().nullable(),
    [BILLING_MODEL.taxable]: yup
        .string()
        .required(i18next.t('validation:general_form.taxable')),
    [BILLING_MODEL.note]: yup
        .string()
        .required(i18next.t('validation:general_form.note_required')),
})

export {
    BILLING_MODEL,
    DEFAULT_BILLING_MOCK,
    RequestValidationSchema as validationSchema,
}
