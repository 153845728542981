import { useEffect, useState } from 'react'

import GoogleIdentityService from '@/services/social-login/google-identity-service'

const useGoogleIdentity = ({ onSuccessCallback, onErrorCallback }) => {
    const [client, setClient] = useState(null)

    const loadClient = async () => {
        try {
            const googleClient = await GoogleIdentityService.initialize({
                onSuccessCallback,
                onErrorCallback,
            })
            setClient(googleClient)
        } catch (err) {
            console.warn(err?.message)
        }
    }

    useEffect(() => {
        loadClient()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return client
}

export default useGoogleIdentity
