import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
    ActionsIcon,
    PlusTableIcon,
    SellIcon,
    WorkedIcon,
} from '@/assets/icons/icons.jsx'
import { StyledCalendarUserContainer } from '@/components/calendar-day/styles.js'
import Dropdown from '@/components/dropdown/Dropdown.jsx'
import useError from '@/hooks/useError.jsx'
import { useConfirmAllocationsMutation } from '@/services/projects/finalBalanceApiSlice.js'
import { handleApiError } from '@/utilities/api/helpers.js'

const UserDataCell = ({ user, setDataForm, finalBalance }) => {
    const { t } = useTranslation()
    const showError = useError()

    const status = user.confirmed_hours >= user.allocated_hours

    const [confirmAllocation] = useConfirmAllocationsMutation()

    const allocationsToSend = Object.values(user?.allocations)
        .map(allocation => {
            return Object.entries(allocation).map(([userKey, item]) => {
                return {
                    date: userKey,
                    hours: item?.allocated_hours,
                }
            })
        })
        .flat()
        .filter(item => item)

    const handleConfirm = async () => {
        if (user.allocated_hours === 0) return
        try {
            await confirmAllocation({
                projectID: finalBalance?.id,
                data: [
                    {
                        user_id: user.id,
                        allocations: allocationsToSend,
                    },
                ],
            })
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        }
    }

    return (
        <StyledCalendarUserContainer
            status={status}
            tabIndex={'-1'}
            key={user.id}
        >
            <div className="name_container">
                <p className="name_">{user.fullName}</p>
                <Dropdown
                    trigger={<ActionsIcon />}
                    items={[
                        {
                            label: t('logs.table.edit_sell'),
                            onSelect: () => {
                                handleConfirm(user.id, 'sell')
                            },
                            icon: <SellIcon />,
                            isToDelete: false,
                            disabled: user.allocated_hours === 0,
                        },
                        {
                            label: t('logs.table.confirm_worked'),
                            onSelect: () => {
                                handleConfirm(user.id, 'worked')
                            },
                            icon: <WorkedIcon />,
                            isToDelete: false,
                            disabled: user.allocated_hours === 0,
                        },
                        {
                            label: t('logs.table.edit_action'),
                            onSelect: () => {
                                setDataForm({
                                    isOpen: true,
                                    id: user.id,
                                    isEdit: true,
                                    typeForm: 'allocation',
                                    data: user,
                                })
                            },
                            icon: <PlusTableIcon />,
                            isToDelete: false,
                        },
                    ]}
                />
            </div>

            <div className="time_container">
                <span className="status_"></span>
                <p>
                    {user.confirmed_hours} di {user.allocated_hours}h
                </p>
            </div>
        </StyledCalendarUserContainer>
    )
}

export default UserDataCell

UserDataCell.propTypes = {
    user: PropTypes.object,
    setDataForm: PropTypes.func,
    finalBalance: PropTypes.object,
}
