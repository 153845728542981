import PropTypes, { shape } from 'prop-types'
import { useNavigate } from 'react-router-dom'

import Divider from '@/components/divider/Divider.jsx'
import { TABLE_ALIGNMENTS } from '@/utilities/constants'

import {
    StyledSubRow,
    StyledTCell,
    StyledTRow,
    StyledExpandedRow,
    StyledTCellText,
} from './style'

const Row = ({
    item,
    itemIndex,
    columns,
    columnsRenderers,
    expandedRowsIndexes,
    alternatingRowColors,
    clickableRowPath,
    selectedRow,
}) => {
    const navigate = useNavigate()

    const onClickRow = () => {
        if (clickableRowPath) {
            navigate(clickableRowPath)
        }
    }

    return (
        <StyledTRow
            alternatingRowColors={alternatingRowColors}
            clickableRowPath={clickableRowPath}
            onClick={clickableRowPath ? onClickRow : null}
            selectedRow={selectedRow === item?.id}
        >
            {columns.map(column => {
                return (
                    <>
                        <StyledTCell
                            width={column.width}
                            alignment={column.alignment}
                            key={column.id}
                            haveIcon={!!column.icon}
                            havePadding={!!column.havePadding}
                        >
                            {column.divider && <Divider />}
                            {column.icon && column.icon(item, itemIndex)}
                            <StyledTCellText
                                allowOverflow={column.allowOverflow}
                            >
                                {columnsRenderers[column.name]
                                    ? columnsRenderers[column.name](
                                          item,
                                          itemIndex
                                      )
                                    : column.name}
                            </StyledTCellText>
                        </StyledTCell>
                    </>
                )
            })}
            {!!columnsRenderers?.expandedRow &&
                expandedRowsIndexes?.includes(itemIndex) && (
                    <StyledExpandedRow>
                        {columnsRenderers?.expandedRow(item, itemIndex)}
                    </StyledExpandedRow>
                )}
            {!!columnsRenderers?.subRow && (
                <StyledSubRow>
                    {columnsRenderers?.subRow(item, itemIndex)}
                </StyledSubRow>
            )}
        </StyledTRow>
    )
}

export default Row

Row.propTypes = {
    item: PropTypes.object.isRequired,
    itemIndex: PropTypes.number.isRequired,
    columns: PropTypes.arrayOf(
        shape({
            alignment: PropTypes.oneOf(Object.values(TABLE_ALIGNMENTS)),
            allowOverflow: PropTypes.bool,
            icon: PropTypes.node,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            mobileFullLength: PropTypes.bool,
            name: PropTypes.string.isRequired,
            sortable: PropTypes.bool,
            width: ({ width }, propName, componentName) => {
                if (!/^\d+%$/.test(width)) {
                    return new Error(
                        `Invalid prop '${propName}' supplied to ${componentName} (${width}). The prop must be a positive percentage number.`
                    )
                }
            },
            divider: PropTypes.bool,
            havePadding: PropTypes.bool,
        })
    ).isRequired,
    columnsRenderers: PropTypes.object.isRequired,
    expandedRowsIndexes: PropTypes.arrayOf(PropTypes.number),
    alternatingRowColors: PropTypes.bool,
    clickableRowPath: PropTypes.string,
    selectedRow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
