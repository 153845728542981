import { Link } from 'react-router-dom'

import { StyledBudget } from '@/pages/dashboard/styles.js'

import i18next from '../../../../i18n.js'

/* eslint-disable */

export const COLUMNS_DASHBOARD_FIRST = {
    project: 'project',
    budget_to_plan: 'budget_to_plan',
}
export const columnsFirst = [
    {
        alignment: 'left',
        label: i18next.t('general.project'),
        name: COLUMNS_DASHBOARD_FIRST.project,
        width: '70%',
        sortable: false,
        divider: false,
    },
    {
        alignment: 'left',
        label: i18next.t('dashboard.table.budget_to_plan'),
        name: COLUMNS_DASHBOARD_FIRST.budget_to_plan,
        width: '30%',
        sortable: false,
        divider: true,
    },
]

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersFirst = () => ({
    [COLUMNS_DASHBOARD_FIRST.project]: ({ id, project }) => (
        <Link>{`#${id} - ${project}`}</Link>
    ),
    [COLUMNS_DASHBOARD_FIRST.budget_to_plan]: ({ budget_to_plan }) => (
        <StyledBudget>{`€ ${budget_to_plan}`}</StyledBudget>
    ),
})
