// Public
export const ROUTE_LOGIN = '/login'
// Authorized

export const ROUTE_DASHBOARD = '/'
export const ROUTE_FINANCE = '/finance'

//settings
export const ROUTE_SETTINGS = '/settings'
export const ROUTE_SETTINGS_TEAMS = 'teams'
export const ROUTE_SETTINGS_MEMBERS = 'members'

// projects
export const ROUTE_PROJECTS = '/projects'
export const ROUTE_STANDARD_PROJECTS = 'standard-projects'
export const ROUTE_FINAL_BALANCE = 'final-balance'

export const ROUTE_RD = 'rd'

/*projects details*/
export const ROUTE_PROJECT_DETAIL = '/standard-projects/:id'
export const ROUTE_RD_DETAIL = '/rd/:id'

// customers
export const ROUTE_CUSTOMERS = '/customers'
export const ROUTE_CUSTOMER_DETAIL = '/customers/:id'
export const ROUTE_LOG_DETAIL_NO_ID = '/logs'
export const ROUTE_LOG_DETAIL = '/logs/:id'
