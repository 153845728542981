import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useTheme } from 'styled-components'

import {
    ArrowSelectIcon,
    CloseSelectIcon,
    RemoveIconMulti,
} from '@/assets/icons/icons'
import ErrorMessage from '@/components/error-message/ErrorMessage.jsx'

import { StyledRoot, generalStyles, StyledLabel } from './styles'

const CreateSelect = ({
    className,
    control,
    helpText,
    size = 'large',
    errors,
    onChangeCallback,
    name,
    label,
    options = [],
    placeholder,
    customComponents,
    required,
    hideSelectedOptions,
    isClearable,
    disabled,
    isLoading,
    onCreateOption,
    isMulti,
    haveDropdownIcon = true,
    ...rest
}) => {
    const theme = useTheme()

    const components = {
        MultiValueRemove,
        DropdownIndicator: haveDropdownIcon ? DropdownIndicator : null,
        ClearIndicator,
        ...customComponents,
    }

    return (
        <StyledRoot className={className}>
            {!!label && (
                <StyledLabel isDisabled={rest.readOnly || disabled}>
                    {label}
                    {required && '*'}
                </StyledLabel>
            )}

            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                        <CreatableSelect
                            components={components}
                            name={name}
                            label={label}
                            options={options}
                            placeholder={placeholder}
                            control={control}
                            errors={errors}
                            hideSelectedOptions={hideSelectedOptions}
                            isClearable={isClearable}
                            isDisabled={disabled}
                            isLoading={isLoading}
                            onChange={onChange || onChangeCallback}
                            onBlur={onBlur}
                            ref={ref}
                            onCreateOption={onCreateOption}
                            isMulti={isMulti}
                            value={value}
                            styles={generalStyles(
                                theme,
                                size,
                                errors,
                                'react-select'
                            )}
                        />
                    )
                }}
            />
            <ErrorMessage errors={errors} helpText={helpText} />
        </StyledRoot>
    )
}

const MultiValueRemove = props => {
    return (
        <components.MultiValueRemove {...props}>
            <RemoveIconMulti />
        </components.MultiValueRemove>
    )
}

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowSelectIcon />
        </components.DropdownIndicator>
    )
}

const ClearIndicator = props => {
    return (
        <components.ClearIndicator {...props}>
            <CloseSelectIcon />
        </components.ClearIndicator>
    )
}

export default CreateSelect

CreateSelect.propTypes = {
    className: PropTypes.string,
    control: PropTypes.object,
    name: PropTypes.string,
    options: PropTypes.array,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    helpText: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    isSearchable: PropTypes.bool,
    isClearable: PropTypes.bool,
    isCreatable: PropTypes.bool,
    addOptionMessage: PropTypes.bool,
    maxItems: PropTypes.number,
    isMulti: PropTypes.bool,
    onChangeCallback: PropTypes.func,
    closeMenuOnSelect: PropTypes.bool,
    required: PropTypes.bool,
    customComponents: PropTypes.object,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['medium', 'large']),
    onChange: PropTypes.func,
    isControlled: PropTypes.bool,
    errors: PropTypes.object,
    onMenuScrollToBottom: PropTypes.func,
    menuPlacement: PropTypes.func,
    menuPosition: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    isOptionDisabled: PropTypes.func,
    hideSelectedOptions: PropTypes.bool,
    isLoading: PropTypes.bool,
    onCreateOption: PropTypes.func,
    value: PropTypes.any,
    haveDropdownIcon: PropTypes.bool,
}
