import i18next from 'i18next'

import { ActionsIcon, EditIcon, TrashIcon } from '@/assets/icons/icons.jsx'
import Chip from '@/components/chip/Chip.jsx'
import Dropdown from '@/components/dropdown/Dropdown.jsx'
import StageStatus from '@/components/stage-status/StageStatus.jsx'
import {
    COMMON_DATE_FORMAT,
    GENERAL_STATUSES,
    INVOICE_STATUS,
    LABELS_TABLES,
} from '@/utilities/constants/index.js'
import { INVOICE_STATUS_OPTIONS } from '@/utilities/constants/list.js'
import {
    getHumanReadableDate,
    formatCurrency,
    translateOptions,
    truncateText,
} from '@/utilities/helpers.js'

/* eslint-disable */

export const COLUMNS_BILLING_NAMES = {
    id: 'id',
    success_manager: 'success_manager',
    date: 'date',
    taxable: 'taxable',
    status: 'status',
    notes: 'notes',
    stage: 'stage',
    actions: 'actions',
}
export const columnsBilling = () => {
    return [
        {
            alignment: 'left',
            label: i18next.t('general.id'),
            name: COLUMNS_BILLING_NAMES.id,
            width: '3%',
            sortable: false,
            divider: false,
        },
        {
            alignment: 'left',
            label: i18next.t('general.success_manager'),
            name: COLUMNS_BILLING_NAMES.success_manager,
            width: '15%',
            sortable: false,
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('projects.tables.activities.date'),
            name: COLUMNS_BILLING_NAMES.date,
            sortable: true,
            width: '10%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('projects.tables.activities.taxable'),
            name: COLUMNS_BILLING_NAMES.taxable,
            sortable: false,
            width: '15%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.status'),
            name: COLUMNS_BILLING_NAMES.status,
            sortable: false,
            width: '6%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('projects.tables.activities.notes'),
            name: COLUMNS_BILLING_NAMES.notes,
            sortable: false,
            width: '30%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('projects.tables.activities.stage'),
            name: COLUMNS_BILLING_NAMES.stage,
            sortable: false,
            width: '19%',
            divider: true,
        },
        {
            alignment: 'space-evenly',
            label: ' ',
            name: COLUMNS_BILLING_NAMES.actions,
            sortable: false,
            width: '2%',
            divider: true,
        },
    ]
}

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersBilling = (openDialogDelete, openEditSidebar) => ({
    [COLUMNS_BILLING_NAMES.id]: ({ id }) => <p>#{id}</p>,
    [COLUMNS_BILLING_NAMES.success_manager]: ({ success_manager }) => {
        return <p>{success_manager?.full_name}</p>
    },
    [COLUMNS_BILLING_NAMES.date]: ({ date }) => {
        return <p>{getHumanReadableDate(date, COMMON_DATE_FORMAT)}</p>
    },
    [COLUMNS_BILLING_NAMES.taxable]: ({ amount }) => (
        <p>{formatCurrency(amount)}</p>
    ),
    [COLUMNS_BILLING_NAMES.status]: ({ status }) => {
        const statusToRender = () => {
            switch (status) {
                case INVOICE_STATUS.paid:
                case INVOICE_STATUS.utt:
                    return GENERAL_STATUSES.success
                case INVOICE_STATUS.issued:
                    return GENERAL_STATUSES.warning
                default:
                    return
            }
        }

        const textToRender = () => {
            const enumStatus = translateOptions(INVOICE_STATUS_OPTIONS)
            return enumStatus.find(item => item.value === status)?.label
        }
        return (
            <Chip
                text={textToRender()}
                size={'medium'}
                variant={statusToRender()}
                fullWidth={false}
            />
        )
    },
    [COLUMNS_BILLING_NAMES.notes]: ({ description }) => {
        return <p>{description}</p>
    },
    [COLUMNS_BILLING_NAMES.stage]: ({ sal }) => {
        return (
            <>
                {sal ? (
                    <StageStatus
                        status={
                            sal?.is_completed === false
                                ? GENERAL_STATUSES.danger
                                : GENERAL_STATUSES.success
                        }
                        text={truncateText(
                            `#${sal?.global_sal_id} - ${sal?.name}`,
                            15
                        )}
                    />
                ) : (
                    '-'
                )}
            </>
        )
    },
    [COLUMNS_BILLING_NAMES.actions]: ({ id }) => (
        <Dropdown
            trigger={<ActionsIcon />}
            items={[
                //TODO: add when have 'fatture in cloud' implemented

                /* {
                     label: i18next.t('customer.actions.view_invoice'),
                     onSelect: () => {},
                     icon: <OpenNewTabIcon />,
                     isToDelete: false,
                 },*/
                {
                    label: i18next.t('customer.actions.edit_invoice'),
                    onSelect: () => {
                        openEditSidebar(id, LABELS_TABLES.billing)
                    },
                    icon: <EditIcon />,
                    isToDelete: false,
                },
                {
                    label: i18next.t('customer.actions.delete_invoice'),
                    onSelect: () => {
                        openDialogDelete(id, LABELS_TABLES.billing)
                    },
                    icon: <TrashIcon />,
                    isToDelete: true,
                },
            ]}
        />
    ),
})
