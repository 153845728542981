import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { EditIcon } from '@/assets/icons/icons'
import { dateFormat } from '@/utilities/helpers'

import { StyledTabDate, StyledSectionBlockTitle } from '../styles'

export const SectionBlock = ({
    startDate,
    endDate,
    title,
    isActive,
    onEdit,
    id,
    editDisabled,
}) => {
    const { t } = useTranslation()
    const theme = useTheme()

    return (
        <>
            {id && <StyledSectionBlockTitle>{id}</StyledSectionBlockTitle>}
            <div className="d-flex justify-content-between mb-2">
                <StyledSectionBlockTitle>{title}</StyledSectionBlockTitle>
                {isActive && (
                    <EditIcon
                        style={{ cursor: 'pointer' }}
                        onClick={editDisabled ? null : onEdit}
                        width={12}
                        height={12}
                        fill={
                            editDisabled
                                ? theme.palette.neutral.base[100]
                                : theme.palette.neutral.base[1000]
                        }
                    />
                )}
            </div>
            <div className="d-flex justify-content-between">
                <StyledTabDate>
                    {`${t(
                        'settings.sidebar_left.labels.start_date'
                    )} ${dateFormat(startDate)}`}
                </StyledTabDate>
                <StyledTabDate>
                    {`${t(
                        'settings.sidebar_left.labels.end_date'
                    )} ${dateFormat(endDate)}`}
                </StyledTabDate>
            </div>
        </>
    )
}

export default SectionBlock

SectionBlock.propTypes = {
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string,
    title: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    onEdit: PropTypes.func,
    id: PropTypes.any,
    editDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}
