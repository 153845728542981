import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { PlusIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button'
import DialogDelete from '@/components/dialog-delete/DialogDelete.jsx'
import Table from '@/components/table/index.jsx'
import useError from '@/hooks/useError.jsx'
import { StyledContainerButtonAddActivity } from '@/pages/projects/detail/detail-r&d/r&d-tables/activity-table/styles.js'
import {
    useAddActivityMutation,
    useDeleteActivityMutation,
    useGetSalActivitiesQuery,
    useGlobalProjectIDQuery,
} from '@/services/projects/projectsApiSlice.js'
import { handleApiError } from '@/utilities/api/helpers.js'
import { tableHeight } from '@/utilities/helpers'

import { columnsActivityChild } from './columnsActivityChild'
import { columnsRenderersActivityChild } from './columnsActivityChild'

const ExpandedTable = ({ openDialogDelete, salID, salData }) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const showError = useError()

    const { id: globalSalID } = useParams()
    //take id from global sal id
    const { data: data } = useGlobalProjectIDQuery({ globalSalID: globalSalID })
    const id = data?.id

    const [isLoading, setIsLoading] = useState(false)
    const [openDeleteActivity, setOpenDeleteActivity] = useState({
        open: false,
        id: null,
    })

    const [addActivity] = useAddActivityMutation()
    const [deleteActivity] = useDeleteActivityMutation()
    const { data: activities } = useGetSalActivitiesQuery({
        ID: id,
        salID: salID,
    })

    const handleAddActivity = async () => {
        setIsLoading(true)
        try {
            await addActivity({
                ID: id,
                salID: salID,
                sortDirection: 'asc',
            }).unwrap()
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        } finally {
            setIsLoading(false)
        }
    }

    const openDialogDeleteActivity = value => {
        setOpenDeleteActivity({
            open: true,
            id: value,
        })
    }
    const handleDelete = async () => {
        try {
            await deleteActivity({
                ID: id,
                salID: salID,
                activityID: openDeleteActivity.id,
            }).unwrap()
            setOpenDeleteActivity({
                open: false,
                id: null,
            })
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        } finally {
            setIsLoading(false)
        }
    }

    const activitiesPercentages =
        activities
            ?.map(activity => {
                return activity.percentage
            })
            .reduce((a, b) => a + b, 0) || 0

    const budgetActivities =
        activities
            ?.map(activity => {
                return activity.budget
            })
            .reduce((a, b) => a + b, 0) || 0

    const hoursActivities =
        activities
            ?.map(activity => {
                return activity.hours
            })
            .reduce((a, b) => a + b, 0) || 0

    const isCompleted = activities?.every(activity => {
        return activity.is_completed
    })

    const percentage = +(100 - activitiesPercentages).toFixed(2)
    const percentageSal = activities?.length > 0 ? percentage : 0

    const deliverySal = useMemo(() => {
        return {
            id: 'deliverySal',
            name: t('activity.table.label_columns.delivery'),
            percentage: percentageSal <= 30 ? percentageSal : 30,
            budget: +(salData?.budget - budgetActivities).toFixed(2),
            hours: +(salData?.hours - hoursActivities).toFixed(2),
            is_completed: !(isCompleted && percentageSal <= 30),
        }
    }, [
        budgetActivities,
        hoursActivities,
        isCompleted,
        percentageSal,
        salData,
        t,
    ])

    const activitiesData = useMemo(() => {
        if (salData?.is_completed) {
            return activities && Array.isArray(activities)
                ? [...activities]
                : []
        } else {
            return activities?.length > 0 && Array.isArray(activities)
                ? [...activities, deliverySal]
                : []
        }
    }, [activities, deliverySal, salData])

    return (
        <>
            <DialogDelete
                open={openDeleteActivity.open}
                onClose={() =>
                    setOpenDeleteActivity({
                        open: false,
                        id: null,
                    })
                }
                onDelete={handleDelete}
            />
            <Table
                columns={columnsActivityChild()}
                columnsRenderers={columnsRenderersActivityChild(
                    openDialogDelete,
                    +id,
                    salID,
                    openDialogDeleteActivity
                )}
                data={activitiesData}
                isLoading={isLoading}
                isPaginated
                bodyheight={tableHeight(activitiesData)}
            />
            {!salData?.is_completed && (
                <StyledContainerButtonAddActivity>
                    <div />
                    <Button
                        type={'button'}
                        size={'small'}
                        variant={'borderless'}
                        className={'add_button'}
                        onClick={handleAddActivity}
                    >
                        <PlusIcon fill={theme.palette.primary.base.active} />
                        {t('project.add_activity')}
                    </Button>
                </StyledContainerButtonAddActivity>
            )}
        </>
    )
}

export default ExpandedTable

ExpandedTable.propTypes = {
    openDialogDelete: PropTypes.func,
    salID: PropTypes.number,
    salData: PropTypes.object,
}
