import PropTypes from 'prop-types'

import { StyledContainerTitle } from '@/pages/dashboard/styles.js'

export const DashboardTablesTitle = ({ buttonSelected }) => {
    return (
        <>
            <StyledContainerTitle>
                <p>{buttonSelected?.label}</p>

                <h1>{buttonSelected?.length}</h1>
            </StyledContainerTitle>
        </>
    )
}

export default DashboardTablesTitle

DashboardTablesTitle.propTypes = {
    buttonSelected: PropTypes.object,
}
