import { Link } from 'react-router-dom'

import { StyledBudget } from '@/pages/dashboard/styles.js'

import i18next from '../../../../i18n.js'

/* eslint-disable */

export const COLUMNS_DASHBOARD_SIXTH = {
    project: 'project',
    total: 'total',
}
export const columnsSixth = [
    {
        alignment: 'left',
        label: i18next.t('general.project'),
        name: COLUMNS_DASHBOARD_SIXTH.project,
        width: '80%',
        sortable: false,
        divider: false,
    },
    {
        alignment: 'left',
        label: i18next.t('dashboard.table.total'),
        name: COLUMNS_DASHBOARD_SIXTH.total,
        width: '20%',
        sortable: false,
        divider: true,
    },
]

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersSixth = () => ({
    [COLUMNS_DASHBOARD_SIXTH.project]: ({ id, project }) => (
        <Link>{`#${id} - ${project}`}</Link>
    ),
    [COLUMNS_DASHBOARD_SIXTH.total]: ({ total }) => (
        <StyledBudget>{`${total}`}</StyledBudget>
    ),
})
