import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from '@/components/button/Button.jsx'
import Dialog from '@/components/dialog/Dialog.jsx'
import { StyledActionButtonDialog } from '@/components/dialog-safe-close/styles.js'

const DialogSafeClose = ({ goBack, open, onClose }) => {
    const { t } = useTranslation()

    return (
        <Dialog
            open={open}
            title={t('commons:dialog.title')}
            actionsDialog={
                <StyledActionButtonDialog>
                    <Button size={'medium'} color={'neutral'} onClick={goBack}>
                        {t('commons:dialog.safeClose.actions.cancel')}
                    </Button>
                    <Button size={'medium'} color={'primary'} onClick={onClose}>
                        {t('commons:dialog.safeClose.actions.confirm')}
                    </Button>
                </StyledActionButtonDialog>
            }
        >
            <p>
                {t('commons:dialog.safeClose.message.0')} <br />
                {t('commons:dialog.safeClose.message.1')}
            </p>
        </Dialog>
    )
}

export default DialogSafeClose

DialogSafeClose.propTypes = {
    goBack: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}
