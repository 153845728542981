import PropTypes from 'prop-types'
import { useState } from 'react'

import { ArrowDownIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'

import SorterModal from './sorter-modal'

const Header = ({ columns, sorter, sortColumnCb }) => {
    const orderOptions = columns
        .filter(e => e.sortable)
        .map(e => ({ label: e.label, value: e.name }))
    const [showSortModal, setShowSortModal] = useState(false)
    const currentSortedColumn = columns.find(
        column => column.name === sorter?.order_by
    )

    const getSortIcon = () => {
        if (currentSortedColumn) {
            return sorter?.order_direction === 'asc' ? (
                <>
                    {/* <ArrowUpIcon
                    onClick={e => {
                        e.stopPropagation()
                        sortColumnCb(null, null)
                    }}
                />*/}
                </>
            ) : sorter?.order_direction === 'desc' ? (
                <ArrowDownIcon
                    onClick={e => {
                        e.stopPropagation()
                        sortColumnCb(sorter?.order_by, 'asc')
                    }}
                />
            ) : (
                <>
                    {/*<ChevronDoubleIcon
                        onClick={e => {
                            e.stopPropagation()
                            sortColumnCb(sorter?.order_by, 'desc')
                        }}
                    />*/}
                </>
            )
        } else {
            return <></>
        }
    }

    return (
        <>
            <Button
                text={`Order by: ${currentSortedColumn?.label ?? '-'}`}
                icon={getSortIcon()}
                onClick={() => {
                    setShowSortModal(true)
                }}
            />
            {showSortModal && (
                <SorterModal
                    onClose={() => {
                        setShowSortModal(false)
                    }}
                    title="commons:order_by"
                    options={orderOptions}
                    sortColumnCb={sortColumnCb}
                />
            )}
        </>
    )
}

export default Header

Header.propTypes = {
    columns: PropTypes.array,
    sorter: PropTypes.object,
    sortColumnCb: PropTypes.func,
}
