import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import { OpenIcon, CloseIcon } from '@/assets/icons/icons'

import {
    StyledSidebarRoot,
    StyledSidebarContainer,
    StyledSidebarChildrenContaier,
    StyledToogleIcon,
} from './styles'

const Sidebar = ({ children, openIcon, onClose, open }) => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (open) {
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }
    }, [open])

    return (
        <StyledSidebarRoot>
            <StyledSidebarContainer isOpen={isOpen}>
                <StyledSidebarChildrenContaier>
                    {children}
                </StyledSidebarChildrenContaier>
            </StyledSidebarContainer>
            <StyledToogleIcon openIcon={openIcon} isOpen={isOpen}>
                {isOpen ? (
                    <CloseIcon
                        onClick={() => {
                            onClose && onClose()
                            setIsOpen(false)
                        }}
                    />
                ) : (
                    <OpenIcon onClick={() => setIsOpen(true)} />
                )}
            </StyledToogleIcon>
        </StyledSidebarRoot>
    )
}
export default Sidebar
Sidebar.propTypes = {
    children: PropTypes.element,
    openIcon: PropTypes.bool,
    open: PropTypes.bool,
    onClose: PropTypes.any,
}
