import PropTypes from 'prop-types'

import CustomersModalWrapper from '@/pages/customers/list/customers-modal/CustomersModalWrapper.jsx'
import BillingFormWrapper from '@/pages/projects/detail/detail-standard-projects/project-form/billing-form/BillingFormWrapper.jsx'
import CostsFormWrapper from '@/pages/projects/detail/detail-standard-projects/project-form/costs-form/CostsFormWrapper.jsx'
import StandardProjectWrapper from '@/pages/projects/list/projects-form/standard-projects-form/StandardProjectWrapper.jsx'
import { LABELS_TABLES } from '@/utilities/constants/index.js'

const ProjectDetailsForm = ({
    dataForm,
    onClose,
    setOpenDialogToConfirm,
    openDialogDelete,
    isEdit,
    applicants,
    customers,
    teams,
    successManagers,
    responsibilities,
    sals,
    suppliers,
}) => {
    const closeModal = isDirty => {
        if (isDirty) {
            setOpenDialogToConfirm(true)
        } else {
            onClose()
        }
    }

    const successManagerOptions = successManagers?.filter(
        successManager => successManager.data?.is_success_manager
    )
    const props = {
        dataForm,
        onClose,
        closeModal,
        openDialogDelete,
        isEdit,
        applicants,
        customers,
        teams,
        sals,
        successManagers: successManagerOptions,
        responsibilities,
        suppliers,
    }

    switch (dataForm?.typeForm) {
        case LABELS_TABLES.billing:
            return <BillingFormWrapper {...props} />
        case LABELS_TABLES.costs:
            return <CostsFormWrapper {...props} />
        case LABELS_TABLES.project:
            return <StandardProjectWrapper canNotDelete={true} {...props} />
        case LABELS_TABLES.customer:
            return (
                <CustomersModalWrapper
                    userSelected={dataForm?.data}
                    canNotDelete={true}
                    {...props}
                />
            )
        default:
            return null
    }
}

export default ProjectDetailsForm

ProjectDetailsForm.propTypes = {
    selectedTab: PropTypes.string,
    dataForm: PropTypes.object,
    onClose: PropTypes.func,
    setOpenDialogToConfirm: PropTypes.func,
    openDialogDelete: PropTypes.func,
    isEdit: PropTypes.bool,
    applicants: PropTypes.array,
    customers: PropTypes.array,
    teams: PropTypes.array,
    successManagers: PropTypes.array,
    responsibilities: PropTypes.array,
    sals: PropTypes.array,
    suppliers: PropTypes.array,
}
