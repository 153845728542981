/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTheme } from 'styled-components'

import Select from '@/components/select/Select'
import { generalStyles } from '@/components/select/styles.js'
import { useGetMembersQuery } from '@/services/settings/settingApiSlice'

export const PaginatedSelect = ({
    required,
    name,
    label,
    placeholder,
    control,
    size,
    errors,
    onChange,
    isSearchable,
    isOptionDisabled,
    isClearable,
    isTeamLead,
}) => {
    const [currentPage, setCurrentPage] = useState(1)
    const [searchValue, setSearchValue] = useState(undefined)

    const { data: fetchedMembers, isFetching } = useGetMembersQuery({
        currentPage: currentPage,
        search: searchValue,
        sortDirection: 'asc',
    })

    const data =
        fetchedMembers?.data?.map(member => ({
            label: `#${member.id}-${member.full_name}`,
            value: member.id,
            data: {
                ...member,
            },
        })) ?? []

    const dataTeamLead =
        fetchedMembers?.data
            ?.filter(i => i?.is_team_lead)
            .map(member => ({
                label: `#${member.id}-${member.full_name}`,
                value: member.id,
                data: {
                    ...member,
                },
            })) ?? []

    const theme = useTheme()

    const handleNextPage = () => {
        if (!isFetching) {
            if (
                fetchedMembers?.pagination?.nextPage !== null &&
                currentPage !== fetchedMembers?.pagination?.lastPage &&
                fetchedMembers.length > 0
            ) {
                setCurrentPage(currentPage + 1)
            }
        }
    }

    return (
        <Select
            isClearable={isClearable}
            size={size}
            required={required}
            name={name}
            label={label}
            placeholder={placeholder}
            control={control ? control : undefined}
            isControlled={!!control}
            options={!isTeamLead ? data : dataTeamLead}
            errors={errors}
            isSearchable={isSearchable}
            styles={generalStyles(theme, size, errors, '')}
            onMenuScrollToBottom={handleNextPage}
            onInputChange={e => setSearchValue(e)}
            isOptionDisabled={isOptionDisabled}
            onChange={onChange}
        />
    )
}

PaginatedSelect.propTypes = {
    isClearable: PropTypes.bool,
    isOptionDisabled: PropTypes.func,
    isTeamLead: PropTypes.bool,
    control: PropTypes.object,
    name: PropTypes.string,
    options: PropTypes.array,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    isMulti: PropTypes.bool,
    required: PropTypes.bool,
    customComponents: PropTypes.object,
    disabled: PropTypes.bool,
    isControlled: PropTypes.bool,
    isSearchable: PropTypes.bool,
    errors: PropTypes.object,
    onChange: PropTypes.func,
    size: PropTypes.oneOf(['medium', 'large']),
}
