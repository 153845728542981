import { API_RESPONSE_TYPES } from '@/services/axiosInstance.js'

import { apiSlice } from '../apiSlice.js'

export const customersApiSlice = apiSlice.injectEndpoints({
    addTagTypes: [
        'Customers',
        'CustomerID',
        'costCustomer',
        'Invoices',
        'projectsCustomer',
    ],
    endpoints: builder => ({
        getCustomersList: builder.query({
            query: (payload = {}) => {
                const { responseType, ...params } = payload
                return {
                    url: `/customers`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['Customers'],
        }),

        addCustomer: builder.mutation({
            query: (payload = {}) => {
                const { data, responseType, ...params } = payload
                return {
                    url: `/customers`,
                    data,
                    method: 'post',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Customers'],
        }),

        getCustomerById: builder.query({
            query: (payload = {}) => {
                const { ID, responseType, ...params } = payload
                return {
                    url: `/customers/${ID}`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['CustomerID'],
        }),

        getCustomerByIdProjects: builder.query({
            query: (payload = {}) => {
                const { ID, responseType, ...params } = payload
                return {
                    url: `/customers/${ID}/projects`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['projectsCustomer'],
        }),

        updateCustomer: builder.mutation({
            query: (payload = {}) => {
                const { ID, data, responseType, ...params } = payload
                return {
                    url: `/customers/${ID}`,
                    data,
                    method: 'patch',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Customers', 'CustomerID'],
        }),

        deleteCustomer: builder.mutation({
            query: (payload = {}) => {
                const { ID, responseType, ...params } = payload
                return {
                    url: `/customers/${ID}`,
                    method: 'delete',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Customers'],
        }),

        //costs
        createCustomerCost: builder.mutation({
            query: (payload = {}) => {
                const { customerID, data, responseType, ...params } = payload
                return {
                    url: `/customers/${customerID}/expenses`,
                    data,
                    method: 'post',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['costCustomer'],
        }),

        getCustomerCosts: builder.query({
            query: (payload = {}) => {
                const { customerID, responseType, ...params } = payload
                return {
                    url: `/customers/${customerID}/expenses`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['costCustomer'],
        }),

        getCustomerCostByID: builder.query({
            query: (payload = {}) => {
                const { customerID, expenseID, responseType, ...params } =
                    payload
                return {
                    url: `/customers/${customerID}/expenses/${expenseID}`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
        }),

        updateCustomerCost: builder.mutation({
            query: (payload = {}) => {
                const { customerID, expenseID, data, responseType, ...params } =
                    payload
                return {
                    url: `/customers/${customerID}/expenses/${expenseID}`,
                    data,
                    method: 'patch',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['costCustomer'],
        }),

        deleteCustomerCost: builder.mutation({
            query: (payload = {}) => {
                const { customerID, expenseID, responseType, ...params } =
                    payload
                return {
                    url: `/customers/${customerID}/expenses/${expenseID}`,
                    method: 'delete',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['costCustomer'],
        }),

        //INVOICES
        createInvoiceCustomers: builder.mutation({
            query: (payload = {}) => {
                const { customerID, data, ...params } = payload
                return {
                    url: `/customers/${customerID}/invoices`,
                    method: 'post',
                    data,
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Invoices'],
        }),

        getInvoicesCustomers: builder.query({
            query: (payload = {}) => {
                const { customerID, ...params } = payload
                return {
                    url: `/customers/${customerID}/invoices`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['Invoices'],
        }),

        getInvoiceByIDCustomers: builder.query({
            query: (payload = {}) => {
                const { customerID, invoiceID, ...params } = payload
                return {
                    url: `/customers/${customerID}/invoices/${invoiceID}`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['ActiveSchedule'],
        }),

        updateInvoiceCustomers: builder.mutation({
            query: (payload = {}) => {
                const { customerID, invoiceID, data, ...params } = payload
                return {
                    url: `/customers/${customerID}/invoices/${invoiceID}`,
                    method: 'patch',
                    data,
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Invoices', 'ActiveSchedule'],
        }),

        deleteInvoiceCustomers: builder.mutation({
            query: (payload = {}) => {
                const { customerID, invoiceID, ...params } = payload
                return {
                    url: `/customers/${customerID}/invoices/${invoiceID}`,
                    method: 'delete',
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Invoices', 'ActiveSchedule'],
        }),

        getFinalBalanceCustomer: builder.query({
            query: (payload = {}) => {
                const { ...params } = payload
                return {
                    url: `final-balances/invoices-summary`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['InvoicesSummary'],
        }),
    }),
})

export const {
    useGetCustomersListQuery,
    useAddCustomerMutation,
    useGetCustomerByIdQuery,
    useUpdateCustomerMutation,
    useDeleteCustomerMutation,

    useGetCustomerByIdProjectsQuery,

    useCreateCustomerCostMutation,
    useGetCustomerCostsQuery,
    useGetCustomerCostByIDQuery,
    useUpdateCustomerCostMutation,
    useDeleteCustomerCostMutation,

    useCreateInvoiceCustomersMutation,
    useGetInvoicesCustomersQuery,
    useGetInvoiceByIDCustomersQuery,
    useUpdateInvoiceCustomersMutation,
    useDeleteInvoiceCustomersMutation,

    useGetFinalBalanceCustomerQuery,
} = customersApiSlice
