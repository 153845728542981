import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { StyledTabItemContainer } from '../styles'

const SectionItem = ({ label, value, width, link }) => {
    const linkType = value => {
        const emailRegex = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i
        if (emailRegex.test(value)) {
            return <a href={`mailto:${value}`}>{value}</a>
        } else {
            return (
                <Link to={value} target="_blank">
                    {value}
                </Link>
            )
        }
    }

    return (
        <StyledTabItemContainer width={width}>
            <p className="label_">{label}</p>
            {link ? (
                value ? (
                    linkType(value)
                ) : (
                    '-'
                )
            ) : (
                <p className="value_">{value || '-'}</p>
            )}
        </StyledTabItemContainer>
    )
}

export default SectionItem

SectionItem.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    width: PropTypes.number,
    link: PropTypes.bool,
}
