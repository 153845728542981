import styled, { css } from 'styled-components'

import { StyledTHead } from '@/components/table/desktop/header/style'
import { StyledTRow } from '@/components/table/desktop/row/style'

export const StyledExpandedContainer = styled.div`
    ${({ theme }) => css`
        width: 100%;
        display: flex;
        flex-flow: column;
        ${StyledTHead} {
            padding: 0px;
            background: ${theme.palette.neutral.base[0]};
        }
        ${StyledTRow} {
            padding: 0px;
            border-bottom: none;
        }
    `}
`
