import dayjs from 'dayjs'
import i18next from 'i18next'

export const getTHAlignment = alignment => {
    switch (alignment) {
        case 'center':
            return 'center'
        case 'right':
            return 'flex-end'
        case 'space-evenly':
            return 'space-evenly'
        default:
            return 'flex-start'
    }
}

export const truncateText = (text, length = 50) => {
    if (text?.length > length) {
        return text?.substring(0, length) + '...'
    } else {
        return text
    }
}

export const formatCurrency = (rate, hideDecimal = false, currency = '€') => {
    if (!rate) return '-'

    const formatter = new Intl.NumberFormat('it', {
        minimumFractionDigits: hideDecimal ? 0 : 2,
    })

    let formattedNumber = formatter.format(rate)

    return currency + ' ' + formattedNumber
}
export const capitalize = ([firstLetter, ...rest]) =>
    firstLetter?.toUpperCase() + rest.join('')

export const getInitials = name => {
    return name.match(/\b\w/g) || []
}
export const getHumanReadableDate = (date, format) => {
    if (format) return dayjs(date).format(format)
    const secondsDiff = dayjs().diff(dayjs(date), 's')
    switch (true) {
        case secondsDiff > 29030400:
            return dayjs(date).format('MM/DD/YYYY')
        case secondsDiff > 2419200: {
            const months = dayjs().diff(dayjs(date), 'M')
            return `${months} ${months > 1 ? `months` : `month`} ago`
        }
        case secondsDiff > 604800: {
            const weeks = dayjs().diff(dayjs(date), 'w')
            return `${weeks} ${weeks > 1 ? `weeks` : `week`} ago`
        }
        case secondsDiff > 86400:
            return `${dayjs().diff(dayjs(date), 'd')}d`
        case secondsDiff > 3600:
            return `${dayjs().diff(dayjs(date), 'h')}h`
        case secondsDiff > 60:
            return `${dayjs().diff(dayjs(date), 'm')}m`
        default:
            return `${secondsDiff}s`
    }
}

export const iterateOverDirtyFields = (dirtyFields, formValues) => {
    let dataToSend = {}
    for (const key in dirtyFields) {
        dataToSend = {
            ...dataToSend,
            [key]: formValues?.[key],
        }
    }
    return dataToSend
}

/*date time helpers*/
export const formatDate = date =>
    date ? dayjs(date).format('YYYY-MM-DD') : date

export const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate()
}

export const getUniqueColor = (string, colorSet) => {
    const getNumericHash = string => {
        let hash = 0
        if (string?.length === 0) return hash
        for (let i = 0; i < string?.length; i++) {
            let char = string.charCodeAt(i)
            hash = (hash << 5) - hash + char
            hash = hash & hash // Convert to 32bit integer
        }
        return hash
    }
    const modulo = colorSet?.length
    const colorIndex = ((getNumericHash(string) % modulo) + modulo) % modulo
    return colorSet[colorIndex]
}
export const translateOptions = options => {
    return options.map(option => ({
        ...option,
        label: i18next.t(option.label),
    }))
}
export const dateFormat = (
    item,
    dateFormat = i18next.t('commons:dateFormatter')
) => {
    return item ? dayjs(item).format(dateFormat) : '-'
}

// translation helpers
export const translatedEnum = (data, type) => {
    return data ? i18next.t(`commons:${type}:${data}`) : '-'
}

export const filterEmptyKeys = obj => {
    const filteredObj = {}
    for (let key in obj) {
        if (obj[key] !== '' && obj[key] !== undefined && obj[key] !== null) {
            filteredObj[key] = obj[key]
        }
    }
    return filteredObj
}

export const retrieveSingleValueForRs = (options, value) => {
    if (
        value === null ||
        value === '' ||
        value === undefined ||
        options === null
    )
        return null
    return options?.find(
        option => option?.value?.toString() === value?.toString()
    )
}

export const legalAge = isMin => {
    if (isMin) {
        const yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        yesterday.setFullYear(yesterday.getFullYear() - 16)
        return yesterday
    } else {
        return new Date(new Date().setFullYear(new Date().getFullYear() - 100))
    }
}

//SET THE HEIGHT OF THE TABLE
// 58px row height
// 338px activity height
export const tableHeight = (data, expandedRows) => {
    if (expandedRows?.length > 0) {
        return `${data?.length * 58 + expandedRows?.length * 338}px`
    } else {
        if (data?.length === 0) {
            return '58px'
        } else {
            return `${data?.length * 58}px`
        }
    }
}

//PAGINATION UPDATE ELEMENTS
//TODO FILTER LOGIC
export const mergeInfiniteQuery = (currentCache, newItems) => {
    if (newItems.pagination.currentPage > 1) {
        const newIds = newItems.data.map(newItem => newItem.id)

        const updatedData = currentCache.data.filter(
            item => !newIds.includes(item.id)
        )

        newItems.data.forEach(newItem => {
            const index = currentCache.data.findIndex(
                item => item.id === newItem.id
            )
            if (index !== -1) {
                currentCache.data[index] = newItem
            }
        })

        currentCache.data = [...updatedData, ...newItems.data]
    } else {
        currentCache.data = [...newItems.data]
    }
}

//SORT DIRECTION
export const handleSortFilter = (filter, setSortDirections) => {
    setSortDirections(prevSortDirections => ({
        ...prevSortDirections,
        [filter]: prevSortDirections[filter] === 'asc' ? 'desc' : 'asc',
    }))
}

export const debounce = (func, wait, immediate) => {
    let timeout
    return function () {
        let context = this,
            args = arguments
        let later = function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }
        let callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}
