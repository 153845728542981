import { Link } from 'react-router-dom'

import { StyledBudget } from '@/pages/dashboard/styles.js'

import i18next from '../../../../i18n.js'

/* eslint-disable */

export const COLUMNS_DASHBOARD_EIGHTH = {
    project: 'project',
    month: 'month',
    time_to_be_confirmed: 'time_to_be_confirmed',
}
export const columnsEighth = [
    {
        alignment: 'left',
        label: i18next.t('general.project'),
        name: COLUMNS_DASHBOARD_EIGHTH.project,
        width: '60%',
        sortable: false,
        divider: false,
    },
    {
        alignment: 'left',
        label: i18next.t('finance.table.month'),
        name: COLUMNS_DASHBOARD_EIGHTH.month,
        width: '20%',
        sortable: false,
        divider: true,
    },
    {
        alignment: 'left',
        label: i18next.t('dashboard.table.hours_to_confirm'),
        name: COLUMNS_DASHBOARD_EIGHTH.time_to_be_confirmed,
        width: '20%',
        sortable: false,
        divider: true,
    },
]

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersEighth = () => ({
    [COLUMNS_DASHBOARD_EIGHTH.project]: ({ id, project }) => (
        <p>{`#${id} - ${project}`}</p>
    ),
    [COLUMNS_DASHBOARD_EIGHTH.month]: ({ month }) => <Link>{`${month}`}</Link>,
    [COLUMNS_DASHBOARD_EIGHTH.time_to_be_confirmed]: ({
        time_to_be_confirmed,
    }) => <StyledBudget>{`${time_to_be_confirmed}`}</StyledBudget>,
})
