import { Link } from 'react-router-dom'

import { StyledBudget } from '@/pages/dashboard/styles.js'

import i18next from '../../../../i18n.js'

/* eslint-disable */

export const COLUMNS_DASHBOARD_SEVENTH = {
    project: 'project',
    sal: 'sal',
    total: 'total_percentage',
}
export const columnsSeventh = [
    {
        alignment: 'left',
        label: i18next.t('general.project'),
        name: COLUMNS_DASHBOARD_SEVENTH.project,
        width: '60%',
        sortable: false,
        divider: false,
    },
    {
        alignment: 'left',
        label: i18next.t('activity.table.label_columns.sal'),
        name: COLUMNS_DASHBOARD_SEVENTH.sal,
        width: '20%',
        sortable: false,
        divider: true,
    },
    {
        alignment: 'left',
        label: i18next.t('dashboard.table.total'),
        name: COLUMNS_DASHBOARD_SEVENTH.total,
        width: '20%',
        sortable: false,
        divider: true,
    },
]

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersSeventh = () => ({
    [COLUMNS_DASHBOARD_SEVENTH.project]: ({ id, project }) => (
        <p>{`#${id} - ${project}`}</p>
    ),
    [COLUMNS_DASHBOARD_SEVENTH.sal]: ({ sal }) => <Link>{`#${sal}`}</Link>,
    [COLUMNS_DASHBOARD_SEVENTH.total]: ({ total_percentage }) => (
        <StyledBudget>{`${total_percentage}%`}</StyledBudget>
    ),
})
