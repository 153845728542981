import * as yup from 'yup'

import i18next from '../../../../../i18n.js'

const ADD_MEMBERS_MODEL = {
    member: 'member',
}

const DEFAULT_ADD_MEMBERS_MOCK = {
    [ADD_MEMBERS_MODEL.member]: null,
}

const RequestValidationSchema = yup.object().shape({
    [ADD_MEMBERS_MODEL.member]: yup
        .object()
        .required(i18next.t('validation:general_form.member')),
})

export {
    ADD_MEMBERS_MODEL,
    DEFAULT_ADD_MEMBERS_MOCK,
    RequestValidationSchema as validationSchema,
}
