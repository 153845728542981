/* eslint-disable no-extra-boolean-cast */
import * as locales from 'date-fns/locale'
import PropTypes from 'prop-types'
import { forwardRef, useMemo } from 'react'
import { useController } from 'react-hook-form'

import { AlertIconBanner, DoneIcon, CalendarIcon } from '@/assets/icons/icons'
import ErrorMessage from '@/components/error-message/ErrorMessage.jsx'

// reference: https://reactdatepicker.com/

//ERP PICKER TYPE PROPS
// SINGLE DATE PROPS: name, control, showMonthDropdown, showYearDropdown, placeholder, pickerType={'days'}, label?
// RANGE DATES PROPS: SINGLE DATE, isRange
// SINGLE MONTH PROPS: name, control, showMonthYearPicker, placeholder, pickerType={'months'}, label?
// RANGE MONTHS PROPS: SINGLE MONTH, isRange

import {
    StyledIcon,
    StyledIcons,
    StyledInputWrapper,
    StyledLabel,
    StyledRoot,
    StyledStatusIcons,
    StyledDatePicker,
} from './styles'

const Datepicker = forwardRef(function InputCalendar(
    {
        control,
        defaultValue,
        label,
        name,
        placeholder,
        helpText,
        icon = <CalendarIcon />,
        iconPosition = 'right',
        rounded,
        iconCallback,
        statusIcon,
        required = false,
        statusIconCallback,
        touched = false,
        errors,
        // rootClassName,
        inputProps,
        disabled = false,
        isClearable = false,
        closeOnScroll = false,
        calendarPlacement = 'bottom',
        dateFormat = 'dd/MM/yyyy',
        showTimeSelect = false,
        showNativeInput,
        showTimeSelectOnly = false,
        isRange = false,
        includeDates,
        includeDateIntervals,
        includeTimes,
        excludeDates,
        excludeDateIntervals,
        excludeTimes,
        filterTime,
        minDate,
        maxDate,
        closeOnSelect = true,
        outOfDate = false,
        openToDate,
        locale = 'it',
        showYearPicker,
        showMonthYearPicker,
        showMonthDropdown,
        showYearDropdown,
        component,
        className,
        isSuccess,
        pickerType,
        ...rest
    },
    ref
) {
    const parsedLocale = useMemo(() => {
        let foundLocale = null

        for (const [key, value] of Object.entries(locales)) {
            if (!!foundLocale) {
                break
            } else {
                if (value.code === locale) {
                    foundLocale = locales[key]
                }
            }
        }

        return foundLocale || locales.enUS
    }, [locale])

    const {
        field: { onChange, value, ...fieldProps },
        formState,
    } = useController({
        name,
        control,
        defaultValue,
    })

    const onChangeHandler = value => {
        onChange(value)
        fieldProps.onBlur()
    }

    return (
        <StyledRoot
            pickerType={pickerType}
            className={className}
            outOfDate={outOfDate}
        >
            {!!label && (
                <StyledLabel htmlFor={name} disabled={disabled}>
                    {label}
                    {component}
                    {required && '*'}
                </StyledLabel>
            )}
            <StyledInputWrapper
                iconPosition={iconPosition}
                touched={touched}
                rounded={rounded}
                hasError={errors}
                isSuccess={isSuccess}
                disabled={disabled}
                isOnlyMonth={pickerType === 'months'}
            >
                <StyledIcons iconPosition={iconPosition}>
                    {icon && !errors && (
                        <StyledIcon
                            {...(iconCallback && { onClick: iconCallback })}
                            disabled={disabled}
                        >
                            {icon}
                        </StyledIcon>
                    )}
                </StyledIcons>

                {showNativeInput ? (
                    <input
                        name={name}
                        type={
                            showTimeSelectOnly
                                ? 'time'
                                : showTimeSelect
                                ? 'datetime-local'
                                : 'date'
                        }
                        placeholder={placeholder}
                        onChange={e => onChangeHandler(e.target.value)}
                        disabled={disabled}
                        ref={ref}
                        {...fieldProps}
                        {...formState}
                        {...inputProps}
                    />
                ) : (
                    <StyledDatePicker
                        onChange={onChangeHandler}
                        selected={isRange ? value?.[0] : value}
                        isClearable={isClearable}
                        placeholderText={placeholder}
                        closeOnScroll={closeOnScroll}
                        popperPlacement={calendarPlacement}
                        dateFormat={dateFormat}
                        showTimeSelect={showTimeSelect && !isRange}
                        showTimeSelectOnly={showTimeSelectOnly}
                        startDate={value?.[0]}
                        endDate={value?.[1]}
                        selectsRange={isRange}
                        includeDates={includeDates}
                        includeDateIntervals={includeDateIntervals}
                        includeTimes={includeTimes}
                        excludeDates={excludeDates}
                        excludeDateIntervals={excludeDateIntervals}
                        excludeTimes={excludeTimes}
                        filterTime={filterTime}
                        minDate={minDate}
                        maxDate={maxDate}
                        disabled={disabled}
                        shouldCloseOnSelect={closeOnSelect}
                        openToDate={openToDate}
                        locale={parsedLocale}
                        required={required}
                        showYearPicker={showYearPicker}
                        showMonthYearPicker={showMonthYearPicker}
                        showMonthDropdown={showMonthDropdown}
                        showYearDropdown={showYearDropdown}
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        formatWeekDay={e => e.substring(0, 2)}
                        ref={ref}
                        {...fieldProps}
                        {...formState}
                        {...inputProps}
                        {...rest}
                        autoComplete="off"
                    />
                )}
                <StyledStatusIcons>
                    {!!errors ? (
                        <StyledIcon errors={errors} touched={touched}>
                            <AlertIconBanner />
                        </StyledIcon>
                    ) : (
                        iconPosition === 'left' &&
                        touched && (
                            <StyledIcon errors={errors} touched={touched}>
                                <DoneIcon data-color />
                            </StyledIcon>
                        )
                    )}
                    {statusIcon && (
                        <StyledIcon
                            {...(statusIconCallback && {
                                onClick: statusIconCallback,
                            })}
                        >
                            {statusIcon}
                        </StyledIcon>
                    )}
                </StyledStatusIcons>
            </StyledInputWrapper>
            {/*help text*/}
            <ErrorMessage errors={errors} helpText={helpText} />
        </StyledRoot>
    )
})

export default Datepicker

Datepicker.propTypes = {
    control: PropTypes.any.isRequired,
    defaultValue: PropTypes.any, //required if isRange
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    helpText: PropTypes.string,
    onChangeCb: PropTypes.func,
    icon: PropTypes.element,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    rounded: PropTypes.bool,
    iconCallback: PropTypes.func,
    statusIcon: PropTypes.element,
    statusIconCallback: PropTypes.func,
    touched: PropTypes.bool,
    errors: PropTypes.object,
    rootClassName: PropTypes.string,
    inputProps: PropTypes.object,
    disabled: PropTypes.bool,
    isClearable: PropTypes.bool,
    closeOnScroll: PropTypes.bool,
    calendarPlacement: PropTypes.oneOf(['bottom', 'top']),
    dateFormat: PropTypes.string,
    showTimeSelect: PropTypes.bool,
    showTimeSelectOnly: PropTypes.bool,
    showNativeInput: PropTypes.bool,
    isRange: PropTypes.bool,
    includeDates: PropTypes.array,
    includeDateIntervals: PropTypes.array,
    includeTimes: PropTypes.array,
    excludeDates: PropTypes.array,
    excludeDateIntervals: PropTypes.array,
    excludeTimes: PropTypes.array,
    filterTime: PropTypes.bool,
    minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    closeOnSelect: PropTypes.bool,
    openToDate: PropTypes.string,
    locale: PropTypes.string,
    showYearPicker: PropTypes.bool,
    showMonthYearPicker: PropTypes.bool,
    showMonthDropdown: PropTypes.bool,
    showYearDropdown: PropTypes.bool,
    component: PropTypes.any,
    className: PropTypes.any,
    isSuccess: PropTypes.bool,
    outOfDate: PropTypes.bool,
    pickerType: PropTypes.oneOf(['days', 'months', 'years']),
    required: PropTypes.bool,
}
