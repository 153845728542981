import { createSlice } from '@reduxjs/toolkit'

const financialSlice = createSlice({
    name: 'financial',
    initialState: {
        date: new Date().toString(),
    },
    reducers: {
        setImportDate: (state, action) => {
            state.date = action.payload
        },
    },
})

export const { setImportDate } = financialSlice.actions

export const selectedDate = state => state?.financial?.date

export default financialSlice.reducer
