import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import CalendarDay from '@/components/calendar-day/CalendarDay'
import DialogDelete from '@/components/dialog-delete/DialogDelete.jsx'
import DialogSafeClose from '@/components/dialog-safe-close/DialogSafeClose.jsx'
import SubHeader from '@/components/subheader/SubHeader'
import useError from '@/hooks/useError.jsx'
import LogSidebar from '@/pages/customers/log/LogSidebar/LogSidebar.jsx'
import RenderLogForm from '@/pages/customers/log/RenderLogForm/RenderLogForm.jsx'
import FinalBalanceSubHeader from '@/pages/projects/detail/detail-final-balance/final-balance-sub-header/FinalBalanceSubHeader.jsx'
import axios from '@/services/axiosInstance.js'
import { useGetCustomersListQuery } from '@/services/customers/customersApiSlice.js'
import {
    useDeleteFinalBalanceByIDMutation,
    useDeleteMemberAllocatedMutation,
    useGetAllAllocationsQuery,
    useGetUsersAllocationsQuery,
} from '@/services/projects/finalBalanceApiSlice.js'
import { useGlobalProjectIDQuery } from '@/services/projects/projectsApiSlice.js'
import {
    useGetMembersQuery,
    useGetTeamsQuery,
} from '@/services/settings/settingApiSlice.js'
import { handleApiError, remapSelectOptions } from '@/utilities/api/helpers.js'

import { StyledContainerFluidSettings } from './styles.js'

const LogDetail = () => {
    const { month, year } = useParams()
    const { id: globalSalID } = useParams()
    const showError = useError()
    //take id from global sal id
    const { data: data } = useGlobalProjectIDQuery({ globalSalID: globalSalID })
    const id = +data?.id

    const [openDialogToConfirm, setOpenDialogToConfirm] = useState(false)
    const [dataForm, setDataForm] = useState({
        isOpen: false,
        typeForm: null,
        id: null,
        isEdit: false,
        data: null,
    })

    const [selectedMonthAndYear, setSelectedMonthAndYear] = useState({
        month: null,
        year: null,
        isFilter: false,
    })

    const [openDialogDelete, setOpenDialogDelete] = useState({
        isOpen: false,
        id: null,
    })

    const { data: members, refetch } = useGetMembersQuery()

    useEffect(() => {
        refetch()
    }, [])

    const { data: teams } = useGetTeamsQuery()
    const { data: customers } = useGetCustomersListQuery()
    const { data: userAllocated } = useGetUsersAllocationsQuery(
        { projectID: id },
        { skip: !id }
    )

    const { data: allocations } = useGetAllAllocationsQuery(
        {
            projectID: id,
            month: selectedMonthAndYear?.month,
            year: selectedMonthAndYear?.year,
        },
        {
            skip:
                !selectedMonthAndYear?.month ||
                !selectedMonthAndYear?.year ||
                !id,
        }
    )

    const [deleteMemberAllocated] = useDeleteMemberAllocatedMutation()
    const [removeAllocationID] = useDeleteFinalBalanceByIDMutation()

    const handleDelete = async () => {
        try {
            setOpenDialogDelete()
            if (openDialogDelete?.type === 'allocationDate') {
                await removeAllocationID({
                    projectID: data.id,
                    userID: dataForm?.data?.id,
                    allocationID:
                        openDialogDelete?.allocationData[openDialogDelete?.id]
                            .id,
                }).unwrap()
            } else {
                await deleteMemberAllocated({
                    projectID: id,
                    userID: openDialogDelete?.id,
                }).unwrap()
            }
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        }
    }

    const handleClick = async () => {
        try {
            await axios.get(`/floatSync/projects/${globalSalID}/sync`)
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        }
    }

    return (
        <>
            <LogSidebar finalBalance={data} setDataForm={setDataForm} />

            <StyledContainerFluidSettings className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-4">
                        <SubHeader title={data?.name}>
                            <FinalBalanceSubHeader
                                onClickHandler={setDataForm}
                                setMonthSelected={setSelectedMonthAndYear}
                                notHavePhase={
                                    data?.has_logged_time_without_phase
                                }
                                onClickToUpdate={handleClick}
                            />
                        </SubHeader>
                    </div>
                    <div className="col-12 mb-4">
                        <CalendarDay
                            month={+month}
                            year={+year}
                            data={allocations}
                            userAllocated={userAllocated}
                            setDataForm={setDataForm}
                            dataForm={dataForm}
                            setSelectedMonthAndYear={setSelectedMonthAndYear}
                            finalBalance={data}
                            selectedMonthAndYear={selectedMonthAndYear}
                        />
                    </div>
                </div>
            </StyledContainerFluidSettings>

            {dataForm?.isOpen && (
                <RenderLogForm
                    dataForm={dataForm}
                    setDataForm={setDataForm}
                    members={members}
                    onClose={() => setDataForm({})}
                    finalBalance={data}
                    allocations={allocations}
                    setOpenDialogToConfirm={setOpenDialogToConfirm}
                    customers={remapSelectOptions(customers?.data)}
                    teams={teams?.data}
                    openDialogDelete={setOpenDialogDelete}
                    userAllocated={userAllocated}
                />
            )}

            <DialogDelete
                open={openDialogDelete?.isOpen}
                onClose={() => setOpenDialogDelete({})}
                onDelete={handleDelete}
            />
            {openDialogToConfirm && (
                <DialogSafeClose
                    goBack={() => {
                        setDataForm({})
                        setOpenDialogToConfirm(false)
                    }}
                    onClose={() => setOpenDialogToConfirm(false)}
                    open={openDialogToConfirm}
                />
            )}
        </>
    )
}

export default LogDetail
