import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import DialogDelete from '@/components/dialog-delete/DialogDelete.jsx'
import DialogSafeClose from '@/components/dialog-safe-close/DialogSafeClose.jsx'
import SidePopover from '@/components/side-popover/SidePopover.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import {
    DEFAULT_TEAM_MOCK,
    validationSchema,
} from '@/pages/settings/settings-form/team-form/teams.schema.js'
import {
    useDeleteTeamMutation,
    useGetMembersQuery,
} from '@/services/settings/settingApiSlice.js'
import { setPanelId } from '@/services/settings/settingsSlice'
import { handleApiError } from '@/utilities/api/helpers.js'

import TeamForm from './TeamForm'

const TeamFormWrapper = ({
    onClose,
    isEdit,
    openDialogToConfirm,
    setOpenDialogToConfirm,
    closeModal,
    openForm,
    setTeams,
    data,
    teamID,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { triggerAlert } = useAlert()
    const showError = useError()

    const [openDialogToDelete, setOpenDialogToDelete] = useState(false)

    const { data: fetchedMembers } = useGetMembersQuery({})
    const [deleteTeam] = useDeleteTeamMutation()

    const deleteUser = async () => {
        try {
            await deleteTeam({ ID: teamID }).unwrap() // this invalidates the getMembers and redo http request
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: t('commons:dialog.success'),
            })
            dispatch(setPanelId(undefined))
            onClose()
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        } finally {
            setOpenDialogToDelete(false)
        }
    }

    const props = {
        onClose,
        isEdit,
        openDialogToConfirm,
        setOpenDialogToConfirm,
        closeModal,
        openForm,
        setTeams,
        teamLead: fetchedMembers?.data,
        data,
        setOpenDialogToDelete,
    }

    const methods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'onChange',
        nativeValidation: false,
        shouldFocusError: true,
        resolver: yupResolver(validationSchema),
        defaultValues: DEFAULT_TEAM_MOCK,
    })

    const {
        formState: { isDirty },
    } = methods

    return (
        <>
            <DialogDelete
                open={openDialogToDelete}
                onClose={() => setOpenDialogToDelete(false)}
                onDelete={deleteUser}
            />
            {openDialogToConfirm && (
                <DialogSafeClose
                    goBack={() => {
                        onClose()
                    }}
                    onClose={() => setOpenDialogToConfirm(false)}
                    open={openDialogToConfirm}
                />
            )}
            <FormProvider {...methods}>
                <SidePopover
                    onClose={() => closeModal(isDirty)}
                    isDirty={isDirty}
                    title={
                        isEdit
                            ? t('settings.actions.edit_team')
                            : t('settings.actions.create_team')
                    }
                >
                    <TeamForm {...props} />
                </SidePopover>
            </FormProvider>
        </>
    )
}

export default TeamFormWrapper

TeamFormWrapper.propTypes = {
    onClose: PropTypes.func,
    isEdit: PropTypes.bool,
    isLoading: PropTypes.bool,
    setIsLoading: PropTypes.func,
    openDialogToConfirm: PropTypes.bool,
    setOpenDialogToConfirm: PropTypes.func,
    closeModal: PropTypes.func,
    openForm: PropTypes.object,
    setTeams: PropTypes.func,
    teams: PropTypes.array,
    data: PropTypes.object,
    teamID: PropTypes.number,
    filter: PropTypes.object,
}
