import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'

import Table from '@/components/table/index.jsx'
import {
    columnsMembers,
    columnsRenderersMembers,
} from '@/pages/settings/tables-settings/columnsMembers.jsx'
import { useGetMembersQuery } from '@/services/settings/settingApiSlice.js'
import { selectPanelId } from '@/services/settings/settingsSlice'
import { tableHeight } from '@/utilities/helpers'

const TableMembers = () => {
    const { dataProps } = useOutletContext()
    const { openPanel, openDeleteDialog, setOpenForm } = dataProps

    const [currentPage, setCurrentPage] = useState(1)

    const { data: members, isFetching } = useGetMembersQuery({
        currentPage: currentPage,
        sortDirection: 'asc',
    })

    const panelId = useSelector(selectPanelId)

    const handleNextPage = () => {
        if (!isFetching) {
            if (
                members?.pagination?.nextPage !== null &&
                currentPage !== members?.pagination?.lastPage
            ) {
                setCurrentPage(currentPage + 1)
            }
        }
    }

    return (
        <Table
            columns={columnsMembers}
            columnsRenderers={columnsRenderersMembers(
                openPanel,
                openDeleteDialog,
                setOpenForm
            )}
            data={members?.data}
            isLoading={isFetching}
            isPaginated
            bodyheight={tableHeight(members?.data)}
            selectedRow={panelId}
            onChangePageCb={handleNextPage}
        />
    )
}

export default TableMembers

TableMembers.propTypes = {
    filter: PropTypes.object,
    openPanel: PropTypes.func,
    openDeleteDialog: PropTypes.func,
    setOpenForm: PropTypes.func,
}
