import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import DialogDelete from '@/components/dialog-delete/DialogDelete.jsx'
import DialogSafeClose from '@/components/dialog-safe-close/DialogSafeClose.jsx'
import SidePopover from '@/components/side-popover/SidePopover.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import {
    DEFAULT_MEMBER_MOCK,
    validationSchema,
} from '@/pages/settings/settings-form/member-form/members.schema'
import {
    useDeleteMemberMutation,
    useGetMemberByIdQuery,
    useGetResponsibilitiesQuery,
    useGetScopesQuery,
} from '@/services/settings/settingApiSlice.js'
import { setPanelId, selectPanelId } from '@/services/settings/settingsSlice'
import {
    handleApiError,
    remapAPIForResponsibilities,
    remapAPIForScopes,
} from '@/utilities/api/helpers.js'

import MemberForm from './MemberForm'

const MemberFormWrapper = ({
    onClose,
    isEdit,
    openForm,
    openDialogToConfirm,
    setOpenDialogToConfirm,
    closeModal,
    canNotDelete,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const panelId = useSelector(selectPanelId)

    const { triggerAlert } = useAlert()
    const showError = useError()
    const title = isEdit
        ? 'settings.actions.edit_member'
        : 'settings.actions.create_member'

    const [openDialogToDelete, setOpenDialogToDelete] = useState(false)

    const { data: scopes } = useGetScopesQuery()

    const { data: member } = useGetMemberByIdQuery(
        {
            ID: openForm.id,
        },
        { skip: !openForm.id }
    )
    const [deleteMember] = useDeleteMemberMutation()
    const { data: responsibilities } = useGetResponsibilitiesQuery()

    const deleteUser = async () => {
        try {
            await deleteMember({ ID: panelId }).unwrap() // this invalidates the getMembers and redo http request
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: t('commons:dialog.success'),
            })
            dispatch(setPanelId(undefined))
            onClose()
        } catch (error) {
            handleApiError({
                error,
                handleGeneralError: showError,
            })
        } finally {
            setOpenDialogToDelete(false)
        }
    }

    const props = {
        onClose,
        isEdit,
        data: member,
        openForm,
        closeModal,
        responsibilities: remapAPIForResponsibilities(responsibilities),
        setOpenDialogToDelete,
    }

    const methods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'onChange',
        nativeValidation: false,
        shouldFocusError: true,
        resolver: yupResolver(validationSchema),
        defaultValues: DEFAULT_MEMBER_MOCK,
    })

    const {
        formState: { isDirty },
    } = methods

    return (
        <>
            <DialogDelete
                open={openDialogToDelete}
                onClose={() => setOpenDialogToDelete(false)}
                onDelete={deleteUser}
            />
            {openDialogToConfirm && (
                <DialogSafeClose
                    goBack={() => {
                        onClose()
                    }}
                    onClose={() => setOpenDialogToConfirm(false)}
                    open={openDialogToConfirm}
                />
            )}
            <FormProvider {...methods}>
                <SidePopover
                    onClose={() => closeModal(isDirty)}
                    isDirty={isDirty}
                    title={t(title)}
                >
                    {scopes && (
                        <MemberForm
                            {...props}
                            scopes={remapAPIForScopes(scopes)}
                            canNotDelete={canNotDelete}
                        />
                    )}
                </SidePopover>
            </FormProvider>
        </>
    )
}

export default MemberFormWrapper

MemberFormWrapper.propTypes = {
    onClose: PropTypes.func,
    isEdit: PropTypes.bool,
    openForm: PropTypes.object,
    openDialogToConfirm: PropTypes.bool,
    setOpenDialogToConfirm: PropTypes.func,
    closeModal: PropTypes.func,
    filter: PropTypes.object,
    userID: PropTypes.number,
    canNotDelete: PropTypes.bool,
}
