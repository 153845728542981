import i18next from 'i18next'
import { Link } from 'react-router-dom'

import { ActionsIcon, EditIcon, TrashIcon } from '@/assets/icons/icons.jsx'
import Dropdown from '@/components/dropdown/Dropdown.jsx'
import { MODAL_TYPE } from '@/utilities/constants/index.js'

/* eslint-disable */

export const COLUMNS_CUSTOMERS_NAMES = {
    id: 'id',
    business_name: 'business_name',
    success_manager: 'success_manager',
    vat: 'vat',
    actions: 'actions',
}
export const columnsCustomers = () => {
    return [
        {
            alignment: 'left',
            label: i18next.t('general.id'),
            name: COLUMNS_CUSTOMERS_NAMES.id,
            width: '5%',
            sortable: false,
            divider: false,
        },
        {
            alignment: 'left',
            label: i18next.t('customer.tables.list.business_name'),
            name: COLUMNS_CUSTOMERS_NAMES.business_name,
            sortable: false,
            width: '40%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.success_manager'),
            name: COLUMNS_CUSTOMERS_NAMES.success_manager,
            sortable: false,
            width: '40%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.vat_number'),
            name: COLUMNS_CUSTOMERS_NAMES.vat,
            sortable: false,
            width: '13%',
            divider: true,
        },
        {
            alignment: 'space-evenly',
            label: ' ',
            name: COLUMNS_CUSTOMERS_NAMES.actions,
            sortable: false,
            width: '2%',
            divider: true,
        },
    ]
}

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersCustomers = (
    goToDetails,
    openDeleteDialog,
    openEditDialog,
    openSideBar
) => ({
    [COLUMNS_CUSTOMERS_NAMES.id]: ({ id }) => <p>#{id}</p>,
    [COLUMNS_CUSTOMERS_NAMES.business_name]: ({ name, id }) => (
        <Link onClick={() => goToDetails(id)}>{name}</Link>
    ),
    [COLUMNS_CUSTOMERS_NAMES.success_manager]: ({ success_manager }) => (
        <p>{success_manager?.full_name}</p>
    ),
    [COLUMNS_CUSTOMERS_NAMES.vat]: ({ vat }) => <p>{vat}</p>,
    [COLUMNS_CUSTOMERS_NAMES.actions]: ({ id }) => (
        <Dropdown
            trigger={<ActionsIcon />}
            items={[
                {
                    label: i18next.t('customer.actions.edit_customer'),
                    onSelect: () => {
                        openSideBar(id, MODAL_TYPE.edit)
                    },
                    icon: <EditIcon />,
                    isToDelete: false,
                },
                {
                    label: i18next.t('customer.actions.delete_customer'),
                    onSelect: () => {
                        openDeleteDialog(id)
                    },
                    icon: <TrashIcon />,
                    isToDelete: true,
                },
            ]}
        />
    ),
})
