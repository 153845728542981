import { Link } from 'react-router-dom'

import { StyledBudget } from '@/pages/dashboard/styles.js'

import i18next from '../../../../i18n.js'

/* eslint-disable */

export const COLUMNS_DASHBOARD_THIRD = {
    project: 'project',
    sal: 'sal',
    available_budget: 'available_budget',
    downloaded_budget: 'downloaded_budget',
}
export const columnsThird = [
    {
        alignment: 'left',
        label: i18next.t('general.project'),
        name: COLUMNS_DASHBOARD_THIRD.project,
        width: '30%',
        sortable: false,
        divider: false,
    },
    {
        alignment: 'left',
        label: i18next.t('activity.table.label_columns.sal'),
        name: COLUMNS_DASHBOARD_THIRD.sal,
        width: '30%',
        sortable: false,
        divider: true,
    },
    {
        alignment: 'left',
        label: i18next.t('dashboard.table.budget_available'),
        name: COLUMNS_DASHBOARD_THIRD.available_budget,
        width: '20%',
        sortable: false,
        divider: true,
    },
    {
        alignment: 'left',
        label: i18next.t('dashboard.table.budget_downloaded'),
        name: COLUMNS_DASHBOARD_THIRD.downloaded_budget,
        width: '20%',
        sortable: false,
        divider: true,
    },
]

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersThird = () => ({
    [COLUMNS_DASHBOARD_THIRD.project]: ({ id, project }) => (
        <p>{`#${id} - ${project}`}</p>
    ),
    [COLUMNS_DASHBOARD_THIRD.sal]: ({ sal }) => <Link>{`#${sal}`}</Link>,
    [COLUMNS_DASHBOARD_THIRD.available_budget]: ({ available_budget }) => (
        <p>{`€ ${available_budget}`}</p>
    ),
    [COLUMNS_DASHBOARD_THIRD.downloaded_budget]: ({ downloaded_budget }) => (
        <StyledBudget>{`€ ${downloaded_budget}`}</StyledBudget>
    ),
})
