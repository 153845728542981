import { css } from 'styled-components'

import Global from '@/theme/global.js'
import { shadows } from '@/theme/shadows.js'
import { typography } from '@/theme/typography.js'

import mediaQueries from './mediaQueries'
import { palette as mainPalette } from './palette'
import Reset from './reset'

const ResetStyles = Reset
const GlobalStyles = Global
export const spacing = 8

const getAppTheme = ({ name = 'light' } = {}) => {
    const palette = mainPalette[name]

    const zIndex = {
        modalOverlay: 19,
        modal: 20,
        snackbar: 40,
        popover: 60,
        toast: 80,
    }

    const headerHeight = 64

    const truncateText = css`
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    `

    return {
        spacing,
        headerHeight,
        zIndex,
        mediaQueries,
        palette,
        typography,
        shadows,
        truncateText,
    }
}

export { ResetStyles, GlobalStyles, getAppTheme }
