import styled, { css } from 'styled-components'

export const StyledRoot = styled.div`
    ${({ variantStyle, theme, visible, position }) => css`
        position: absolute;
        pointer-events: auto;
        max-width: 314px;
        min-width: 314px;
        z-index: ${theme.zIndex.toast};
        padding: ${theme.spacing / 4}px ${theme.spacing * 2}px;
        color: ${theme.palette[variantStyle.status].base.text};
        background-color: ${theme.palette[variantStyle.status].base.background};
        border: 1px solid ${theme.palette[variantStyle.status].base.active};
        transition: transform 0.2s ease-in-out;
        will-change: transform;

        ${position === 'top-left' &&
        css`
            top: ${theme.spacing}px;
            left: ${theme.spacing}px;
        `}
        ${position === 'top-right' &&
        css`
            top: ${theme.spacing * 8}px;
            right: 0;
        `}
        ${position === 'bottom-left' &&
        css`
            bottom: ${theme.spacing}px;
            left: ${theme.spacing}px;
        `}
        ${position === 'bottom-right' &&
        css`
            bottom: ${theme.spacing}px;
            right: ${theme.spacing}px;
        `}


        ${visible &&
        css`
            transform: translateX(0);
        `}
        ${!visible &&
        css`
            display: none;
            ${position === 'top-left' &&
            css`
                transform: translateX(-200%);
            `}
            ${position === 'top-right' &&
            css`
                transform: translateX(200%);
            `}
            ${position === 'bottom-left' &&
            css`
                transform: translateX(-200%);
            `}
            ${position === 'bottom-right' &&
            css`
                transform: translateX(200%);
            `}
        `}
    `}
`

export const StyledContainer = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        gap: ${theme.spacing}px;
    `}
`

export const StyledContent = styled.div`
    ${({ theme }) => css`
        ${theme.typography.paragraph.p12.bold};
        grid-column-start: 2;
        display: flex;
        flex-direction: column;
        margin-right: ${theme.spacing * 2}px;
        justify-content: center;
    `}
`

export const StyledMessage = styled.span`
    ${({ theme }) => css`
        word-break: break-word;
        ${theme.typography.paragraph.p12.bold};
        cursor: default;
    `}
`

export const StyledIcon = styled.span`
    display: flex;
`

export const StyledCloseIconWrapper = styled.span`
    grid-column-start: 3;
    cursor: pointer;
    display: flex;
    & svg {
        & path {
            fill: ${({ theme, variantStyle }) =>
                theme.palette[variantStyle.status].base.text};
        }
    }
`
