import styled, { css } from 'styled-components'

export const StyledContainerFluidSettings = styled.div`
    ${({ theme }) => css`
        padding: ${theme.spacing * 4}px ${theme.spacing * 3}px;
        overflow-x: auto;
    `}
`

export const StyledContainerLogsFilter = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: 93px 250px 150px;
        grid-gap: ${theme.spacing}px;
        grid-template-rows: 1fr;
        color: ${theme.palette.primary[0]};
        & input {
            height: 28px;
        }
    `}
`
