import PropTypes from 'prop-types'
import { useController } from 'react-hook-form'
import CreatableSelect from 'react-select'
import ReactSelect from 'react-select'
import { useTheme } from 'styled-components'

import { generalStyles } from '@/components/select/styles.js'

const SelectField = ({
    className,
    control,
    name,
    options = [],
    placeholder,
    defaultValue,
    isSearchable = false,
    isClearable = false,
    isCreatable = false,
    maxItems = 100,
    isMulti,
    onChangeCallback,
    closeMenuOnSelect = !isMulti,
    disabled,
    errors,
    size = 'large',
    components,
    createLabel,
    ...rest
}) => {
    const theme = useTheme()

    const {
        field: { onChange, ...fieldProps },
        fieldState: { error, isTouched },
        formState,
    } = useController({
        name,
        control,
        defaultValue,
    })
    const onChangeHandler = value => {
        if (typeof onChangeCallback === 'function') onChangeCallback(value)
        if (!isMulti || (isMulti && value.length <= maxItems)) onChange(value) // Limit multi select
    }

    const selectProps = {
        ...rest,
        options,
        closeMenuOnSelect,
        isSearchable,
        isClearable,
        isMulti,
        isDisabled: rest.readOnly || disabled,
        classNamePrefix: isCreatable ? 'creatable_select' : 'select',
        placeholder,
        error,
        isTouched,
        disabled,
        className,
        ...rest,
    }

    return (
        <>
            {isCreatable ? (
                <CreatableSelect
                    formatCreateLabel={createLabel}
                    onChange={onChangeHandler}
                    components={components}
                    {...formState} // from Controller
                    {...fieldProps} // from Controller
                    {...selectProps} // from Component
                    styles={generalStyles(theme, size, errors, '')}
                />
            ) : (
                <ReactSelect
                    onChange={onChangeHandler}
                    components={components}
                    {...formState}
                    {...fieldProps}
                    {...selectProps}
                    styles={generalStyles(theme, size, errors, 'react-select')}
                />
            )}
        </>
    )
}

export default SelectField

SelectField.propTypes = {
    className: PropTypes.string,
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
    ]),
    isSearchable: PropTypes.bool,
    isClearable: PropTypes.bool,
    isCreatable: PropTypes.bool,
    addOptionMessage: PropTypes.string,
    maxItems: PropTypes.number,
    isMulti: PropTypes.bool,
    onChangeCallback: PropTypes.func,
    closeMenuOnSelect: PropTypes.bool,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    components: PropTypes.object,
    createLabel: PropTypes.func,
    errors: PropTypes.object,
}
