import PropTypes from 'prop-types'

import Button from '@/components/button/Button.jsx'

import { StyledErrorMessage } from './style'

const ErrorMessage = ({ onRefresh }) => {
    return (
        <StyledErrorMessage>
            <h4>Something went wrong. Please try again later.</h4>
            {onRefresh && <Button text={'Refresh'} onClick={onRefresh} />}
        </StyledErrorMessage>
    )
}

export default ErrorMessage

ErrorMessage.propTypes = {
    onRefresh: PropTypes.func,
}
