import i18next from 'i18next'

import {
    ActionsIcon,
    EditIcon,
    ServiceIcon,
    TrashIcon,
    CostsProjectIcon,
} from '@/assets/icons/icons.jsx'
import Chip from '@/components/chip/Chip.jsx'
import Dropdown from '@/components/dropdown/Dropdown.jsx'
import StageStatus from '@/components/stage-status/StageStatus.jsx'
import Tooltip from '@/components/tooltip/Tooltip.jsx'
import { StyledContainerCell } from '@/pages/customers/detail/styles.js'
import {
    COSTS_TYPE,
    INVOICE_STATUS,
    LABELS_TABLES,
} from '@/utilities/constants'
import {
    COMMON_DATE_FORMAT,
    GENERAL_STATUSES,
} from '@/utilities/constants/index.js'
import {
    COSTS_TYPE_OPTIONS,
    INVOICE_STATUS_OPTIONS,
} from '@/utilities/constants/list.js'
import {
    getHumanReadableDate,
    truncateText,
    formatCurrency,
    translateOptions,
} from '@/utilities/helpers.js'

/* eslint-disable */

export const COLUMNS_INVOICES_NAMES = {
    id: 'id',
    success_manager: 'success_manager',
    typology: 'typology',
    date: 'date',
    taxable: 'taxable',
    status: 'status',
    note: 'note',
    stage: 'stage',
    actions: 'actions',
}
export const columnsInvoices = () => {
    return [
        {
            alignment: 'left',
            label: i18next.t('general.id'),
            name: COLUMNS_INVOICES_NAMES.id,
            width: '5%',
            sortable: false,
            divider: false,
        },
        {
            alignment: 'left',
            label: i18next.t('general.success_manager'),
            name: COLUMNS_INVOICES_NAMES.success_manager,
            width: '13%',
            sortable: false,
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.typology'),
            name: COLUMNS_INVOICES_NAMES.typology,
            sortable: false,
            width: '13%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('customer.tables.billing.date'),
            name: COLUMNS_INVOICES_NAMES.date,
            sortable: true,
            width: '10%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('customer.tables.billing.taxable'),
            name: COLUMNS_INVOICES_NAMES.taxable,
            sortable: false,
            width: '10%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.status'),
            name: COLUMNS_INVOICES_NAMES.status,
            sortable: false,
            width: '7%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('customer.tables.billing.notes'),
            name: COLUMNS_INVOICES_NAMES.note,
            sortable: false,
            width: '25%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('customer.tables.billing.stage'),
            name: COLUMNS_INVOICES_NAMES.stage,
            sortable: false,
            width: '15%',
            divider: true,
        },
        {
            alignment: 'space-evenly',
            label: ' ',
            name: COLUMNS_INVOICES_NAMES.actions,
            sortable: false,
            width: '2%',
            divider: true,
        },
    ]
}

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersInvoices = (
    openDialogDelete,
    openEditSidebar
) => ({
    [COLUMNS_INVOICES_NAMES.id]: ({ id }) => <p>#{id}</p>,
    [COLUMNS_INVOICES_NAMES.success_manager]: ({ success_manager }) => (
        <p>{success_manager?.full_name}</p>
    ),
    [COLUMNS_INVOICES_NAMES.typology]: ({ type }) => {
        const typologyLabel = translateOptions(COSTS_TYPE_OPTIONS)?.find(
            item => {
                return item.value === type
            }
        )
        return (
            <Tooltip
                text={`${typologyLabel?.label} | ${typologyLabel?.label}`}
                icon={
                    type === COSTS_TYPE.project ? (
                        <CostsProjectIcon />
                    ) : (
                        <ServiceIcon />
                    )
                }
                showPosition="bottom"
            >
                <StyledContainerCell>
                    {type === COSTS_TYPE.project ? (
                        <CostsProjectIcon />
                    ) : (
                        <ServiceIcon />
                    )}
                    <p>{typologyLabel?.label}</p>
                </StyledContainerCell>
            </Tooltip>
        )
    },
    [COLUMNS_INVOICES_NAMES.date]: ({ date }) => (
        <p>{getHumanReadableDate(date, COMMON_DATE_FORMAT)}</p>
    ),
    [COLUMNS_INVOICES_NAMES.taxable]: ({ amount }) => (
        <p>{formatCurrency(amount)}</p>
    ),
    [COLUMNS_INVOICES_NAMES.status]: ({ status }) => {
        const statusToRender = () => {
            switch (status) {
                case INVOICE_STATUS.paid:
                case INVOICE_STATUS.utt:
                    return GENERAL_STATUSES.success
                case INVOICE_STATUS.issued:
                    return GENERAL_STATUSES.warning
                default:
                    return
            }
        }

        const textToRender = () => {
            const enumStatus = translateOptions(INVOICE_STATUS_OPTIONS)
            return enumStatus.find(item => item.value === status)?.label
        }
        return (
            <Chip
                text={textToRender()}
                size={'medium'}
                variant={statusToRender()}
                fullWidth={false}
            />
        )
    },
    [COLUMNS_INVOICES_NAMES.note]: ({ description }) => (
        <Tooltip text={description} showPosition={'bottom'}>
            <p>{description ? truncateText(description, 30) : '-'}</p>
        </Tooltip>
    ),
    [COLUMNS_INVOICES_NAMES.stage]: ({ stage }) => {
        return (
            <StageStatus
                status={
                    stage === LABELS_TABLES.final_balance &&
                    GENERAL_STATUSES.success
                }
                text={stage}
            />
        )
    },
    [COLUMNS_INVOICES_NAMES.actions]: data => (
        <Dropdown
            trigger={<ActionsIcon />}
            items={[
                //TODO: add when have 'fatture in cloud' implemented
                /*{
                    label: i18next.t('customer.tables.billing.view'),
                    onSelect: () => {},
                    icon: <OpenNewTabIcon />,
                    isToDelete: false,
                },*/
                {
                    label: i18next.t('customer.actions.edit_invoice'),
                    onSelect: () => {
                        openEditSidebar(data.id, LABELS_TABLES.billing, data)
                    },
                    icon: <EditIcon />,
                    isToDelete: false,
                },
                {
                    label: i18next.t('customer.actions.delete_invoice'),
                    onSelect: () => {
                        openDialogDelete(data.id, data)
                    },
                    icon: <TrashIcon />,
                    isToDelete: true,
                },
            ]}
        />
    ),
})
