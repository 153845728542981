import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import DialogDelete from '@/components/dialog-delete/DialogDelete.jsx'
import DialogSafeClose from '@/components/dialog-safe-close/DialogSafeClose.jsx'
import SubHeader from '@/components/subheader/SubHeader'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import CustomerDetailsForm from '@/pages/customers/detail/CustomerDetailsForm.jsx'
import CustomerFilters from '@/pages/customers/detail/customer-filters/CustomerFilters.jsx'
import CustomerTabs from '@/pages/customers/detail/customer-tabs/CustomerTabs.jsx'
import { StyledContainerFluid } from '@/pages/settings/styles.js'
import {
    useDeleteCustomerCostMutation,
    useDeleteInvoiceCustomersMutation,
    useGetCustomerByIdQuery,
    useGetCustomersListQuery,
} from '@/services/customers/customersApiSlice.js'
import { useDeleteInvoiceFinalBalanceMutation } from '@/services/projects/finalBalanceApiSlice.js'
import {
    useDeleteInvoiceStandardMutation,
    useGetSuppliersQuery,
} from '@/services/projects/projectsApiSlice.js'
import {
    useGetMembersQuery,
    useGetTeamsQuery,
} from '@/services/settings/settingApiSlice.js'
import { handleApiError, remapSelectOptions } from '@/utilities/api/helpers.js'
import { COSTS_TYPE, LABELS_TABLES } from '@/utilities/constants/index.js'

import SidebarWrapper from './sidebar-detail/SidebarWrapper'

const CustomerDetail = () => {
    const { id } = useParams()
    const showError = useError()
    const { t } = useTranslation()
    const { triggerAlert } = useAlert()
    const [selectedTab, setSelectedTab] = useState(null)

    const [openDialogToConfirm, setOpenDialogToConfirm] = useState(false)
    const [openDialogToDelete, setOpenDialogToDelete] = useState(false)
    const [idSelected, setIdSelected] = useState(null)
    const [filters, setFilters] = useState({})

    const [dataForm, setDataForm] = useState({
        open: false,
        formType: null,
        id: null,
        isEdit: false,
        data: null,
    })

    const [deleteCustomerCost] = useDeleteCustomerCostMutation()
    const [deleteInvoice] = useDeleteInvoiceCustomersMutation()
    const [deleteInvoiceProject] = useDeleteInvoiceStandardMutation()
    const [deleteFinalBalanceInvoice] = useDeleteInvoiceFinalBalanceMutation()
    const { data: suppliers } = useGetSuppliersQuery()
    const { data: customerDetails } = useGetCustomerByIdQuery(
        { ID: id },
        { skip: !id }
    )
    const { data: teams } = useGetTeamsQuery()
    const { data: successManagers } = useGetMembersQuery({
        is_success_manager: true,
    })
    const { data: customers } = useGetCustomersListQuery()

    const openDialogDelete = (id, data) => {
        setIdSelected({
            id: id,
            data: data ? data : null,
        })
        setOpenDialogToDelete(true)
    }

    const handleDelete = async () => {
        try {
            switch (selectedTab) {
                case LABELS_TABLES.billing:
                    if (idSelected?.data?.type === COSTS_TYPE.service) {
                        await deleteInvoice({
                            customerID: id,
                            invoiceID: idSelected?.id,
                        }).unwrap()
                    } else {
                        await deleteInvoiceProject({
                            projectID: idSelected?.data?.project?.id,
                            invoiceID: idSelected?.id,
                        }).unwrap()
                    }
                    break
                case LABELS_TABLES.costs:
                    await deleteCustomerCost({
                        customerID: id,
                        expenseID: idSelected?.id,
                    }).unwrap()
                    break

                case LABELS_TABLES.final_balances:
                    await deleteFinalBalanceInvoice({
                        customerID: id,
                        invoiceID: idSelected?.id,
                    }).unwrap()
                    break
                default:
                    break
            }
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: t('commons:dialog.success'),
            })
            setDataForm({})
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        } finally {
            setOpenDialogToDelete(false)
        }
    }

    const openEditSidebar = (id, type, data) => {
        setIdSelected({
            id: id,
            data: data ? data : null,
        })
        setDataForm({
            open: true,
            formType: type,
            id: id,
            isEdit: true,
            data: data,
        })
    }

    return (
        <>
            <DialogDelete
                open={openDialogToDelete}
                onClose={() => setOpenDialogToDelete(false)}
                onDelete={handleDelete}
            />

            {openDialogToConfirm && (
                <DialogSafeClose
                    goBack={() => {
                        setDataForm({})
                        setOpenDialogToConfirm(false)
                    }}
                    onClose={() => setOpenDialogToConfirm(false)}
                    open={openDialogToConfirm}
                />
            )}

            <SidebarWrapper customerId={+id} setDataForm={setDataForm} />
            <StyledContainerFluid className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-4">
                        <SubHeader title={customerDetails?.name}>
                            <CustomerFilters
                                selectedTab={selectedTab}
                                setOpenSidebar={setDataForm}
                                setFilters={setFilters}
                                filters={filters}
                            />
                        </SubHeader>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <CustomerTabs
                            onSelectedTab={setSelectedTab}
                            openDialogDelete={openDialogDelete}
                            openEditSidebar={openEditSidebar}
                            filters={filters}
                            setDataForm={setDataForm}
                        />
                    </div>
                </div>
                {dataForm.open && (
                    <CustomerDetailsForm
                        onClose={() => setDataForm({})}
                        isEdit={dataForm.isEdit}
                        dataForm={dataForm}
                        setOpenDialogToConfirm={setOpenDialogToConfirm}
                        openDialogDelete={openDialogDelete}
                        suppliers={suppliers}
                        customers={remapSelectOptions(customers?.data)}
                        teams={teams?.data}
                        successManagers={remapSelectOptions(
                            successManagers?.data
                        )}
                    />
                )}
            </StyledContainerFluid>
        </>
    )
}

export default CustomerDetail
