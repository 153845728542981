export const remapResponseBilling = analytics => {
    return [
        {
            id: 1,
            title: 'Totale da fatturare',
            value: analytics?.total,
            haveProgress: false,
        },
        {
            id: 2,
            title: 'Fatture scadenzate',
            value: analytics?.amount_issued,
            color: '#FFB800',
            haveProgress: true,
            progress: analytics?.remaining_to_invoice_percentage || 0,
            remaining: analytics?.remaining_to_invoice,
        },
    ]
}
