import * as AlertDialog from '@radix-ui/react-alert-dialog'
import PropTypes from 'prop-types'

import { CloseIcon } from '@/assets/icons/icons'

import {
    StyledActionsContainer,
    StyledAlertDialogContent,
    StyledAlertDialogDescription,
    StyledAlertDialogOverlay,
    StyledAlertDialogTitle,
} from './styles'

const Dialog = ({
    title,
    children,
    actionsDialog,
    triggerDialog,
    open,
    onOpenChange,
    defaultOpen,
    canDismiss = false,
}) => {
    return (
        <AlertDialog.Root
            open={open}
            onOpenChange={onOpenChange}
            defaultOpen={defaultOpen}
        >
            <AlertDialog.Trigger asChild>{triggerDialog}</AlertDialog.Trigger>

            <AlertDialog.Portal>
                <StyledAlertDialogOverlay />

                <StyledAlertDialogContent>
                    <StyledAlertDialogTitle>
                        {title}
                        {canDismiss && (
                            <AlertDialog.Cancel>
                                <CloseIcon />
                            </AlertDialog.Cancel>
                        )}
                    </StyledAlertDialogTitle>

                    <StyledAlertDialogDescription>
                        {children}
                    </StyledAlertDialogDescription>

                    <StyledActionsContainer>
                        <AlertDialog.Action asChild>
                            {actionsDialog}
                        </AlertDialog.Action>
                    </StyledActionsContainer>
                </StyledAlertDialogContent>
            </AlertDialog.Portal>
        </AlertDialog.Root>
    )
}

export default Dialog

Dialog.propTypes = {
    open: PropTypes.bool,
    onOpenChange: PropTypes.func,
    defaultOpen: PropTypes.bool,
    children: PropTypes.node,
    title: PropTypes.string,
    actionsDialog: PropTypes.any,
    triggerDialog: PropTypes.element,
    cancelDialog: PropTypes.bool,
    canDismiss: PropTypes.bool,
}
