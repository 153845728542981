import TableLoader from '@/components/table/table-loader/index.jsx'

import { StyledTCell, StyledTRow } from '../row/style'

const Loader = ({ columns }) => {
    return Array.from(Array(4).keys()).map((row, index) => (
        <StyledTRow key={index}>
            {columns.map((column, index) => (
                <StyledTCell
                    alignment={column.alignment}
                    width={column.width}
                    key={index}
                >
                    <TableLoader />
                </StyledTCell>
            ))}
        </StyledTRow>
    ))
}

export default Loader
