import styled, { css } from 'styled-components'

export const StyledCustomerFilters = styled.div`
    min-width: 140px;
`

export const StyledCustomerBilling = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: 174px 140px;
        grid-gap: ${theme.spacing}px;
        grid-template-rows: 1fr;
        color: ${theme.palette.primary[0]};
    `}
`
