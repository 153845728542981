import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { PlusIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button'
import Table from '@/components/table/index.jsx'
import Tabs from '@/components/tabs/Tabs.jsx'
import useError from '@/hooks/useError.jsx'
import {
    columnsRDCosts,
    columnsRenderersRDCosts,
} from '@/pages/projects/detail/detail-r&d/r&d-tables/columnsRAndDCosts.jsx'
import { currentExpandedRows } from '@/services/projects/projectsSlice.js'
import {
    useCreateRDProjectSALMutation,
    useGetRDProjectCostsQuery,
    useGetRDProjectSALsQuery,
} from '@/services/projects/r&dApiSlice.js'
import { handleApiError } from '@/utilities/api/helpers.js'
import { TABLE_HEIGHT_WITH_WIDGET } from '@/utilities/constants/index.js'
import { handleSortFilter } from '@/utilities/helpers'

import {
    columnsActivity,
    columnsRenderersActivity,
} from '../r&d-tables/activity-table/columnsActivity'

const RAndDTabs = ({
    id,
    setSelectedTab,
    isLoading,
    openDialogDelete,
    openEditSidebar,
}) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const showError = useError()
    const expandedRows = useSelector(currentExpandedRows)
    const location = useLocation()

    const [createSal] = useCreateRDProjectSALMutation()
    const [sortDirections, setSortDirections] = useState({
        sals: 'asc',
        costs: 'asc',
    })
    const { data: sals } = useGetRDProjectSALsQuery(
        {
            id: id,
            sortDirection: sortDirections?.sals,
        },
        {
            skip: !id,
        }
    )

    const { data: costs } = useGetRDProjectCostsQuery(
        {
            projectID: id,
            sortDirection: sortDirections?.costs,
        },
        {
            skip: location.search !== '?section=Costi',
        }
    )

    const onSectionClick = section => {
        const searchParams = new URLSearchParams(location.search)
        setSelectedTab(section)
        searchParams.set('section', section)
        navigate(`?${searchParams.toString()}`)
    }
    const tableColumns = useMemo(() => {
        return {
            activity: columnsActivity(),
            costs: columnsRDCosts(),
        }
    }, [t])
    const handleSubmit = async e => {
        try {
            e.preventDefault()
            createSal({
                id: id,
            })
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        }
    }

    const RAdnDTabs = [
        {
            id: 1,
            value: t('projects.tabs.activities'),
            label: t('projects.tabs.activities'),
            component: (
                <>
                    {sals && (
                        <Table
                            columns={tableColumns.activity}
                            columnsRenderers={columnsRenderersActivity(
                                openDialogDelete,
                                openEditSidebar
                            )}
                            data={sals}
                            isLoading={isLoading}
                            isPaginated
                            bodyheight={TABLE_HEIGHT_WITH_WIDGET}
                            expandedRowsIndexes={expandedRows}
                            sortColumnCb={() =>
                                handleSortFilter('sals', setSortDirections)
                            }
                        />
                    )}
                    <Button
                        type={'button'}
                        size={'small'}
                        variant={'borderless'}
                        className={'add_button'}
                        onClick={handleSubmit}
                    >
                        <PlusIcon fill={theme.palette.primary.base.active} />
                        {t('project.add_sal')}
                    </Button>
                </>
            ),
        },
        {
            id: 2,
            value: t('projects.tabs.costs'),
            label: t('projects.tabs.costs'),
            component: (
                <Table
                    columns={tableColumns.costs}
                    columnsRenderers={columnsRenderersRDCosts(
                        openDialogDelete,
                        openEditSidebar
                    )}
                    data={costs}
                    isLoading={isLoading}
                    isPaginated
                    bodyheight={TABLE_HEIGHT_WITH_WIDGET}
                    sortColumnCb={() =>
                        handleSortFilter('costs', setSortDirections)
                    }
                />
            ),
        },
    ]

    return (
        <>
            <Tabs
                onValueChange={e => onSectionClick(e)}
                triggers={RAdnDTabs}
                defaultValue={t('projects.tabs.activities')}
                haveDivider
            />
        </>
    )
}

export default RAndDTabs

RAndDTabs.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setSelectedTab: PropTypes.func,
    isLoading: PropTypes.bool,
    openDialogDelete: PropTypes.func,
    openEditSidebar: PropTypes.func,
}
