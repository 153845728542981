import styled, { css } from 'styled-components'

export const StyledErrorMessage = styled.small`
    ${({ theme: { palette, typography } }) => css`
        color: ${palette.danger.base.text};
        ${typography.paragraph.p12.bold}
    `}
`

export const StyledSmall = styled.small`
    ${({ theme: { palette } }) => css`
        color: ${palette.neutral.base[400]};
    `}
`
