import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { CurrencyIcon, TrashIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import Datepicker from '@/components/date-picker/DatePicker.jsx'
import InputCurrency from '@/components/input-currency/InputCurrency.jsx'
import Select from '@/components/select/Select.jsx'
import TextArea from '@/components/text-area/TextArea.jsx'
import useError from '@/hooks/useError.jsx'
import { BILLING_MODEL } from '@/pages/customers/detail/customer-form/billing-form/billing.schema.js'
import {
    StyledContainerFormActions,
    StyledFormBilling,
} from '@/pages/customers/detail/customer-form/billing-form/styles.js'
import { StyledContainerDelete } from '@/pages/customers/detail/customer-tables/table-projects/projects-form/styles.js'
import {
    useCreateInvoiceCustomersMutation,
    useGetCustomerCostsQuery,
    useGetInvoiceByIDCustomersQuery,
    useUpdateInvoiceCustomersMutation,
} from '@/services/customers/customersApiSlice.js'
import { useUpdateInvoiceStandardMutation } from '@/services/projects/projectsApiSlice.js'
import { formatDateFromApi, handleApiError } from '@/utilities/api/helpers.js'
import { COSTS_TYPE } from '@/utilities/constants/index.js'
import { INVOICE_STATUS_OPTIONS } from '@/utilities/constants/list.js'
import {
    iterateOverDirtyFields,
    retrieveSingleValueForRs,
    translateOptions,
} from '@/utilities/helpers.js'

const BillingForm = ({
    isEdit,
    closeModal,
    dataForm,
    openDialogDelete,
    isFinance = false,
}) => {
    const { t } = useTranslation()
    const showError = useError()
    const { id } = useParams()
    const customerID = isFinance ? dataForm?.data?.customer?.id : id
    const invoiceID = isFinance ? dataForm?.data?.id : dataForm?.id
    const { data: customerInvoiceData } = useGetInvoiceByIDCustomersQuery(
        {
            customerID: customerID,
            invoiceID: invoiceID,
        },
        { skip: !isEdit }
    )

    const { data: customerCost } = useGetCustomerCostsQuery({
        customerID: customerID,
    })
    const optionsCosts = customerCost?.data?.map(item => {
        return {
            value: item.id,
            label: `#${item.id} - ${item.description}`,
            data: item,
        }
    })
    const [createInvoice] = useCreateInvoiceCustomersMutation()
    const [updateInvoiceCustomer] = useUpdateInvoiceCustomersMutation()
    const [updateInvoiceStandardProject] = useUpdateInvoiceStandardMutation()

    const {
        register,
        control,
        handleSubmit,
        reset,
        setError,
        watch,
        formState: {
            errors,
            isDirty,
            touchedFields,
            dirtyFields,
            isSubmitting,
        },
    } = useFormContext()

    const onSubmit = async data => {
        try {
            const remappedData = {
                ...data,
                [BILLING_MODEL.taxable]: parseFloat(
                    data[BILLING_MODEL.taxable]
                ),
                [BILLING_MODEL.status]: data[BILLING_MODEL.status]?.value,
                [BILLING_MODEL.associate_cost]:
                    data[BILLING_MODEL.associate_cost]?.value,
                sal_id:
                    data[BILLING_MODEL.associate_cost]?.data?.sal?.id ?? null,
            }

            if (isEdit) {
                if (dataForm?.data?.type === COSTS_TYPE.service) {
                    await updateInvoiceCustomer({
                        customerID: customerID,
                        invoiceID: invoiceID,
                        data: iterateOverDirtyFields(dirtyFields, remappedData),
                    }).unwrap()
                } else {
                    await updateInvoiceStandardProject({
                        projectID: dataForm?.data?.project?.id,
                        invoiceID: invoiceID,
                        data: iterateOverDirtyFields(dirtyFields, remappedData),
                    }).unwrap()
                }
            } else {
                await createInvoice({
                    customerID: customerID,
                    data: remappedData,
                }).unwrap()
            }
            closeModal()
        } catch (error) {
            handleApiError({
                error,
                handleGeneralError: showError,
                handleFormError: setError,
            })
        }
    }

    useEffect(() => {
        if (isEdit && customerInvoiceData) {
            reset({
                ...customerInvoiceData,
                [BILLING_MODEL.date]: formatDateFromApi(
                    customerInvoiceData[BILLING_MODEL.date]
                ),
                [BILLING_MODEL.status]: retrieveSingleValueForRs(
                    translateOptions(INVOICE_STATUS_OPTIONS),
                    customerInvoiceData[BILLING_MODEL.status]
                ),
                [BILLING_MODEL.associate_cost]: retrieveSingleValueForRs(
                    optionsCosts,
                    customerInvoiceData.expense?.id
                ),
            })
        }
    }, [customerInvoiceData, isEdit])

    return (
        <>
            <StyledFormBilling
                isEdit={isEdit}
                onSubmit={handleSubmit(onSubmit)}
                className="container-fluid d-grid p-0 gap-3"
            >
                <div className="row">
                    <div className="col-6">
                        <Datepicker
                            required
                            label={t('projects.fields.labels.date')}
                            placeholder={t(
                                'projects.fields.placeholder.start_date'
                            )}
                            control={control}
                            name={BILLING_MODEL.date}
                            touched={touchedFields[BILLING_MODEL.date]}
                            errors={errors[BILLING_MODEL.date]}
                            showMonthDropdown
                            showYearDropdown
                            pickerType={'days'}
                        />
                    </div>

                    <div className="col-6">
                        <Select
                            required
                            name={BILLING_MODEL.status}
                            label={t('general.status')}
                            placeholder={t(
                                'projects.fields.placeholder.status'
                            )}
                            control={control}
                            touched={touchedFields[BILLING_MODEL.status]}
                            errors={errors[BILLING_MODEL.status]}
                            size="large"
                            options={translateOptions(INVOICE_STATUS_OPTIONS)}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <InputCurrency
                            required
                            label={t('projects.fields.labels.taxable')}
                            placeholder={t(
                                'projects.fields.placeholder.taxable'
                            )}
                            width="100%"
                            size={'large'}
                            icon={<CurrencyIcon />}
                            iconPosition={'right'}
                            control={control}
                            errors={errors[BILLING_MODEL.taxable]}
                            name={BILLING_MODEL.taxable}
                            inputProps={{
                                value: watch(BILLING_MODEL.taxable),
                            }}
                        />
                    </div>
                    <div className="col-6">
                        <Select
                            name={BILLING_MODEL.associate_cost}
                            label={t('projects.fields.labels.associate_cost')}
                            placeholder={t(
                                'projects.fields.placeholder.associate_cost'
                            )}
                            control={control}
                            touched={
                                touchedFields[BILLING_MODEL.associate_cost]
                            }
                            errors={errors[BILLING_MODEL.associate_cost]}
                            size="large"
                            isClearable
                            options={optionsCosts}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <TextArea
                            required
                            label={t('projects.fields.labels.note')}
                            placeholder={t('projects.fields.placeholder.note')}
                            width="100%"
                            size={'large'}
                            errors={errors[BILLING_MODEL.note]}
                            {...register(BILLING_MODEL.note)}
                            resize={'vertical'}
                        />
                    </div>
                </div>

                {isEdit && (
                    <div className="row">
                        <StyledContainerDelete className="col-12">
                            <TrashIcon />
                            <p
                                onClick={() => {
                                    openDialogDelete(
                                        dataForm?.id,
                                        dataForm?.data
                                    )
                                }}
                            >
                                {t('final_balance.actions.delete')}
                            </p>
                        </StyledContainerDelete>
                    </div>
                )}

                <StyledContainerFormActions className="row d-flex">
                    <div className="col-md-4">
                        <Button
                            size="large"
                            color={'neutral'}
                            isUppercase
                            onClick={() => {
                                closeModal(isDirty)
                            }}
                        >
                            {t('commons:button.cancel')}
                        </Button>
                    </div>
                    <div className="col-md-8">
                        <Button
                            type="submit"
                            size="large"
                            isUppercase
                            disabled={(!isDirty && isEdit) || isSubmitting}
                        >
                            {isEdit
                                ? t('commons:button.save')
                                : t('commons:button.create')}
                        </Button>
                    </div>
                </StyledContainerFormActions>
            </StyledFormBilling>
        </>
    )
}

export default BillingForm

BillingForm.propTypes = {
    isEdit: PropTypes.bool,
    closeModal: PropTypes.func,
    dataForm: PropTypes.object,
    openDialogDelete: PropTypes.func,
    isFinance: PropTypes.bool,
}
