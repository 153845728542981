import styled, { css } from 'styled-components'

export const StyledContainerCard = styled.div`
    ${({ theme }) => css`
        border-radius: ${theme.spacing / 2}px;
        padding: ${theme.spacing}px;
        min-height: 126px;

        border: 1px solid ${theme.palette.neutral.base[200]};
        display: flex;
        gap: ${theme.spacing / 2}px;
    `}
`

export const StyledContainerItem = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing * 2}px;
        padding: ${theme.spacing}px ${theme.spacing * 2}px;
        width: 220px;

        & small {
            color: ${theme.palette.neutral.base[300]};
            ${theme.typography.paragraph.p14.medium};
        }
    `}
`

export const StyledContainerPara = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing / 2}px;
    `}
`

export const StyledValue = styled.div`
    ${({ theme }) => css`
        ${theme.typography.paragraph.p24};
        color: ${theme.palette.neutral.base[1000]};
    `}
`

export const StyledContainerProgress = styled.div`
    ${({ theme }) => css`
        display: flex;
        gap: ${theme.spacing}px;
        align-items: center;
        max-width: 180px;
        & > p {
            ${theme.typography.paragraph.p12.regular};
        }
    `}
`

export const StyledContainerHours = styled.div`
    ${({ theme }) => css`
        display: flex;
        gap: ${theme.spacing / 2}px;
        align-items: center;
        & > p {
            ${theme.typography.paragraph.p12.bold};
        }
    `}
`
