import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

import {
    ActionsIcon,
    CostsProjectIcon,
    EditIcon,
    ServiceIcon,
    TrashIcon,
} from '@/assets/icons/icons.jsx'
import Chip from '@/components/chip/Chip.jsx'
import Dropdown from '@/components/dropdown/Dropdown.jsx'
import StageStatus from '@/components/stage-status/StageStatus.jsx'
import Tooltip from '@/components/tooltip/Tooltip.jsx'
import { StyledContainerCell } from '@/pages/customers/detail/styles.js'
import { COLUMNS_STANDARD_PROJECTS_NAMES } from '@/pages/projects/list/projects-tables/projects-table/columnsStandardProjects.jsx'
import {
    COSTS_STATUS,
    COSTS_TYPE,
    GENERAL_STATUSES,
    LABELS_TABLES,
} from '@/utilities/constants/index.js'
import {
    COSTS_STATUS_OPTIONS,
    COSTS_TYPE_OPTIONS,
} from '@/utilities/constants/list.js'
import {
    getHumanReadableDate,
    truncateText,
    formatCurrency,
    translateOptions,
    retrieveSingleValueForRs,
} from '@/utilities/helpers.js'

/* eslint-disable */

export const COLUMNS_COSTS_NAMES = {
    id: 'id',
    success_manager: 'success_manager',
    typology: 'typology',
    date: 'date',
    taxable: 'taxable',
    status: 'status',
    note: 'note',
    stage: 'stage',
    actions: 'actions',
}
export const columnsCosts = () => {
    return [
        {
            alignment: 'left',
            label: i18next.t('general.id'),
            name: COLUMNS_STANDARD_PROJECTS_NAMES.id,
            width: '3%',
            sortable: false,
            divider: false,
        },
        {
            alignment: 'left',
            label: i18next.t('projects.tables.costs.supplier_reference'),
            name: COLUMNS_COSTS_NAMES.success_manager,
            width: '15%',
            sortable: false,
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.typology'),
            name: COLUMNS_COSTS_NAMES.typology,
            sortable: false,
            width: '10%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('customer.tables.billing.date'),
            name: COLUMNS_COSTS_NAMES.date,
            sortable: true,
            width: '10%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('customer.tables.billing.taxable'),
            name: COLUMNS_COSTS_NAMES.taxable,
            sortable: false,
            width: '10%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.status'),
            name: COLUMNS_COSTS_NAMES.status,
            sortable: false,
            width: '6%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('customer.tables.billing.notes'),
            name: COLUMNS_COSTS_NAMES.note,
            sortable: false,
            width: '30%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('customer.tables.billing.stage'),
            name: COLUMNS_COSTS_NAMES.stage,
            sortable: false,
            width: '14%',
            divider: true,
        },
        {
            alignment: 'space-evenly',
            label: ' ',
            name: COLUMNS_COSTS_NAMES.actions,
            sortable: false,
            width: '2%',
            divider: true,
        },
    ]
}

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersCosts = (openDialogDelete, openEditSidebar) => ({
    [COLUMNS_COSTS_NAMES.id]: ({ id }) => <p>#{id}</p>,
    [COLUMNS_COSTS_NAMES.success_manager]: ({ supplier }) => (
        <p>{supplier?.business_name || '-'}</p>
    ),
    [COLUMNS_COSTS_NAMES.typology]: ({ type }) => {
        const { t } = useTranslation()
        const typeCost = retrieveSingleValueForRs(COSTS_TYPE_OPTIONS, type)
        return (
            <Tooltip
                text={`${t(`${typeCost.label}`)} | ${t(`${typeCost.label}`)}`}
                icon={
                    type === COSTS_TYPE.project ? (
                        <CostsProjectIcon />
                    ) : (
                        <ServiceIcon />
                    )
                }
                showPosition="bottom"
            >
                <StyledContainerCell>
                    {type === COSTS_TYPE.project ? (
                        <CostsProjectIcon />
                    ) : (
                        <ServiceIcon />
                    )}
                    <p> {t(`${typeCost.label}`)}</p>
                </StyledContainerCell>
            </Tooltip>
        )
    },
    [COLUMNS_COSTS_NAMES.date]: ({ date }) => (
        <p>{getHumanReadableDate(date, 'MM/DD/YYYY')}</p>
    ),
    [COLUMNS_COSTS_NAMES.taxable]: ({ amount }) => (
        <p>{formatCurrency(amount)}</p>
    ),
    [COLUMNS_COSTS_NAMES.status]: ({ status }) => {
        const statusToRender = () => {
            switch (status) {
                case COSTS_STATUS.paid:
                    return GENERAL_STATUSES.success
                case COSTS_STATUS.received:
                    return GENERAL_STATUSES.warning
                default:
                    return
            }
        }

        const textToRender = () => {
            const enumStatus = translateOptions(COSTS_STATUS_OPTIONS)
            return enumStatus.find(item => item.value === status)?.label
        }
        return (
            <Chip
                text={textToRender()}
                size={'medium'}
                variant={statusToRender()}
                fullWidth={false}
            />
        )
    },
    [COLUMNS_COSTS_NAMES.note]: ({ description }) => (
        <p>{description ? truncateText(description, 30) : '-'}</p>
    ),
    [COLUMNS_COSTS_NAMES.stage]: ({ sal }) => {
        return (
            <>
                {sal ? (
                    <StageStatus
                        status={
                            sal?.is_completed === false
                                ? GENERAL_STATUSES.danger
                                : GENERAL_STATUSES.success
                        }
                        text={truncateText(
                            `#${sal?.global_sal_id} - ${sal?.name}`,
                            15
                        )}
                    />
                ) : (
                    '-'
                )}
            </>
        )
    },
    [COLUMNS_COSTS_NAMES.actions]: item => {
        return (
            <Dropdown
                trigger={<ActionsIcon />}
                items={[
                    {
                        label: i18next.t('customer.actions.edit_cost'),
                        onSelect: () => {
                            openEditSidebar(item?.id, LABELS_TABLES.costs, item)
                        },
                        icon: <EditIcon />,
                        isToDelete: false,
                    },
                    {
                        label: i18next.t('customer.actions.delete_cost'),
                        onSelect: () => {
                            openDialogDelete(item?.id, LABELS_TABLES.costs)
                        },
                        icon: <TrashIcon />,
                        isToDelete: true,
                    },
                ]}
            />
        )
    },
})
