import PropTypes from 'prop-types'
import { useState } from 'react'

import { SearchIcon } from '@/assets/icons/icons'
import Input from '@/components/input/Input.jsx'

const Search = ({ placeholder, handleSearch }) => {
    const [timeoutId, setTimeoutId] = useState(null)

    const search = event => {
        event.preventDefault()

        if (timeoutId) {
            clearTimeout(timeoutId)
        }

        const newTimeoutId = setTimeout(() => {
            handleSearch(event.target.value)
        }, 500)

        setTimeoutId(newTimeoutId)
    }

    return (
        <Input
            icon={<SearchIcon />}
            iconPosition={'left'}
            placeholder={placeholder}
            onChange={event => search(event)}
            size={'medium'}
        />
    )
}

export default Search

Search.propTypes = {
    placeholder: PropTypes.string,
    setDataFiltered: PropTypes.func,
    dataNotFiltered: PropTypes.array,
    handleSearch: PropTypes.func,
}
