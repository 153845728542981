import PropTypes, { shape } from 'prop-types'

import { TABLE_ALIGNMENTS } from '@/utilities/constants'

import ErrorMessage from '../../error-message'
import Loader from '../loader'
import Row from '../row'

import { StyledNoResults, StyledPaginatedTbody, StyledTbody } from './style'

const Body = ({
    columns,
    expandedRowsIndexes,
    columnsRenderers,
    onClickRow,
    bodyheight,
    isError,
    onRefresh,
    data,
    page,
    isLoading,
    onChangePageCb,
    isPaginated,
}) => {
    if (isError) {
        return (
            <StyledTbody bodyheight={bodyheight}>
                <ErrorMessage onRefresh={onRefresh} />
            </StyledTbody>
        )
    }

    switch (isPaginated) {
        case true:
            return (
                <>
                    {!!data.length && !(isLoading && page === 1) ? (
                        <StyledPaginatedTbody
                            bodyheight={bodyheight}
                            data={data}
                            endReached={onChangePageCb}
                            overscan={100}
                            itemContent={itemIndex => {
                                const item = data[itemIndex]
                                const clickableRowPath = onClickRow(item)

                                return (
                                    <Row
                                        columns={columns}
                                        expandedRowsIndexes={
                                            expandedRowsIndexes
                                        }
                                        itemIndex={itemIndex}
                                        item={item}
                                        columnsRenderers={columnsRenderers}
                                        clickableRowPath={clickableRowPath}
                                    />
                                )
                            }}
                            components={{
                                ...(isLoading && { Footer: () => <Loader /> }),
                            }}
                        />
                    ) : (
                        <StyledNoResults bodyheight={bodyheight}>
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <span>
                                    {isError
                                        ? 'Something went wrong'
                                        : 'No results'}
                                </span>
                            )}
                        </StyledNoResults>
                    )}
                </>
            )

        case false:
            return (
                <>
                    <StyledTbody bodyheight={bodyheight}>
                        {data.map((item, itemIndex) => {
                            const clickableRowPath = onClickRow(item)

                            return (
                                <Row
                                    key={itemIndex}
                                    columns={columns}
                                    expandedRowsIndexes={expandedRowsIndexes}
                                    itemIndex={itemIndex}
                                    item={item}
                                    columnsRenderers={columnsRenderers}
                                    clickableRowPath={clickableRowPath}
                                />
                            )
                        })}
                    </StyledTbody>
                </>
            )
    }
}

export default Body

Body.propTypes = {
    columns: PropTypes.arrayOf(
        shape({
            alignment: PropTypes.oneOf(Object.values(TABLE_ALIGNMENTS)),
            allowOverflow: PropTypes.bool,
            icon: PropTypes.node,
            label: PropTypes.string,
            mobileFullLength: PropTypes.bool,
            name: PropTypes.string.isRequired,
            sortable: PropTypes.bool,
            width: ({ width }, propName, componentName) => {
                if (!/^\d+%$/.test(width)) {
                    return new Error(
                        `Invalid prop '${propName}' supplied to ${componentName} (${width}). The prop must be a positive percentage number.`
                    )
                }
            },
            divider: PropTypes.bool,
        })
    ).isRequired,
    expandedRowsIndexes: PropTypes.arrayOf(PropTypes.number),
    columnsRenderers: PropTypes.objectOf(PropTypes.func),
    onClickRow: PropTypes.func,
    bodyheight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isError: PropTypes.bool,
    onRefresh: PropTypes.func,
    data: PropTypes.array.isRequired,
    page: PropTypes.number,
    isLoading: PropTypes.bool,
    onChangePageCb: PropTypes.func,
    isPaginated: PropTypes.bool,
}
