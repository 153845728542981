import styled, { css } from 'styled-components'

export const StyledContainerLogin = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${theme.spacing * 21}px;
        height: 100%;
    `}
`

export const StyledColLogin = styled.div`
    ${({ theme }) => css`
        margin-top: ${theme.spacing * 16}px;
    `}
`

export const StyledColParagraph = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${theme.spacing}px;
        margin-bottom: ${theme.spacing * 5}px;

        p {
            color: ${theme.palette.neutral.base[500]};
            ${theme.typography.paragraph.p16.regular};
        }
    `}
`

export const StyledContainerError = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        gap: ${theme.spacing * 2}px;
        color: ${theme.palette.danger.base.active};
    `}
`

export const StyledContainerButtonGoogle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
