import PropTypes from 'prop-types'

import { capitalize } from '@/utilities/helpers.js'

import { StyledCalendarSelectDateContainer } from '../styles'

const TableDateSelect = ({
    handleChangeDate,
    selectedMonth,
    selectedYear,
    currentData,
}) => {
    const months = new Array(12).fill(0).map((_, i) => {
        const date = new Date(selectedYear, i)
        return capitalize(date.toLocaleString('ita', { month: 'long' }))
    })

    const isAllUsersSatisfyCondition = currentData?.every(
        user => user.confirmed_hours >= user.allocated_hours
    )

    return (
        <StyledCalendarSelectDateContainer
            status={isAllUsersSatisfyCondition === true ? 'active' : 'inactive'}
        >
            <button onClick={() => handleChangeDate('decrease')}>{`<`}</button>
            <div className="date_container">
                <span>{months[selectedMonth - 1]}</span>
                <span>{selectedYear} </span>
                <span className="status_"></span>
            </div>
            <button onClick={() => handleChangeDate('increase')}>{`>`}</button>
        </StyledCalendarSelectDateContainer>
    )
}

export default TableDateSelect

TableDateSelect.propTypes = {
    handleChangeDate: PropTypes.func,
    selectedMonth: PropTypes.number,
    selectedYear: PropTypes.number,
    currentData: PropTypes.array,
}
