import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import {
    StyledTRow,
    StyledTCell,
    StyledTCellLabel,
    StyledTCellValue,
    StyledExpandedRow,
    StyledSubRow,
} from './style'

const Row = ({
    item,
    itemIndex,
    columnsRenderers,
    expandedRowsIndexes,
    columns,
    clickableRowPath,
}) => {
    const navigate = useNavigate()

    const onClickRow = () => {
        if (clickableRowPath) navigate(clickableRowPath)
    }

    return (
        <StyledTRow onClick={onClickRow} clickableRowPath={clickableRowPath}>
            {columns.map((column, index) => (
                <StyledTCell key={index}>
                    {(column.label || column.icon) && (
                        <StyledTCellLabel>
                            {column.icon}
                            <span>{column.label}</span>
                        </StyledTCellLabel>
                    )}
                    <StyledTCellValue
                        mobileFullLength={column.mobileFullLength}
                    >
                        {columnsRenderers[column.name](item, itemIndex)}
                    </StyledTCellValue>
                </StyledTCell>
            ))}
            {!!columnsRenderers?.expandedRow &&
                expandedRowsIndexes.includes(itemIndex) && (
                    <StyledExpandedRow>
                        {columnsRenderers?.expandedRow(item, itemIndex)}
                    </StyledExpandedRow>
                )}
            {!!columnsRenderers?.subRow && (
                <StyledSubRow>
                    {columnsRenderers?.subRow(item, itemIndex)}
                </StyledSubRow>
            )}
        </StyledTRow>
    )
}

export default Row

Row.propTypes = {
    item: PropTypes.object.isRequired,
    itemIndex: PropTypes.number.isRequired,
    columnsRenderers: PropTypes.object.isRequired,
    expandedRowsIndexes: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    clickableRowPath: PropTypes.string,
}
