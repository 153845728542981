import * as yup from 'yup'

import i18next from '../../../../i18n.js'

const TEAM_MODEL = {
    name: 'name',
    team_leader: 'team_lead_id',
    rate: 'rate',
    product_line: 'product_line',
    status: 'is_active',
}

const DEFAULT_TEAM_MOCK = {
    [TEAM_MODEL.name]: null,
    [TEAM_MODEL.team_leader]: null,
    [TEAM_MODEL.rate]: null,
    [TEAM_MODEL.product_line]: null,
    [TEAM_MODEL.status]: null,
}
const RequestValidationSchema = yup.object().shape({
    [TEAM_MODEL.name]: yup
        .string()
        .required(i18next.t('validation:general_form.name_required')),
    [TEAM_MODEL.team_leader]: yup
        .object()
        .required(i18next.t('validation:general_form.team_leader_required')),
    [TEAM_MODEL.rate]: yup
        .string()
        .required(i18next.t('validation:general_form.rate_required')),
    [TEAM_MODEL.product_line]: yup
        .object()
        .required(i18next.t('validation:general_form.project_line_required')),
    [TEAM_MODEL.status]: yup
        .object()
        .required(i18next.t('validation:general_form.status')),
})

export {
    TEAM_MODEL,
    DEFAULT_TEAM_MOCK,
    RequestValidationSchema as validationSchema,
}
