import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AlertIconGoogle, LogoIcon } from '@/assets/icons/icons.jsx'
import ButtonGoogle from '@/components/button-google/ButtonGoogle.jsx'
import Spinner from '@/components/spinner/Spinner.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useGoogleIdentity from '@/hooks/useGoogleIdentity.jsx'
import useUser from '@/hooks/useUser.jsx'
import {
    StyledColLogin,
    StyledColParagraph,
    StyledContainerButtonGoogle,
    StyledContainerError,
    StyledContainerLogin,
} from '@/pages/login/styles.js'
import AuthAPI from '@/services/auth/auth.http.js'
import { handleApiError } from '@/utilities/api/helpers.js'

const Login = () => {
    const { t } = useTranslation()
    const { triggerAlert } = useAlert()
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const { createUser } = useUser()

    const onErrorCallback = err => {
        if (err.type === 'popup_failed_to_open') {
            triggerAlert({
                variant: TOAST_STATUSES.FAILURE,
                message: t('commons:toast.login_google_error'),
            })
        } else if (err.type === 'popup_closed') {
            setIsLoading(false)
            triggerAlert({
                variant: TOAST_STATUSES.FAILURE,
                message: t('commons:toast.login_google_error'),
            })
        }
    }

    const sendGoogleAuthResponse = async authResponse => {
        try {
            const { data } = await AuthAPI.login.send({
                ...authResponse,
                token: authResponse.access_token,
                scope: authResponse.scope.split(' '),
            })
            createUser(data)
        } catch (error) {
            setError(error.message)
            handleApiError({ error, handleGeneralError: setError })
        } finally {
            setIsLoading(false)
        }
    }

    const googleClient = useGoogleIdentity({
        onSuccessCallback: sendGoogleAuthResponse,
        onErrorCallback: onErrorCallback,
    })

    const singInWithGoogle = () => {
        setIsLoading(true)
        if (googleClient) {
            googleClient.requestAccessToken()
        } else {
            setIsLoading(false)
            triggerAlert({
                variant: TOAST_STATUSES.FAILURE,
                message: t('commons:toast.login_google_error'),
            })
        }
    }

    return (
        <>
            {isLoading && <Spinner />}
            <StyledContainerLogin className={'container'}>
                <div className={'row'}>
                    <StyledColLogin className={'col-12'}>
                        <LogoIcon />
                    </StyledColLogin>
                </div>

                <div className={'row'}>
                    <StyledColParagraph className={'col-12'}>
                        {error ? (
                            <>
                                <StyledContainerError>
                                    <AlertIconGoogle />
                                    <h1>{t('login.error.error_title')}</h1>
                                </StyledContainerError>
                                <p>
                                    {t('login.error.error_message_1')}
                                    {t('login.error.error_message_2')}
                                </p>
                            </>
                        ) : (
                            <>
                                <h1>{t('login.title')}</h1>
                                <p>{t('login.message')}</p>
                            </>
                        )}
                    </StyledColParagraph>

                    <StyledContainerButtonGoogle>
                        <ButtonGoogle onClick={singInWithGoogle} />
                    </StyledContainerButtonGoogle>
                </div>
            </StyledContainerLogin>
        </>
    )
}

export default Login
