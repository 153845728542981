import * as yup from 'yup'

import i18next from '../../../../../../i18n.js'

const COSTS_PROJECTS_MODEL = {
    date: 'date',
    status: 'status',
    taxable: 'amount',
    sal: 'sal',
    supplier: 'supplier_id',
    note: 'description',
}

const DEFAULT_COSTS_PROJECTS_MOCK = {
    [COSTS_PROJECTS_MODEL.date]: null,
    [COSTS_PROJECTS_MODEL.status]: null,
    [COSTS_PROJECTS_MODEL.taxable]: null,
    [COSTS_PROJECTS_MODEL.sal]: null,
    [COSTS_PROJECTS_MODEL.supplier]: null,
    [COSTS_PROJECTS_MODEL.note]: '',
}

const RequestValidationSchema = yup.object().shape({
    [COSTS_PROJECTS_MODEL.date]: yup
        .date()
        .required(i18next.t('validation:general_form.date')),
    [COSTS_PROJECTS_MODEL.status]: yup
        .object()
        .required(i18next.t('validation:general_form.status')),
    [COSTS_PROJECTS_MODEL.sal]: yup
        .object()
        .required(i18next.t('validation:general_form.sal_required')),
    [COSTS_PROJECTS_MODEL.taxable]: yup
        .string()
        .required(i18next.t('validation:general_form.taxable')),
    [COSTS_PROJECTS_MODEL.supplier]: yup.object().nullable(),
    [COSTS_PROJECTS_MODEL.note]: yup
        .string()
        .required(i18next.t('validation:general_form.note_required')),
})

export {
    COSTS_PROJECTS_MODEL,
    DEFAULT_COSTS_PROJECTS_MOCK,
    RequestValidationSchema as validationSchema,
}
