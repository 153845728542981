import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { apiSlice } from '@/services/apiSlice.js'

import financialSlice from '../services/financial/financialSlice.js'
import projectsSlice from '../services/projects/projectsSlice.js'
import settingsSlice from '../services/settings/settingsSlice.js'

import appReducer from './slices/app.slice.js'

const combinedReducer = combineReducers({
    app: appReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    settings: settingsSlice,
    projects: projectsSlice,
    financial: financialSlice,
})

const store = configureStore({
    reducer: combinedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware),
})

export default store
