import styled, { css } from 'styled-components'

export const StyledLi = styled.li`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: 1fr 1fr 70px 20px;
        grid-template-rows: 1fr;
        grid-gap: ${theme.spacing * 2}px;
        padding: ${theme.spacing * 2}px 0;
    `};
`

export const StyledContainerRow = styled.div`
    ${({ theme }) => css`
        padding: ${theme.spacing * 4}px 0;
    `};
`

export const StyledContainerScrollableFields = styled.div`
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
`

export const StyledContainerCloseIcon = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
`
