import styled, { css } from 'styled-components'

export const StyledForm = styled.form`
    ${({ theme, isEdit }) => css`
        grid-template-rows: ${isEdit
            ? `repeat(4, max-content) auto`
            : `repeat(3, max-content) auto `};
        grid-gap: ${theme.spacing * 2}px;
        height: 100%;
    `}
`
