import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useWatch, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
    ClockIcon,
    CurrencyIcon,
    LinkIcon,
    PercentageIcon,
    TrashIcon,
} from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import Datepicker from '@/components/date-picker/DatePicker.jsx'
import Divider from '@/components/divider/Divider.jsx'
import Input from '@/components/input/Input.jsx'
import InputCurrency from '@/components/input-currency/InputCurrency.jsx'
import QuerySelect from '@/components/query-select/QuerySelect.jsx'
import Select from '@/components/select/Select.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import { StyledContainerFormActions } from '@/pages/customers/detail/customer-form/billing-form/styles.js'
import { StyledContainerDelete } from '@/pages/customers/detail/customer-tables/table-projects/projects-form/styles.js'
import CustomersModalWrapper from '@/pages/customers/list/customers-modal/CustomersModalWrapper.jsx'
import {
    DEFAULT_STANDARD_PROJECTS_MOCK,
    STANDARD_PROJECTS_MODEL,
} from '@/pages/projects/list/projects-form/standard-projects-form/standardProjects.schema.js'
import { StyledForm } from '@/pages/projects/list/projects-form/standard-projects-form/styles.js'
import {
    useAddProjectMutation,
    useGetProjectIDQuery,
    useGetProjectsQuery,
    useUpdateProjectMutation,
} from '@/services/projects/projectsApiSlice.js'
import {
    formatDateFromApi,
    handleApiError,
    remapSelectOptions,
} from '@/utilities/api/helpers.js'
import { STATUS_OPTIONS_PROJECTS } from '@/utilities/constants/list.js'
import {
    iterateOverDirtyFields,
    retrieveSingleValueForRs,
    translateOptions,
} from '@/utilities/helpers.js'

const StandardProjectForm = ({
    dataForm,
    isEdit,
    openDialogDelete,
    closeModal,
    successManagers,
    customers,
    teams,
    canNotDelete = false,
}) => {
    const { t } = useTranslation()
    const showError = useError()
    const { triggerAlert } = useAlert()

    const [addProject] = useAddProjectMutation()
    const [updateProject] = useUpdateProjectMutation()

    const { data: data } = useGetProjectIDQuery(
        { ID: dataForm.id },
        { skip: !isEdit }
    )

    const [openFormCustomer, setOpenFormCustomer] = useState(false)

    const {
        register,
        control,
        handleSubmit,
        reset,
        setError,
        setValue,
        watch,
        getValues,
        formState: {
            errors,
            isDirty,
            touchedFields,
            dirtyFields,
            isSubmitting,
        },
    } = useFormContext()

    const onSubmit = async data => {
        try {
            const remappedData = {
                ...data,
                [STANDARD_PROJECTS_MODEL.budget]:
                    Math.round(
                        parseFloat(data[STANDARD_PROJECTS_MODEL.budget]) * 100
                    ) / 100,
                [STANDARD_PROJECTS_MODEL.status]:
                    data[STANDARD_PROJECTS_MODEL.status]?.value,
                [STANDARD_PROJECTS_MODEL.base_price]: parseFloat(
                    data[STANDARD_PROJECTS_MODEL.base_price].replace(',', '.')
                ),
                [STANDARD_PROJECTS_MODEL.success_manager]:
                    data[STANDARD_PROJECTS_MODEL.success_manager]?.value,
                [STANDARD_PROJECTS_MODEL.team]:
                    data[STANDARD_PROJECTS_MODEL.team]?.value,
                [STANDARD_PROJECTS_MODEL.customer]:
                    data[STANDARD_PROJECTS_MODEL.customer]?.value,
                [STANDARD_PROJECTS_MODEL.days]:
                    +data[STANDARD_PROJECTS_MODEL.days],
                [STANDARD_PROJECTS_MODEL.markup]:
                    +data[STANDARD_PROJECTS_MODEL.markup],
            }

            if (isEdit) {
                await updateProject({
                    ID: dataForm.id,
                    data: iterateOverDirtyFields(dirtyFields, remappedData),
                }).unwrap()
            } else {
                await addProject({
                    data: remappedData,
                }).unwrap()
            }
            closeModal()
            reset(DEFAULT_STANDARD_PROJECTS_MOCK)
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: isEdit
                    ? t('commons:toast.updated_success')
                    : t('commons:toast.created_success'),
            })
        } catch (error) {
            handleApiError({
                error,
                handleGeneralError: showError,
                handleFormError: setError,
            })
        }
    }

    const haveSelectedTeam = useWatch({
        control,
        name: STANDARD_PROJECTS_MODEL.team,
    })

    const budgetMarkup = useWatch({
        control,
        name: STANDARD_PROJECTS_MODEL.budget,
    })

    const valueOfDays = useWatch({
        control,
        name: STANDARD_PROJECTS_MODEL.days,
    })

    const rateOfTeam = useMemo(() => {
        if (haveSelectedTeam) {
            return teams.find(team => team.id === haveSelectedTeam.value)?.rate
        }
        return 0
    }, [haveSelectedTeam])

    useEffect(() => {
        if (isEdit) {
            setValue(STANDARD_PROJECTS_MODEL.base_price, data?.team?.rate)
        } else {
            if (rateOfTeam) {
                setValue(STANDARD_PROJECTS_MODEL.base_price, rateOfTeam)
            }
        }
    }, [rateOfTeam])

    const calculateMarkup = useMemo(() => {
        const budget = parseFloat(valueOfDays) * parseFloat(rateOfTeam)
        const markupCalc = (parseFloat(budgetMarkup) / budget - 1) * 100
        return isNaN(markupCalc) ? 0 : Math.floor(markupCalc * 100) / 100
    }, [budgetMarkup, valueOfDays, data, isEdit])

    useEffect(() => {
        setValue(STANDARD_PROJECTS_MODEL.markup, calculateMarkup)
    }, [calculateMarkup])

    useEffect(() => {
        if (isEdit && !!data) {
            reset({
                ...data,
                [STANDARD_PROJECTS_MODEL.customer]: retrieveSingleValueForRs(
                    customers,
                    data?.customer?.id
                ),
                [STANDARD_PROJECTS_MODEL.start_date]: formatDateFromApi(
                    data?.start_date
                ),
                [STANDARD_PROJECTS_MODEL.end_date]: formatDateFromApi(
                    data?.end_date
                ),
                [STANDARD_PROJECTS_MODEL.success_manager]:
                    retrieveSingleValueForRs(
                        successManagers,
                        data?.success_manager?.id
                    ),
                [STANDARD_PROJECTS_MODEL.team]: retrieveSingleValueForRs(
                    remapSelectOptions(teams),
                    data?.team?.id
                ),
                [STANDARD_PROJECTS_MODEL.status]: retrieveSingleValueForRs(
                    translateOptions(STATUS_OPTIONS_PROJECTS),
                    data[STANDARD_PROJECTS_MODEL.status]
                ),
                [STANDARD_PROJECTS_MODEL.markup]: data?.markup,
            })
        }
    }, [data, isEdit])

    const startDate = getValues(STANDARD_PROJECTS_MODEL.start_date)

    const minEndDate = useMemo(() => {
        const date = new Date(startDate)
        date.setDate(date.getDate() + 1)
        return date
    }, [startDate])

    const projectName = useWatch({
        control,
        name: STANDARD_PROJECTS_MODEL.project,
    })

    const { data: projects } = useGetProjectsQuery({
        name: projectName,
    })

    useEffect(() => {
        if (
            projects?.data?.length > 0 &&
            !!dirtyFields[STANDARD_PROJECTS_MODEL.project]
        ) {
            setError(STANDARD_PROJECTS_MODEL.project, {
                type: 'manual',
                message: t('validation:general_form.project_name_exists'),
            })
        }
    }, [projectName, teams])

    return (
        <>
            <div
                style={{
                    zIndex: 1,
                }}
            >
                {openFormCustomer && (
                    <CustomersModalWrapper
                        onClose={() => setOpenFormCustomer(false)}
                        isEdit={false}
                        successManagers={successManagers}
                    />
                )}
            </div>

            <StyledForm
                style={{
                    zIndex: 0,
                }}
                isEdit={isEdit}
                onSubmit={handleSubmit(onSubmit)}
                className="container-fluid p-0 d-grid"
            >
                <div className="row">
                    <div className="col-6">
                        <Input
                            label={t('general.project')}
                            placeholder={t(
                                'projects.fields.placeholder.project'
                            )}
                            required
                            width="100%"
                            size={'large'}
                            touched={
                                touchedFields[STANDARD_PROJECTS_MODEL.project]
                            }
                            errors={errors[STANDARD_PROJECTS_MODEL.project]}
                            {...register(STANDARD_PROJECTS_MODEL.project)}
                        />
                    </div>

                    <div className="col-6">
                        <QuerySelect
                            required
                            name={STANDARD_PROJECTS_MODEL.customer}
                            label={t('projects.fields.labels.customer')}
                            placeholder={t(
                                'projects.fields.placeholder.customer'
                            )}
                            control={control}
                            options={customers}
                            touched={
                                touchedFields[STANDARD_PROJECTS_MODEL.customer]
                            }
                            errors={errors[STANDARD_PROJECTS_MODEL.customer]}
                            size="large"
                            onClickHandler={() => setOpenFormCustomer(true)}
                            cta={t('projects.actions.add_customer')}
                            isOptionDisabled={customers =>
                                !customers?.data?.is_active
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <Divider
                            orientation={'horizontal'}
                            width={'100%'}
                            height={'1px'}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <Datepicker
                            required
                            name={STANDARD_PROJECTS_MODEL.start_date}
                            label={t('projects.fields.labels.start_date')}
                            placeholder={t(
                                'projects.fields.placeholder.start_date'
                            )}
                            control={control}
                            touched={
                                touchedFields[
                                    STANDARD_PROJECTS_MODEL.start_date
                                ]
                            }
                            errors={errors[STANDARD_PROJECTS_MODEL.start_date]}
                            showMonthDropdown
                            showYearDropdown
                            pickerType={'days'}
                            className="mb-2"
                        />
                    </div>

                    <div className="col-6">
                        <Datepicker
                            required
                            name={STANDARD_PROJECTS_MODEL.end_date}
                            label={t('projects.fields.labels.end_date')}
                            placeholder={t(
                                'projects.fields.placeholder.end_date'
                            )}
                            control={control}
                            touched={
                                touchedFields[STANDARD_PROJECTS_MODEL.end_date]
                            }
                            errors={errors[STANDARD_PROJECTS_MODEL.end_date]}
                            showMonthDropdown
                            showYearDropdown
                            pickerType={'days'}
                            className="mb-2"
                            minDate={minEndDate}
                            disabled={!startDate}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <Select
                            required
                            name={STANDARD_PROJECTS_MODEL.status}
                            label={t('general.status')}
                            placeholder={t(
                                'projects.fields.placeholder.select'
                            )}
                            control={control}
                            options={translateOptions(STATUS_OPTIONS_PROJECTS)}
                            touched={
                                touchedFields[STANDARD_PROJECTS_MODEL.status]
                            }
                            errors={errors[STANDARD_PROJECTS_MODEL.status]}
                            size="large"
                        />
                    </div>
                    <div className="col-6">
                        <Select
                            required
                            name={STANDARD_PROJECTS_MODEL.success_manager}
                            label={t('general.success_manager')}
                            placeholder={t(
                                'projects.fields.placeholder.select'
                            )}
                            control={control}
                            options={successManagers}
                            touched={
                                touchedFields[
                                    STANDARD_PROJECTS_MODEL.success_manager
                                ]
                            }
                            errors={
                                errors[STANDARD_PROJECTS_MODEL.success_manager]
                            }
                            size="large"
                            isSearchable
                            isOptionDisabled={options =>
                                !options?.data?.is_active
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <Select
                            required
                            name={STANDARD_PROJECTS_MODEL.team}
                            label={t('general.team')}
                            placeholder={t(
                                'projects.fields.placeholder.select'
                            )}
                            control={control}
                            options={remapSelectOptions(teams)}
                            touched={
                                touchedFields[STANDARD_PROJECTS_MODEL.team]
                            }
                            errors={errors[STANDARD_PROJECTS_MODEL.team]}
                            size="large"
                            isSearchable
                            isOptionDisabled={teams => !teams?.data?.is_active}
                        />
                    </div>

                    <div className="col-6">
                        <InputCurrency
                            label={t('projects.fields.labels.rate')}
                            placeholder={t('projects.fields.placeholder.rate')}
                            required
                            width="100%"
                            size={'large'}
                            touched={
                                touchedFields[
                                    STANDARD_PROJECTS_MODEL.base_price
                                ]
                            }
                            errors={errors[STANDARD_PROJECTS_MODEL.base_price]}
                            control={control}
                            name={STANDARD_PROJECTS_MODEL.base_price}
                            icon={<CurrencyIcon />}
                            iconPosition={'right'}
                            disabled={!haveSelectedTeam}
                            allowDecimals
                            allowNegativeValue={false}
                            inputProps={{
                                value: watch(
                                    STANDARD_PROJECTS_MODEL.base_price
                                ),
                            }}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <Input
                            label={t('projects.fields.labels.days')}
                            placeholder={t('projects.fields.placeholder.days')}
                            required
                            width="100%"
                            size={'large'}
                            type={'number'}
                            touched={
                                touchedFields[STANDARD_PROJECTS_MODEL.days]
                            }
                            errors={errors[STANDARD_PROJECTS_MODEL.days]}
                            {...register(STANDARD_PROJECTS_MODEL.days)}
                            icon={<ClockIcon />}
                            iconPosition={'right'}
                            min={1}
                        />
                    </div>

                    <div className="col-6">
                        <InputCurrency
                            label={t('projects.fields.labels.budget')}
                            placeholder={t('projects.fields.placeholder.rate')}
                            required
                            width="100%"
                            size={'large'}
                            touched={
                                touchedFields[STANDARD_PROJECTS_MODEL.budget]
                            }
                            errors={errors[STANDARD_PROJECTS_MODEL.budget]}
                            control={control}
                            name={STANDARD_PROJECTS_MODEL.budget}
                            icon={<CurrencyIcon />}
                            iconPosition={'right'}
                            allowDecimals
                            allowNegativeValue={false}
                            inputProps={{
                                value: watch(STANDARD_PROJECTS_MODEL.budget),
                            }}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <Input
                            type={'number'}
                            label={t('projects.fields.labels.markup')}
                            placeholder={t('projects.fields.placeholder.rate')}
                            width="100%"
                            size={'large'}
                            touched={
                                touchedFields[STANDARD_PROJECTS_MODEL.markup]
                            }
                            errors={errors[STANDARD_PROJECTS_MODEL.markup]}
                            {...register(STANDARD_PROJECTS_MODEL.markup)}
                            icon={<PercentageIcon />}
                            iconPosition={'right'}
                            disabled
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <Divider
                            orientation={'horizontal'}
                            width={'100%'}
                            height={'1px'}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <Input
                            icon={<LinkIcon />}
                            iconPosition={'right'}
                            label={t(
                                'project.fields.label.link_drive_operation'
                            )}
                            placeholder={t(
                                'project.fields.placeholder.link_drive_operation'
                            )}
                            width="100%"
                            size={'large'}
                            errors={
                                errors[
                                    STANDARD_PROJECTS_MODEL.link_drive_operation
                                ]
                            }
                            {...register(
                                STANDARD_PROJECTS_MODEL.link_drive_operation
                            )}
                        />
                    </div>

                    <div className="col-6">
                        <Input
                            icon={<LinkIcon />}
                            iconPosition={'right'}
                            label={t(
                                'project.fields.label.link_drive_management'
                            )}
                            placeholder={t(
                                'project.fields.placeholder.link_drive_management'
                            )}
                            width="100%"
                            size={'large'}
                            errors={
                                errors[
                                    STANDARD_PROJECTS_MODEL
                                        .link_drive_management
                                ]
                            }
                            {...register(
                                STANDARD_PROJECTS_MODEL.link_drive_management
                            )}
                        />
                    </div>
                </div>

                {isEdit && (
                    <div className="row">
                        {!canNotDelete && (
                            <StyledContainerDelete className="col-12">
                                <TrashIcon />
                                <p
                                    onClick={() => {
                                        openDialogDelete(dataForm?.id)
                                    }}
                                >
                                    {t(`projects.actions.delete_project`)}
                                </p>
                            </StyledContainerDelete>
                        )}
                    </div>
                )}

                <StyledContainerFormActions className="row d-flex">
                    <div className="col-4">
                        <Button
                            size="large"
                            color={'neutral'}
                            isUppercase
                            type={'button'}
                            onClick={() => closeModal(isDirty)}
                        >
                            {t('commons:dialog.error.actions.cancel')}
                        </Button>
                    </div>
                    <div className="col-8">
                        <Button
                            type="submit"
                            size="large"
                            isUppercase
                            disabled={(!isDirty && isEdit) || isSubmitting}
                        >
                            {isEdit
                                ? t(`commons:button.save`)
                                : t('commons:button.create')}
                        </Button>
                    </div>
                </StyledContainerFormActions>
            </StyledForm>
        </>
    )
}

export default StandardProjectForm

StandardProjectForm.propTypes = {
    dataForm: PropTypes.object,
    isEdit: PropTypes.bool,
    openDialogDelete: PropTypes.func,
    closeModal: PropTypes.func,
    successManagers: PropTypes.array,
    customers: PropTypes.array,
    teams: PropTypes.array,
    canNotDelete: PropTypes.bool,
}
