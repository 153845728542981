import PropTypes from 'prop-types'
import { useOutletContext } from 'react-router-dom'

import Table from '@/components/table/index.jsx'
import { columnsRenderersFinalBalance } from '@/pages/projects/list/projects-tables/final-balances-table/columnsFinalBalance.jsx'
import { tableHeight } from '@/utilities/helpers'

const FinalBalanceTables = () => {
    const { props } = useOutletContext()

    const {
        finalBalance,
        tableColumns,
        openDialogDelete,
        openEditSidebar,
        goToDetails,
        goToCustomer,
    } = props

    return (
        <Table
            columns={tableColumns.finalBalance}
            columnsRenderers={columnsRenderersFinalBalance(
                openDialogDelete,
                openEditSidebar,
                goToDetails,
                goToCustomer
            )}
            data={finalBalance}
            isPaginated
            bodyheight={tableHeight(finalBalance)}
        />
    )
}

export default FinalBalanceTables

FinalBalanceTables.propTypes = {
    finalBalance: PropTypes.array,
    tableColumns: PropTypes.object,
    openDialogDelete: PropTypes.func,
    openEditSidebar: PropTypes.func,
    goToDetails: PropTypes.func,
    goToCustomer: PropTypes.func,
}
