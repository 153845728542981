import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import {
    CloseMemberIcon,
    StatusIconError,
    UploadIcon,
} from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import DialogDelete from '@/components/dialog-delete/DialogDelete.jsx'
import Progress from '@/components/progress/Progress.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import {
    StyledBaseContainer,
    StyledCategoryName,
    StyledContainer,
    StyledContainerButtonBase,
    StyledContainerErrorMessage,
    StyledContainerFinancialImport,
    StyledContainerPending,
} from '@/components/upload-container/styles.js'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import {
    useCreateFinancialImportMutation,
    useDeleteFinancialImportMutation,
} from '@/services/financial/financialImportsApiSlice'
import { handleApiError } from '@/utilities/api/helpers.js'

const UploadContainer = ({ item, month, year }) => {
    const { file_name, category, id, url } = item
    const showError = useError()
    const { t } = useTranslation()
    const { triggerAlert } = useAlert()
    const theme = useTheme()

    const [addFinancialImport, { error, isLoading, isSuccess }] =
        useCreateFinancialImportMutation()
    const [deleteImport] = useDeleteFinancialImportMutation()

    const [openDialogToDelete, setOpenDialogToDelete] = useState(false)
    const [file, setFile] = useState(null)
    const [base64String, setBase64String] = useState('')

    useEffect(() => {
        if (file && file?.type && base64String !== '') {
            handleSubmit(file?.type, base64String)
        }
        return
    }, [file, base64String])

    useEffect(() => {
        if (isSuccess) {
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: t('commons:toast.uploaded_success'),
            })
        }
        return
    }, [isSuccess])

    const cleanFile = () => {
        setFile(null)
        setBase64String('')
    }

    const handleFileChange = event => {
        const selectedFile = event.target.files[0]
        setFile(selectedFile)

        const reader = new FileReader()
        reader.onloadend = function () {
            const base64 = reader.result.split(',')[1] // remove "data:file/type;base64,"
            setBase64String(base64)
        }
        reader.readAsDataURL(selectedFile)
    }

    const handleSubmit = async (type, fileBase64) => {
        //type: text/csv or application/pdf
        const parts = type.split('/')
        const mime = parts[1]

        await addFinancialImport({
            data: {
                category: +category,
                file: fileBase64,
                mimeType: mime,
                month: month,
                year: year,
            },
        }).unwrap()
        cleanFile()
    }

    const deleteFile = async () => {
        try {
            await deleteImport({ ID: id }).unwrap()
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: t('commons:dialog.success'),
            })
            cleanFile()
        } catch (error) {
            handleApiError({
                error,
                handleGeneralError: showError,
            })
        } finally {
            setOpenDialogToDelete(false)
        }
    }

    const downloadURL = () => {
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${file_name}`)

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
    }

    const renderFinancialImport = () => {
        switch (true) {
            case file_name !== undefined:
                return (
                    <StyledBaseContainer>
                        <p
                            onClick={() => downloadURL()}
                            style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                            }}
                        >
                            {file_name}
                        </p>
                        <CloseMemberIcon
                            width="18px"
                            height="18px"
                            onClick={() => setOpenDialogToDelete(true)}
                        />
                    </StyledBaseContainer>
                )
            case error?.status !== undefined:
                return (
                    <StyledBaseContainer>
                        <StyledContainerErrorMessage>
                            <StatusIconError fill="none" />
                            <p>{t('finance.upload_error')}</p>
                        </StyledContainerErrorMessage>
                        <input
                            type="file"
                            placeholder={t('finance.upload_file')}
                            accept=".csv"
                            id={`inputField-${category}`}
                            onChange={handleFileChange}
                        />
                        <StyledContainerButtonBase error>
                            <Button
                                size="small"
                                variant="ghost"
                                color="neutral"
                            >
                                <label htmlFor={`inputField-${category}`}>
                                    <UploadIcon />
                                    {t('finance.actions.upload')}
                                </label>
                            </Button>
                        </StyledContainerButtonBase>
                    </StyledBaseContainer>
                )
            case isLoading:
                return (
                    <StyledContainerPending>
                        <p>{file?.name}</p>
                        <Progress
                            width={'100%'}
                            height={6}
                            color={theme.palette.primary.base.active}
                            progressValue={50}
                        />
                    </StyledContainerPending>
                )
            default:
                return (
                    <StyledBaseContainer>
                        {file?.name ? (
                            <p>{file?.name}</p>
                        ) : (
                            <p>{t('finance.upload_file')}</p>
                        )}
                        <input
                            type="file"
                            placeholder={t('finance.upload_file')}
                            accept=".csv"
                            onChange={handleFileChange}
                            id={`inputField-${category}`}
                            style={{ display: 'none' }}
                        />

                        <StyledContainerButtonBase>
                            <Button
                                size="small"
                                variant="ghost"
                                color="neutral"
                            >
                                <label htmlFor={`inputField-${category}`}>
                                    <UploadIcon />
                                    {t('finance.actions.upload')}
                                </label>
                            </Button>
                        </StyledContainerButtonBase>
                    </StyledBaseContainer>
                )
        }
    }

    return (
        <>
            <StyledContainer>
                <StyledCategoryName>{`Categoria ${category}`}</StyledCategoryName>
                <StyledContainerFinancialImport>
                    {renderFinancialImport()}
                </StyledContainerFinancialImport>
            </StyledContainer>

            <DialogDelete
                open={openDialogToDelete}
                onClose={() => setOpenDialogToDelete(false)}
                onDelete={deleteFile}
            />
        </>
    )
}

export default UploadContainer

UploadContainer.propTypes = {
    item: PropTypes.object,
    month: PropTypes.number,
    year: PropTypes.number,
}
