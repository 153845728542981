import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { PlusIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import Divider from '@/components/divider/Divider.jsx'
import Select from '@/components/select/Select.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import { StyledContainerFormActions } from '@/pages/customers/detail/customer-form/billing-form/styles.js'
import AllocationList from '@/pages/projects/detail/detail-final-balance/final-balance-form/allocation-form/AllocationList.jsx'
import {
    ALLOCATION_MODEL,
    DEFAULT_ALLOCATION_MOCK,
} from '@/pages/projects/detail/detail-final-balance/final-balance-form/allocation-form/allocationForm.schema.js'
import {
    StyledContainerRow,
    StyledContainerScrollableFields,
} from '@/pages/projects/detail/detail-final-balance/final-balance-form/allocation-form/styles.js'
import {
    useCreateFinalBalanceAllocationsMutation,
    useGetFinalBalanceAllocationsQuery,
    useUpdateFinalBalanceAllocationMutation,
} from '@/services/projects/finalBalanceApiSlice.js'
import {
    formatDateFromApi,
    handleApiError,
    remapSelectOptions,
} from '@/utilities/api/helpers.js'
import {
    iterateOverDirtyFields,
    retrieveSingleValueForRs,
} from '@/utilities/helpers.js'

const AllocationForm = ({
    closeModal,
    dataForm,
    members,
    finalBalance,
    openDialogDelete,
}) => {
    const { t } = useTranslation()
    const showError = useError()
    const { triggerAlert } = useAlert()
    const user = dataForm?.data

    const {
        control,
        handleSubmit,
        reset,
        formState: {
            errors,
            touchedFields,
            isDirty,
            isSubmitting,
            dirtyFields,
        },
    } = useFormContext()

    const { prepend } = useFieldArray({
        control,
        name: ALLOCATION_MODEL.allocation,
    })
    const [addAllocation] = useCreateFinalBalanceAllocationsMutation()
    const [updateAllocation] = useUpdateFinalBalanceAllocationMutation()

    const { data: allocationData } = useGetFinalBalanceAllocationsQuery({
        projectID: finalBalance.id,
        userID: user?.id,
    })

    const [touchedIndex, setTouchedIndex] = useState(null)
    const onSubmit = async data => {
        try {
            const allocationID =
                data[ALLOCATION_MODEL.allocation][touchedIndex].id

            const dataToSubmit = data?.allocation?.filter(
                (item, index) => index === touchedIndex
            )

            await updateAllocation({
                projectID: finalBalance.id,
                userID: user?.id,
                allocationID: allocationID,
                data: {
                    start_date: dayjs(dataToSubmit[0].start_date).format(
                        'YYYY-MM-DD'
                    ),
                    end_date: dayjs(dataToSubmit[0].end_date).format(
                        'YYYY-MM-DD'
                    ),
                    hours: +dataToSubmit[0].hours,
                },
            }).unwrap()
            closeModal()
        } catch (error) {
            triggerAlert({
                variant: TOAST_STATUSES.FAILURE,
                message: t('commons:toast.something_wrong'),
            })
            handleApiError({ error, handleGeneralError: showError })
        }
    }

    useEffect(() => {
        if (allocationData) {
            const initialValues = {}

            allocationData.forEach((item, index) => {
                initialValues[`${ALLOCATION_MODEL.allocation}[${index}].id`] =
                    item.id
                initialValues[
                    `${ALLOCATION_MODEL.allocation}[${index}].start_date`
                ] = formatDateFromApi(item.start_date)
                initialValues[
                    `${ALLOCATION_MODEL.allocation}[${index}].end_date`
                ] = formatDateFromApi(item.end_date)
                initialValues[
                    `${ALLOCATION_MODEL.allocation}[${index}].hours`
                ] = item.hours
            })

            reset({
                ...initialValues,
                [ALLOCATION_MODEL.name]: retrieveSingleValueForRs(
                    remapSelectOptions(members),
                    user?.id
                ),
            })
        }
    }, [dataForm, allocationData])

    const prependAllocation = async () => {
        prepend(DEFAULT_ALLOCATION_MOCK || {})
        try {
            await addAllocation({
                projectID: finalBalance.id,
                userID: user?.id,
                data: iterateOverDirtyFields(
                    dirtyFields,
                    DEFAULT_ALLOCATION_MOCK
                ),
            }).unwrap()
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        }
    }
    const onInputFocus = index => {
        setTouchedIndex(index)
    }

    const removeAllocation = index => {
        openDialogDelete({
            isOpen: true,
            id: index,
            type: 'allocationDate',
            allocationData: allocationData,
        })
    }

    return (
        <form
            style={{
                height: '100%',
            }}
            onSubmit={handleSubmit(onSubmit)}
            className="container-fluid p-0 d-grid"
        >
            <div className="row">
                <div className="col-12">
                    <Select
                        name={ALLOCATION_MODEL.name}
                        label={t('costs_project.fields.placeholder.supplier')}
                        placeholder={t(
                            'costs_project.fields.placeholder.supplier'
                        )}
                        control={control}
                        options={remapSelectOptions(members)}
                        touched={touchedFields[ALLOCATION_MODEL.name]}
                        errors={errors[ALLOCATION_MODEL.name]}
                        size="large"
                    />
                </div>
                <div className="col-12">
                    <Divider
                        orientation={'horizontal'}
                        width={'100%'}
                        height={'1px'}
                    />
                </div>
            </div>

            <StyledContainerScrollableFields className="container-fluid p-0">
                <div className="row">
                    <div className="col-12">
                        <ul>
                            {allocationData?.length > 0 &&
                                allocationData?.map((item, index) => (
                                    <AllocationList
                                        key={item.id}
                                        fields={item}
                                        index={index}
                                        control={control}
                                        onInputFocus={onInputFocus}
                                        removeAllocation={removeAllocation}
                                    />
                                ))}
                        </ul>
                    </div>
                </div>
                <StyledContainerRow className="row">
                    <div className="col-12">
                        <Button
                            type={'button'}
                            collapsed
                            onClick={prependAllocation}
                        >
                            <PlusIcon />
                        </Button>
                    </div>
                </StyledContainerRow>
            </StyledContainerScrollableFields>

            <StyledContainerFormActions className="row d-flex">
                <div className="col-4">
                    <Button
                        size="large"
                        color={'neutral'}
                        isUppercase
                        type={'button'}
                        onClick={() => closeModal(isDirty)}
                    >
                        {t('commons:dialog.error.actions.cancel')}
                    </Button>
                </div>
                <div className="col-8">
                    <Button
                        type="submit"
                        size="large"
                        isUppercase
                        disabled={!isDirty || isSubmitting} //TODO: is edit
                    >
                        {t(`projects.actions.save_project`)}
                    </Button>
                </div>
            </StyledContainerFormActions>
        </form>
    )
}

export default AllocationForm
AllocationForm.propTypes = {
    closeModal: PropTypes.func,
    dataForm: PropTypes.object,
    members: PropTypes.array,
    finalBalance: PropTypes.object,
    openDialogDelete: PropTypes.func,
}
