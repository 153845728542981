import styled, { css } from 'styled-components'

export const StyledFormAddMembers = styled.form`
    height: 100%;
    grid-template-rows: repeat(2, max-content) auto;
`

export const StyledContainerFormActions = styled.div`
    height: 100%;
    align-items: end;
`

export const StyledContainerMembersName = styled.div`
    ${({ theme }) => css`
        ${theme.typography.paragraph.p14.regular}
        display: flex;
        justify-content: space-between;
        & svg {
            cursor: pointer;
        }
    `}
`

export const StyledContainerMembers = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin-top: ${theme.spacing * 2}px;
        gap: ${theme.spacing * 2}px;
    `}
`

export const StyledContainer = styled.div`
    overflow-y: auto;
    height: 100%;
    max-height: 500px;
    ::-webkit-scrollbar {
        display: none;
    }
`
