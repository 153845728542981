import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import SidePopover from '@/components/side-popover/SidePopover.jsx'
import FinalInvoiceForm from '@/pages/customers/detail/customer-form/final-invoice-form/FinalInvoiceForm.jsx'
import {
    DEFAULT_FINAL_BALANCE_MOCK,
    validationSchema,
} from '@/pages/customers/detail/customer-form/final-invoice-form/finalInvoice.schema.js'

const FinalInvoiceFormWrapper = ({
    dataForm,
    closeModal,
    openDialogDelete,
    isEdit,
    isFinance,
}) => {
    const { t } = useTranslation()

    const methods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'onChange',
        nativeValidation: false,
        shouldFocusError: true,
        resolver: yupResolver(validationSchema),
        defaultValues: DEFAULT_FINAL_BALANCE_MOCK,
    })

    const {
        formState: { isDirty },
    } = methods
    return (
        <FormProvider {...methods}>
            <SidePopover
                onClose={() => closeModal(isDirty)}
                isDirty={isDirty}
                title={
                    isEdit
                        ? t('customer.form.edit_title_final_balance')
                        : t('customer.form.create_title_final_balance')
                }
            >
                <FinalInvoiceForm
                    isEdit={isEdit}
                    closeModal={closeModal}
                    dataForm={dataForm}
                    openDialogDelete={openDialogDelete}
                    isFinance={isFinance}
                />
            </SidePopover>
        </FormProvider>
    )
}

export default FinalInvoiceFormWrapper

FinalInvoiceFormWrapper.propTypes = {
    dataForm: PropTypes.object,
    onClose: PropTypes.func,
    closeModal: PropTypes.func,
    openDialogDelete: PropTypes.func,
    isEdit: PropTypes.bool,
    isFinance: PropTypes.bool,
}
