import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from '@/components/button/Button.jsx'
import Dialog from '@/components/dialog/Dialog.jsx'
import { StyledActionButtonDialog } from '@/components/dialog-delete/styles.js'

const DialogDelete = ({ onDelete, open, onClose }) => {
    const { t } = useTranslation()

    return (
        <Dialog
            open={open}
            title={t('commons:dialog.title')}
            actionsDialog={
                <StyledActionButtonDialog>
                    <Button size={'medium'} color={'danger'} onClick={onDelete}>
                        {t('commons:dialog.error.actions.confirm')}
                    </Button>
                    <Button size={'medium'} color={'neutral'} onClick={onClose}>
                        {t('commons:dialog.error.actions.cancel')}
                    </Button>
                </StyledActionButtonDialog>
            }
        >
            <div>
                <p>{t('commons:dialog.error.message.0')}</p>
                <p>{t('commons:dialog.error.message.1')}</p>
            </div>
        </Dialog>
    )
}

export default DialogDelete

DialogDelete.propTypes = {
    onDelete: PropTypes.func,
    open: PropTypes.bool,
    onClose: PropTypes.func,
}
