import PropTypes from 'prop-types'

import { getInitials, getUniqueColor } from '@/utilities/helpers'

import { StyledAvatar } from './styles'

const colorSet = [
    '#FFAB91',
    '#80DEEA',
    '#EF9A9A',
    '#CE93D8',
    '#AED581',
    '#9FA7DF',
    '#BCAAA4',
    '#FFE082',
    '#F48FB1',
    '#DCE775',
]

const UserInitialsAvatar = ({ username, id, size }) => {
    const uniqueColor = getUniqueColor(id, colorSet)
    const initials = getInitials(username).slice(0, 2)

    return (
        <StyledAvatar uniquecolor={uniqueColor} size={size}>
            <span>{initials}</span>
        </StyledAvatar>
    )
}

export default UserInitialsAvatar

UserInitialsAvatar.propTypes = {
    size: PropTypes.number,
    id: PropTypes.number,
    username: PropTypes.string,
}
