import i18next from 'i18next'

import EditCellRow from '@/components/edit-cell-row/EditCellRow.jsx'
import { COLUMNS_TEAMS_NAMES } from '@/pages/settings/tables-settings/columnsTeams.jsx'
import { useUpdatePayrollMutation } from '@/services/financial/financeApiSlice.js'
import { useGetTeamsQuery } from '@/services/settings/settingApiSlice.js'
import { remapSelectOptions } from '@/utilities/api/helpers'
import { EDIT_CELL_TYPE } from '@/utilities/constants/commons'

/* eslint-disable */
export const COLUMNS_PAYROLLS_NAMES = {
    id: 'id',
    name: 'name',
    team: 'team',
    typology: 'typology',
    cost: 'cost',
    month: 'month',
    year: 'year',
    actions: 'actions',
}

export const columnsPayrolls = () => {
    return [
        {
            alignment: 'left',
            label: i18next.t('general.id'),
            name: COLUMNS_TEAMS_NAMES.id,
            width: '5%',
            sortable: false,
            divider: false,
        },
        {
            alignment: 'left',
            label: i18next.t('general.name'),
            name: COLUMNS_PAYROLLS_NAMES.name,
            sortable: false,
            width: '30%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.team'),
            name: COLUMNS_PAYROLLS_NAMES.team,
            sortable: false,
            width: '30%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.typology'),
            name: COLUMNS_PAYROLLS_NAMES.typology,
            sortable: false,
            width: '15%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('finance.table.cost'),
            name: COLUMNS_PAYROLLS_NAMES.cost,
            sortable: false,
            width: '10%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('finance.table.month'),
            name: COLUMNS_PAYROLLS_NAMES.month,
            sortable: false,
            width: '5%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('finance.table.year'),
            name: COLUMNS_PAYROLLS_NAMES.year,
            sortable: false,
            width: '5%',
            divider: true,
        },
        {
            alignment: 'left',
            label: '',
            name: 'actions',
            sortable: false,
            width: '0%',
            divider: false,
        },
    ]
}

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersPayrolls = () => ({
    [COLUMNS_PAYROLLS_NAMES.id]: ({ id }) => {
        return <p>#{id}</p>
    },
    [COLUMNS_PAYROLLS_NAMES.name]: ({ name }) => {
        return <p>{name}</p>
    },
    [COLUMNS_PAYROLLS_NAMES.team]: ({ team, id, project }) => {
        const [updatePayroll] = useUpdatePayrollMutation()

        const handleBlur = e => {
            updatePayroll({
                id: project?.id,
                salID: id,
                data: {
                    team_id: e,
                },
            })
        }

        const { data: teams } = useGetTeamsQuery({})

        return (
            <>
                {team ? (
                    <EditCellRow
                        optionsData={remapSelectOptions(teams?.data)}
                        handleBlur={handleBlur}
                        data={`#${team?.id} - ${team?.name}`}
                        type={EDIT_CELL_TYPE.select}
                        lastPage={teams?.pagination?.lastPage}
                        currentPage={teams?.pagination?.currentPage}
                    />
                ) : (
                    <p>{'-'}</p>
                )}
            </>
        )
    },
    [COLUMNS_PAYROLLS_NAMES.typology]: ({
        user_contract_type,
        id,
        project,
    }) => {
        const [updatePayroll] = useUpdatePayrollMutation()

        const handleBlur = e => {
            updatePayroll({
                id: project?.id,
                salID: id,
                data: {
                    contract_id: e,
                },
            })
        }

        return (
            <>
                {user_contract_type ? (
                    <EditCellRow
                        optionsData={remapSelectOptions(teams?.data)}
                        handleBlur={handleBlur}
                        data={`#${user_contract_type?.id} - ${user_contract_type?.name}`}
                        type={EDIT_CELL_TYPE.select}
                        lastPage={user_contract_type?.pagination?.lastPage}
                        currentPage={
                            user_contract_type?.pagination?.currentPage
                        }
                    />
                ) : (
                    <p>{'-'}</p>
                )}
            </>
        )
    },
    [COLUMNS_PAYROLLS_NAMES.cost]: ({ total, id, project }) => {
        const [updatePayroll] = useUpdatePayrollMutation()

        const handleBlur = e => {
            updatePayroll({
                id: project?.id,
                salID: id,
                data: {
                    total: e,
                },
            })
        }
        return (
            <EditCellRow
                handleBlur={handleBlur}
                data={total ? total : '-'}
                type={EDIT_CELL_TYPE.currency}
            />
        )
    },
    [COLUMNS_PAYROLLS_NAMES.month]: ({ month }) => {
        return <p>{month}</p>
    },
    [COLUMNS_PAYROLLS_NAMES.year]: ({ year }) => {
        return <p>{year}</p>
    },
    [COLUMNS_PAYROLLS_NAMES.actions]: () => {
        return <p>{''}</p>
    },
})
