import styled, { css } from 'styled-components'

import { ArrowForTable } from '@/assets/icons/icons.jsx'

export const StyledHeader = styled.div`
    ${({ numMonths }) => css`
        display: grid;
        grid-template-columns: 210px 24px repeat(${numMonths}, 130px) 24px;
        height: 55px;
        align-items: center;

        & svg {
            cursor: pointer;
        }
    `}
`
export const StyledYearHeaderCell = styled.div`
    ${({ theme }) => css`
        ${theme.typography.paragraph.p16.bold};
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${theme.spacing}px;
    `}
`

export const StyledHeaderCellMonth = styled.div`
    ${({ isTheCurrentMonth }) => css`
        width: 128px;
        text-align: center;
        vertical-align: middle;
        font-weight: ${isTheCurrentMonth ? 800 : 600};
    `}
`

export const StyledArrowForTable = styled(ArrowForTable)`
    ${({ theme, isCurrentYear, canRotate }) => css`
        cursor: ${isCurrentYear ? 'not-allowed' : 'pointer'};
        transform: ${canRotate && 'rotate(180deg)'};

        & path {
            fill: ${isCurrentYear
                ? theme.palette.neutral.base[200]
                : theme.palette.neutral.base[1000]};
        }
    `}
`

export const StyledRow = styled.div`
    ${({ theme, isOpen, numMonths, lastCellIndex }) => css`
        display: grid;
        grid-template-columns: 210px repeat(${numMonths}, 134px) auto;
        ${theme.typography.paragraph.p14.medium};
        background: ${theme.palette.secondary.base.background};
        height: 40px;
        max-width: fit-content;

        & svg {
            transform: rotate(270deg);
            transition: transform 0.3s ease-in-out;
            cursor: pointer;
            ${isOpen &&
            css`
                transform: rotate(360deg);
            `}
        }

        & > :nth-child(${lastCellIndex + 2})::after {
            content: none;
            max-width: 134px;
        }
    `}
`

export const StyledCell = styled.div`
    ${({ theme, isFirst, isSubRow, isSecondRow }) => css`
        display: flex;
        align-items: center;
        padding: 8px;
        height: 40px;
        position: relative;

        ${isFirst &&
        css`
            justify-content: space-between;
        `}
        ${isSubRow &&
        css`
            ${theme.typography.paragraph.p14.regular};
        `}
        &::after {
            content: '';
            width: 1px;
            height: 22px;
            background-color: ${theme.palette.neutral.base[300]};
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }

        ${isSecondRow &&
        css`
            display: flex;
            justify-content: center;
            align-items: center;
        `}
    `}
`

export const StyledContainerChildRow = styled.div`
    ${({ theme, numMonths, lastCellIndex }) => css`
        display: grid;
        grid-template-columns: 210px repeat(${numMonths}, 134px) auto;
        height: 40px;
        border-bottom: 1px solid ${theme.palette.neutral.base[200]};
        max-width: fit-content;

        & > :nth-child(${lastCellIndex + 2})::after {
            content: none;
        }
    `}
`

export const StyledOpenSubRowContainer = styled.div`
    margin-bottom: 16px;
`

export const StyledSecondRow = styled.div`
    ${({ theme, allPaidOrUtt }) => {
        const fill = allPaidOrUtt
            ? theme.palette.success.base.active
            : theme.palette.warning.base.active

        return css`
            display: flex;
            justify-content: center;
            align-items: center;
            gap: ${theme.spacing + 2}px;

            & svg {
                & path {
                    fill: ${fill};
                }
            }
        `
    }}
`
