export const shadows = {
    //tooltip for progress bar and dropdown menu
    10: ' 0px 0px 4px rgba(0, 0, 0, 0.25)',
    //primary input
    20: '0px 0px 0px 2px rgba(127, 99, 244, 0.28)',
    //neutral
    30: '0px 3px 6px -4px rgba(0, 0, 0, 0.08), 0px 6px 16px rgba(0, 0, 0, 0.06)',
    //modal and tooltip dialog
    40: '0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
    50: '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)',
    60: '0px 2px 0px 0px rgba(0, 0, 0, 0.02)',
    90: '-2px 0px 4px rgba(0, 0, 0, 0.05)',
}
