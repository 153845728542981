import { createGlobalStyle, css } from 'styled-components'
import { withTheme } from 'styled-components'

const Reset = createGlobalStyle`
    ${({ theme }) => {
        const { palette } = theme

        return css`
            * {
                -ms-overflow-style: none;
                scrollbar-width: none;
            }
            html,
            body,
            div,
            span,
            applet,
            object,
            iframe,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p,
            blockquote,
            pre,
            a,
            abbr,
            acronym,
            address,
            big,
            cite,
            code,
            del,
            dfn,
            em,
            img,
            ins,
            kbd,
            q,
            s,
            samp,
            small,
            strike,
            strong,
            sub,
            sup,
            tt,
            var,
            b,
            u,
            i,
            center,
            dl,
            dt,
            dd,
            ol,
            ul,
            li,
            fieldset,
            form,
            label,
            legend,
            table,
            caption,
            tbody,
            tfoot,
            thead,
            tr,
            th,
            td,
            article,
            aside,
            canvas,
            details,
            embed,
            figure,
            figcaption,
            footer,
            header,
            hgroup,
            menu,
            nav,
            output,
            ruby,
            section,
            summary,
            time,
            mark,
            audio,
            video {
                margin: 0;
                padding: 0;
                border: 0;
                font-size: 100%;
                vertical-align: baseline;
            }

            /* HTML5 display-role reset for older browsers */

            article,
            aside,
            details,
            figcaption,
            figure,
            footer,
            header,
            hgroup,
            menu,
            nav,
            section {
                display: block;
            }

            ol,
            ul {
                list-style: none;
            }

            blockquote,
            q {
                quotes: none;
            }

            blockquote:before,
            blockquote:after,
            q:before,
            q:after {
                content: none;
            }

            table {
                border-collapse: collapse;
                border-spacing: 0;
            }

            input,
            textarea,
            select,
            button {
                font-family: inherit;
            }

            svg[viewBox='0 0 24 24']:not([data-color]) {
                fill: ${palette.neutral.base[1000]};
            }

            textarea {
                overflow-wrap: break-word;
                white-space: revert;
            }

            form {
                text-align: left;
            }

            *:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
                all: unset;
                display: revert;
            }

            *,
            *::before,
            *::after {
                box-sizing: border-box;
            }

            img {
                max-width: 100%;
            }
        `
    }}
`

export default withTheme(Reset)
