import styled, { css } from 'styled-components'

export const StyledContainerStageStatus = styled.div`
    ${({ theme, onClick }) => css`
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        gap: ${theme.spacing * 2}px;

        ${onClick &&
        css`
            cursor: pointer;
        `}
        & svg {
            cursor: default;
            flex: none;
        }
    `}
`
