import { createSlice } from '@reduxjs/toolkit'

const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        panelId: undefined,
    },
    reducers: {
        setPanelId: (state, action) => {
            state.panelId = action.payload
        },
    },
})

export const { setPanelId } = settingsSlice.actions

export const selectPanelId = state => state?.settings?.panelId

export default settingsSlice.reducer
