import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import SidePopover from '@/components/side-popover/SidePopover.jsx'
import AllocationForm from '@/pages/projects/detail/detail-final-balance/final-balance-form/allocation-form/AllocationForm.jsx'
import {
    validationSchema,
    DEFAULT_ALLOCATION_MOCK,
} from '@/pages/projects/detail/detail-final-balance/final-balance-form/allocation-form/allocationForm.schema.js'

const AllocationFormWrapper = ({
    dataForm,
    setIsLoading,
    openDialogDelete,
    closeModal,
    members,
    finalBalance,
}) => {
    const { t } = useTranslation()

    const methods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'onChange',
        nativeValidation: false,
        shouldFocusError: true,
        resolver: yupResolver(validationSchema),
        defaultValues: DEFAULT_ALLOCATION_MOCK,
    })

    const {
        formState: { isDirty },
    } = methods

    return (
        <FormProvider {...methods}>
            <SidePopover
                style={{ zIndex: 10000 }}
                onClose={() => closeModal(isDirty)}
                isDirty={isDirty}
                title={t(`logs.table.edit_action`)}
            >
                <AllocationForm
                    dataForm={dataForm}
                    isEdit={dataForm?.isEdit}
                    openDialogDelete={openDialogDelete}
                    closeModal={closeModal}
                    setIsLoading={setIsLoading}
                    members={members}
                    finalBalance={finalBalance}
                />
            </SidePopover>
        </FormProvider>
    )
}

export default AllocationFormWrapper

AllocationFormWrapper.propTypes = {
    onClose: PropTypes.func,
    dataForm: PropTypes.object,
    setIsLoading: PropTypes.func,
    openDialogDelete: PropTypes.func,
    closeModal: PropTypes.func,
    members: PropTypes.array,
    finalBalance: PropTypes.object,
}
