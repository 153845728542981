/* eslint-disable no-extra-boolean-cast */
import DatePicker from 'react-datepicker'
import styled, { css } from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'

export const StyledDatePicker = styled(DatePicker)`
    ${({ theme, disabled }) => css`
        padding: ${theme.spacing}px;
        border: 1px solid ${theme.palette.neutral.base[300]};
        border-radius: ${theme.spacing - 2}px;

        &:hover {
            box-shadow: ${disabled ? 'none' : theme.shadows[20]};
            border: 1px solid ${theme.palette.primary.base.active};
        }
    `}
`
export const StyledStatusIcons = styled.span`
    ${({ theme }) => css`
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        gap: ${theme.spacing / 2}px;
        left: unset;
        right: ${theme.spacing * 2}px;
    `}
`

export const StyledIcon = styled.span`
    ${({ theme, errors, touched, disabled }) => css`
        display: flex;
        align-items: center;
        justify-items: center;
        & svg {
            width: 12px;
            height: 12px;
            ${!!errors
                ? css`
                      cursor: initial;
                  `
                : touched &&
                  css`
                      cursor: initial;
                      fill: ${theme.palette.success.base.text};
                  `};
            & g {
                fill: ${disabled
                    ? theme.palette.neutral.base[200]
                    : theme.palette.neutral.base[1000]};
            }
        }
    `}
`

export const StyledErrorMessage = styled.small`
    ${({ theme }) => css`
        color: ${theme.palette.danger.base.text};
    `}
`

export const StyledIcons = styled.span`
    ${({ theme, iconPosition }) => css`
        position: absolute;
        top: 50%;
        ${iconPosition === 'left'
            ? css`
                  left: ${theme.spacing * 2}px;
              `
            : css`
                  right: ${theme.spacing * 2}px;
              `};
        transform: translateY(-50%);
        display: flex;
        gap: ${theme.spacing / 2}px;
    `}
`

export const StyledInputWrapper = styled.div`
    ${({
        theme,
        iconPosition,
        touched,
        rounded,
        hasError,
        isSuccess,
        disabled,
        isOnlyMonth,
    }) => css`
    position: relative;
    width: 100%;
    /*min-width: 250px;*/


    & input {
        height: ${isOnlyMonth ? '28px' : '40px'};
        width: 100%;
        border-radius: 5px;
        padding-left: ${
            iconPosition === 'left'
                ? `${theme.spacing * 6}px`
                : `${theme.spacing * 2}px`
        };
        padding-right: ${
            touched ? `${theme.spacing * 5}px` : `${theme.spacing * 6}px`
        };
        ${
            rounded &&
            css`
                border-radius: 100px;
            `
        };
        ${
            hasError &&
            css`
                border-color: ${theme.palette.danger.base.text} !important;
            `
        };
        ${
            isSuccess &&
            css`
                border-color: ${theme.palette.success.base.text} !important;
            `
        };
          ${
              disabled &&
              css`
                  border: 1px solid ${theme.palette.neutral.base[200]};
                  cursor: not-allowed;
                  color: ${theme.palette.neutral.base[200]};
              `
          };
        &:hover {
          border-color: ${
              disabled
                  ? theme.palette.primary.base.text
                  : theme.palette.neutral.base[200]
          }
        }
        &:focus-within {
            ${
                !hasError &&
                css`
                    box-shadow: ${theme.shadows[20]};
                    border: 1px solid ${theme.palette.primary.base.active};
                `
            }
        }
    },
`}
`

export const StyledLabel = styled.label`
    ${({ theme, disabled }) => css`
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: 1fr auto;
        margin-bottom: ${theme.spacing}px;
        ${theme.typography.paragraph.p14.medium};
        color: ${theme.palette.neutral.base[500]};
        ${disabled &&
        css`
            color: ${theme.palette.neutral.base[200]};
        `}
    `}
`

export const StyledRoot = styled.div`
    ${({ theme, pickerType, disabled, outOfDate }) => css`
        position: relative;
        width: 100%;
        & .react-datepicker-popper {
            z-index: 2;
            inset: auto !important;
            transform: none !important;
            max-width: 282px;
            width: auto;
        }
        & .react-datepicker__input-container {
            ${theme.typography.paragraph.p14.regular};
            > input {
                border-color: ${theme.palette.neutral.base[200]};

                color: ${
                    outOfDate
                        ? theme.palette.danger.base.active
                        : theme.palette.neutral.base[500]
                };
                &:hover {
                    border-color: ${
                        disabled
                            ? theme.palette.primary.base.text
                            : theme.palette.neutral.base[200]
                    }
                }
            }
        }


        & .react-datepicker__year-wrapper{
            display: flow;
        }


        & .react-datepicker__close-icon {
            padding: 0 15px 0 0;
            z-index: 2;
        }
        & .react-datepicker__close-icon::after {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${theme.palette.neutral.base[1000]};
            height: 12px;
            width: 12px;
            font-size: 10px;
            padding: ${theme.spacing}px;
        }
        & .react-datepicker {
            font-family: inherit;
            background-color: ${theme.palette.neutral.base[0]};
            color: ${theme.palette.neutral.base[1000]};
            border: 1px solid ${theme.palette.neutral.base[200]};
            border-radius: 5px;
            display: flex;
            flex-flow: column;
            text-transform: capitalize;
            & .react-datepicker__day--selected,
            .react-datepicker__day--in-selecting-range,
            .react-datepicker__day--in-range,
            .react-datepicker__month-text--selected,
            .react-datepicker__month-text--in-selecting-range,
            .react-datepicker__month-text--in-range,
            .react-datepicker__quarter-text--selected,
            .react-datepicker__quarter-text--in-selecting-range,
            .react-datepicker__quarter-text--in-range,
            .react-datepicker__year-text--selected,
            .react-datepicker__year-text--in-selecting-range,
            .react-datepicker__year-text--in-range {
                background-color: ${
                    theme.palette.primary.base.active
                }!important;
                color: ${theme.palette.primary.base.text};
            }
            &
                .react-datepicker__time-container
                .react-datepicker__time
                .react-datepicker__time-box
                ul.react-datepicker__time-list
                li.react-datepicker__time-list-item--selected {
                background-color: ${theme.palette.primary.base.background};
                color: ${theme.palette.primary.base.text};
            }
            & .react-datepicker__day--keyboard-selected,
            .react-datepicker__month-text--keyboard-selected,
            .react-datepicker__quarter-text--keyboard-selected,
            .react-datepicker__year-text--keyboard-selected {
                background-color: ${theme.palette.neutral.base[0]};
                color: ${theme.palette.neutral.base[1000]} !important;
            }
            & .react-datepicker__header {
                background-color: ${theme.palette.primary.base.background};
                color: ${theme.palette.primary.base.text};
                border-radius: 5px;
            }
            & .react-datepicker__current-month,
            .react-datepicker-time__header,
            .react-datepicker-year-header {
                ${theme.typography.paragraph.p14.regular};
                display: ${pickerType === 'days' ? 'none' : 'inherit'};
                background-color: ${theme.palette.neutral.base[0]};
                color: ${theme.palette.neutral.base[1000]};
                min-height: 24px;
            }

            & .react-datepicker__year-read-view--down-arrow,
            .react-datepicker__month-read-view--down-arrow,
            .react-datepicker__month-year-read-view--down-arrow,
            .react-datepicker__navigation-icon::before {
                border-color: ${theme.palette.neutral.base[500]};
                border-width: 2px 2px 0 0;
                width: 7px;
                height: 7px;
            }
            & .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
                border-width: 1px 1px 0 0;
                top: calc(50% - 3px)!important;
                right: -4px;
                width: 5px;
                height: 5px;
        }
            & .react-datepicker__navigation {
                top: 6px;
            }
            & .react-datepicker__month-dropdown-container, .react-datepicker__month-dropdown-container--scroll {
                margin: 0!important;
            }
            & .react-datepicker__year-dropdown-container, .react-datepicker__year-dropdown-container--scroll {
                margin: 0!important;
            }
            & .react-datepicker__month-read-view,
            .react-datepicker__year-read-view {
                visibility: visible !important;
                width: fit-content;
               padding-right: ${theme.spacing}px;
               text-align: left;
            }
            & .react-datepicker__year-read-view {
            margin-left: ${theme.spacing * 3}px;
            }
            &
            & .react-datepicker__day-names, .react-datepicker__week, .react-datepicker__month-wrapper {
                display: flex;
                justify-content: space-between;
                padding: ${theme.spacing / 2}px ${theme.spacing}px;
            }
            & .react-datepicker__day-names {
                display: flex;
                justify-content: space-between;
                padding: 0 ${theme.spacing * 3}px;
                padding-top: ${theme.spacing}px;
            }
            & .react-datepicker__day-name {
                ${theme.typography.paragraph.p12.bold};
                color: ${theme.palette.neutral.base[300]}!important;
            }
            & .react-datepicker__month {
                margin: ${theme.spacing * 0}px;
                padding: ${theme.spacing / 2}px;
                ${theme.typography.paragraph.p14.regular};
            }
            .react-datepicker__header__dropdown--scroll, .react-datepicker-year-header {
                ${theme.typography.paragraph.p14.medium};
                color: ${theme.palette.neutral.base[1000]}!important;
                display: flex;
                justify-content: center;
                gap: ${theme.spacing}px;
                align-items: center;
                padding-bottom: ${theme.spacing * 2}px;
                border-bottom: 1px solid ${theme.palette.neutral.base[100]};
            }
            & .react-datepicker__week {
               padding: ${theme.spacing / 2};
            }
            & .react-datepicker__day {
                ${theme.typography.paragraph.p12.regular};
                width: 32px;
                height: 24px;
             border-radius: 2px;
            margin: ${theme.spacing * 0};
            display: flex;
            align-items: center;
            justify-content: center;
            }
            & .react-datepicker__month-text {

                padding: ${theme.spacing / 2}px;
             border-radius: 5px;
            }
            & .react-datepicker__day--today {

                border: 1px solid ${theme.palette.primary.base.active}
            }
        &
            .react-datepicker-popper[data-placement^='top']
            .react-datepicker__triangle::before,
        .react-datepicker-popper[data-placement^='bottom']
            .react-datepicker__triangle::before,
        .react-datepicker-popper[data-placement^='top']
            .react-datepicker__triangle::after,
        .react-datepicker-popper[data-placement^='bottom']
            .react-datepicker__triangle::after {
            border-bottom-color: ${
                theme.palette.primary.base.background
            } !important;
        }
        & .react-datepicker__triangle {
            display: none;
        }
        & .react-datepicker__day--outside-month {
            color: ${theme.palette.neutral.base[100]}!important
        }
        & .react-datepicker__header {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0 !important;
            border-bottom: none;
            padding: ${theme.spacing * 2}px 0px;
            color: ${theme.palette.primary.base.text};
            background-color: ${theme.palette.neutral.base[0]}!important;
        }
        & .react-datepicker__header--has-time-select {
            border-top-right-radius: 0;
        }
        & .react-datepicker__navigation--years {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

& .react-datepicker__navigation {
    height: 43px;
    width: 35px;
}

& .react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

& .react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}
& .react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
    background-color: ${theme.palette.neutral.base[0]};
    color: ${theme.palette.neutral.base[1000]};
    padding: ${theme.spacing / 2}px;
}
& .react-datepicker__month-option--selected_month, .react-datepicker__year-option--selected_year {
    background-color: ${theme.palette.secondary.base.active};
    color: ${theme.palette.neutral.base[0]};
    & .react-datepicker__month-option--selected, .react-datepicker__year-option--selected {
        display: none;
    }
}
& .react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
    background-color: ${theme.palette.secondary.base.background};
    color: ${theme.palette.neutral.base[1000]};
}
& .react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
    border-radius: 5px;
    ${theme.typography.paragraph.p14.regular};
}
& .react-datepicker__navigation--next {
    & .react-datepicker__navigation-icon--next {
        background: url('/src/components/date-picker/images/next.svg') no-repeat;
        width: 10px;
    height: 10px;
    &::before {
 display: none;
    }
    }
}
& .react-datepicker__navigation--previous {
    & .react-datepicker__navigation-icon--previous {
        background: url('/src/components/date-picker/images/previous.svg') no-repeat;
        width: 10px;
    height: 10px;
    &::before {
 display: none;
    }
    }
}
    `}
`
