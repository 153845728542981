import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import SidePopover from '@/components/side-popover/SidePopover.jsx'
import CostsForm from '@/pages/projects/detail/detail-standard-projects/project-form/costs-form/CostsForm.jsx'
import {
    validationSchema,
    DEFAULT_COSTS_PROJECTS_MOCK,
} from '@/pages/projects/detail/detail-standard-projects/project-form/costs-form/costsForm.schema.js'

const CostsFormWrapper = ({
    dataForm,
    closeModal,
    openDialogDelete,
    isEdit,
    sals,
    suppliers,
    isFinance,
    isCustomer,
}) => {
    const { t } = useTranslation()

    const methods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'onChange',
        nativeValidation: false,
        shouldFocusError: true,
        resolver: yupResolver(validationSchema),
        defaultValues: DEFAULT_COSTS_PROJECTS_MOCK,
    })

    const {
        formState: { isDirty },
    } = methods

    return (
        <FormProvider {...methods}>
            <SidePopover
                onClose={() => closeModal(isDirty)}
                isDirty={isDirty}
                title={
                    isEdit
                        ? t(`costs_project.edit_title`)
                        : t(`costs_project.create_title`)
                }
            >
                <CostsForm
                    isEdit={isEdit}
                    closeModal={closeModal}
                    dataForm={dataForm}
                    openDialogDelete={openDialogDelete}
                    sals={sals}
                    suppliers={suppliers}
                    isFinance={isFinance}
                    isCustomer={isCustomer}
                />
            </SidePopover>
        </FormProvider>
    )
}

export default CostsFormWrapper

CostsFormWrapper.propTypes = {
    dataForm: PropTypes.object,
    onClose: PropTypes.func,
    closeModal: PropTypes.func,
    openDialogDelete: PropTypes.func,
    isEdit: PropTypes.bool,
    sals: PropTypes.array,
    suppliers: PropTypes.array,
    isFinance: PropTypes.bool,
    isCustomer: PropTypes.bool,
}
