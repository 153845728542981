import i18next from 'i18next'

import { dateFormat, translatedEnum } from '@/utilities/helpers'

export const memberFields = data => {
    return {
        personal_data: [
            {
                label: i18next.t('general.id'),
                value: `#${data?.id}`,
                width: 100,
            },
            {
                label: i18next.t('general.name'),
                value: data?.first_name,
                width: 50,
            },
            {
                label: i18next.t('general.surname'),
                value: data?.last_name,
                width: 50,
            },
            {
                label: i18next.t('general.birth_date'),
                value: dateFormat(data?.birth_date),
                width: 50,
            },
            {
                label: i18next.t('settings.sidebar_left.labels.phone_number'),
                value: data?.phone_number,
                width: 50,
            },
            {
                label: i18next.t('general.personal_email'),
                value: data?.personal_email,
                width: 100,
                link: true,
            },
        ],
        member_specifications: [
            {
                label: i18next.t('general.status'),
                value: data?.is_active
                    ? i18next.t('commons:status.active')
                    : i18next.t('commons:status.inactive'),
                width: 100,
                link: false,
            },
            {
                label: i18next.t('general.can_access'),
                value: data?.can_access
                    ? i18next.t('commons:access.yes')
                    : i18next.t('commons:access.no'),
                width: 50,
            },
            {
                label: i18next.t('general.role'),
                value: translatedEnum(data?.role, 'role'),
                width: 50,
            },
            {
                label: i18next.t('general.success_manager'),
                value: data?.is_success_manager
                    ? i18next.t('commons:access.yes')
                    : i18next.t('commons:access.no'),
                width: 50,
            },
            {
                label: i18next.t('settings.sidebar_left.labels.responsibility'),
                value: translatedEnum(
                    data?.responsibility?.key_name,
                    'responsibility'
                ),
                width: 50,
            },
            {
                label: i18next.t('general.scope'),
                value: translatedEnum(data?.scope?.key_name, 'scope'),
                width: 50,
            },
            {
                label: i18next.t('general.specificity'),
                value: data?.specificities
                    ?.map(specificity => specificity?.key_name)
                    .filter(keyName => keyName)
                    .join(', '),
                width: 50,
            },
            {
                label: i18next.t('general.work_email'),
                value: data?.work_email,
                width: 100,
                link: true,
            },
        ],
    }
}
