import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import { EditIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import SectionItem from '@/components/sidebar-tab-item/section-item/SectionItem'
import SectionTitle from '@/components/sidebar-tab-item/section-title/SectionTitle'
import {
    StyledSliderSectionContainer,
    StyledTabInfoContainer,
    StyledSidebarSubHeader,
    StyledTabCtaContainer,
    StyledTabNavLink,
    StyledUsersInfoContainer,
    StyledSingleItem,
    StyledNoOptionsMessage,
} from '@/components/sidebar-tab-item/styles'
import Spinner from '@/components/spinner/Spinner'
import { useGetMemberByIdQuery } from '@/services/settings/settingApiSlice.js'
import { setPanelId, selectPanelId } from '@/services/settings/settingsSlice'
import {
    TYPE_USERS,
    ROUTE_SETTINGS,
    ROUTE_SETTINGS_TEAMS,
} from '@/utilities/constants'

import { memberFields } from './memberFields'

const MemberSidebar = ({ setOpenForm }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const dispatch = useDispatch()

    const panelId = useSelector(selectPanelId)

    const { data: fetchedMember, isFetching } = useGetMemberByIdQuery(
        { ID: panelId },
        { skip: !panelId }
    )

    if (!fetchedMember) {
        return null
    }

    const member = memberFields(fetchedMember)

    if (isFetching) {
        return <Spinner overlay={false} overlayFullscreen={false} />
    }

    return (
        <>
            <StyledSidebarSubHeader>
                {<h3>{fetchedMember?.full_name}</h3>}
                <StyledTabCtaContainer>
                    <Button
                        onClick={() =>
                            setOpenForm({
                                isOpen: true,
                                typeForm: TYPE_USERS.members,
                                id: fetchedMember.id,
                                isEdit: true,
                            })
                        }
                        collapsed
                    >
                        <EditIcon
                            width={10}
                            height={10}
                            fill={theme.palette.neutral.base[0]}
                        />
                    </Button>
                </StyledTabCtaContainer>
            </StyledSidebarSubHeader>
            <StyledSliderSectionContainer>
                <SectionTitle
                    title={t('settings.sidebar_left.subtitles.personal_data')}
                />
                <StyledTabInfoContainer>
                    {member.personal_data.map((item, index) => {
                        return (
                            <SectionItem
                                label={item.label}
                                value={item.value}
                                width={item.width}
                                link={item.link}
                                key={index}
                            />
                        )
                    })}
                </StyledTabInfoContainer>
            </StyledSliderSectionContainer>

            <StyledSliderSectionContainer>
                <SectionTitle
                    title={t(
                        'settings.sidebar_left.subtitles.specifications_member'
                    )}
                />

                <StyledTabInfoContainer>
                    {member.member_specifications.map((item, index) => {
                        return (
                            <SectionItem
                                label={item.label}
                                value={item.value}
                                width={item.width}
                                link={item.link}
                                key={index}
                            />
                        )
                    })}
                </StyledTabInfoContainer>
            </StyledSliderSectionContainer>
            <StyledSliderSectionContainer>
                <SectionTitle
                    title={t('settings.sidebar_left.subtitles.my_teams')}
                />

                <StyledUsersInfoContainer>
                    {fetchedMember?.my_teams.length > 0 ? (
                        fetchedMember?.my_teams?.map(team => {
                            return (
                                <StyledTabNavLink
                                    key={team.id}
                                    to={`${ROUTE_SETTINGS}/${ROUTE_SETTINGS_TEAMS}?id=${team.id}`}
                                    disabled={!team.is_active}
                                    onClick={() =>
                                        dispatch(setPanelId(undefined))
                                    }
                                >
                                    {team.name}
                                </StyledTabNavLink>
                            )
                        })
                    ) : (
                        <StyledSingleItem className="value_">
                            <StyledNoOptionsMessage>
                                {t('settings.sidebar_left.subtitles.no_teams')}
                            </StyledNoOptionsMessage>
                        </StyledSingleItem>
                    )}
                </StyledUsersInfoContainer>
            </StyledSliderSectionContainer>

            <div>
                <SectionTitle
                    title={t('settings.sidebar_left.subtitles.my_projects')}
                />

                <StyledUsersInfoContainer>
                    {fetchedMember?.my_internal_projects?.length > 0 ? (
                        fetchedMember.my_internal_projects?.map(project => {
                            return (
                                <StyledTabNavLink
                                    key={project.id}
                                    to={`${ROUTE_SETTINGS}/${ROUTE_SETTINGS_TEAMS}?id=${project.id}`}
                                    disabled={!project.is_active}
                                    onClick={() =>
                                        dispatch(setPanelId(undefined))
                                    }
                                >
                                    {project.name}
                                </StyledTabNavLink>
                            )
                        })
                    ) : (
                        <StyledSingleItem className="value_">
                            <StyledNoOptionsMessage>
                                {t(
                                    'settings.sidebar_left.subtitles.no_projects'
                                )}
                            </StyledNoOptionsMessage>
                        </StyledSingleItem>
                    )}
                </StyledUsersInfoContainer>
            </div>
        </>
    )
}

export default MemberSidebar

MemberSidebar.propTypes = {
    data: PropTypes.object,
    setOpenForm: PropTypes.func,
}
