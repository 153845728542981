import { Link } from 'react-router-dom'

import { CostsProjectIcon, ServiceIcon } from '@/assets/icons/icons.jsx'
import Chip from '@/components/chip/Chip.jsx'
import StageStatus from '@/components/stage-status/StageStatus.jsx'
import Tooltip from '@/components/tooltip/Tooltip.jsx'
import { StyledContainerCell } from '@/pages/customers/detail/styles.js'
import { StyledBudget } from '@/pages/dashboard/styles.js'
import {
    GENERAL_STATUSES,
    LABELS_TABLES,
    PROJECT_STATUS,
} from '@/utilities/constants/index.js'

import i18next from '../../../../i18n.js'

/* eslint-disable */

export const COLUMNS_DASHBOARD_TENTH = {
    customer_name: 'customer_name',
    billing_typology: 'billing_typology',
    date: 'date',
    taxable: 'taxable',
    status: 'status',
    stage: 'stage',
}
export const columnsTenth = [
    {
        alignment: 'left',
        label: i18next.t('finance.table.customer_name'),
        name: COLUMNS_DASHBOARD_TENTH.customer_name,
        width: '20%',
        sortable: false,
        divider: false,
    },
    {
        alignment: 'left',
        label: i18next.t('dashboard.table.billing_type'),
        name: COLUMNS_DASHBOARD_TENTH.billing_typology,
        width: '20%',
        sortable: false,
        divider: true,
    },
    {
        alignment: 'left',
        label: i18next.t('finance.table.date'),
        name: COLUMNS_DASHBOARD_TENTH.date,
        width: '15%',
        sortable: true,
        divider: true,
    },
    {
        alignment: 'left',
        label: i18next.t('finance.table.taxable'),
        name: COLUMNS_DASHBOARD_TENTH.taxable,
        width: '15%',
        sortable: false,
        divider: true,
    },
    {
        alignment: 'left',
        label: i18next.t('general.status'),
        name: COLUMNS_DASHBOARD_TENTH.status,
        width: '8%',
        sortable: false,
        divider: true,
    },
    {
        alignment: 'left',
        label: i18next.t('customer.tables.stage'),
        name: COLUMNS_DASHBOARD_TENTH.stage,
        width: '22%',
        sortable: false,
        divider: true,
    },
]

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersTenth = () => ({
    [COLUMNS_DASHBOARD_TENTH.customer_name]: ({ customer_name }) => (
        <Link>{customer_name}</Link>
    ),
    [COLUMNS_DASHBOARD_TENTH.billing_typology]: ({ typology }) => (
        <Tooltip
            text={`${typology} | ${typology}`}
            icon={
                typology === LABELS_TABLES.project ? (
                    <CostsProjectIcon />
                ) : (
                    <ServiceIcon />
                )
            }
            showPosition="bottom"
        >
            <StyledContainerCell>
                {typology === LABELS_TABLES.project ? (
                    <CostsProjectIcon />
                ) : (
                    <ServiceIcon />
                )}
                <p>{typology}</p>
            </StyledContainerCell>
        </Tooltip>
    ),
    [COLUMNS_DASHBOARD_TENTH.date]: ({ date }) => (
        <StyledBudget>{date}</StyledBudget>
    ),
    [COLUMNS_DASHBOARD_TENTH.taxable]: ({ taxable }) => <p>{`€ ${taxable}`}</p>,
    [COLUMNS_DASHBOARD_TENTH.status]: ({ status }) => (
        <Chip
            text={status}
            size={'medium'}
            variant={
                status === PROJECT_STATUS.open ? GENERAL_STATUSES.success : ''
            }
        />
    ),
    [COLUMNS_DASHBOARD_TENTH.stage]: ({ stage }) => (
        <StageStatus status={stage?.status} text={stage?.name} />
    ),
})
