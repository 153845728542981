import PropTypes, { shape } from 'prop-types'

import { TABLE_ALIGNMENTS } from '@/utilities/constants'

import DesktopTable from './desktop/index'
import MobileTable from './mobile/index'

const Table = ({
    footerComponent,
    headerComponent,
    isMobileVersion,
    ...rest
}) => {
    return (
        <div>
            {headerComponent}
            {isMobileVersion ? (
                <MobileTable {...rest} />
            ) : (
                <DesktopTable {...rest} />
            )}
            {footerComponent}
        </div>
    )
}

export default Table

Table.propTypes = {
    alternatingRowColors: PropTypes.bool,
    columns: PropTypes.arrayOf(
        shape({
            alignment: PropTypes.oneOf(Object.values(TABLE_ALIGNMENTS)),
            allowOverflow: PropTypes.bool,
            icon: PropTypes.node,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            mobileFullLength: PropTypes.bool,
            name: PropTypes.string.isRequired,
            sortable: PropTypes.bool,
            width: ({ width }, propName, componentName) => {
                if (!/^\d+%$/.test(width)) {
                    return new Error(
                        `Invalid prop '${propName}' supplied to ${componentName} (${width}). The prop must be a positive percentage number.`
                    )
                }
            },
            divider: PropTypes.bool,
            havePadding: PropTypes.bool,
        })
    ).isRequired,
    columnsRenderers: PropTypes.object.isRequired,
    data: PropTypes.array,
    isError: PropTypes.bool,
    expandedRowsIndexes: PropTypes.arrayOf(PropTypes.number),
    footerComponent: PropTypes.element,
    headerComponent: PropTypes.element,
    isLoading: PropTypes.bool,
    isMobileVersion: PropTypes.bool,
    isPaginated: PropTypes.bool,
    onChangePageCb: PropTypes.func,
    onClickRow: PropTypes.func,
    onRefresh: PropTypes.func,
    page: PropTypes.number,
    sortColumnCb: PropTypes.func,
    sorter: PropTypes.object,
    bodyheight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
