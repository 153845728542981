import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { EditIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import SectionItem from '@/components/sidebar-tab-item/section-item/SectionItem'
import SectionTitle from '@/components/sidebar-tab-item/section-title/SectionTitle'
import {
    StyledSliderSectionContainer,
    StyledTabInfoContainer,
    StyledSidebarSubHeader,
    StyledTabCtaContainer,
} from '@/components/sidebar-tab-item/styles'
import { LABELS_TABLES } from '@/utilities/constants/index.js'

import { standardFields } from './standardFields'

const StandardSidebar = ({ data, setDataForm }) => {
    const { t } = useTranslation()
    const theme = useTheme()

    const project = standardFields(data)
    const { pathname } = useLocation()
    const typeForm = () => {
        if (pathname.includes('standard-projects')) {
            return LABELS_TABLES.project
        } else if (pathname.includes('logs')) {
            return LABELS_TABLES.final_balances
        } else {
            return LABELS_TABLES.projects
        }
    }

    return (
        <>
            <StyledSidebarSubHeader>
                <h3>
                    {data?.name} #{data?.global_project_id}
                </h3>
                <StyledTabCtaContainer>
                    <Button
                        onClick={() =>
                            setDataForm({
                                isOpen: true,
                                typeForm: typeForm(),
                                id: data?.id,
                                isEdit: true,
                                data: data,
                            })
                        }
                        collapsed
                    >
                        <EditIcon
                            width={10}
                            height={10}
                            fill={theme.palette.neutral.base[0]}
                        />
                    </Button>
                </StyledTabCtaContainer>
            </StyledSidebarSubHeader>
            <StyledSliderSectionContainer>
                <StyledTabInfoContainer>
                    {project.general.map((item, index) => {
                        return (
                            <SectionItem
                                label={item.label}
                                value={item.value}
                                width={item.width}
                                key={index}
                            />
                        )
                    })}
                </StyledTabInfoContainer>
            </StyledSliderSectionContainer>

            <StyledSliderSectionContainer>
                <SectionTitle title={t('project.sidebar_left.info')} />
                <StyledTabInfoContainer>
                    {project.info.map((item, index) => {
                        return (
                            <SectionItem
                                label={item.label}
                                value={item.value}
                                width={item.width}
                                key={index}
                            />
                        )
                    })}
                </StyledTabInfoContainer>
            </StyledSliderSectionContainer>

            <StyledSliderSectionContainer>
                <SectionTitle title={t('project.sidebar_left.reporting')} />

                <StyledTabInfoContainer>
                    {project.reporting.map((item, index) => {
                        return (
                            <SectionItem
                                label={item.label}
                                value={item.value}
                                width={item.width}
                                key={index}
                            />
                        )
                    })}
                </StyledTabInfoContainer>
            </StyledSliderSectionContainer>

            <StyledSliderSectionContainer>
                <SectionTitle title={t('project.sidebar_left.link')} />

                <StyledTabInfoContainer>
                    {project.link.map((item, index) => {
                        return (
                            <SectionItem
                                label={item.label}
                                value={item.value}
                                width={item.width}
                                link={item?.link}
                                key={index}
                            />
                        )
                    })}
                </StyledTabInfoContainer>
            </StyledSliderSectionContainer>

            <div>
                <SectionTitle title={t('general.team')} />

                <StyledTabInfoContainer>
                    {project.team.map((item, index) => {
                        return (
                            <SectionItem
                                label={item.label}
                                value={item.value}
                                width={item.width}
                                key={index}
                            />
                        )
                    })}
                </StyledTabInfoContainer>
            </div>
        </>
    )
}

export default StandardSidebar

StandardSidebar.propTypes = {
    data: PropTypes.any,
    setDataForm: PropTypes.func,
}
