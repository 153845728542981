import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PlusIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import Search from '@/components/search/Search.jsx'
import Select from '@/components/select/Select.jsx'
import { StyledContainerFilter } from '@/pages/settings/styles.js'
import {
    useGetMembersQuery,
    useGetTeamsQuery,
} from '@/services/settings/settingApiSlice.js'
import { TYPE_USERS } from '@/utilities/constants/index.js'
import { CONTRACT_TYPE_OPTIONS } from '@/utilities/constants/list.js'
import { translateOptions } from '@/utilities/helpers.js'

import { PaginatedSelect } from '../members-select/PaginatedSelect'

const SubHeaderFilters = ({ setOpenForm, isMembers }) => {
    const { t } = useTranslation()
    const onClickHandler = () => {
        setOpenForm({
            isOpen: true,
            id: null,
            typeForm: isMembers ? TYPE_USERS.members : TYPE_USERS.teams,
            isEdit: false,
        })
    }
    const [currentType, setCurrentType] = useState(null)
    const [currentSearch, setCurrentSearch] = useState(null)
    const [currentTeamLead, setCurrentTeamLead] = useState(null)

    useGetMembersQuery({
        currentPage: 1,
        type: currentType,
        search: currentSearch,
        sortDirection: 'asc',
    })

    useGetTeamsQuery({
        currentPage: 1,
        team_lead_id: currentTeamLead,
        sortDirection: 'asc',
    })

    return (
        <StyledContainerFilter isMembers={isMembers}>
            {isMembers ? (
                <>
                    <Select
                        isClearable
                        isControlled={false}
                        size={'medium'}
                        options={translateOptions(CONTRACT_TYPE_OPTIONS)}
                        placeholder={t('general.typology')}
                        onChange={e => {
                            setCurrentType(e?.value)
                        }}
                    />
                    <Search
                        placeholder={t('settings.filters.placeholder.search')}
                        handleSearch={value => {
                            setCurrentSearch(value)
                        }}
                    />
                </>
            ) : (
                <PaginatedSelect
                    isClearable
                    isSearchable
                    isControlled={false}
                    size={'medium'}
                    isTeamLead={true}
                    placeholder={t('general.team_lead')}
                    onChange={e => {
                        setCurrentTeamLead(e?.value)
                    }}
                />
            )}

            <Button type={'button'} size={'small'} onClick={onClickHandler}>
                <PlusIcon />
                {isMembers
                    ? t('general.add_member')
                    : t('settings.actions.add_team')}
            </Button>
        </StyledContainerFilter>
    )
}

export default SubHeaderFilters

SubHeaderFilters.propTypes = {
    setOpenForm: PropTypes.func,
    setFilter: PropTypes.func,
    setTeamLeadFilter: PropTypes.func,
    isMembers: PropTypes.bool,
}
