import PropTypes from 'prop-types'

import AllocationCell from '@/components/calendar-day/table-body/AllocationCell.jsx'
import UserDataCell from '@/components/calendar-day/table-body/UserDataCell.jsx'

import {
    StyledCalendarDataContainer,
    StyledCalendarDayBodyContainer,
} from '../styles'

const TableBody = ({ currentData, setDataForm, finalBalance }) => {
    return (
        <StyledCalendarDayBodyContainer>
            {currentData.map((dateObject, index) => {
                return (
                    <StyledCalendarDataContainer key={index}>
                        <UserDataCell
                            user={dateObject}
                            setDataForm={setDataForm}
                            finalBalance={finalBalance}
                        />

                        {dateObject?.allocations &&
                            Object.values(dateObject?.allocations).map(
                                allocation => {
                                    return Object.entries(allocation).map(
                                        ([userKey, item]) => {
                                            return (
                                                <AllocationCell
                                                    key={userKey}
                                                    cell={item}
                                                    currentDate={userKey}
                                                    finalBalance={finalBalance}
                                                    userId={item?.userId}
                                                    isLarge={item?.isLarge}
                                                />
                                            )
                                        }
                                    )
                                }
                            )}
                    </StyledCalendarDataContainer>
                )
            })}
        </StyledCalendarDayBodyContainer>
    )
}

export default TableBody

TableBody.propTypes = {
    currentData: PropTypes.array,
    setDataForm: PropTypes.func,
    finalBalance: PropTypes.object,
}
