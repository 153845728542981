import i18next from 'i18next'

export const customerFields = (customer, success_manager) => {
    return {
        billing_data: [
            {
                label: i18next.t('general.vat_number'),
                value: customer.vat,
                width: 100,
            },
            {
                label: i18next.t('customer.sidebar_left.labels.sdi'),
                value: customer.sdi,
                width: 100,
            },
            {
                label: i18next.t('customer.sidebar_left.labels.pec'),
                value: customer.pec,
                width: 100,
            },
        ],
        customer_address: [
            {
                label: i18next.t(
                    'customer.sidebar_left.labels.billing_address'
                ),
                value: customer.billing_address,
                width: 100,
            },
            {
                label: i18next.t('customer.sidebar_left.labels.city'),
                value: customer.city,
                width: 100,
            },
            {
                label: i18next.t('customer.sidebar_left.labels.postal_code'),
                value: customer.postal_code,
                width: 100,
            },
        ],
        account_data: [
            {
                label: i18next.t('general.success_manager'),
                value: success_manager?.full_name,
                width: 100,
            },
            {
                label: i18next.t('customer.sidebar_left.labels.email'),
                value: customer.email,
                width: 100,
            },
            {
                label: i18next.t('customer.sidebar_left.labels.phone_number'),
                value: customer.phone_number,
                width: 100,
            },
        ],
    }
}
