import commons from './commons.json'
import pages from './pages.json'
import validation from './validation.json'

const translations = {
    pages,
    commons,
    validation,
}

export default translations
