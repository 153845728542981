import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import DialogDelete from '@/components/dialog-delete/DialogDelete.jsx'
import DialogSafeClose from '@/components/dialog-safe-close/DialogSafeClose.jsx'
import SidePopover from '@/components/side-popover/SidePopover.jsx'
import Spinner from '@/components/spinner/Spinner.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import {
    DEFAULT_ASSIGN_TEAM_MOCK,
    validationSchema,
} from '@/pages/settings/settings-form/assign-teams-form/assignTeam.schema.js'
import { useGetTeamsQuery } from '@/services/settings/settingApiSlice.js'
import {
    useDeleteAssignedTeamMutation,
    useGetAssignTeamByIdQuery,
} from '@/services/settings/settingApiSlice.js'
import { remapSelectOptions } from '@/utilities/api/helpers.js'
import { handleApiError } from '@/utilities/api/helpers.js'

import AssignTeamForm from './AssignTeamForm'

const AssignTeamFormWrapper = ({
    onClose,
    isEdit,
    openDialogToConfirm,
    setOpenDialogToConfirm,
    closeModal,
    openForm,
    userTeamHistoryID,
    userID,
}) => {
    const [openDialogToDelete, setOpenDialogToDelete] = useState(false)
    const { t } = useTranslation()

    const { triggerAlert } = useAlert()
    const showError = useError()
    const { data: teams } = useGetTeamsQuery({})
    const { data, isFetching } = useGetAssignTeamByIdQuery(
        {
            ID: userID,
            userTeamHistoryID,
        },
        { skip: !userID || !userTeamHistoryID }
    )

    const [deleteAssignedTeam] = useDeleteAssignedTeamMutation()

    const deleteUser = async () => {
        try {
            await deleteAssignedTeam({
                ID: openForm.data.userID,
                historyTeamID: openForm.data.id,
            }).unwrap()
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: t('commons:dialog.success'),
            })
            onClose()
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        } finally {
            setOpenDialogToDelete(false)
        }
    }

    const teamsOptions = remapSelectOptions(teams?.data)

    const props = {
        onClose,
        isEdit,
        openDialogToConfirm,
        setOpenDialogToConfirm,
        closeModal,
        openForm,
        data,
        teamsOptions,
        setOpenDialogToDelete,
    }

    const methods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'onChange',
        nativeValidation: false,
        shouldFocusError: true,
        resolver: yupResolver(validationSchema),
        defaultValues: DEFAULT_ASSIGN_TEAM_MOCK,
    })

    const {
        formState: { isDirty },
    } = methods

    if (isFetching) {
        return (
            <Spinner
                overlay={false}
                overlayFullscreen={false}
                className={undefined}
            />
        )
    }

    return (
        <>
            <DialogDelete
                open={openDialogToDelete}
                onClose={() => setOpenDialogToDelete(false)}
                onDelete={deleteUser}
            />
            {openDialogToConfirm && (
                <DialogSafeClose
                    goBack={() => {
                        onClose()
                    }}
                    onClose={() => setOpenDialogToConfirm(false)}
                    open={openDialogToConfirm}
                />
            )}
            <FormProvider {...methods}>
                <SidePopover
                    onClose={() => closeModal(isDirty)}
                    isDirty={isDirty}
                    title={t(
                        isEdit
                            ? 'settings.actions.edit_assign_team'
                            : 'settings.actions.create_assign_team'
                    )}
                >
                    <AssignTeamForm {...props} />
                </SidePopover>
            </FormProvider>
        </>
    )
}

export default AssignTeamFormWrapper

AssignTeamFormWrapper.propTypes = {
    onClose: PropTypes.func,
    isEdit: PropTypes.bool,
    isLoading: PropTypes.bool,
    setIsLoading: PropTypes.func,
    openDialogToConfirm: PropTypes.bool,
    setOpenDialogToConfirm: PropTypes.func,
    closeModal: PropTypes.func,
    openForm: PropTypes.object,
    data: PropTypes.object,
    userTeamHistoryID: PropTypes.number,
    historyTeamID: PropTypes.number,
    userID: PropTypes.number,
}
