import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'

import Divider from '@/components/divider/Divider.jsx'
import Tooltip from '@/components/tooltip/Tooltip.jsx'
import { formatCurrency } from '@/utilities/helpers'

import {
    StyledSalProgressBar,
    StyledSalProgressBarContainer,
    StyledSalProgressBarTooltip,
} from './styles'

const MultiProgress = ({ multiData }) => {
    const theme = useTheme()
    return (
        <>
            <Divider orientation={'horizontal'} width={'100%'} height={'1px'} />
            <StyledSalProgressBarContainer>
                <StyledSalProgressBar
                    sustained={multiData?.sustained}
                    planned={multiData?.planned}
                    consumed={multiData?.consumed}
                    scheduled={multiData?.scheduled}
                    remaining={multiData?.remaining}
                >
                    <Tooltip
                        showPosition={'bottom'}
                        customChildren={<div className="sustained_"></div>}
                        customContent={
                            <StyledSalProgressBarTooltip>
                                <div
                                    className="icon_"
                                    style={{
                                        background:
                                            theme.palette.warning.base.active,
                                    }}
                                />
                                <div>
                                    <p>Costi sostenuti (fatture ricevute)</p>
                                    <span>{`${
                                        multiData?.sustained
                                    }h | ${formatCurrency(
                                        multiData?.sustained_amount,
                                        true
                                    )}`}</span>
                                </div>
                            </StyledSalProgressBarTooltip>
                        }
                        customcolor={theme.palette.warning.base.active}
                    ></Tooltip>
                    <Tooltip
                        showPosition={'bottom'}
                        customChildren={<div className="planned_"></div>}
                        customContent={
                            <StyledSalProgressBarTooltip>
                                <div
                                    className="icon_"
                                    style={{
                                        background:
                                            theme.palette.warning.base
                                                .background,
                                    }}
                                />
                                <div>
                                    <p>
                                        Costi pianificati (fatture pianificate)
                                    </p>
                                    <span>{`${
                                        multiData?.planned
                                    }h | ${formatCurrency(
                                        multiData?.planned_amount,
                                        true
                                    )}`}</span>
                                </div>
                            </StyledSalProgressBarTooltip>
                        }
                        customcolor={theme.palette.warning.base.background}
                    ></Tooltip>
                    <Tooltip
                        showPosition={'bottom'}
                        customChildren={<div className="consumed_"></div>}
                        customContent={
                            <StyledSalProgressBarTooltip>
                                <div
                                    className="icon_"
                                    style={{
                                        background:
                                            theme.palette.success.base.active,
                                    }}
                                />
                                <div>
                                    <p>Ore consumate (da Float)</p>
                                    <span>{`${
                                        multiData?.consumed
                                    }h | ${formatCurrency(
                                        multiData?.consumed_amount,
                                        true
                                    )}`}</span>
                                </div>
                            </StyledSalProgressBarTooltip>
                        }
                        customcolor={theme.palette.success.base.active}
                    />
                    <Tooltip
                        showPosition={'bottom'}
                        customChildren={<div className="scheduled_"></div>}
                        customContent={
                            <StyledSalProgressBarTooltip>
                                <div
                                    className="icon_"
                                    style={{
                                        background:
                                            theme.palette.success.base
                                                .background,
                                    }}
                                />
                                <div>
                                    <p>Ore pianificate (da Float)</p>
                                    <span>{`${
                                        multiData?.scheduled
                                    }h | ${formatCurrency(
                                        multiData?.schedules_amount,
                                        true
                                    )}`}</span>
                                </div>
                            </StyledSalProgressBarTooltip>
                        }
                        customcolor={theme.palette.success.base.background}
                    />
                    <div className="remaining_"></div>
                </StyledSalProgressBar>
                <p className="remaining_hours_">
                    {multiData?.remaining} h rimanenti
                </p>
            </StyledSalProgressBarContainer>
            <Divider orientation={'horizontal'} width={'100%'} height={'1px'} />
        </>
    )
}

export default MultiProgress

MultiProgress.propTypes = {
    multiData: PropTypes.any,
}
