import PropTypes from 'prop-types'

import { StyledDividerRoot } from './styles'

const Divider = ({
    orientation = 'vertical',
    width = '1px',
    height = '22px',
}) => {
    return (
        <StyledDividerRoot
            decorative
            orientation={orientation}
            width={width}
            height={height}
        />
    )
}

export default Divider

Divider.propTypes = {
    orientation: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
