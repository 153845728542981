import styled, { css } from 'styled-components'

export const StyledContainerDelete = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: ${theme.spacing / 2}px;
        ${theme.typography.paragraph.p12.bold};
        color: ${theme.palette.danger.base.active};
        margin-bottom: ${theme.spacing * 4}px;

        & svg {
            width: ${theme.spacing * 3}px;
            height: ${theme.spacing * 3}px;
        }

        &:hover {
            text-decoration: underline;
        }
    `}
`
