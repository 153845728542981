export const palette = {
    light: {
        neutral: {
            base: {
                0: '#FFFFFF',
                100: '#D8D8DB',
                200: '#B2B1B7',
                300: '#8B8993',
                400: '#646270',
                500: '#3E3B4C',
                1000: '#171428',
            },
        },
        primary: {
            base: {
                active: '#7F63F4',
                disabled: '#BDB4E7',
                hover: '#5F42D9',
                background: '#DFD9FA',
                text: '#361BA9',
            },
        },
        secondary: {
            base: {
                active: '#5E98FF',
                disabled: '#A1B3D2',
                hover: '#2166E0',
                background: '#F2F6FD',
                text: '#032F7C',
            },
        },
        success: {
            base: {
                active: '#6FD285',
                disabled: '#C0D9C6',
                hover: '#199F38',
                background: '#D2F3D9',
                text: '#0E7224',
            },
        },
        warning: {
            base: {
                active: '#FFC107',
                disabled: '#DFC062',
                hover: '#EBB000',
                background: '#FFEFC0',
                text: '#A8820F',
            },
        },
        danger: {
            base: {
                active: '#F23157',
                disabled: '#DD91A0',
                hover: '#D6183D',
                background: '#FFD8DF',
                text: '#CC092F',
            },
        },
    },
    dark: {
        neutral: {
            base: {
                0: '#171428',
                100: '#3E3B4C',
                200: '#646270',
                300: '#8B8993',
                400: '#B2B1B7',
                500: '#F1F1F1',
                1000: '#FFFFFF',
            },
        },
        primary: {
            base: {
                active: '#7F63F4',
                disabled: '#BDB4E7',
                hover: '#BDB4E7',
                background: '#DFD9FA',
                text: '#361BA9',
            },
        },
        secondary: {
            base: {
                active: '#242136',
                disabled: '#45405F',
                hover: '#09061A',
                background: '#ADA7CB',
                text: '#171428',
            },
        },
        success: {
            base: {
                active: '#4CBC65',
                disabled: '#C0D9C6',
                hover: '#199F38',
                background: '#D2F3D9',
                text: '#0E7224',
            },
        },
        warning: {
            base: {
                active: '#F5B903',
                disabled: '#DFC062',
                hover: '#D8A200',
                background: '#FFEFC0',
                text: '#A8820F',
            },
        },
        danger: {
            base: {
                active: '#DB2448',
                disabled: '#DD91A0',
                hover: '#AF1735',
                background: '#FFC1CD',
                text: '#B70024',
            },
        },
    },
}
