import PropTypes from 'prop-types'

import { StyledSectionTitleContainer } from '../styles'

const SectionTitle = ({ title }) => {
    return (
        <StyledSectionTitleContainer>
            <p className="title_">{title}</p>
            <div className="line_"></div>
        </StyledSectionTitleContainer>
    )
}

export default SectionTitle

SectionTitle.propTypes = {
    title: PropTypes.string,
}
