import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { EditIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import SectionItem from '@/components/sidebar-tab-item/section-item/SectionItem.jsx'
import SectionTitle from '@/components/sidebar-tab-item/section-title/SectionTitle.jsx'
import {
    StyledSidebarSubHeader,
    StyledSliderSectionContainer,
    StyledTabCtaContainer,
    StyledTabInfoContainer,
} from '@/components/sidebar-tab-item/styles.js'
import { memberFields } from '@/pages/settings/sidebar-settings/member/memberFields.jsx'
import { LABELS_TABLES } from '@/utilities/constants/index.js'

const ApplicantSidebar = ({ data, setDataForm }) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const member = memberFields(data)

    return (
        <>
            <StyledSidebarSubHeader>
                {
                    <h3>
                        {data?.full_name} #{data.id}
                    </h3>
                }
                <StyledTabCtaContainer>
                    <Button
                        onClick={() =>
                            setDataForm({
                                isOpen: true,
                                typeForm: LABELS_TABLES.member,
                                id: data.id,
                                isEdit: true,
                            })
                        }
                        collapsed
                    >
                        <EditIcon
                            width={10}
                            height={10}
                            fill={theme.palette.neutral.base[0]}
                        />
                    </Button>
                </StyledTabCtaContainer>
            </StyledSidebarSubHeader>
            <StyledSliderSectionContainer>
                <SectionTitle
                    title={t('settings.sidebar_left.subtitles.personal_data')}
                />
                <StyledTabInfoContainer>
                    {member.personal_data.map((item, index) => {
                        return (
                            <SectionItem
                                label={item.label}
                                value={item.value}
                                width={item.width}
                                link={item.link}
                                key={index}
                            />
                        )
                    })}
                </StyledTabInfoContainer>
            </StyledSliderSectionContainer>

            <StyledSliderSectionContainer>
                <SectionTitle
                    title={t(
                        'settings.sidebar_left.subtitles.specifications_member'
                    )}
                />

                <StyledTabInfoContainer>
                    {member.member_specifications.map((item, index) => {
                        return (
                            <SectionItem
                                label={item.label}
                                value={item.value}
                                width={item.width}
                                link={item.link}
                                key={index}
                            />
                        )
                    })}
                </StyledTabInfoContainer>
            </StyledSliderSectionContainer>
        </>
    )
}

export default ApplicantSidebar

ApplicantSidebar.propTypes = {
    data: PropTypes.object,
    setDataForm: PropTypes.func,
}
