import styled, { css } from 'styled-components'

export const StyledSalProgressBarContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        gap: ${theme.spacing * 2}px;
        align-items: center;
        width: 100%;
        padding: 0 ${theme.spacing * 2}px;
        margin: ${theme.spacing / 2}px 0;
        & .remaining_hours_ {
            white-space: nowrap;
            color: ${theme.palette.neutral.base[1000]};
            ${theme.typography.paragraph.p12.bold};
        }
    `}
`

export const StyledSalProgressBar = styled.div`
    ${({ theme, sustained, planned, consumed, scheduled, remaining }) => css`
        display: flex;
        align-items: center;
        width: 100%;
        height: 8px;
        border-radius: 5px;
        overflow: hidden;
        background-color: ${theme.palette.secondary.base.background};
        & button {
            width: 100%;
        }
        & .sustained_ {
            width: ${sustained}%;
            height: 8px;
            background-color: ${theme.palette.warning.base.active};
        }
        & .planned_ {
            width: ${planned}%;
            height: 8px;
            background-color: ${theme.palette.warning.base.background};
        }
        & .consumed_ {
            width: ${consumed}%;
            height: 8px;
            background-color: ${theme.palette.success.base.active};
        }
        & .scheduled_ {
            width: ${scheduled}%;
            height: 8px;
            background-color: ${theme.palette.success.base.background};
        }
        & .remaining_ {
            width: ${remaining}%;
            height: 8px;
            background-color: ${theme.palette.secondary.base.background};
        }
    `}
`

export const StyledSalProgressBarTooltip = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: start;
        gap: ${theme.spacing}px;
        width: 100%;
        color: ${theme.palette.neutral.base[1000]};
        ${theme.typography.paragraph.p12.regular};
        & .icon_ {
            width: 8px;
            height: 16px;
            border-radius: 8px;
            display: flex;
        }
    `}
`
