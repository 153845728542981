import PropTypes, { shape } from 'prop-types'

import { TABLE_ALIGNMENTS } from '@/utilities/constants'

import ErrorMessage from '../../error-message'
import Loader from '../loader'
import Row from '../row'

import {
    StyledNoResultsMessage,
    StyledPaginatedTbody,
    StyledTbody,
} from './style'

const Body = ({
    onClickRow,
    columns,
    columnsRenderers,
    expandedRowsIndexes,
    alternatingRowColors,
    isError,
    isLoading,
    onRefresh,
    bodyheight,
    data,
    isRefetching,
    onChangePageCb,
    page,
    isPaginated,
    selectedRow,
}) => {
    if (isError) {
        return (
            <StyledTbody bodyheight={bodyheight}>
                <ErrorMessage onRefresh={onRefresh} />
            </StyledTbody>
        )
    }

    //TODO: test pagination in depth
    switch (isPaginated) {
        case true:
            return (
                <>
                    {!!data.length &&
                    !(isLoading && page === 1) &&
                    !isRefetching ? (
                        <StyledPaginatedTbody
                            bodyheight={bodyheight}
                            data={data}
                            endReached={onChangePageCb}
                            overscan={100}
                            itemContent={itemIndex => {
                                const item = data[itemIndex]
                                const clickableRowPath = onClickRow(item)

                                return (
                                    <Row
                                        item={item}
                                        itemIndex={itemIndex}
                                        columns={columns}
                                        columnsRenderers={columnsRenderers}
                                        expandedRowsIndexes={
                                            expandedRowsIndexes
                                        }
                                        alternatingRowColors={
                                            alternatingRowColors
                                        }
                                        clickableRowPath={clickableRowPath}
                                        key={itemIndex}
                                        selectedRow={selectedRow}
                                    />
                                )
                            }}
                            components={{
                                ...(isLoading && {
                                    Footer: () => <Loader columns={columns} />,
                                }),
                            }}
                        />
                    ) : (
                        <StyledTbody bodyheight={bodyheight}>
                            {isLoading ? (
                                <Loader columns={columns} />
                            ) : (
                                <StyledNoResultsMessage>
                                    No Result
                                </StyledNoResultsMessage>
                            )}
                        </StyledTbody>
                    )}
                </>
            )

        case false:
            return (
                <>
                    <StyledTbody bodyheight={bodyheight}>
                        {data.map((item, itemIndex) => {
                            const clickableRowPath = onClickRow(item)

                            return (
                                <Row
                                    item={item}
                                    itemIndex={itemIndex}
                                    columns={columns}
                                    columnsRenderers={columnsRenderers}
                                    expandedRowsIndexes={expandedRowsIndexes}
                                    alternatingRowColors={alternatingRowColors}
                                    clickableRowPath={clickableRowPath}
                                    key={itemIndex}
                                />
                            )
                        })}

                        {isLoading && <Loader columns={columns} />}
                    </StyledTbody>
                </>
            )
    }
}

export default Body

Body.propTypes = {
    onClickRow: PropTypes.func,
    columns: PropTypes.arrayOf(
        shape({
            alignment: PropTypes.oneOf(Object.values(TABLE_ALIGNMENTS)),
            allowOverflow: PropTypes.bool,
            icon: PropTypes.node,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            mobileFullLength: PropTypes.bool,
            name: PropTypes.string.isRequired,
            sortable: PropTypes.bool,
            width: ({ width }, propName, componentName) => {
                if (!/^\d+%$/.test(width)) {
                    return new Error(
                        `Invalid prop '${propName}' supplied to ${componentName} (${width}). The prop must be a positive percentage number.`
                    )
                }
            },
            divider: PropTypes.bool,
        })
    ).isRequired,
    columnsRenderers: PropTypes.object,
    expandedRowsIndexes: PropTypes.arrayOf(PropTypes.number),
    alternatingRowColors: PropTypes.bool,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    onRefresh: PropTypes.func,
    bodyheight: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    isRefetching: PropTypes.bool,
    onChangePageCb: PropTypes.func,
    page: PropTypes.number,
    isPaginated: PropTypes.bool,
    selectedRow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
