import styled, { css } from 'styled-components'

export const StyledFiltersWrapper = styled.div`
    ${({ width, notHavePhase }) => css`
        display: grid;
        grid-template-columns: ${notHavePhase
            ? `150px ${width}px`
            : `${width}px`};
    `}
`
