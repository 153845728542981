/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'

import Select from '@/components/select/Select.jsx'

const ScrollSelect = ({
    value,
    onChange,
    size,
    onBlur,
    options,
    autofocus = false,
    menuPosition = 'fixed',
    menuIsOpen,
    getData,
    isOptionDisabled,
    required,
    lastPage,
    currentPage,
    name,
    label,
    placeholder,
    control,

    touched,
    errors,
    isClearable,
    isSearchable = true,
    isControlled = false,

    disabled = false,
}) => {
    const handleMenuScrollToBottom = () => {
        if (lastPage !== currentPage) {
            getData(currentPage + 1)
        }
    }

    return (
        <Select
            isSearchable={isSearchable}
            isControlled={isControlled}
            value={value}
            onChange={onChange}
            size={size}
            onBlur={onBlur}
            options={options}
            autoFocus={autofocus}
            menuPosition={menuPosition}
            menuIsOpen={menuIsOpen}
            lastPage={lastPage}
            currentPage={currentPage}
            onMenuScrollToBottom={handleMenuScrollToBottom}
            required={required}
            name={name}
            placeholder={placeholder}
            label={label}
            control={control}
            errors={errors}
            touched={touched}
            isOptionDisabled={isOptionDisabled}
            isClearable={isClearable}
            disabled={disabled}
        />
    )
}

export default ScrollSelect

ScrollSelect.propTypes = {
    isSearchable: PropTypes.bool,
    isControlled: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
    size: PropTypes.string,
    onBlur: PropTypes.func,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    autofocus: PropTypes.bool,
    menuPosition: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    menuIsOpen: PropTypes.bool,
    getData: PropTypes.func,
    isOptionDisabled: PropTypes.func,
    required: PropTypes.bool,
    lastPage: PropTypes.number,
    currentPage: PropTypes.number,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    control: PropTypes.object,

    touched: PropTypes.any,
    errors: PropTypes.object,
    isClearable: PropTypes.bool,
}
