import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AddIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import SectionBlock from '@/components/sidebar-tab-item/section-block/SectionBlock'
import SectionTitle from '@/components/sidebar-tab-item/section-title/SectionTitle.jsx'
import {
    StyledSidebarSubHeader,
    StyledTabCtaContainer,
    StyledHistoryItem,
    StyledSliderSectionContainer,
    StyledSingleItem,
    StyledNoOptionsMessage,
} from '@/components/sidebar-tab-item/styles'
import Spinner from '@/components/spinner/Spinner'
import Tooltip from '@/components/tooltip/Tooltip.jsx'
import {
    useGetMemberByIdQuery,
    useGetUserTeamHistoryByIdQuery,
} from '@/services/settings/settingApiSlice'
import { TYPE_USERS } from '@/utilities/constants'
import { LABELS_TABLES } from '@/utilities/constants/commons'

const MemberTeamHistorySidebar = ({ setOpenForm, panelId }) => {
    const { data: featchedTeamHistory, isFetching: isFetchingTeamHistory } =
        useGetUserTeamHistoryByIdQuery({ ID: panelId }, { skip: !panelId })
    const { data: memberData, isFetching: isFetchingMember } =
        useGetMemberByIdQuery({ ID: panelId }, { skip: !panelId })

    const data = featchedTeamHistory?.data

    const { t } = useTranslation()
    const currentDate = new Date()

    const userInactive = useMemo(() => {
        return memberData?.is_active === false
    }, [memberData])

    const canAddTeam = useMemo(() => {
        return data?.find(
            item =>
                (item && new Date(item.leaved_at) > currentDate) ||
                item.leaved_at === null
        )
    }, [data])

    const teamActive = useMemo(() => {
        return data?.filter(item => {
            const teamStartDate = new Date(item.joined_at)
            const teamEndDate = item.leaved_at ? new Date(item.leaved_at) : null

            const isTeamLeavingToday =
                teamEndDate !== null &&
                teamEndDate.toDateString() === currentDate.toDateString()

            return (
                (teamStartDate <= currentDate &&
                    (!teamEndDate ||
                        teamEndDate >= currentDate ||
                        isTeamLeavingToday)) ||
                teamStartDate > currentDate
            )
        })
    }, [data, currentDate])

    const teamNotActive = useMemo(() => {
        return data?.filter(item => {
            if (item.leaved_at === null) return false
            const teamEndDate = new Date(item.leaved_at)
            const isTeamLeavingToday =
                teamEndDate.toDateString() === currentDate.toDateString()
            if (isTeamLeavingToday) return false

            return teamEndDate < currentDate
        })
    }, [data])

    if (isFetchingTeamHistory || isFetchingMember) {
        return <Spinner overlay={false} overlayFullscreen={false} />
    }

    return (
        <>
            <StyledSidebarSubHeader>
                {<h3>{LABELS_TABLES.team}</h3>}
                <StyledTabCtaContainer>
                    <Tooltip
                        visibilityCondition={
                            canAddTeam !== undefined || userInactive
                        }
                        text={t('settings.sidebar_left.subtitles.check_team')}
                        showPosition="bottom"
                    >
                        <Button
                            onClick={() =>
                                setOpenForm({
                                    isOpen: true,
                                    typeForm: TYPE_USERS.assignTeam,
                                    data: {
                                        userID: panelId,
                                    },
                                    isEdit: false,
                                })
                            }
                            collapsed
                            disabled={canAddTeam || userInactive}
                        >
                            <AddIcon />
                        </Button>
                    </Tooltip>
                </StyledTabCtaContainer>
            </StyledSidebarSubHeader>

            <StyledSliderSectionContainer>
                <SectionTitle
                    title={t('settings.sidebar_left.subtitles.actual_team')}
                />
                {teamActive?.length > 0 ? (
                    teamActive?.map((team, index) => {
                        return (
                            <StyledHistoryItem
                                key={team.id}
                                noDivider={index === teamActive.length - 1}
                            >
                                <SectionBlock
                                    startDate={team.joined_at}
                                    endDate={team?.leaved_at}
                                    title={`
                        #${team.team?.id}
                        | ${team.team?.name}`}
                                    editDisabled={userInactive}
                                    isActive={team?.team?.is_active}
                                    onEdit={() => {
                                        setOpenForm({
                                            isOpen: true,
                                            typeForm: TYPE_USERS.assignTeam,
                                            data: {
                                                ...team,
                                                userID: panelId,
                                            },
                                            isEdit: true,
                                        })
                                    }}
                                />
                            </StyledHistoryItem>
                        )
                    })
                ) : (
                    <StyledSingleItem className="value_">
                        <StyledNoOptionsMessage>
                            {t('settings.sidebar_left.subtitles.no_teams')}
                        </StyledNoOptionsMessage>
                    </StyledSingleItem>
                )}
            </StyledSliderSectionContainer>

            <div>
                <SectionTitle
                    title={t('settings.sidebar_left.subtitles.past_team')}
                />

                {teamNotActive?.length > 0 ? (
                    teamNotActive?.map(team => {
                        return (
                            <StyledHistoryItem key={team.id}>
                                <SectionBlock
                                    startDate={team.joined_at}
                                    endDate={team?.leaved_at}
                                    title={`
                        #${team.team?.id}
                        | ${team.team?.name}`}
                                    isActive={team?.team?.is_active}
                                    editDisabled={userInactive}
                                    onEdit={() => {
                                        setOpenForm({
                                            isOpen: true,
                                            typeForm: TYPE_USERS.assignTeam,
                                            data: {
                                                ...team,
                                                userID: panelId,
                                            },
                                            isEdit: true,
                                        })
                                    }}
                                />
                            </StyledHistoryItem>
                        )
                    })
                ) : (
                    <StyledSingleItem className="value_">
                        <StyledNoOptionsMessage>
                            {t('settings.sidebar_left.subtitles.no_teams')}
                        </StyledNoOptionsMessage>
                    </StyledSingleItem>
                )}
            </div>
        </>
    )
}

export default MemberTeamHistorySidebar

MemberTeamHistorySidebar.propTypes = {
    data: PropTypes.array,
    setOpenForm: PropTypes.func,
    panelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    memberData: PropTypes.object,
}
