import PropTypes from 'prop-types'

import {
    StyledSmall,
    StyledErrorMessage,
} from '@/components/error-message/styles.js'

const ErrorMessage = ({ errors, helpText }) => {
    return (
        <>
            {errors?.message && (
                <StyledErrorMessage>{errors.message}</StyledErrorMessage>
            )}
            {helpText && <StyledSmall>{helpText}</StyledSmall>}
        </>
    )
}

export default ErrorMessage

ErrorMessage.propTypes = {
    errors: PropTypes.object,
    helpText: PropTypes.string,
}
