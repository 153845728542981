import { API_RESPONSE_TYPES } from '@/services/axiosInstance.js'

import { apiSlice } from '../apiSlice.js'

// Injects these endpoints to main apiSlice
export const standardProjectsApiSlice = apiSlice.injectEndpoints({
    addTagTypes: [
        'StandardProjects',
        'standardProjectID',
        'Sals',
        'Activities',
        'Costs',
        'Suppliers',
        'Invoices',
    ],
    endpoints: builder => ({
        globalProjectID: builder.query({
            query: (payload = {}) => {
                const { globalSalID, responseType, ...params } = payload
                return {
                    url: `/projects/${globalSalID}`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['projectsCustomer'],
        }),

        //PROJECTS
        addProject: builder.mutation({
            query: (payload = {}) => {
                const { responseType, data } = payload
                const url = '/standard-projects'
                return {
                    url,
                    method: 'post',
                    data,
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['StandardProjects', 'projectsCustomer'],
        }),

        getProjects: builder.query({
            query: (payload = {}) => {
                const { responseType, ...params } = payload
                return {
                    url: '/standard-projects',
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['StandardProjects'],
        }),

        getProjectID: builder.query({
            query: (payload = {}) => {
                const { responseType, ID, ...params } = payload
                return {
                    url: `/standard-projects/${ID}`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['standardProjectID', 'CustomerID'],
        }),

        updateProject: builder.mutation({
            query: (payload = {}) => {
                const { responseType, ID, data } = payload
                const url = `/standard-projects/${ID}`
                return {
                    url,
                    method: 'patch',
                    data,
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: [
                'standardProjectID',
                'StandardProjects',
                'projectsCustomer',
            ],
        }),

        deleteProject: builder.mutation({
            query: (payload = {}) => {
                const { responseType, ID } = payload
                const url = `/standard-projects/${ID}`
                return {
                    url,
                    method: 'delete',
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['StandardProjects', 'projectsCustomer'],
        }),

        //PROJECT HISTORY
        getProjectHistory: builder.query({
            query: (payload = {}) => {
                const { responseType, ID, ...params } = payload
                return {
                    url: `/standard-projects/${ID}/actions-history`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
        }),

        getHistoryType: builder.query({
            query: (payload = {}) => {
                const { responseType, ...params } = payload
                return {
                    url: `/standard-projects/actions-history/types`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
        }),

        //PROJECT SAL
        addStandardSal: builder.mutation({
            query: (payload = {}) => {
                const { responseType, ID, data, ...params } = payload
                const url = `/standard-projects/${ID}/sals`
                return {
                    url,
                    method: 'post',
                    data,
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Sals'],
        }),

        getSals: builder.query({
            query: (payload = {}) => {
                const { responseType, ID, ...params } = payload
                return {
                    url: `/standard-projects/${ID}/sals`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['Sals'],
        }),

        getStandardIdSals: builder.query({
            query: (payload = {}) => {
                const { responseType, salID, ID, ...params } = payload
                return {
                    url: `/standard-projects/${ID}/sals/${salID}`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
        }),

        updateStandardSal: builder.mutation({
            query: (payload = {}) => {
                const { responseType, ID, salID, data } = payload
                const url = `/standard-projects/${ID}/sals/${salID}`
                return {
                    url,
                    method: 'patch',
                    data,
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Sals', 'Activities'],
        }),

        deleteStandardSal: builder.mutation({
            query: (payload = {}) => {
                const { responseType, ID, salID } = payload
                const url = `/standard-projects/${ID}/sals/${salID}`
                return {
                    url,
                    method: 'delete',
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Sals'],
        }),

        //PROJECT SAL ACTIVITIES
        addActivity: builder.mutation({
            query: (payload = {}) => {
                const { responseType, ID, salID, data } = payload
                const url = `/standard-projects/${ID}/sals/${salID}/activities`
                return {
                    url,
                    method: 'post',
                    data,
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Activities', 'Sals'],
        }),

        getSalActivities: builder.query({
            query: (payload = {}) => {
                const { responseType, ID, salID, ...params } = payload
                return {
                    url: `/standard-projects/${ID}/sals/${salID}/activities`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['Activities'],
        }),

        getSalActivityById: builder.query({
            query: (payload = {}) => {
                const { responseType, ID, salID, activityID, ...params } =
                    payload
                return {
                    url: `/standard-projects/${ID}/sals/${salID}/activities/${activityID}`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
        }),

        updateActivity: builder.mutation({
            query: (payload = {}) => {
                const { responseType, ID, salID, activityID, data } = payload
                const url = `/standard-projects/${ID}/sals/${salID}/activities/${activityID}`
                return {
                    url,
                    method: 'patch',
                    data,
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Activities'],
        }),

        deleteActivity: builder.mutation({
            query: (payload = {}) => {
                const { responseType, ID, salID, activityID } = payload
                const url = `/standard-projects/${ID}/sals/${salID}/activities/${activityID}`
                return {
                    url,
                    method: 'delete',
                    params: {
                        responseType:
                            responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Activities'],
        }),

        //COSTS
        addCost: builder.mutation({
            query: (payload = {}) => {
                const { projectID, salID, data, ...params } = payload
                const url = `/standard-projects/${projectID}/sals/${salID}/expenses`
                return {
                    url,
                    method: 'post',
                    data,
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Costs'],
        }),

        getCosts: builder.query({
            query: (payload = {}) => {
                const { projectID, ...params } = payload
                return {
                    url: `/standard-projects/${projectID}/expenses`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['Costs'],
        }),

        getCostByID: builder.query({
            query: (payload = {}) => {
                const { projectID, salID, expensesID, ...params } = payload
                return {
                    url: `/standard-projects/${projectID}/sals/${salID}/expenses/${expensesID}`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
        }),

        updateCost: builder.mutation({
            query: (payload = {}) => {
                const { projectID, salID, expensesID, data, ...params } =
                    payload
                const url = `/standard-projects/${projectID}/sals/${salID}/expenses/${expensesID}`
                return {
                    url,
                    method: 'patch',
                    data,
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Costs'],
        }),

        deleteCost: builder.mutation({
            query: (payload = {}) => {
                const { projectID, salID, expensesID, ...params } = payload
                const url = `/standard-projects/${projectID}/sals/${salID}/expenses/${expensesID}`
                return {
                    url,
                    method: 'delete',
                    params: {
                        ...params,
                        responseType:
                            params.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Costs'],
        }),

        //supplier
        getSuppliers: builder.query({
            query: (payload = {}) => {
                const { ...params } = payload
                return {
                    url: `/suppliers`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['Suppliers'],
        }),

        createSupplier: builder.mutation({
            query: (payload = {}) => {
                const { data, ...params } = payload
                return {
                    url: `/suppliers`,
                    method: 'post',
                    data,
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Suppliers'],
        }),

        //INVOICES

        createInvoiceStandard: builder.mutation({
            query: (payload = {}) => {
                const { projectID, data, ...params } = payload
                return {
                    url: `/standard-projects/${projectID}/invoices`,
                    method: 'post',
                    data,
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Invoices'],
        }),

        getInvoicesStandard: builder.query({
            query: (payload = {}) => {
                const { projectID, ...params } = payload
                return {
                    url: `/standard-projects/${projectID}/invoices`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['Invoices'],
        }),

        getInvoiceByIDStandard: builder.query({
            query: (payload = {}) => {
                const { projectID, invoiceID, ...params } = payload
                return {
                    url: `/standard-projects/${projectID}/invoices/${invoiceID}`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['ActiveSchedule'],
        }),

        updateInvoiceStandard: builder.mutation({
            query: (payload = {}) => {
                const { projectID, invoiceID, data, ...params } = payload
                return {
                    url: `/standard-projects/${projectID}/invoices/${invoiceID}`,
                    method: 'patch',
                    data,
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Invoices', 'ActiveSchedule'],
        }),

        deleteInvoiceStandard: builder.mutation({
            query: (payload = {}) => {
                const { projectID, invoiceID, ...params } = payload
                return {
                    url: `/standard-projects/${projectID}/invoices/${invoiceID}`,
                    method: 'delete',
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Invoices', 'ActiveSchedule'],
        }),

        refetchProject: builder.mutation({
            query: (payload = {}) => {
                const { projectID, ...params } = payload
                return {
                    url: `/floatSync/projects/${projectID}/sync`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
        }),
    }),
})

export const {
    useGlobalProjectIDQuery,

    useAddProjectMutation,
    useGetProjectsQuery,
    useGetProjectIDQuery,
    useUpdateProjectMutation,
    useDeleteProjectMutation,

    useGetProjectHistoryQuery,
    useGetHistoryTypeQuery,

    useAddStandardSalMutation,
    useGetSalsQuery,
    useGetStandardIdSalsQuery,
    useUpdateStandardSalMutation,
    useDeleteStandardSalMutation,

    useAddActivityMutation,
    useGetSalActivitiesQuery,
    useGetSalActivityByIdQuery,
    useUpdateActivityMutation,
    useDeleteActivityMutation,

    useAddCostMutation,
    useGetCostsQuery,
    useGetCostByIDQuery,
    useUpdateCostMutation,
    useDeleteCostMutation,

    useGetSuppliersQuery,
    useCreateSupplierMutation,

    useCreateInvoiceStandardMutation,
    useGetInvoicesStandardQuery,
    useGetInvoiceByIDStandardQuery,
    useUpdateInvoiceStandardMutation,
    useDeleteInvoiceStandardMutation,

    useRefetchProjectMutation,
} = standardProjectsApiSlice
