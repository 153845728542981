import styled, { css } from 'styled-components'

export const StyledSidebarRoot = styled.div`
    position: relative;
`

export const StyledSidebarContainer = styled.div`
    ${({ theme, isOpen }) => css`
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: ${theme.palette.neutral.base[0]};
        border: 1px solid ${theme.palette.neutral.base[200]};
        width: ${isOpen ? '314px' : '0px'};
        display: flex;
        margin-top: -1px;
        margin-left: ${isOpen ? '0' : '-1px'};
        opacity: ${isOpen ? '1' : '0'};
        flex-shrink: 0;
        height: calc(100vh - (${theme.headerHeight}px));
        transition: all 0.3s;
        z-index: 2;
    `}
`
export const StyledSidebarChildrenContaier = styled.div`
    display: flex;
    flex-shrink: 0;
    width: 314px;
`

export const StyledToogleIcon = styled.div`
    ${({ theme, openIcon, isOpen }) => css`
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        display: ${(!openIcon && isOpen) || openIcon ? 'flex' : 'none'};
        align-items: center;
        justify-content: center;
        background-color: ${theme.palette.neutral.base[0]};
        border: 1px solid ${theme.palette.neutral.base[200]};
        border-radius: 0px 0px 5px 0px;
        position: absolute;
        top: -1px;
        right: -23px;
        & svg {
            cursor: pointer;
        }
    `}
`
