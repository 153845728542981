const breakpoints = {
    width: {
        sm: 575,
        md: 767,
        lg: 991,
        xl: 1199,
        xxl: 1399,
    },
}

const mediaQueries = {
    sm: `@media (max-width: ${breakpoints.width.sm}px)`,
    md: `@media (max-width: ${breakpoints.width.md}px)`,
    lg: `@media (max-width: ${breakpoints.width.lg}px)`,
    xl: `@media (max-width: ${breakpoints.width.xl}px)`,
    xxl: `@media (max-width: ${breakpoints.width.xxl}px)`,
}

export default mediaQueries
