import PropTypes, { shape } from 'prop-types'

import { SortIcon } from '@/assets/icons/icons.jsx'
import Divider from '@/components/divider/Divider.jsx'
import { TABLE_ALIGNMENTS } from '@/utilities/constants'

import { SortButton, StyledTH, StyledTHead } from './style'

const Header = ({ columns, sorter, sortColumnCb }) => {
    const handleSort = (sorterDirection, columnName) => {
        switch (sorterDirection) {
            case 'asc':
                sortColumnCb(null, null)
                break
            case 'desc':
                sortColumnCb(columnName, 'asc')
                break
            default:
                sortColumnCb(columnName, 'desc')
        }
    }

    return (
        <StyledTHead>
            {columns.map(column => {
                const sorterDirection =
                    sorter?.order_by === column.name
                        ? sorter?.order_direction
                        : null

                return (
                    <StyledTH
                        alignment={column.alignment}
                        width={column.width}
                        key={column.name}
                        havePadding={!!column.havePadding}
                    >
                        {column.divider &&
                            column.name !==
                                columns[columns.length - 1].name && <Divider />}
                        {column.icon}
                        <span title={column.label}>{column.label}</span>

                        {column.sortable && (
                            <SortButton
                                sorterDirection={sorterDirection}
                                onClick={() =>
                                    handleSort(sorterDirection, column.name)
                                }
                            >
                                <SortIcon />
                            </SortButton>
                        )}
                    </StyledTH>
                )
            })}
        </StyledTHead>
    )
}

export default Header

Header.propTypes = {
    columns: PropTypes.arrayOf(
        shape({
            alignment: PropTypes.oneOf(Object.values(TABLE_ALIGNMENTS)),
            allowOverflow: PropTypes.bool,
            icon: PropTypes.node,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            mobileFullLength: PropTypes.bool,
            name: PropTypes.string.isRequired,
            sortable: PropTypes.bool,
            width: ({ width }, propName, componentName) => {
                if (!/^\d+%$/.test(width)) {
                    return new Error(
                        `Invalid prop '${propName}' supplied to ${componentName} (${width}). The prop must be a positive percentage number.`
                    )
                }
            },
            divider: PropTypes.bool,
        })
    ).isRequired,
    sorter: PropTypes.object,
    sortColumnCb: PropTypes.func,
}
