import { API_RESPONSE_TYPES } from '@/services/axiosInstance.js'

import { apiSlice } from '../apiSlice.js'

// Injects these endpoints to main apiSlice
export const finalBalanceProjectsApiSlice = apiSlice.injectEndpoints({
    addTagTypes: [
        'finalBalanceProjects',
        'finalBalanceProjectID',
        'MembersAllocations',
    ],
    endpoints: builder => {
        return {
            //final-balance
            getFinalBalanceProjects: builder.query({
                query: (payload = {}) => {
                    const { responseType, ...params } = payload
                    return {
                        url: '/final-balance-project',
                        method: 'get',
                        params: {
                            ...params,
                            responseType:
                                responseType ?? API_RESPONSE_TYPES.extended,
                        },
                    }
                },
                providesTags: ['finalBalanceProjects'],
            }),

            createFinalBalanceProject: builder.mutation({
                query: (payload = {}) => {
                    const { responseType, data, ...params } = payload
                    return {
                        url: '/final-balance-project',
                        method: 'post',
                        data,
                        params: {
                            ...params,
                            responseType:
                                responseType ?? API_RESPONSE_TYPES.extended,
                        },
                    }
                },
                invalidatesTags: ['finalBalanceProjects', 'projectsCustomer'],
            }),

            getFinalBalanceID: builder.query({
                query: (payload = {}) => {
                    const { ID, responseType, ...params } = payload
                    return {
                        url: `/final-balance-project/${ID}`,
                        method: 'get',
                        params: {
                            ...params,
                            responseType:
                                responseType ?? API_RESPONSE_TYPES.extended,
                        },
                    }
                },
                providesTags: ['finalBalanceProjectID'],
            }),

            updateFinalBalanceProject: builder.mutation({
                query: (payload = {}) => {
                    const { ID, responseType, data, ...params } = payload
                    return {
                        url: `/final-balance-project/${ID}`,
                        method: 'patch',
                        data,
                        params: {
                            ...params,
                            responseType:
                                responseType ?? API_RESPONSE_TYPES.extended,
                        },
                    }
                },
                invalidatesTags: [
                    'finalBalanceProjects',
                    'finalBalanceProjectID',
                    'projectsCustomer',
                ],
            }),

            deleteFinalBalanceProject: builder.mutation({
                query: (payload = {}) => {
                    const { ID, responseType, ...params } = payload
                    return {
                        url: `/final-balance-project/${ID}`,
                        method: 'delete',
                        params: {
                            ...params,
                            responseType:
                                responseType ?? API_RESPONSE_TYPES.extended,
                        },
                    }
                },
                invalidatesTags: ['finalBalanceProjects', 'projectsCustomer'],
            }),

            //final-balance-history
            getFinalBalanceProjectHistory: builder.query({
                query: (payload = {}) => {
                    const { ID, responseType, ...params } = payload
                    return {
                        url: `/final-balance-projects/${ID}/actions-history`,
                        method: 'get',
                        params: {
                            ...params,
                            responseType:
                                responseType ?? API_RESPONSE_TYPES.extended,
                        },
                    }
                },
            }),

            getFinalBalanceProjectsHistoryType: builder.query({
                query: (payload = {}) => {
                    const { responseType, ...params } = payload
                    return {
                        url: `/final-balance-projects/history/types`,
                        method: 'get',
                        params: {
                            ...params,
                            responseType:
                                responseType ?? API_RESPONSE_TYPES.extended,
                        },
                    }
                },
            }),

            //final-balance allocations
            getAllAllocations: builder.query({
                query: (payload = {}) => {
                    const { projectID, ...params } = payload
                    return {
                        url: `/final-balance-project/${projectID}/users-allocations`,
                        method: 'get',
                        params: {
                            ...params,
                            responseType:
                                params?.responseType ??
                                API_RESPONSE_TYPES.extended,
                        },
                    }
                },
                providesTags: ['MembersAllocations', 'Allocations'],
            }),

            getUsersAllocations: builder.query({
                query: (payload = {}) => {
                    const { projectID, ...params } = payload
                    return {
                        url: `/final-balance-projects/${projectID}/allocations`,
                        method: 'get',
                        params: {
                            ...params,
                            responseType:
                                params?.responseType ??
                                API_RESPONSE_TYPES.extended,
                        },
                    }
                },
                providesTags: ['MembersAllocations', 'Allocations'],
            }),

            createFinalBalanceAllocations: builder.mutation({
                query: (payload = {}) => {
                    const { projectID, userID, responseType, ...params } =
                        payload
                    return {
                        url: `/final-balance-projects/${projectID}/users/${userID}/allocations`,
                        method: 'post',
                        params: {
                            ...params,
                            responseType:
                                responseType ?? API_RESPONSE_TYPES.extended,
                        },
                    }
                },
                invalidatesTags: ['MembersAllocations'],
            }),

            getFinalBalanceAllocations: builder.query({
                query: (payload = {}) => {
                    const { projectID, userID, responseType, ...params } =
                        payload
                    return {
                        url: `/final-balance-projects/${projectID}/users/${userID}/allocations`,
                        method: 'get',
                        params: {
                            ...params,
                            responseType:
                                responseType ?? API_RESPONSE_TYPES.extended,
                        },
                    }
                },
                providesTags: ['MembersAllocations'],
            }),

            //final-balance single allocations
            updateFinalBalanceAllocation: builder.mutation({
                query: (payload = {}) => {
                    const {
                        projectID,
                        userID,
                        allocationID,
                        responseType,
                        data,
                        ...params
                    } = payload
                    return {
                        url: `/final-balance-projects/${projectID}/users/${userID}/allocations/${allocationID}`,
                        method: 'patch',
                        data,
                        params: {
                            ...params,
                            responseType:
                                responseType ?? API_RESPONSE_TYPES.extended,
                        },
                    }
                },
                invalidatesTags: ['MembersAllocations'],
            }),

            getByIDFinalBalanceAllocation: builder.query({
                query: (payload = {}) => {
                    const {
                        projectID,
                        userID,
                        allocationID,
                        responseType,
                        ...params
                    } = payload
                    return {
                        url: `/final-balance-projects/${projectID}/users/${userID}/allocations/${allocationID}`,
                        method: 'get',
                        params: {
                            ...params,
                            responseType:
                                responseType ?? API_RESPONSE_TYPES.extended,
                        },
                    }
                },
            }),

            deleteFinalBalanceByID: builder.mutation({
                query: (payload = {}) => {
                    const {
                        projectID,
                        userID,
                        allocationID,
                        responseType,
                        ...params
                    } = payload
                    return {
                        url: `/final-balance-projects/${projectID}/users/${userID}/allocations/${allocationID}`,
                        method: 'delete',
                        params: {
                            ...params,
                            responseType:
                                responseType ?? API_RESPONSE_TYPES.extended,
                        },
                    }
                },
                invalidatesTags: ['MembersAllocations'],
            }),

            deleteMemberAllocated: builder.mutation({
                query: (payload = {}) => {
                    const { projectID, userID, responseType, ...params } =
                        payload
                    return {
                        url: `/final-balance-projects/${projectID}/users/${userID}/allocations`,
                        method: 'delete',
                        params: {
                            ...params,
                            responseType:
                                responseType ?? API_RESPONSE_TYPES.extended,
                        },
                    }
                },
                invalidatesTags: ['MembersAllocations'],
            }),

            //confirm allocations
            confirmAllocations: builder.mutation({
                query: (payload = {}) => {
                    const { projectID, data, responseType, ...params } = payload
                    return {
                        url: `/final-balance-project/${projectID}/confirm-allocations`,
                        method: 'post',
                        data,
                        params: {
                            ...params,
                            responseType:
                                responseType ?? API_RESPONSE_TYPES.extended,
                        },
                    }
                },
                invalidatesTags: ['Allocations'],
            }),

            //invoices
            createInvoiceFinalBalance: builder.mutation({
                query: (payload = {}) => {
                    const { customerID, data, ...params } = payload
                    return {
                        url: `/customers/${customerID}/final-balance-invoices`,
                        method: 'post',
                        data,
                        params: {
                            ...params,
                            responseType:
                                params?.responseType ??
                                API_RESPONSE_TYPES.extended,
                        },
                    }
                },
                invalidatesTags: ['InvoicesSummary'],
            }),

            getInvoicesFinalBalance: builder.query({
                query: (payload = {}) => {
                    const { projectID, ...params } = payload
                    return {
                        url: `/final-balance-projects/${projectID}/invoices`,
                        method: 'get',
                        params: {
                            ...params,
                            responseType:
                                params?.responseType ??
                                API_RESPONSE_TYPES.extended,
                        },
                    }
                },
            }),

            updateInvoiceFinalBalance: builder.mutation({
                query: (payload = {}) => {
                    const { customerID, invoiceID, data, ...params } = payload
                    return {
                        url: `/customers/${customerID}/final-balance-invoices/${invoiceID}`,
                        method: 'patch',
                        data,
                        params: {
                            ...params,
                            responseType:
                                params?.responseType ??
                                API_RESPONSE_TYPES.extended,
                        },
                    }
                },
                invalidatesTags: ['ActiveSchedule'],
            }),

            getInvoiceByIDFinalBalance: builder.query({
                query: (payload = {}) => {
                    const { customerID, invoiceID, ...params } = payload
                    return {
                        url: `/customers/${customerID}/final-balance-invoices/${invoiceID}`,
                        method: 'get',
                        params: {
                            ...params,
                            responseType:
                                params?.responseType ??
                                API_RESPONSE_TYPES.extended,
                        },
                    }
                },
                providesTags: ['ActiveSchedule'],
            }),

            deleteInvoiceFinalBalance: builder.mutation({
                query: (payload = {}) => {
                    const { customerID, invoiceID, ...params } = payload
                    return {
                        url: `/customers/${customerID}/final-balance-invoices/${invoiceID}`,
                        method: 'delete',
                        params: {
                            ...params,
                            responseType:
                                params?.responseType ??
                                API_RESPONSE_TYPES.extended,
                        },
                    }
                },
                invalidatesTags: ['ActiveSchedule'],
            }),
        }
    },
})

export const {
    useGetFinalBalanceProjectsQuery,
    useCreateFinalBalanceProjectMutation,
    useGetFinalBalanceIDQuery,
    useUpdateFinalBalanceProjectMutation,
    useDeleteFinalBalanceProjectMutation,

    useGetFinalBalanceProjectHistoryQuery,
    useGetFinalBalanceProjectsHistoryTypeQuery,

    useGetAllAllocationsQuery,
    useGetUsersAllocationsQuery,
    useCreateFinalBalanceAllocationsMutation,
    useGetFinalBalanceAllocationsQuery,

    useUpdateFinalBalanceAllocationMutation,
    useGetByIDFinalBalanceAllocationQuery,
    useDeleteFinalBalanceByIDMutation,
    useDeleteMemberAllocatedMutation,

    useConfirmAllocationsMutation,

    useCreateInvoiceFinalBalanceMutation,
    useGetInvoicesFinalBalanceQuery,
    useUpdateInvoiceFinalBalanceMutation,
    useGetInvoiceByIDFinalBalanceQuery,
    useDeleteInvoiceFinalBalanceMutation,
} = finalBalanceProjectsApiSlice
