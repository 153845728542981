import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'

import { CloseIcon, DoneIcon } from '@/assets/icons/icons.jsx'
import { StyledIcon } from '@/components/input/styles.js'

const StatusInputIcon = ({
    statusIcon,
    errors,
    touched,
    statusIconCallback,
}) => {
    const theme = useTheme()
    return (
        <>
            {errors ? (
                <StyledIcon>
                    <CloseIcon fill={theme.palette.danger.base.text} />
                </StyledIcon>
            ) : (
                touched && (
                    <StyledIcon>
                        <DoneIcon />
                    </StyledIcon>
                )
            )}
            {statusIcon && (
                <StyledIcon
                    {...(statusIconCallback && {
                        onClick: statusIconCallback,
                    })}
                >
                    {statusIcon}
                </StyledIcon>
            )}
        </>
    )
}

export default StatusInputIcon

StatusInputIcon.propTypes = {
    statusIcon: PropTypes.node,
    errors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    touched: PropTypes.bool,
    statusIconCallback: PropTypes.func,
}
