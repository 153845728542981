import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CustomerIcon } from '@/assets/icons/icons.jsx'
import Sidebar from '@/components/sidebar/Sidebar.jsx'
import Spinner from '@/components/spinner/Spinner.jsx'
import Tabs from '@/components/tabs/Tabs.jsx'
import { useGetCustomerByIdQuery } from '@/services/customers/customersApiSlice.js'
import { TYPE_USERS } from '@/utilities/constants'

import CustomerSidebar from './customer/CustomerSidebar'

const SidebarWrapper = ({ customerId, setDataForm }) => {
    const { t } = useTranslation()
    const [tabValue, setTabValue] = useState(t('customer.title'))

    const { data: customerData, isFetching } = useGetCustomerByIdQuery(
        { ID: customerId },
        { skip: !customerId }
    )

    const sideBarCutomerData = [
        {
            id: 1,
            value: t('customer.title'),
            label: t('customer.title'),
            icon: <CustomerIcon />,
            component: (
                <>
                    {customerData && (
                        <CustomerSidebar
                            data={customerData}
                            setOpenForm={setDataForm}
                        />
                    )}
                </>
            ),
        },
        /*{
            id: 3,
            value: t('customer.sidebar_left.tabs.history'),
            label: t('customer.sidebar_left.tabs.history'),
            icon: <HistoryIcon />,
            component: <>{historyData && <></>}</>,
        },*/
    ]

    return (
        <>
            {isFetching && <Spinner />}
            <Sidebar
                openIcon={true}
                onClose={() => {
                    setTabValue(t('customer.title'))
                }}
                open={true}
            >
                <Tabs
                    defaultValue={tabValue}
                    triggers={sideBarCutomerData}
                    isSidePanel={true}
                    haveDivider
                    onValueChange={e => setTabValue(e)}
                />
            </Sidebar>
        </>
    )
}

export default SidebarWrapper

SidebarWrapper.propTypes = {
    customerId: PropTypes.number,
    panelType: PropTypes.oneOf([TYPE_USERS.members, TYPE_USERS.teams]),
    setDataForm: PropTypes.func,
}
