import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import DialogDelete from '@/components/dialog-delete/DialogDelete.jsx'
import DialogSafeClose from '@/components/dialog-safe-close/DialogSafeClose.jsx'
import ProgressCard from '@/components/progress-card/ProgressCard.jsx'
import Spinner from '@/components/spinner/Spinner.jsx'
import SubHeader from '@/components/subheader/SubHeader.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import ProjectDetailsForm from '@/pages/projects/detail/detail-standard-projects/project-form/ProjectDetailsForm.jsx'
import ProjectSubHeader from '@/pages/projects/detail/detail-standard-projects/project-sub-header/ProjectSubHeader.jsx'
import ProjectTabsDetails from '@/pages/projects/detail/detail-standard-projects/project-tabs/ProjectTabsDetails.jsx'
import { StyledContainerFluid } from '@/pages/settings/styles.js'
import { useGetCustomersListQuery } from '@/services/customers/customersApiSlice.js'
import {
    useDeleteCostMutation,
    useDeleteInvoiceStandardMutation,
    useDeleteStandardSalMutation,
    useGetProjectIDQuery,
    useGetSalsQuery,
    useGetSuppliersQuery,
    useGlobalProjectIDQuery,
} from '@/services/projects/projectsApiSlice.js'
import {
    useGetMembersQuery,
    useGetResponsibilitiesQuery,
    useGetTeamsQuery,
} from '@/services/settings/settingApiSlice.js'
import {
    handleApiError,
    remapAPIForResponsibilities,
    remapSelectOptions,
} from '@/utilities/api/helpers.js'
import { remapRespForThreeCards } from '@/utilities/api/remapResponse.js'
import { LABELS_TABLES } from '@/utilities/constants/index.js'

import axios from '../../../../services/axiosInstance'
import SidebarDetail from '../sidebar-detail/SidebarDetail'

const ProjectDetail = () => {
    const { id: globalSalID } = useParams()
    //take id from global sal id
    const { data: data } = useGlobalProjectIDQuery({ globalSalID: globalSalID })
    const id = data?.id

    const { t } = useTranslation()
    const showError = useError()
    const { triggerAlert } = useAlert()
    const [selectedTab, setSelectedTab] = useState(
        t('projects.tabs.activities')
    )
    const [openDialogToConfirm, setOpenDialogToConfirm] = useState(false)
    const [openDialogToDelete, setOpenDialogToDelete] = useState(false)
    const [idSelected, setIdSelected] = useState(null)
    const [idToDelete, setIdToDelete] = useState(null)

    const [dataForm, setDataForm] = useState({
        isOpen: false,
        typeForm: null,
        id: null,
        isEdit: false,
        data: null,
    })
    const { data: project } = useGetProjectIDQuery(
        { ID: id },
        {
            skip: !id,
        }
    )

    const [deleteSal] = useDeleteStandardSalMutation()
    const [deleteCost] = useDeleteCostMutation()
    const [deleteInvoice] = useDeleteInvoiceStandardMutation()
    const { data: teams } = useGetTeamsQuery()
    const { data: applicants } = useGetMembersQuery()
    const { data: successManagers } = useGetMembersQuery({
        is_success_manager: true,
    })
    const { data: responsibilities } = useGetResponsibilitiesQuery({})
    const { data: customers, isFetching } = useGetCustomersListQuery()
    const { data: sals } = useGetSalsQuery({ ID: id }, { skip: !id })
    const { data: suppliers } = useGetSuppliersQuery()

    const onClickHandler = () => {
        return setDataForm({
            isOpen: true,
            typeForm: selectedTab,
            isEdit: false,
        })
    }

    const openEditSidebar = (id, typeForm) => {
        setDataForm({
            isOpen: true,
            typeForm: typeForm,
            isEdit: true,
            id: id,
        })
    }

    const handleDelete = async () => {
        try {
            switch (selectedTab) {
                case LABELS_TABLES.activities:
                    await deleteSal({
                        ID: id,
                        salID: idSelected,
                    }).unwrap()
                    break
                case LABELS_TABLES.billing:
                    await deleteInvoice({
                        projectID: id,
                        invoiceID: idSelected,
                    }).unwrap()
                    break
                case LABELS_TABLES.costs:
                    await deleteCost({
                        projectID: id,
                        salID: idToDelete.salID,
                        expensesID: idToDelete.expensesID,
                    }).unwrap()
                    break
                default:
                    break
            }
            setOpenDialogToDelete(false)
            setDataForm({})
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: t('commons:dialog.success'),
            })
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        }
    }

    const openDialogDelete = (id, idToDelete) => {
        setIdSelected(id)
        setOpenDialogToDelete(true)
        setIdToDelete({
            ...idToDelete,
        })
    }

    const dataAnalytics =
        data?.analytics && remapRespForThreeCards(data?.analytics, data)

    const [analyticsInvoices, setAnalyticsInovices] = useState([])

    const handleClick = async () => {
        try {
            await axios.get(`/floatSync/projects/${globalSalID}/sync`)
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        }
    }

    return (
        <>
            {isFetching && <Spinner />}
            <SidebarDetail projectData={project} setDataForm={setDataForm} />
            <StyledContainerFluid className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-4">
                        <SubHeader title={project?.name}>
                            <ProjectSubHeader
                                onClickHandler={onClickHandler}
                                selectedTab={selectedTab}
                                notHavePhase={
                                    data?.has_logged_time_without_phase
                                }
                                onClickToUpdate={handleClick}
                            />
                        </SubHeader>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <ProjectTabsDetails
                            id={id}
                            isLoading={isFetching}
                            openEditSidebar={openEditSidebar}
                            openDialogDelete={openDialogDelete}
                            setSelectedTab={setSelectedTab}
                            setAnalyticsInovices={setAnalyticsInovices}
                        />
                    </div>
                </div>

                <div className="row">
                    {selectedTab !== LABELS_TABLES.costs && (
                        <div
                            className={
                                selectedTab !== LABELS_TABLES.billing
                                    ? `col-7`
                                    : `col-5`
                            }
                        >
                            <ProgressCard
                                data={
                                    selectedTab !== LABELS_TABLES.billing
                                        ? dataAnalytics
                                        : analyticsInvoices
                                }
                            />
                        </div>
                    )}
                </div>

                <DialogDelete
                    open={openDialogToDelete}
                    onClose={() => setOpenDialogToDelete(false)}
                    onDelete={handleDelete}
                />

                {openDialogToConfirm && (
                    <DialogSafeClose
                        goBack={() => {
                            setDataForm({})
                            setOpenDialogToConfirm(false)
                        }}
                        onClose={() => setOpenDialogToConfirm(false)}
                        open={openDialogToConfirm}
                    />
                )}

                {dataForm.isOpen && (
                    <ProjectDetailsForm
                        dataForm={dataForm}
                        onClose={() => setDataForm({})}
                        isEdit={dataForm.isEdit}
                        setOpenDialogToConfirm={setOpenDialogToConfirm}
                        openDialogDelete={openDialogDelete}
                        applicants={remapSelectOptions(applicants?.data)}
                        customers={remapSelectOptions(customers?.data)}
                        teams={teams?.data}
                        successManagers={remapSelectOptions(
                            successManagers?.data
                        )}
                        responsibilities={remapAPIForResponsibilities(
                            responsibilities
                        )}
                        sals={remapSelectOptions(sals)}
                        suppliers={suppliers}
                    />
                )}
            </StyledContainerFluid>
        </>
    )
}

export default ProjectDetail
