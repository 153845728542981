import { Outlet } from 'react-router-dom'

import Toast from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'

const PublicLayout = () => {
    const { isAlertOpen, alertData, closeAlert } = useAlert()

    return (
        <>
            <Toast
                visible={isAlertOpen}
                message={alertData.message}
                variant={alertData.variant}
                dismissCallback={closeAlert}
                position={'top-right'}
            />
            <Outlet />
        </>
    )
}

export default PublicLayout
