import PropTypes from 'prop-types'
import ContentTableLoader from 'react-content-loader'

const TableLoader = ({ height = 24, width = '100%' }) => {
    return (
        <ContentTableLoader
            height={height}
            width={width}
            primarycolor="#ddd"
            secondarycolor="#eee"
        >
            <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
        </ContentTableLoader>
    )
}

export default TableLoader

TableLoader.propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
