import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import styled, { css } from 'styled-components'

export const StyledDropdownTrigger = styled(DropdownMenu.Trigger)`
    cursor: pointer;
    display: flex;
    align-items: center;
`

export const StyledDropdownContent = styled(DropdownMenu.Content)`
    ${({ theme, minwidth }) => css`
        min-width: ${minwidth}px;
        padding: ${theme.spacing}px ${theme.spacing * 2}px;
        box-shadow: ${theme.shadows[10]};
        border: 1px solid ${theme.palette.neutral.base[200]};
        background-color: ${theme.palette.neutral.base[0]};
        border-radius: ${theme.spacing - 2}px;
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing}px;
        &[data-side='top'] {
            animation-name: slideDownAndFade;
        }
        &[data-side='right'] {
            animation-name: slideLeftAndFade;
        }
        &[data-side='bottom'] {
            animation-name: slideUpAndFade;
        }
        &[data-side='left'] {
            animation-name: slideRightAndFade;
        }
        @keyframes slideUpAndFade {
            from {
                opacity: 0;
                transform: translateY(2px);
            }
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }

        @keyframes slideRightAndFade {
            from {
                opacity: 0;
                transform: translateX(-2px);
            }
            to {
                opacity: 1;
                transform: translateX(0);
            }
        }

        @keyframes slideDownAndFade {
            from {
                opacity: 0;
                transform: translateY(-2px);
            }
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }

        @keyframes slideLeftAndFade {
            from {
                opacity: 0;
                transform: translateX(2px);
            }
            to {
                opacity: 1;
                transform: translateX(0);
            }
        }

        & h3 {
            ${theme.typography.paragraph.p12.bold};
        }
    `}
`

export const StyledDropdownItem = styled(DropdownMenu.Item)`
    ${({ theme, haveHover }) => css`
        cursor: pointer;
        padding: ${theme.spacing / 2}px;
        border-radius: 8px;

        &[data-disabled] {
            color: ${theme.palette.secondary.base.text};
            pointer-events: none;
        }

        ${haveHover &&
        css`
            &:focus-within {
                background: ${theme.palette.neutral.base[100]};
            }
        `}
    `}
`

export const StyledDropdownItemChild = styled.div`
    ${({ theme, isToDelete, disabled }) => css`
        cursor: pointer;
        display: flex;
        justify-content: start;
        align-items: center;
        gap: ${theme.spacing}px;

        &.icon-container,
        svg {
            height: 10px;
            width: 9px;

            & g {
                ${disabled &&
                css`
                    fill: ${theme.palette.neutral.base[200]};
                `}
            }
        }

        & span {
            ${theme.typography.paragraph.p12.regular};
            color: ${isToDelete
                ? theme.palette.danger.base.active
                : theme.palette.neutral.base[1000]};
            ${disabled &&
            css`
                cursor: not-allowed;
                color: ${theme.palette.neutral.base[200]};
            `}
        }
    `}
`
