import PropTypes from 'prop-types'

import ItemProgressCard from '@/components/progress-card/ItemProgressCard.jsx'
import { StyledContainerCard } from '@/components/progress-card/styles.js'

const ProgressCard = ({ data }) => {
    return (
        <div
            style={{
                position: 'absolute',
                bottom: '0',
                marginBottom: '16px',
            }}
        >
            <StyledContainerCard>
                {data?.map((item, index) => {
                    return (
                        <>
                            <ItemProgressCard
                                key={index}
                                item={item}
                                lastItem={data[data.length - 1].id}
                            />
                        </>
                    )
                })}
            </StyledContainerCard>
        </div>
    )
}

export default ProgressCard

ProgressCard.propTypes = {
    data: PropTypes.array,
}
