import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { EditIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import SectionItem from '@/components/sidebar-tab-item/section-item/SectionItem'
import SectionTitle from '@/components/sidebar-tab-item/section-title/SectionTitle'
import {
    StyledSliderSectionContainer,
    StyledTabInfoContainer,
    StyledSidebarSubHeader,
    StyledTabCtaContainer,
} from '@/components/sidebar-tab-item/styles'
import { LABELS_TABLES } from '@/utilities/constants/index.js'

import { customerFields } from './customerFields'

const CustomerSidebar = ({ data, setOpenForm }) => {
    const { t } = useTranslation()
    const theme = useTheme()

    const customer = data
    const { success_manager } = data

    const customerData = customerFields(customer, success_manager)

    return (
        <>
            <StyledSidebarSubHeader>
                {
                    <h3>
                        {data?.name} #{data?.id}
                    </h3>
                }
                <StyledTabCtaContainer>
                    <Button
                        onClick={() =>
                            setOpenForm({
                                open: true,
                                formType: LABELS_TABLES.customer,
                                isEdit: true,
                                id: data?.id,
                                data: data,
                            })
                        }
                        collapsed
                    >
                        <EditIcon
                            width={10}
                            height={10}
                            fill={theme.palette.neutral.base[0]}
                        />
                    </Button>
                </StyledTabCtaContainer>
            </StyledSidebarSubHeader>
            <StyledSliderSectionContainer>
                <StyledTabInfoContainer>
                    <SectionItem
                        label={t('customer.sidebar_left.labels.business_name')}
                        value={customer.name}
                        width={100}
                    />
                </StyledTabInfoContainer>
            </StyledSliderSectionContainer>

            <StyledSliderSectionContainer>
                <SectionTitle
                    title={t('customer.sidebar_left.subtitles.billing_data')}
                />
                <StyledTabInfoContainer>
                    {customerData.billing_data.map((item, index) => {
                        return (
                            <SectionItem
                                label={item.label}
                                value={item.value}
                                width={item.width}
                                key={index}
                            />
                        )
                    })}
                </StyledTabInfoContainer>
            </StyledSliderSectionContainer>

            <StyledSliderSectionContainer>
                <SectionTitle
                    title={t('customer.sidebar_left.subtitles.link')}
                />

                <StyledTabInfoContainer>
                    <SectionItem
                        label={t('customer.sidebar_left.labels.link_drive')}
                        value={customer.drive_url}
                        link={true}
                        width={100}
                    />
                </StyledTabInfoContainer>
            </StyledSliderSectionContainer>

            <StyledSliderSectionContainer>
                <SectionTitle
                    title={t('customer.sidebar_left.subtitles.billing_address')}
                />

                <StyledTabInfoContainer>
                    {customerData.customer_address.map((item, index) => {
                        return (
                            <SectionItem
                                label={item.label}
                                value={item.value}
                                width={item.width}
                                key={index}
                            />
                        )
                    })}
                </StyledTabInfoContainer>
            </StyledSliderSectionContainer>

            <StyledSliderSectionContainer>
                <SectionTitle
                    title={t('customer.sidebar_left.subtitles.success_data')}
                />

                <StyledTabInfoContainer>
                    {customerData.account_data.map((item, index) => {
                        return (
                            <SectionItem
                                label={item.label}
                                value={item.value}
                                width={item.width}
                                key={index}
                            />
                        )
                    })}
                </StyledTabInfoContainer>
            </StyledSliderSectionContainer>

            <div>
                <SectionTitle title={t('customer.sidebar_left.labels.note')} />

                <StyledTabInfoContainer>
                    <SectionItem label="" value={customer.note} width={100} />
                </StyledTabInfoContainer>
            </div>
        </>
    )
}

export default CustomerSidebar

CustomerSidebar.propTypes = {
    data: PropTypes.any,
    setOpenForm: PropTypes.func,
}
