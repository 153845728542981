import styled, { css } from 'styled-components'

import { StyledIcons } from '../date-picker/styles'
import { StyledInputWrapper } from '../input/styles'
import { StyledInputWrapper as InputCurrency } from '../input-currency/styles'

export const StyledContainerStage = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        gap: ${theme.spacing * 2}px;
    `}
`

//REMOVE INPUT STYLE

export const StyledCellRowContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        width: 100%;

        & ${StyledInputWrapper}, ${InputCurrency} {
            border: none !important;
            padding: 0px;
        }

        & .select__control {
            border: none !important;
            min-height: 24px;
        }

        & .react-datepicker__input-container {
            & input {
                border: none !important;
                height: 24px;
                padding-left: ${theme.spacing}px;
                padding-right: ${theme.spacing}px;
            }
        }

        & ${StyledIcons} {
            display: none;
        }
    `}
`

export const StyledSalContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        gap: ${theme.spacing * 2}px;
    `}
`

export const StyledSalItem = styled.div`
    ${({ theme, disabled }) => css`
        display: flex;
        align-items: center;
        width: 100%;
        gap: ${theme.spacing / 2}px;
        padding: ${theme.spacing}px;
        min-height: 30px;

        & .sal_input {
            width: 100%;
        }

        & .sal_value {
            width: 100%;
            display: flex;
            align-items: center;
        }

        ${disabled &&
        css`
            color: ${theme.palette.neutral.base[200]};
            cursor: not-allowed;
        `}
    `}
`

export const StyledSalNumber = styled.div`
    ${({ theme, disabled }) => css`
        display: flex;
        align-items: center;
        width: 100%;
        gap: ${theme.spacing / 2}px;
        padding: ${theme.spacing}px;
        min-height: 30px;

        & div {
            padding: 0;
        }

        & .sal_input {
            width: 100%;
            padding: ${theme.spacing / 2}px;
            border-radius: 5px;
        }

        & .sal_value {
            width: 100%;
            min-height: 24px;
            display: flex;
            align-items: center;
        }

        ${disabled &&
        css`
            color: ${theme.palette.neutral.base[200]};
            cursor: not-allowed;
        `}
    `}
`

export const StyledDeliveryButton = styled.p`
    ${({ theme, disabled }) => css`
        color: ${theme.palette.neutral.base[1000]};
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
        ${disabled &&
        css`
            color: ${theme.palette.neutral.base[200]};
            cursor: not-allowed;
        `}
    `}
`
