import PropTypes from 'prop-types'
import { useState } from 'react'

import ContractFormWrapper from '@/pages/settings/settings-form//contract-form/ContractFormWrapper'
import AssignTeamFormWrapper from '@/pages/settings/settings-form/assign-teams-form/AssignTeamFormWrapper'
import MemberFormWrapper from '@/pages/settings/settings-form/member-form/MemberFormWrapper'
import TeamFormWrapper from '@/pages/settings/settings-form/team-form/TeamFormWrapper'
import { TYPE_USERS } from '@/utilities/constants/index.js'

const SettingsRenderForm = ({ isEdit, openForm, onClose, filter }) => {
    const [openDialogToConfirm, setOpenDialogToConfirm] = useState(false)

    const closeModal = isDirty => {
        if (isDirty) {
            setOpenDialogToConfirm(true)
        } else {
            onClose()
        }
    }

    const props = {
        onClose,
        isEdit,
        openForm,
        openDialogToConfirm,
        setOpenDialogToConfirm,
        closeModal,
    }

    switch (openForm?.typeForm) {
        case TYPE_USERS.contract:
            return (
                <ContractFormWrapper
                    userID={openForm?.data?.userID}
                    contractID={openForm?.data?.id}
                    {...props}
                />
            )
        case TYPE_USERS.assignTeam:
            return (
                <AssignTeamFormWrapper
                    historyTeamID={openForm?.data?.team?.id}
                    userTeamHistoryID={openForm?.data?.id}
                    userID={openForm?.data?.userID}
                    {...props}
                />
            )
        case TYPE_USERS.members:
            return (
                <MemberFormWrapper
                    userID={openForm?.id}
                    filter={filter}
                    {...props}
                />
            )

        case TYPE_USERS.teams:
            return (
                <TeamFormWrapper
                    filter={filter}
                    teamID={openForm?.id}
                    {...props}
                />
            )
    }
}

export default SettingsRenderForm

SettingsRenderForm.propTypes = {
    isEdit: PropTypes.bool,
    openForm: PropTypes.object,
    onClose: PropTypes.func,
    filter: PropTypes.object,
}
