import PropTypes from 'prop-types'

import { daysInMonth } from '@/utilities/helpers.js'

import { StyledCalendarUserContainer } from '../styles'

const EmptyTableBody = ({ selectedMonth, selectedYear }) => {
    const numberOfDays = daysInMonth(selectedMonth, selectedYear)
    const rowData = []
    rowData.push(
        <StyledCalendarUserContainer>
            <div className="name_container">
                <p className="name_"></p>
            </div>
        </StyledCalendarUserContainer>
    )
    for (let day = 1; day <= numberOfDays; day++) {
        rowData.push(<div key={day}></div>)
    }
    return (
        <div>
            <div>{rowData}</div>
        </div>
    )
}

export default EmptyTableBody

EmptyTableBody.propTypes = {
    selectedMonth: PropTypes.number,
    selectedYear: PropTypes.number,
    data: PropTypes.any,
}
