import styled, { css } from 'styled-components'

export const StyledTRow = styled.div`
    ${({ theme: { spacing, colors, mediaQuery }, clickableRowPath }) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 1.25}px;
        background: ${colors.white};
        border-radius: 8px;
        border: 1px solid ${colors.transparentBlack};
        padding: ${spacing * 2}px;
        margin-right: ${spacing / 4}px;

        &:not(last-child) {
            margin-bottom: ${spacing * 2}px;
        }

        ${clickableRowPath &&
        css`
            transition: ease-in-out 200ms;
            cursor: pointer;
            &:hover {
                background: ${colors.backgroundGrey};
            }
        `}

        ${mediaQuery.small} {
            gap: ${spacing * 2}px;
        }
    `}
`

export const StyledTCell = styled.div`
    ${({ theme: { spacing, mediaQuery } }) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: ${spacing / 2}px;
        min-width: 0;

        * {
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        ${mediaQuery.small} {
            flex-direction: column;
        }
    `}
`

export const StyledTCellLabel = styled.div`
    ${({ theme: { spacing, fontSize, colors } }) => css`
        flex: none;
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        font-size: ${fontSize.xxs}px;
        color: ${colors.darkGrey};

        & svg {
            width: 16px;
        }
    `}
`

export const StyledTCellValue = styled.span`
    ${({ theme: { fontSize }, mobileFullLength }) => css`
        font-size: ${fontSize.xxs}px;
        font-weight: 600;
        width: ${mobileFullLength ? '100%' : 'auto'};
    `}
`

export const StyledExpandedRow = styled.div`
    width: 100%;
`

export const StyledSubRow = styled.div`
    ${({ theme: { colors, fontSize } }) => css`
        width: 100%;
        color: ${colors.lightBlack};
        font-size: ${fontSize.xxs}px;
    `}
`
