import styled, { css } from 'styled-components'
export const StyledContainer = styled.div`
    ${({ theme, fullWidth }) => css`
        padding-right: ${theme.spacing}px;

        ${fullWidth &&
        css`
            padding: 0;
            width: 100%;
        `}
    `}
`

export const StyledChipContainer = styled.div`
    ${({
        theme,
        outlined,
        textOnly,
        size,
        rounded,
        isStrokeIcon,
        isPathFilledIcon,
        iconPosition,
        textUppercase,
        isClickable,
        fullWidth,
        disabled,
        variant,
    }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${theme.spacing}px;
        ${theme.typography.paragraph.p14.regular};
        flex-flow: ${iconPosition === 'left' ? 'row' : 'row-reverse'};
        ${size === 'small'
            ? css`
                  height: 16px !important;
              `
            : css`
                  height: 24px;
              `};
        ${size === 'large'
            ? css`
                  height: 32px;
              `
            : css`
                  height: 24px;
              `};
        width: ${fullWidth ? '100%' : 'max-content'};
        max-width: 100%;
        padding: ${fullWidth
            ? `0 ${theme.spacing}px`
            : `0 ${theme.spacing * 2}px`};
        border-radius: ${rounded ? `${theme.spacing * 4}px` : '4px'};
        background: ${outlined || textOnly
            ? 'transparent'
            : `${
                  variant
                      ? theme.palette[variant]?.base.active
                      : theme.palette.neutral.base[200]
              }`};
        border: none;
        ${outlined &&
        css`
            border: 1px solid ${theme.palette.primary.base.text};
        `};
        ${disabled &&
        css`
            opacity: 0.5;
            cursor: not-allowed !important;
        `};
        ${isClickable &&
        !disabled &&
        css`
            transition: ease-in-out 400ms;
            cursor: pointer;
        `}
        & svg {
            ${size === 'large'
                ? css`
                      height: 16px;
                  `
                : css`
                      height: 12px;
                  `};
            ${!isStrokeIcon &&
            css`
                fill: ${theme.palette.primary.base.text};
            `};
            & path {
                ${!isStrokeIcon &&
                css`
                    fill: ${theme.palette.primary.base.text};
                `};
                ${isPathFilledIcon && (outlined || textOnly)
                    ? css`
                          fill: ${theme.palette.primary.base.text};
                      `
                    : css`
                          fill: ${theme.palette.neutral.base[0]};
                      `};
            }
        }
        & span {
            ${theme.typography.paragraph.p14.regular};
            text-transform: ${textUppercase ? 'uppercase' : 'inherit'};
            color: ${outlined || textOnly
                ? theme.palette.primary.base.text
                : theme.palette.neutral.base[0]};
        }
    `}
`
