import styled, { css } from 'styled-components'

import Button from '@/components/button/Button.jsx'

export const StyledQueryButton = styled(Button)`
    ${({ theme }) => css`
        background: ${theme.palette.neutral.base[0]};
        color: ${theme.palette.neutral.base[1000]};
        justify-content: start;
        ${theme.typography.paragraph.p14.regular};
        padding: ${theme.spacing}px;
        border-radius: ${theme.spacing}px;
        border: 4px solid ${theme.palette.neutral.base[0]};
    `}
`
