import * as yup from 'yup'

import i18next from '../../../../i18n.js'

const ASSIGN_TEAM_MODEL = {
    team: 'team_id',
    start_date: 'joined_at',
    end_date: 'leaved_at',
}

const DEFAULT_ASSIGN_TEAM_MOCK = {
    [ASSIGN_TEAM_MODEL.team]: null,
    [ASSIGN_TEAM_MODEL.start_date]: null,
    [ASSIGN_TEAM_MODEL.end_date]: null,
}
const RequestValidationSchema = yup.object().shape({
    [ASSIGN_TEAM_MODEL.team]: yup
        .object()
        .required(i18next.t('validation:general_form.name_required')),
    [ASSIGN_TEAM_MODEL.start_date]: yup
        .date()
        .required(i18next.t('validation:general_form.start_date_required')),
    [ASSIGN_TEAM_MODEL.end_date]: yup.date().nullable(),
})

export {
    ASSIGN_TEAM_MODEL,
    DEFAULT_ASSIGN_TEAM_MOCK,
    RequestValidationSchema as validationSchema,
}
