import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'

import EmptyTableBody from '@/components/calendar-day/table-body/EmptyTableBody'
import TableBody from '@/components/calendar-day/table-body/TableBody.jsx'
import TableHeader from '@/components/calendar-day/table-header/TableHeader.jsx'
import TableDateSelect from '@/components/calendar-day/table-select/TableDateSelect'
import { remappedData } from '@/pages/customers/log/remappedData.js'
import { daysInMonth } from '@/utilities/helpers.js'

import {
    StyledCalendarDayContainer,
    StyledCalendarDayHeadContainer,
} from './styles'

const CalendarDay = ({
    month,
    year,
    userAllocated,
    data,
    setDataForm,
    setSelectedMonthAndYear,
    finalBalance,
    selectedMonthAndYear,
}) => {
    const [selectedMonth, setSelectedMonth] = useState(
        new Date().getMonth() + 1
    )
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const numberOfDays = daysInMonth(selectedMonth, selectedYear)
    const days = [...Array(numberOfDays).keys()].map((_, i) => i + 1)

    const currentData = useMemo(() => {
        return remappedData(data, userAllocated, selectedMonth)
    }, [data, selectedMonth, userAllocated])

    useEffect(() => {
        if (selectedMonth && selectedYear) {
            setSelectedMonthAndYear({
                month: selectedMonth,
                year: selectedYear,
            })
        }
    }, [selectedMonth, selectedYear, setSelectedMonthAndYear])

    useEffect(() => {
        if (year || month) {
            setSelectedYear(year)
            setSelectedMonth(month)
        }
    }, [year, month])

    useEffect(() => {
        if (selectedMonthAndYear?.isFilter) {
            setSelectedYear(selectedMonthAndYear.year)
            setSelectedMonth(selectedMonthAndYear.month)
        }
    }, [selectedMonthAndYear])
    const handleChangeDate = direction => {
        if (direction === 'increase') {
            if (selectedMonth === 12) {
                setSelectedMonth(1)
                setSelectedYear(selectedYear + 1)
            } else {
                setSelectedMonth(selectedMonth + 1)
            }
        } else {
            if (selectedMonth === 1) {
                setSelectedMonth(12)
                setSelectedYear(selectedYear - 1)
            } else {
                setSelectedMonth(selectedMonth - 1)
            }
        }
    }

    //SHORTCUTS
    // Tab = forward navigaiton and confirm status
    // Shift + Tab = backward navigation

    return (
        <StyledCalendarDayContainer>
            <StyledCalendarDayHeadContainer>
                <TableDateSelect
                    handleChangeDate={handleChangeDate}
                    selectedMonth={selectedMonth}
                    selectedYear={selectedYear}
                    currentData={currentData}
                />
                <TableHeader
                    selectedMonth={selectedMonth}
                    selectedYear={selectedYear}
                    days={days}
                    currentData={currentData}
                />
            </StyledCalendarDayHeadContainer>

            {currentData?.length ? (
                <TableBody
                    currentData={currentData}
                    setDataForm={setDataForm}
                    finalBalance={finalBalance}
                />
            ) : (
                <EmptyTableBody
                    selectedMonth={selectedMonth}
                    selectedYear={selectedYear}
                />
            )}
        </StyledCalendarDayContainer>
    )
}

export default CalendarDay

CalendarDay.propTypes = {
    month: PropTypes.number,
    year: PropTypes.number,
    data: PropTypes.any,
    setDataForm: PropTypes.func,
    setSelectedMonthAndYear: PropTypes.func,
    userAllocated: PropTypes.array,
    finalBalance: PropTypes.object,
    selectedMonthAndYear: PropTypes.object,
}
