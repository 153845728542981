import PhoneInput from 'react-phone-input-2'
import styled, { css } from 'styled-components'

export const StyledContainer = styled.div`
    ${({ width }) => css`
        display: flex;
        width: ${width};
        flex-direction: column;
    `}
`

export const StyledLabel = styled.label`
    ${({
        theme: { spacing, palette, truncateText, typography },
        disabled,
    }) => css`
        display: block;
        margin-bottom: ${spacing}px;
        color: ${palette.neutral.base[500]};
        ${typography.heading4}

        ${truncateText}
        ${disabled &&
        css`
            color: ${palette.neutral.base[200]};
        `}
    `}
`

export const StyledInputWrapper = styled.div`
    ${({ theme, hasError, isSuccess, disabled, width }) => css`
        display: flex;
        align-items: center;
        width: ${width};
        border: 1px solid ${theme.palette.neutral.base[200]};
        border-radius: ${theme.spacing - 2}px;
        gap: ${theme.spacing}px;
        padding-right: ${theme.spacing + 4}px;
        padding-left: ${theme.spacing - 7}px;
        ${theme.typography.paragraph.p14.regular}

        ${hasError &&
        css`
            border: 1px solid ${theme.palette.danger.base.text};
        `};

        ${isSuccess &&
        css`
            border: 1px solid ${theme.palette.success.base.text};
        `};

        &:focus-within {
            ${!hasError &&
            css`
                box-shadow: ${theme.shadows[20]};
                border: 1px solid ${theme.palette.primary.base.active};
            `}
        }

        ${disabled &&
        css`
            border: 1px solid ${theme.palette.neutral.base[200]};
            background-color: transparent;
            cursor: not-allowed;
            color: ${theme.palette.neutral.base[200]};
        `};

        ${!disabled &&
        css`
            &:hover {
                box-shadow: ${theme.shadows[20]};
            }
        `}
    `}
`

export const StyledInput = styled(PhoneInput)`
    ${({ theme }) => css`
        .react-tel-input,
        .form-control {
            height: 37px;
            width: 100%;
            border: none;
            ${theme.typography.paragraph.p14.regular}
            border-radius: ${theme.spacing - 2}px;
        }

        .flag-dropdown {
            background-color: transparent;
            border: none;
            padding: 0;
            border-right: 1px solid ${theme.palette.neutral.base[200]};
        }

        .country,
        .dial-code {
            ${theme.typography.paragraph.p14.regular}
            color: ${theme.palette.neutral.base[1000]} !important;
            border-radius: ${theme.spacing - 2}px;
        }

        .country-list {
            box-shadow: none;
            border-radius: ${theme.spacing - 2}px;
            border: 1px solid ${theme.palette.neutral.base[200]};
        }

        .country {
            height: 26px;
            padding: 0 ${theme.spacing}px !important;
            margin: 0 !important;
            display: flex;
            align-items: center !important;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .highlight {
            background-color: ${theme.palette.secondary.base.active} !important;
            color: ${theme.palette.neutral.base[0]} !important;

            & .dial-code {
                color: ${theme.palette.neutral.base[0]} !important;
            }
        }

        .flag {
            margin-right: ${theme.spacing}px;
            border-radius: ${theme.spacing - 6}px;
        }

        .country-list {
            width: 280px;
            padding: 0 4px;
            overflow-x: hidden;
        }

        .search,
        .search-box {
            padding: 0 !important;
            margin: 0 !important;
            cursor: text !important;
        }

        .search {
            height: 32px;
            border-bottom: 1px solid ${theme.palette.neutral.base[200]} !important;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0 4px;

            .search-emoji {
                content: url('https://img.icons8.com/search');
                height: 12px;
                width: 12px;
                margin: 0 ${theme.spacing}px;
            }
        }

        .search-box {
            border-radius: 0;
            border: none !important;
            box-shadow: none !important;
            color: ${theme.palette.neutral.base[1000]} !important;

            ${theme.typography.paragraph.p14.regular} !important;

            &::placeholder {
                color: ${theme.palette.neutral.base[200]} !important;
            }

            &::-webkit-search-cancel-button {
                -webkit-appearance: none;
            }
        }

        .no-entries-message {
            ${theme.typography.paragraph.p14.regular}
            color: ${theme.palette.neutral.base[1000]} !important;
        }
    `}
`
