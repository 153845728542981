import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Search from '@/components/search/Search.jsx'
import Select from '@/components/select/Select.jsx'
import { StyledContainerPassiveScheduleFinance } from '@/pages/finance/finance-filters/styles.js'
import { CONTRACT_TYPE_OPTIONS } from '@/utilities/constants/list.js'
import { translateOptions } from '@/utilities/helpers.js'

const PassiveSchedule = ({ setFilters, filters }) => {
    const { t } = useTranslation()

    return (
        <StyledContainerPassiveScheduleFinance>
            <Select
                isControlled={false}
                size={'medium'}
                placeholder={t('general.typology')}
                options={translateOptions(CONTRACT_TYPE_OPTIONS)}
                onChange={value =>
                    setFilters({
                        ...filters,
                        contractType: value?.value,
                    })
                }
            />

            <Search
                placeholder={t('settings.filters.placeholder.search')}
                handleSearch={value => {
                    setFilters({ search: value })
                }}
            />
        </StyledContainerPassiveScheduleFinance>
    )
}

export default PassiveSchedule

PassiveSchedule.propTypes = {
    setFilters: PropTypes.func,
    filters: PropTypes.object,
}
