import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Select from '@/components/select/Select.jsx'
import SubHeader from '@/components/subheader/SubHeader'
import buttonList from '@/pages/dashboard/buttonList.js'
import DashboardTables from '@/pages/dashboard/dashboard-body/DashboardTables.jsx'
import DashboardTablesTitle from '@/pages/dashboard/dashboard-body/DashboardTablesTitle.jsx'
import {
    columnsFirst,
    columnsRenderersFirst,
} from '@/pages/dashboard/dashboard-body/dashboard-tables/columnsDashboardFirst.jsx'
import {
    StyledButton,
    StyledContainerButton,
    StyledContainerDashboardBody,
    StyledContainerSelect,
    StyledContainerTables,
} from '@/pages/dashboard/styles.js'

const Dashboard = () => {
    const { t } = useTranslation()
    const [buttonListArray, setButtonListArray] = useState([])

    const [buttonSelected, setButtonSelected] = useState({
        id: 1,
        label: t('dashboard.button.not_assigned_budget'),
        length: 2,
        columns: columnsFirst,
        columnsRenderers: columnsRenderersFirst(),
    })

    useEffect(() => {
        setButtonListArray(buttonList({ t }))
    }, [t])

    return (
        <>
            <div className="container-fluid p-4">
                <div className="row">
                    <div className="col-12 mb-4">
                        <SubHeader title={'Dashboard'}>
                            <StyledContainerSelect>
                                <Select
                                    isControlled={false}
                                    size={'medium'}
                                    placeholder={t('general.team_lead')}
                                />
                            </StyledContainerSelect>
                        </SubHeader>
                    </div>
                </div>

                <div className="row">
                    <StyledContainerDashboardBody>
                        <StyledContainerButton>
                            {buttonListArray.map(button => (
                                <StyledButton
                                    //style
                                    isActive={button?.id === buttonSelected?.id}
                                    //button props
                                    key={button?.id}
                                    size={'large'}
                                    variant={'ghost'}
                                    color={'danger'}
                                    onClick={() =>
                                        setButtonSelected({
                                            ...button,
                                        })
                                    }
                                    disabled={button?.length === 0}
                                >
                                    <p>{button?.label}</p>

                                    <small>({button?.length})</small>
                                </StyledButton>
                            ))}
                        </StyledContainerButton>

                        <StyledContainerTables>
                            <DashboardTablesTitle
                                buttonSelected={buttonSelected}
                            />
                            <DashboardTables buttonSelected={buttonSelected} />
                        </StyledContainerTables>
                    </StyledContainerDashboardBody>
                </div>
            </div>
        </>
    )
}

export default Dashboard
