import { API_RESPONSE_TYPES } from '@/services/axiosInstance.js'

import { apiSlice } from '../apiSlice.js'

//invoices -> scandeziario attivo
export const financeApiSlice = apiSlice.injectEndpoints({
    addTagTypes: ['Payroll'],
    endpoints: builder => ({
        createPayroll: builder.mutation({
            query: (payload = {}) => {
                const { data, ...params } = payload
                return {
                    url: `/payrolls`,
                    method: 'post',
                    data,
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Payroll'],
        }),

        getPayrolls: builder.query({
            query: (payload = {}) => {
                const { data, ...params } = payload
                return {
                    url: `/payrolls`,
                    method: 'get',
                    data,
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['Payroll'],
        }),

        updatePayroll: builder.mutation({
            query: (payload = {}) => {
                const { ID, data, ...params } = payload
                return {
                    url: `/payrolls/${ID}`,
                    method: 'patch',
                    data,
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Payroll'],
        }),

        deletePayroll: builder.mutation({
            query: (payload = {}) => {
                const { ID, ...params } = payload
                return {
                    url: `/payrolls/${ID}`,
                    method: 'delete',
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            invalidatesTags: ['Payroll'],
        }),

        //Active Schedule
        getActiveSchedule: builder.query({
            query: (payload = {}) => {
                const { ...params } = payload
                return {
                    url: `/invoices`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
            providesTags: ['ActiveSchedule'],
        }),

        getPassiveSchedule: builder.query({
            query: (payload = {}) => {
                const { ...params } = payload
                return {
                    url: `/expenses`,
                    method: 'get',
                    params: {
                        ...params,
                        responseType:
                            params?.responseType ?? API_RESPONSE_TYPES.extended,
                    },
                }
            },
        }),
    }),
})

export const {
    useCreatePayrollMutation,
    useGetPayrollsQuery,
    useUpdatePayrollMutation,
    useDeletePayrollMutation,

    useGetActiveScheduleQuery,
    useGetPassiveScheduleQuery,
} = financeApiSlice
