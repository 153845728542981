import PropTypes, { shape } from 'prop-types'

import Body from '@/components/table/mobile/body/Body.jsx'
import { TABLE_ALIGNMENTS } from '@/utilities/constants'

import Header from './header'

const MobileTable = ({
    isLoading = false,
    isError,
    bodyheight = 'auto',
    columns = [],
    data = [],
    isPaginated,
    onChangePageCb,
    page,
    sortColumnCb,
    sorter,
    columnsRenderers,
    onClickRow = Function.prototype,
    onRefresh,
    expandedRowsIndexes = [],
}) => {
    const bodyProps = {
        columns,
        expandedRowsIndexes,
        columnsRenderers,
        onClickRow,
        data,
        isLoading,
        isError,
        onChangePageCb,
        onRefresh,
        page,
        bodyheight,
        isPaginated,
    }

    return (
        <div>
            <Header
                columns={columns}
                sorter={sorter}
                sortColumnCb={sortColumnCb}
            />
            <Body {...bodyProps} />
        </div>
    )
}

export default MobileTable

MobileTable.propTypes = {
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    bodyheight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    columns: PropTypes.arrayOf(
        shape({
            alignment: PropTypes.oneOf(Object.values(TABLE_ALIGNMENTS)),
            allowOverflow: PropTypes.bool,
            icon: PropTypes.node,
            label: PropTypes.string,
            mobileFullLength: PropTypes.bool,
            name: PropTypes.string.isRequired,
            sortable: PropTypes.bool,
            width: ({ width }, propName, componentName) => {
                if (!/^\d+%$/.test(width)) {
                    return new Error(
                        `Invalid prop '${propName}' supplied to ${componentName} (${width}). The prop must be a positive percentage number.`
                    )
                }
            },
            divider: PropTypes.bool,
        })
    ).isRequired,
    data: PropTypes.array.isRequired,
    isPaginated: PropTypes.bool,
    onChangePageCb: PropTypes.func,
    page: PropTypes.number,
    sortColumnCb: PropTypes.func,
    sorter: PropTypes.object,
    columnsRenderers: PropTypes.object.isRequired,
    onClickRow: PropTypes.func,
    onRefresh: PropTypes.func,
    expandedRowsIndexes: PropTypes.arrayOf(PropTypes.number),
}
