import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { PlusIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button'
import DialogDelete from '@/components/dialog-delete/DialogDelete.jsx'
import Search from '@/components/search/Search.jsx'
import Spinner from '@/components/spinner/Spinner.jsx'
import SubHeader from '@/components/subheader/SubHeader'
import Table from '@/components/table/index.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import {
    columnsCustomers,
    columnsRenderersCustomers,
} from '@/pages/customers/list/columnsCustomers.jsx'
import CustomersModalWrapper from '@/pages/customers/list/customers-modal/CustomersModalWrapper.jsx'
import { StyledContainerFilterCustomer } from '@/pages/customers/list/styles.js'
import { StyledContainerFluid } from '@/pages/settings/styles.js'
import {
    useDeleteCustomerMutation,
    useGetCustomerByIdQuery,
    useGetCustomersListQuery,
} from '@/services/customers/customersApiSlice.js'
import { useGetMembersQuery } from '@/services/settings/settingApiSlice.js'
import { handleApiError, remapSelectOptions } from '@/utilities/api/helpers.js'
import * as routeNames from '@/utilities/constants/index.js'
import { tableHeight } from '@/utilities/helpers'

const CustomersList = () => {
    const navigate = useNavigate()
    const showError = useError()
    const { t } = useTranslation()
    const { triggerAlert } = useAlert()
    const [openDialogToDelete, setOpenDialogToDelete] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [openSideBarToAdd, setOpenSideBarToAdd] = useState(false)
    const [customerID, setCustomerID] = useState(null)

    const [page, setPage] = useState(1)
    const [filters, setFilters] = useState({})

    useEffect(() => {
        setPage(1)
    }, [])

    const { data: successManagers } = useGetMembersQuery({
        is_success_manager: true,
    })
    const { data: customers } = useGetCustomersListQuery({
        page,
        ...filters,
    })

    const sortedCustomers = [...(customers?.data || [])].sort((a, b) => {
        if (a.name === 'Humans.tech') {
            return -1
        } else if (b.name === 'Humans.tech') {
            return 1
        }
    })

    const { data: customerData } = useGetCustomerByIdQuery(
        { ID: customerID },
        { skip: !customerID }
    )
    const [deleteCustomer] = useDeleteCustomerMutation()

    const openDeleteDialog = id => {
        setOpenDialogToDelete(true)
        setCustomerID(id)
    }

    const openSideBar = async id => {
        setOpenSideBarToAdd(!openSideBarToAdd)
        setCustomerID(id)
    }

    const goToDetails = id => {
        navigate(routeNames.ROUTE_CUSTOMER_DETAIL.replace(':id', id))
    }

    const handleDeleteCustomer = async () => {
        setIsLoading(true)
        try {
            await deleteCustomer({
                ID: customerID,
            }).unwrap()
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: t('commons:dialog.success'),
            })
            setCustomerID(null)
            setOpenDialogToDelete(false)
            setOpenSideBarToAdd(false)
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        } finally {
            setIsLoading(false)
            setOpenDialogToDelete(false)
        }
    }

    const onClose = () => {
        setOpenSideBarToAdd(false)
        setCustomerID(null)
    }

    return (
        <>
            {isLoading && <Spinner />}
            <DialogDelete
                open={openDialogToDelete}
                onClose={() => setOpenDialogToDelete(false)}
                onDelete={handleDeleteCustomer}
            />

            <StyledContainerFluid className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-4">
                        <SubHeader title={t('customers.title')}>
                            <StyledContainerFilterCustomer>
                                <Search
                                    placeholder={t(
                                        'customers.filters.placeholder.search'
                                    )}
                                    dataNotFiltered={customers?.data}
                                    handleSearch={value => {
                                        setFilters({ search: value })
                                    }}
                                />
                                <Button
                                    type={'button'}
                                    size={'small'}
                                    onClick={() => {
                                        setOpenSideBarToAdd(!openSideBarToAdd)
                                    }}
                                >
                                    <PlusIcon />
                                    {t('customers.actions.add_customer')}
                                </Button>
                            </StyledContainerFilterCustomer>
                        </SubHeader>
                    </div>

                    <div className="col-12">
                        <Table
                            columns={columnsCustomers()}
                            columnsRenderers={columnsRenderersCustomers(
                                goToDetails,
                                openDeleteDialog,
                                handleDeleteCustomer,
                                openSideBar
                            )}
                            data={sortedCustomers}
                            bodyheight={tableHeight(sortedCustomers)}
                            isPaginated
                        />
                    </div>
                </div>
            </StyledContainerFluid>

            {openSideBarToAdd && (
                <CustomersModalWrapper
                    onClose={onClose}
                    userSelected={customerData}
                    isEdit={!!customerID}
                    deleteUser={handleDeleteCustomer}
                    openDialogToDelete={openDialogToDelete}
                    setOpenDialogToDelete={setOpenDialogToDelete}
                    successManagers={remapSelectOptions(successManagers?.data)}
                />
            )}
        </>
    )
}

export default CustomersList
