import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const StyledSidebarSubHeader = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        min-height: 32px;
        margin-bottom: ${theme.spacing * 3}px;
        & h3 {
            color: ${theme.palette.neutral.base[1000]};
            ${theme.typography.paragraph.p24};
            word-wrap: break-word;
            max-width: 225px;
        }
    `}
`

export const StyledTabCtaContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        gap: ${theme.spacing}px;
    `}
`

export const StyledSliderSectionContainer = styled.div`
    ${({ theme }) => css`
        margin-bottom: ${theme.spacing * 3}px;
    `}
`

export const StyledSectionTitleContainer = styled.div`
    ${({ theme }) => css`
        margin-bottom: ${theme.spacing}px;
        gap: ${theme.spacing}px;
        display: flex;
        align-items: center;
        & .title_ {
            ${theme.typography.paragraph.p14.medium};
            width: auto;
            color: ${theme.palette.neutral.base[1000]};
            white-space: nowrap;
        }
        & .line_ {
            width: 100%;
            height: 1px;
            background-color: ${theme.palette.neutral.base[100]};
        }
    `}
`

export const StyledTabInfoContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        width: 100%;
        flex-flow: row wrap;
        gap: ${theme.spacing}px ${theme.spacing * 2}px;
    `}
`

export const StyledTeamItemContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        width: 100%;
        margin-bottom: ${theme.spacing * 2}px;
    `}
`

export const StyledUsersInfoContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        width: 100%;
        flex-flow: column;
        gap: ${theme.spacing * 1.25}px;
    `}
`

export const StyledSingleItem = styled.div`
    ${({ theme }) => css`
        ${theme.typography.paragraph.p14.regular};
        color: ${theme.palette.neutral.base[500]};
    `}
`

export const StyledTabItemContainer = styled.div`
    ${({ theme, width }) => css`
        display: flex;
        flex-flow: column;
        width: ${width}%;
        min-height: 38px;
        max-width: ${width === 100 ? '100%' : '125px'};
        & .label_ {
            ${theme.typography.paragraph.p12.bold};
            color: ${theme.palette.neutral.base[300]};
        }
        & .value_ {
            ${theme.typography.paragraph.p14.regular};
            color: ${theme.palette.neutral.base[500]};
            word-wrap: break-word;
        }
    `}
`
export const StyledTabNavLink = styled(NavLink)`
    ${({ theme, disabled }) => css`
        width: 100%;
        ${theme.typography.paragraph.p14.regular};
        color: ${theme.palette.neutral.base[500]};
        ${disabled && `opacity: .5;`}
    `}
`

export const StyledTabDate = styled.div`
    ${({ theme }) => css`
        color: ${theme.palette.neutral.base[300]};
        ${theme.typography.paragraph.p12.regular};
    `}
`

export const StyledHistoryItem = styled.div`
    ${({ theme, noDivider }) => css`
        padding-bottom: ${theme.spacing * 2}px;
        margin-bottom: ${theme.spacing * 2}px;
        border-bottom: 1px solid ${theme.palette.neutral.base[200]};
        color: ${theme.palette.neutral.base[500]};
        & p {
            ${theme.typography.paragraph.p14.bold};
            & span {
                ${theme.typography.paragraph.p14.regular};
            }
        }
        ${noDivider &&
        `
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
        `}
    `}
`

export const StyledSectionBlockTitle = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: ${theme.spacing}px;
        ${theme.typography.paragraph.p14.bold};
        color: ${theme.palette.neutral.base[500]};
        & h5 {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        & svg {
            width: 24px;
        }
    `}
`

export const StyledNoOptionsMessage = styled.div`
    ${({ theme }) => css`
        color: ${theme.palette.neutral.base[300]};
        ${theme.typography.paragraph.p14.regular};
    `}
`
