import styled, { css } from 'styled-components'

export const StyledBaseButton = styled.button`
    ${({ isUppercase, ...props }) => {
        const { size, collapsed, variant, theme } = props
        return css`
            ${getVariant(variant)}
            ${getSize(size, collapsed)}
            border-radius: ${theme.spacing - 2}px;
            cursor: pointer;
            //children
            display: flex;
            align-items: center;
            justify-content: center;
            gap: ${theme.spacing}px;
            ${theme.typography.buttonText}
            &:disabled {
                cursor: not-allowed;
            }
            &.add_button {
                justify-content: center;
                max-width: 150px;
            }
            & svg {
                width: 12px;
                height: 12px;
            }

            ${collapsed &&
            css`
                border-radius: 50%;
            `}

            ${isUppercase &&
            css`
                text-transform: uppercase;
            `}
        `
    }}
`

const getSize = (size, collapsed) => {
    switch (size) {
        case 'small':
            return css`
                height: 28px;
                width: ${collapsed ? '28px' : '100%'};
            `
        case 'medium':
            return css`
                height: 32px;
                width: ${collapsed ? '32px' : '100%'};
            `
        case 'large':
            return css`
                height: 40px;
                width: ${collapsed ? '40px' : '100%'};
            `
        case 'fullWidth':
            return css`
                height: 48px;
                width: 100%;
            `
        default:
            break
    }
}

const getVariant = variant => {
    switch (variant) {
        case 'filled':
            return FilledVariant
        case 'ghost':
            return GhostVariant
        case 'borderless':
            return BorderlessVariant
        case 'underlined':
            return UnderlineVariant
        default:
            break
    }
}

const FilledVariant = ({ theme, color, gradient, collapsed }) => {
    const neutral = color === 'neutral'
    return css`
        color: ${theme.palette.neutral.base[neutral ? '1000' : '0']};
        background-color: ${theme.palette[color][gradient][
            neutral ? '100' : 'active'
        ]};
        border: 1px solid ${theme.palette[color][gradient].active};

        &:hover {
            border-color: ${theme.palette[color][gradient].hover};
            color: ${theme.palette.neutral.base[neutral ? '1000' : '0']};
            background-color: ${theme.palette[color][gradient][
                neutral ? '200' : 'hover'
            ]};
        }

        ${neutral &&
        css`
            border: none;
        `}
        &:disabled {
            border-color: ${theme.palette[color][gradient].disabled};
            color: ${theme.palette[color][gradient][
                neutral ? '200' : 'disabled'
            ]};
            background-color: ${theme.palette.secondary.base.background};

            & svg {
                fill: ${theme.palette.neutral.base[0]};
                & path {
                    fill: ${theme.palette.neutral.base[1000]};
                }
            }

            ${collapsed &&
            css`
                border-color: ${theme.palette.neutral.base[100]};
                background-color: ${theme.palette.secondary.base.background};

                & svg {
                    fill: ${theme.palette.secondary.base.background};
                }
            `}
        }
    `
}

const GhostVariant = ({ theme, color, gradient, collapsed }) => {
    return css`
        background-color: transparent;
        color: ${theme.palette[color][gradient].active};
        border: 1px solid ${theme.palette[color][gradient].disabled};

        &:hover {
            border-color: ${theme.palette[color][gradient].hover};
            color: ${theme.palette[color][gradient].hover};
        }

        & svg {
            fill: ${theme.palette[color][gradient].active};
        }

        &:disabled {
            color: ${theme.palette[color][gradient].disabled};
            border: 1px solid ${theme.palette[color][gradient].disabled};
            background-color: ${theme.palette.secondary.base.background};

            & svg {
                fill: ${theme.palette[color][gradient].disabled};
            }
        }

        ${collapsed &&
        css`
            border: 1px solid ${theme.palette[color][gradient].disabled};
            background-color: ${theme.palette.neutral.base[0]};

            & svg {
                fill: ${theme.palette[color][gradient].hover};
            }

            &:hover {
                border-color: ${theme.palette[color][gradient].hover};
            }

            &:disabled {
                border-color: ${theme.palette.neutral.base[100]};
                background: ${theme.palette.neutral.base[0]};
                & svg {
                    fill: ${theme.palette.secondary.base.background};
                }
            }
        `}
    `
}

const BorderlessVariant = ({ theme, color, gradient }) => {
    return css`
        background-color: transparent;
        border: 0;
        color: ${theme.palette[color][gradient].active};

        & svg {
            fill: ${theme.palette[color][gradient].active};
        }

        &:hover {
            border-radius: ${theme.spacing + 4}px;
            background-color: ${theme.palette.neutral.base[0]};
        }

        &:disabled {
            color: ${theme.palette[color][gradient].disabled};

            &:hover {
                border: none;
            }

            & svg {
                fill: ${theme.palette[color][gradient].background};
            }
        }
    `
}

const UnderlineVariant = ({ theme, color, gradient }) => {
    return css`
        background-color: transparent;
        color: ${theme.palette[color][gradient].active};
        text-decoration: underline;

        & svg {
            fill: ${theme.palette[color][gradient].background};
        }

        &:hover {
            color: ${theme.palette[color][gradient].hover};
        }

        &:disabled {
            color: ${theme.palette[color][gradient].disabled};

            & svg {
                fill: ${theme.palette[color][gradient].background};
            }
        }
    `
}
