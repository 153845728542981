import PropTypes from 'prop-types'

import { GoogleIcon } from '@/assets/icons/icons.jsx'
import { StyledButtonGoogle } from '@/components/button-google/styles.js'

const ButtonGoogle = ({ onClick }) => {
    return (
        <>
            <StyledButtonGoogle onClick={onClick} type={'button'}>
                <GoogleIcon />
                <p>Login with Google</p>
            </StyledButtonGoogle>
        </>
    )
}

export default ButtonGoogle

ButtonGoogle.propTypes = {
    onClick: PropTypes.func,
}
