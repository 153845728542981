import styled, { css } from 'styled-components'

export const StyledCategoryName = styled.div`
    ${({ theme }) => css`
        ${theme.typography.paragraph.p14.regular};
        color: ${theme.palette.neutral.base[1000]};
        margin-bottom: ${theme.spacing / 2}px;
    `}
`
export const StyledContainerFinancialImport = styled.div`
    ${({ theme }) => css`
        display: flex;
        border: 1px solid ${theme.palette.neutral.base[1000]};
        border-radius: ${theme.spacing / 2}px;
        padding: ${theme.spacing}px ${theme.spacing * 2}px;
        min-height: 48px;
    `}
`

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledBaseContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        & > p {
            ${theme.typography.paragraph.p14.regular};
            color: ${theme.palette.neutral.base[1000]};
        }
        input {
            display: none;
        }
    `}
`

export const StyledContainerButtonBase = styled.div`
    ${({ theme, error }) => css`
        width: 98px;
        & > button {
            border: ${error
                ? `1px dashed ${theme.palette.danger.base.active}`
                : `1px dashed ${theme.palette.neutral.base[1000]}`};
            box-shadow: ${theme.shadows[60]};
            ${theme.typography.paragraph.p14.regular};
        }
        & label {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: ${theme.spacing}px;
        }
    `}
`

export const StyledContainerErrorMessage = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        color: ${theme.palette.danger.base.active};
        ${theme.typography.paragraph.p14.regular};
        gap: ${theme.spacing}px;
    `}
`

export const StyledContainerPending = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: ${theme.spacing / 2}px;

        & > p {
            ${theme.typography.paragraph.p10.bold};
        }
    `}
`
