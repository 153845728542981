import styled, { css } from 'styled-components'

export const StyledContainerCell = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: ${theme.spacing}px;
    `}
`
