import styled, { css } from 'styled-components'

export const StyledContainerFilterCustomer = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: 174px 162px;
        grid-gap: ${theme.spacing}px;
        grid-template-rows: 1fr;
        color: ${theme.palette.primary[0]};
    `}
`
export const StyledFormCustomer = styled.form`
    ${({ isEdit }) => css`
        grid-template-rows: ${!isEdit
            ? ' repeat(12, max-content) auto'
            : ' repeat(13, max-content) auto'};
        height: 100%;
    `}
`
