import { Virtuoso } from 'react-virtuoso'
import styled, { css } from 'styled-components'

const BodyStyle = css`
    ${({ theme: { spacing }, bodyheight }) => css`
        height: ${bodyheight} !important;
        position: relative;
        overflow-y: auto;
        margin-top: ${spacing * 2}px;
    `}
`

export const StyledTbody = styled.div`
    ${BodyStyle}
`

export const StyledPaginatedTbody = styled(Virtuoso)`
    ${BodyStyle}
`

export const StyledNoResults = styled.div`
    ${({ bodyheight, theme: { spacing, colors, styledTypography } }) => css`
        ${BodyStyle};
        height: ${bodyheight} !important;
        background: ${colors.white};
        ${styledTypography.title};
        color: ${colors.grey};
        text-align: center;
        padding-top: ${spacing * 3}px;
    `}
`
