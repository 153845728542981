import * as yup from 'yup'

import i18next from '../../../../i18n.js'

const CUSTOMERS_MODEL = {
    business_name: 'name',
    email: 'email',
    success_manager: 'success_manager_id',
    vat: 'vat',
    sdi: 'sdi',
    pec: 'pec',
    link_drive: 'drive_url',
    billing_address: 'billing_address',
    city: 'city',
    cap: 'postal_code',
    phone_number: 'phone_number',
    note: 'note',
    status: 'is_active',
}

const DEFAULT_CUSTOMERS_MOCK = {
    [CUSTOMERS_MODEL.business_name]: '',
    [CUSTOMERS_MODEL.email]: null,
    [CUSTOMERS_MODEL.success_manager]: null,
    [CUSTOMERS_MODEL.vat]: '',
    [CUSTOMERS_MODEL.sdi]: null,
    [CUSTOMERS_MODEL.pec]: '',
    [CUSTOMERS_MODEL.link_drive]: '',
    [CUSTOMERS_MODEL.billing_address]: '',
    [CUSTOMERS_MODEL.city]: '',
    [CUSTOMERS_MODEL.cap]: null,
    [CUSTOMERS_MODEL.phone_number]: null,
    [CUSTOMERS_MODEL.note]: null,
    [CUSTOMERS_MODEL.status]: null,
}

const RequestValidationSchema = yup.object().shape({
    [CUSTOMERS_MODEL.business_name]: yup
        .string()
        .required(i18next.t('validation:general_form.business_name_required')),
    [CUSTOMERS_MODEL.email]: yup
        .string()
        .email(i18next.t('validation:general_form.invalid_email'))
        .required(i18next.t('validation:general_form.email_required')),
    [CUSTOMERS_MODEL.success_manager]: yup
        .object()
        .required(
            i18next.t('validation:general_form.success_manager_required')
        ),
    [CUSTOMERS_MODEL.status]: yup
        .object()
        .required(i18next.t('validation:general_form.status')),
    [CUSTOMERS_MODEL.vat]: yup
        .string()
        .min(10, i18next.t('validation:general_form.vat_check'))
        .max(13, i18next.t('validation:general_form.vat_check'))
        .required(i18next.t('validation:general_form.vat_required')),
    [CUSTOMERS_MODEL.sdi]: yup
        .string()
        .nullable()
        .transform(value => (value === '' ? null : value)),
    [CUSTOMERS_MODEL.pec]: yup
        .string()
        .transform(value => (value === '' ? null : value))
        .nullable(),
    [CUSTOMERS_MODEL.link_drive]: yup
        .string()
        .matches(
            /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/,
            i18next.t('validation:general_form.invalid_url')
        )
        .nullable()
        .transform(value => (value === '' ? null : value)),
    [CUSTOMERS_MODEL.billing_address]: yup
        .string()
        .nullable()
        .transform(value => (value === '' ? null : value)),
    [CUSTOMERS_MODEL.city]: yup
        .string()
        .nullable()
        .transform(value => (value === '' ? null : value)),
    [CUSTOMERS_MODEL.cap]: yup
        .string()
        .nullable()
        .transform(value => (value === '' ? null : value)),
    [CUSTOMERS_MODEL.phone_number]: yup
        .string()
        .nullable()
        .transform(value => (value === '' ? null : value)),
    [CUSTOMERS_MODEL.note]: yup
        .string()
        .nullable()
        .transform(value => (value === '' ? null : value)),
})

export {
    CUSTOMERS_MODEL,
    DEFAULT_CUSTOMERS_MOCK,
    RequestValidationSchema as validationSchema,
}
