import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import SidePopover from '@/components/side-popover/SidePopover.jsx'
import StandardProjectForm from '@/pages/projects/list/projects-form/standard-projects-form/StandardProjectForm.jsx'
import {
    DEFAULT_STANDARD_PROJECTS_MOCK,
    validationSchema,
} from '@/pages/projects/list/projects-form/standard-projects-form/standardProjects.schema.js'

const StandardProjectWrapper = ({
    dataForm,
    openDialogDelete,
    closeModal,
    customers,
    teams,
    successManagers,
    canNotDelete,
}) => {
    const { t } = useTranslation()

    const methods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'onChange',
        nativeValidation: false,
        shouldFocusError: true,
        resolver: yupResolver(validationSchema),
        defaultValues: DEFAULT_STANDARD_PROJECTS_MOCK,
    })

    const {
        formState: { isDirty },
    } = methods

    return (
        <FormProvider {...methods}>
            <SidePopover
                onClose={() => closeModal(isDirty)}
                isDirty={isDirty}
                title={
                    dataForm?.isEdit
                        ? t(`projects.actions.edit_project`)
                        : t(`projects.actions.create_project`)
                }
            >
                <StandardProjectForm
                    dataForm={dataForm}
                    isEdit={dataForm?.isEdit}
                    openDialogDelete={openDialogDelete}
                    successManagers={successManagers}
                    closeModal={closeModal}
                    customers={customers}
                    teams={teams}
                    canNotDelete={canNotDelete}
                />
            </SidePopover>
        </FormProvider>
    )
}

export default StandardProjectWrapper

StandardProjectWrapper.propTypes = {
    onClose: PropTypes.func,
    dataForm: PropTypes.object,
    openDialogDelete: PropTypes.func,
    closeModal: PropTypes.func,
    customers: PropTypes.array,
    teams: PropTypes.array,
    successManagers: PropTypes.array,
    canNotDelete: PropTypes.bool,
}
