import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { PlusIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button'
import Table from '@/components/table/index.jsx'
import Tabs from '@/components/tabs/Tabs.jsx'
import useError from '@/hooks/useError.jsx'
import {
    columnsActivity,
    columnsRenderersActivity,
} from '@/pages/projects/detail/detail-standard-projects/project-tables/activity-table/columnsActivity.jsx'
import {
    columnsBilling,
    columnsRenderersBilling,
} from '@/pages/projects/detail/detail-standard-projects/project-tables/billing-table/columnsBilling.jsx'
import {
    columnsCosts,
    columnsRenderersCosts,
} from '@/pages/projects/detail/detail-standard-projects/project-tables/costs-table/columnsCosts.jsx'
import {
    useAddStandardSalMutation,
    useGetCostsQuery,
    useGetInvoicesStandardQuery,
    useGetSalsQuery,
} from '@/services/projects/projectsApiSlice.js'
import {
    currentExpandedRows,
    setExpandedRows,
} from '@/services/projects/projectsSlice'
import { handleApiError } from '@/utilities/api/helpers.js'
import { remapResponseBilling } from '@/utilities/api/remapResponseBilling.js'
import { TABLE_HEIGHT_WITH_WIDGET } from '@/utilities/constants/index.js'
import { handleSortFilter } from '@/utilities/helpers'

const ProjectTabsDetails = ({
    id,
    setSelectedTab,
    isLoading,
    openEditSidebar,
    openDialogDelete,
    setAnalyticsInovices,
}) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const showError = useError()
    const expandedRows = useSelector(currentExpandedRows)

    const location = useLocation()
    const [addStandardSal] = useAddStandardSalMutation()
    const [sortDirections, setSortDirections] = useState({
        sals: 'asc',
        invoices: 'asc',
        costs: 'asc',
    })

    const { data: sals, isFetching } = useGetSalsQuery(
        {
            ID: id,
            sortDirection: sortDirections?.sals,
        },
        {
            skip: !id,
        }
    )
    const { data: costs } = useGetCostsQuery(
        { projectID: id, sortDirection: sortDirections?.costs },
        { skip: location.search !== '?section=Costi' }
    )

    const { data: invoices } = useGetInvoicesStandardQuery(
        {
            projectID: id,
            sortDirection: sortDirections?.invoices,
        },
        {
            skip: location.search !== '?section=Fatturazione',
        }
    )

    useEffect(() => {
        if (invoices?.analytics) {
            const dataAnalytics = remapResponseBilling(invoices?.analytics)
            setAnalyticsInovices(dataAnalytics)
        }
    }, [invoices])

    useEffect(() => {
        dispatch(setExpandedRows([]))
    }, [])

    const onSectionClick = section => {
        const searchParams = new URLSearchParams(location.search)
        setSelectedTab(section)
        searchParams.set('section', section)
        navigate(`?${searchParams?.toString()}`)
    }
    const tableColumns = useMemo(() => {
        return {
            activity: columnsActivity(),
            billing: columnsBilling(),
            costs: columnsCosts(),
        }
    }, [t])

    const handleSubmit = async e => {
        try {
            e.preventDefault()
            addStandardSal({
                ID: id,
            })
        } catch (error) {
            handleApiError({ error, handleGeneralError: showError })
        }
    }

    const detailsTabs = [
        {
            id: 1,
            value: t('projects.tabs.activities'),
            label: t('projects.tabs.activities'),
            component: (
                <div>
                    <Table
                        columns={tableColumns.activity}
                        columnsRenderers={columnsRenderersActivity(
                            openDialogDelete
                        )}
                        data={sals}
                        isLoading={isFetching}
                        isPaginated
                        bodyheight={TABLE_HEIGHT_WITH_WIDGET}
                        expandedRowsIndexes={expandedRows}
                        sortColumnCb={() =>
                            handleSortFilter('sals', setSortDirections)
                        }
                    />

                    <Button
                        type={'button'}
                        size={'small'}
                        variant={'borderless'}
                        className={'add_button'}
                        onClick={handleSubmit}
                    >
                        <PlusIcon fill={theme.palette.primary.base.active} />
                        {t('project.add_sal')}
                    </Button>
                </div>
            ),
        },
        {
            id: 2,
            value: t('projects.tabs.billing'),
            label: t('projects.tabs.billing'),
            component: (
                <Table
                    columns={tableColumns.billing}
                    columnsRenderers={columnsRenderersBilling(
                        openDialogDelete,
                        openEditSidebar
                    )}
                    data={invoices?.invoices}
                    isLoading={isLoading}
                    isPaginated
                    bodyheight={TABLE_HEIGHT_WITH_WIDGET}
                    sortColumnCb={() =>
                        handleSortFilter('invoices', setSortDirections)
                    }
                />
            ),
        },
        {
            id: 3,
            value: t('projects.tabs.costs'),
            label: t('projects.tabs.costs'),
            component: (
                <Table
                    columns={tableColumns.costs}
                    columnsRenderers={columnsRenderersCosts(
                        openDialogDelete,
                        openEditSidebar
                    )}
                    data={costs}
                    isLoading={isLoading}
                    isPaginated
                    bodyheight={TABLE_HEIGHT_WITH_WIDGET}
                    sortColumnCb={() =>
                        handleSortFilter('costs', setSortDirections)
                    }
                />
            ),
        },
    ]

    return (
        <>
            <Tabs
                onValueChange={e => onSectionClick(e)}
                triggers={detailsTabs}
                defaultValue={t('projects.tabs.activities')}
                haveDivider
            />
        </>
    )
}

export default ProjectTabsDetails

ProjectTabsDetails.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setSelectedTab: PropTypes.func,
    isLoading: PropTypes.bool,
    openEditSidebar: PropTypes.func,
    openDialogDelete: PropTypes.func,
    handleExpandedRows: PropTypes.func,
    setAnalyticsInovices: PropTypes.func,
}
