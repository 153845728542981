import styled, { css } from 'styled-components'

import { getTHAlignment } from '@/utilities/helpers.js'

export const StyledTHead = styled.div`
    ${({ theme: { spacing, palette } }) => css`
        background-color: ${palette.secondary.base.background};
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 ${spacing}px 0 ${spacing}px;
    `}
`

export const StyledTH = styled.div`
    ${({
        alignment,
        width,
        havePadding,
        theme: { palette, spacing, typography },
    }) => css`
        display: flex;
        justify-content: ${getTHAlignment(alignment)};
        align-items: center;
        gap: ${spacing}px;
        width: ${width};
        color: ${palette.neutral.base[500]};
        padding: ${havePadding ? `0 ${spacing}px 0 ${spacing}px` : '0'};

        ${typography.paragraph.p14.bold};

        & span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        & svg {
            width: 24px;
        }
    `}
`

export const SortButton = styled.button`
    ${({ theme: { palette }, sorterDirection }) => css`
        flex: none;
        width: 28px;
        height: 28px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
        color: ${palette.neutral.base[500]};
        transition: background-color ease-in-out 200ms;
        margin-left: auto;
        cursor: pointer;
        & svg {
            ${sorterDirection === 'asc' &&
            css`
                transform: rotate(180deg);
            `}
        }
    `}
`
