import { Separator } from '@radix-ui/react-separator'
import styled, { css } from 'styled-components'

export const StyledDividerRoot = styled(Separator)`
    ${({ theme, height, width }) => css`
        height: ${height};
        width: ${width};
        background-color: ${theme.palette.neutral.base[200]};
    `}
`
