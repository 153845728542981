import i18next from 'i18next'

import { ActionsIcon, EditIcon, TrashIcon } from '@/assets/icons/icons.jsx'
import Chip from '@/components/chip/Chip.jsx'
import Dropdown from '@/components/dropdown/Dropdown.jsx'
import {
    COMMON_DATE_FORMAT,
    COSTS_STATUS,
    GENERAL_STATUSES,
    LABELS_TABLES,
} from '@/utilities/constants/index.js'
import { COSTS_STATUS_OPTIONS } from '@/utilities/constants/list.js'
import { getHumanReadableDate, translateOptions } from '@/utilities/helpers.js'

/* eslint-disable */

export const COLUMNS_COSTS_NAMES = {
    id: 'id',
    supplier: 'supplier',
    date: 'date',
    taxable: 'taxable',
    status: 'status',
    notes: 'notes',
    sal: 'sal',
    actions: 'actions',
}
export const columnsCosts = () => {
    return [
        {
            alignment: 'left',
            label: i18next.t('general.id'),
            name: COLUMNS_COSTS_NAMES.id,
            width: '5%',
            sortable: false,
            divider: false,
        },
        {
            alignment: 'left',
            label: i18next.t('projects.tables.costs.supplier_reference'),
            name: COLUMNS_COSTS_NAMES.supplier,
            width: '14%',
            sortable: false,
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('projects.tables.activities.date'),
            name: COLUMNS_COSTS_NAMES.date,
            sortable: true,
            width: '10%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('projects.tables.activities.taxable'),
            name: COLUMNS_COSTS_NAMES.taxable,
            sortable: false,
            width: '10%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.status'),
            name: COLUMNS_COSTS_NAMES.status,
            sortable: false,
            width: '6%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('projects.tables.activities.notes'),
            name: COLUMNS_COSTS_NAMES.notes,
            sortable: false,
            width: '48%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('projects.tables.costs.sal'),
            name: COLUMNS_COSTS_NAMES.sal,
            sortable: false,
            width: '5%',
            divider: true,
        },
        {
            alignment: 'space-evenly',
            label: ' ',
            name: COLUMNS_COSTS_NAMES.actions,
            sortable: false,
            width: '2%',
            divider: true,
        },
    ]
}

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersCosts = (openDialogDelete, openEditSidebar) => ({
    [COLUMNS_COSTS_NAMES.id]: ({ id }) => <p>#{id}</p>,
    [COLUMNS_COSTS_NAMES.supplier]: ({ supplier }) => (
        <p>{supplier?.business_name}</p>
    ),
    [COLUMNS_COSTS_NAMES.date]: ({ date }) => {
        return <p>{getHumanReadableDate(date, COMMON_DATE_FORMAT)}</p>
    },
    [COLUMNS_COSTS_NAMES.taxable]: ({ amount }) => <p>€ {amount}</p>,
    [COLUMNS_COSTS_NAMES.status]: ({ status }) => {
        const statusToRender = () => {
            switch (status) {
                case COSTS_STATUS.paid:
                    return GENERAL_STATUSES.success
                case COSTS_STATUS.received:
                    return GENERAL_STATUSES.warning
                default:
                    return
            }
        }

        const textToRender = () => {
            const enumStatus = translateOptions(COSTS_STATUS_OPTIONS)
            return enumStatus.find(item => item.value === status)?.label
        }
        return (
            <Chip
                text={textToRender()}
                size={'medium'}
                variant={statusToRender()}
                fullWidth={false}
            />
        )
    },
    [COLUMNS_COSTS_NAMES.notes]: ({ description }) => {
        return <p>{description}</p>
    },
    [COLUMNS_COSTS_NAMES.sal]: ({ sal }) => {
        return <p>#{sal?.id}</p>
    },
    [COLUMNS_COSTS_NAMES.actions]: data => {
        const idToDelete = {
            salID: data?.sal?.id,
            expensesID: data?.id,
        }
        return (
            <Dropdown
                trigger={<ActionsIcon />}
                items={[
                    //TODO: add when have 'fatture in cloud' implemented

                    /* {
                         label: i18next.t('customer.actions.view_invoice'),
                         onSelect: () => {},
                         icon: <OpenNewTabIcon />,
                         isToDelete: false,
                     },*/
                    {
                        label: i18next.t('customer.actions.edit_invoice'),
                        onSelect: () => {
                            openEditSidebar(data?.id, LABELS_TABLES.costs)
                        },
                        icon: <EditIcon />,
                        isToDelete: false,
                    },
                    {
                        label: i18next.t('customer.actions.delete_invoice'),
                        onSelect: () => {
                            openDialogDelete(
                                data?.id,
                                idToDelete,
                                LABELS_TABLES.costs
                            )
                        },
                        icon: <TrashIcon />,
                        isToDelete: true,
                    },
                ]}
            />
        )
    },
})
