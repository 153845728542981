import styled, { css } from 'styled-components'

export const StyledBody = styled.div`
    ${({ theme: { spacing } }) => css`
        padding: ${spacing * 2.5}px ${spacing * 4};
    `}
`

export const StyledOption = styled.div`
    ${({ theme: { fontSize, colors } }) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        font-size: ${fontSize.sm}px;
        font-weight: 700;
        color: ${colors.lightBlack};
        transition: ease-in-out 400ms;
        cursor: pointer;

        &:not(:last-child) {
            border-bottom: 1px solid ${colors.inputGrey};
        }

        &:hover {
            opacity: 0.8;
        }
    `}
`
