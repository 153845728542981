import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { StatusIconSuccess, TrashIcon } from '@/assets/icons/icons.jsx'
import EditCellRow from '@/components/edit-cell-row/EditCellRow.jsx'
import {
    StyledSalContainer,
    StyledSalItem,
    StyledSalNumber,
} from '@/components/edit-cell-row/styles'
import Spinner from '@/components/spinner/Spinner.jsx'
import {
    useAddActivityMutation,
    useUpdateActivityMutation,
} from '@/services/projects/projectsApiSlice.js'
import { EDIT_CELL_TYPE } from '@/utilities/constants/commons'
/* eslint-disable */

export const COLUMNS_ACTIVITY_CHILD = {
    empty: 'empty',
    activity: 'activity',
    percentage: 'percentage',
    hours: 'hours',
    budget: 'budget',
    space: 'space',
    actions: 'actions',
}

export const columnsActivityChild = () => {
    const { t } = useTranslation()
    return [
        {
            alignment: 'left',
            label: ' ',
            name: COLUMNS_ACTIVITY_CHILD.empty,
            sortable: false,
            width: '5%',
            divider: false,
        },
        {
            alignment: 'left',
            label: t('project.activity'),
            name: COLUMNS_ACTIVITY_CHILD.activity,
            sortable: false,
            width: '37%',
            divider: false,
        },
        {
            alignment: 'left',
            label: t('project.divide'),
            name: COLUMNS_ACTIVITY_CHILD.percentage,
            sortable: false,
            width: '10%',
            divider: true,
        },
        {
            label: '',
            name: COLUMNS_ACTIVITY_CHILD.budget,
            width: '10%',
        },
        {
            alignment: 'left',
            label: '',
            name: COLUMNS_ACTIVITY_CHILD.hours,
            sortable: false,
            width: '10%',
        },

        {
            label: '',
            name: COLUMNS_ACTIVITY_CHILD.space,
            width: '25%',
        },
        {
            alignment: 'space-evenly',
            label: ' ',
            name: COLUMNS_ACTIVITY_CHILD.actions,
            sortable: false,
            width: '3%',
            divider: true,
        },
    ]
}

//the file is commented because the variables id, last_name ecc... they cannot be declared in proptypes this is not props but return from tables
//TODO: make the proptypes, if possible, a warning and not an error

export const columnsRenderersActivityChild = (
    openDialogDelete,
    projectId,
    salID,
    openDialogDeleteActivity
) => {
    return {
        [COLUMNS_ACTIVITY_CHILD.empty]: () => {},
        [COLUMNS_ACTIVITY_CHILD.activity]: data => {
            const { id, name, is_completed } = data
            const [
                updateActivityData,
                { isLoading, isSuccess, error: apiError },
            ] = useUpdateActivityMutation()
            const theme = useTheme()
            const [addActivity] = useAddActivityMutation()

            const handleBlur = e => {
                if (e.target.value !== name) {
                    updateActivityData({
                        ID: projectId,
                        salID: salID,
                        activityID: id,
                        data: {
                            name: e.target.value,
                        },
                    })
                }
            }

            const setCompleted = () => {
                updateActivityData({
                    ID: projectId,
                    salID: salID,
                    activityID: id,
                    data: {
                        is_completed: !is_completed,
                    },
                })
            }

            const deliverySal = () => {
                addActivity({
                    ID: projectId,
                    salID: salID,
                    data: {
                        name: data.name,
                        budget: data.budget,
                        hours: data.hours,
                        is_sal_completion: true,
                        percentage: data.percentage,
                    },
                })
            }
            return (
                <StyledSalContainer>
                    {id === 'deliverySal' ? null : (
                        <StatusIconSuccess
                            fill={
                                !is_completed
                                    ? theme.palette.neutral.base[0]
                                    : theme.palette.success.base.background
                            }
                            fillPath={
                                !is_completed
                                    ? theme.palette.neutral.base[1000]
                                    : theme.palette.success.base.active
                            }
                            onClick={setCompleted}
                        />
                    )}
                    <StyledSalItem>
                        {isLoading ? (
                            <Spinner overlay={true} overlayFullscreen={true} />
                        ) : (
                            <EditCellRow
                                disabled={is_completed}
                                data={name}
                                handleBlur={handleBlur}
                                onSubmit={deliverySal}
                                type={
                                    id === 'deliverySal'
                                        ? EDIT_CELL_TYPE.delivery_button
                                        : EDIT_CELL_TYPE.text
                                }
                                isSuccess={isSuccess}
                                errors={apiError}
                            />
                        )}
                    </StyledSalItem>
                </StyledSalContainer>
            )
        },
        [COLUMNS_ACTIVITY_CHILD.percentage]: data => {
            const { id, percentage, is_completed } = data
            const [
                updateActivityData,
                { isLoading, isSuccess, error: apiError },
            ] = useUpdateActivityMutation()

            const methods = useForm()
            const { control } = methods

            const handleBlur = e => {
                if (+e.target.value !== percentage) {
                    updateActivityData({
                        ID: projectId,
                        salID: salID,
                        activityID: +id,
                        data: {
                            percentage: +e.target.value,
                        },
                    })
                }
            }

            return (
                <StyledSalNumber disabled={is_completed}>
                    <p>%</p>
                    {isLoading ? (
                        <Spinner overlay={true} overlayFullscreen={true} />
                    ) : (
                        <EditCellRow
                            readOnly={id === 'deliverySal'}
                            disabled={is_completed}
                            data={percentage}
                            defaultvalue={percentage}
                            handleBlur={handleBlur}
                            control={control}
                            type={EDIT_CELL_TYPE.currency}
                            isSuccess={isSuccess}
                            errors={apiError}
                        />
                    )}
                </StyledSalNumber>
            )
        },

        [COLUMNS_ACTIVITY_CHILD.hours]: data => {
            const { is_completed, percentage, id } = data
            const hours = id === 'deliverySal' ? data?.hours : data?.sal?.hours
            return (
                <StyledSalNumber disabled={is_completed}>
                    <p>h</p>
                    <p>{parseFloat((hours * percentage) / 100).toFixed(2)}</p>
                </StyledSalNumber>
            )
        },

        [COLUMNS_ACTIVITY_CHILD.budget]: data => {
            const { is_completed, id, percentage } = data
            const budget =
                id === 'deliverySal' ? data?.budget : data?.sal?.budget
            return (
                <StyledSalNumber disabled={is_completed}>
                    <p>€</p>
                    <p>{parseFloat((budget * percentage) / 100)}</p>
                </StyledSalNumber>
            )
        },
        [COLUMNS_ACTIVITY_CHILD.space]: () => {},
        [COLUMNS_ACTIVITY_CHILD.actions]: ({ id, is_completed }) => {
            const theme = useTheme()
            return (
                <>
                    {id !== 'deliverySal' && (
                        <TrashIcon
                            onClick={() =>
                                !is_completed && openDialogDeleteActivity(id)
                            }
                            width={24}
                            height={24}
                            style={{
                                cursor: !is_completed ? 'pointer' : 'default',
                            }}
                            fill={
                                !is_completed
                                    ? theme.palette.neutral.base[400]
                                    : theme.palette.neutral.base[200]
                            }
                        />
                    )}
                </>
            )
        },
    }
}
