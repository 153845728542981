import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { CurrencyIcon, TrashIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import Datepicker from '@/components/date-picker/DatePicker.jsx'
import InputCurrency from '@/components/input-currency/InputCurrency.jsx'
import Select from '@/components/select/Select.jsx'
import TextArea from '@/components/text-area/TextArea.jsx'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import {
    StyledContainerFormActions,
    StyledFormBilling,
} from '@/pages/customers/detail/customer-form/billing-form/styles.js'
import { StyledContainerDelete } from '@/pages/customers/detail/customer-tables/table-projects/projects-form/styles.js'
import { BILLING_PROJECTS_MODEL } from '@/pages/projects/detail/detail-standard-projects/project-form/billing-form/billingForm.schema.js'
import {
    useCreateInvoiceStandardMutation,
    useGetInvoiceByIDStandardQuery,
    useGlobalProjectIDQuery,
    useUpdateInvoiceStandardMutation,
} from '@/services/projects/projectsApiSlice.js'
import { formatDateFromApi, handleApiError } from '@/utilities/api/helpers.js'
import { INVOICE_STATUS_OPTIONS } from '@/utilities/constants/list.js'
import {
    filterEmptyKeys,
    iterateOverDirtyFields,
    retrieveSingleValueForRs,
    translateOptions,
} from '@/utilities/helpers.js'

const BillingForm = ({
    isEdit,
    closeModal,
    dataForm,
    openDialogDelete,
    sals,
    isFinance = false,
}) => {
    const { t } = useTranslation()
    const showError = useError()
    const { triggerAlert } = useAlert()
    const { id: globalSalID } = useParams()
    //take id from global sal id
    const { data: data } = useGlobalProjectIDQuery({
        globalSalID: globalSalID
            ? globalSalID
            : dataForm?.data?.global_project_id,
    })
    const id = data?.id
    const invoiceID = isFinance ? dataForm?.data?.id : dataForm?.id
    const salsWithNoInvoice = sals?.filter(
        sal => sal.data.has_invoice === false
    )

    const { data: invoiceData } = useGetInvoiceByIDStandardQuery(
        { projectID: id, invoiceID: invoiceID },
        { skip: !isEdit }
    )

    const [createInvoice] = useCreateInvoiceStandardMutation()
    const [updateInvoice] = useUpdateInvoiceStandardMutation()
    const {
        register,
        control,
        handleSubmit,
        reset,
        setError,
        watch,
        formState: {
            errors,
            isDirty,
            touchedFields,
            dirtyFields,
            isSubmitting,
        },
    } = useFormContext()

    const onSubmit = async data => {
        const remappedData = {
            ...data,
            [BILLING_PROJECTS_MODEL.status]: retrieveSingleValueForRs(
                translateOptions(INVOICE_STATUS_OPTIONS),
                data[BILLING_PROJECTS_MODEL.status]?.value
            ).value,
            [BILLING_PROJECTS_MODEL.sal]:
                data[BILLING_PROJECTS_MODEL.sal]?.value,
            [BILLING_PROJECTS_MODEL.taxable]: parseFloat(
                data[BILLING_PROJECTS_MODEL.taxable]
            ),
        }
        try {
            if (isEdit) {
                await updateInvoice({
                    projectID: id,
                    invoiceID: invoiceID,
                    data: iterateOverDirtyFields(dirtyFields, remappedData),
                }).unwrap()
            } else {
                const filteredData = filterEmptyKeys(remappedData)
                await createInvoice({
                    projectID: id,
                    data: filteredData,
                }).unwrap()
            }
            closeModal()
        } catch (error) {
            if (invoiceData?.status === 'cancelled') {
                triggerAlert({
                    variant: TOAST_STATUSES.FAILURE,
                    message: t('commons:toast.check_billing'),
                })
            }
            handleApiError({
                error,
                handleGeneralError: showError,
                handleFormError: setError,
            })
        }
    }

    useEffect(() => {
        if (isEdit && invoiceData) {
            reset({
                ...invoiceData,
                [BILLING_PROJECTS_MODEL.date]: formatDateFromApi(
                    invoiceData[BILLING_PROJECTS_MODEL.date]
                ),
                [BILLING_PROJECTS_MODEL.status]: retrieveSingleValueForRs(
                    translateOptions(INVOICE_STATUS_OPTIONS),
                    invoiceData[BILLING_PROJECTS_MODEL.status]
                ),
                [BILLING_PROJECTS_MODEL.sal]: retrieveSingleValueForRs(
                    sals,
                    invoiceData.sal.id
                ),
            })
        }
    }, [invoiceData, isEdit])

    return (
        <StyledFormBilling
            isEdit={isEdit}
            onSubmit={handleSubmit(onSubmit)}
            className="container-fluid d-grid p-0 gap-3"
        >
            <div className="row">
                <div className="col-6">
                    <Datepicker
                        required
                        label={t('projects.fields.labels.date')}
                        placeholder={t(
                            'billing_project.fields.placeholder.date'
                        )}
                        control={control}
                        name={BILLING_PROJECTS_MODEL.date}
                        touched={touchedFields[BILLING_PROJECTS_MODEL.date]}
                        errors={errors[BILLING_PROJECTS_MODEL.date]}
                        showMonthDropdown
                        showYearDropdown
                        pickerType={'days'}
                    />
                </div>

                <div className="col-6">
                    <Select
                        required
                        name={BILLING_PROJECTS_MODEL.status}
                        label={t('general.status')}
                        placeholder={t('projects.fields.placeholder.status')}
                        control={control}
                        touched={touchedFields[BILLING_PROJECTS_MODEL.status]}
                        errors={errors[BILLING_PROJECTS_MODEL.status]}
                        size="large"
                        options={translateOptions(INVOICE_STATUS_OPTIONS)}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <InputCurrency
                        required
                        label={t('projects.fields.labels.taxable')}
                        placeholder={t('projects.fields.placeholder.taxable')}
                        width="100%"
                        size={'large'}
                        icon={<CurrencyIcon />}
                        iconPosition={'right'}
                        control={control}
                        errors={errors[BILLING_PROJECTS_MODEL.taxable]}
                        name={BILLING_PROJECTS_MODEL.taxable}
                        allowNegativeValue={false}
                        inputProps={{
                            value: watch(BILLING_PROJECTS_MODEL.taxable),
                        }}
                    />
                </div>
                <div className="col-6">
                    <Select
                        name={BILLING_PROJECTS_MODEL.sal}
                        label={t('billing_project.fields.labels.sal')}
                        placeholder={t(
                            'billing_project.fields.placeholder.sal'
                        )}
                        control={control}
                        touched={touchedFields[BILLING_PROJECTS_MODEL.sal]}
                        errors={errors[BILLING_PROJECTS_MODEL.sal]}
                        size="large"
                        isClearable
                        options={salsWithNoInvoice}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <TextArea
                        required
                        label={t('projects.fields.labels.note')}
                        placeholder={t('projects.fields.placeholder.note')}
                        width="100%"
                        size={'large'}
                        errors={errors[BILLING_PROJECTS_MODEL.note]}
                        {...register(BILLING_PROJECTS_MODEL.note)}
                        resize={'vertical'}
                    />
                </div>
            </div>

            {isEdit && (
                <div className="row">
                    <StyledContainerDelete className="col-12">
                        <TrashIcon />
                        <p
                            onClick={() => {
                                openDialogDelete(dataForm?.id)
                            }}
                        >
                            {t('customer.actions.delete_billing')}
                        </p>
                    </StyledContainerDelete>
                </div>
            )}

            <StyledContainerFormActions className="row d-flex">
                <div className="col-md-4">
                    <Button
                        size="large"
                        color={'neutral'}
                        isUppercase
                        onClick={() => {
                            closeModal(isDirty)
                        }}
                    >
                        {t('commons:button.cancel')}
                    </Button>
                </div>
                <div className="col-md-8">
                    <Button
                        type="submit"
                        size="large"
                        isUppercase
                        disabled={(!isDirty && isEdit) || isSubmitting}
                    >
                        {isEdit
                            ? t('commons:button.save')
                            : t('commons:button.create')}
                    </Button>
                </div>
            </StyledContainerFormActions>
        </StyledFormBilling>
    )
}

export default BillingForm

BillingForm.propTypes = {
    isEdit: PropTypes.bool,
    closeModal: PropTypes.func,
    dataForm: PropTypes.object,
    openDialogDelete: PropTypes.func,
    sals: PropTypes.array,
    isFinance: PropTypes.bool,
}
