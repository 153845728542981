import PropTypes from 'prop-types'
import { useState } from 'react'

import FinalBalanceWrapper from '@/pages/projects/list/projects-form/final-balance-form/FinalBalanceWrapper.jsx'
import RAndDWrapper from '@/pages/projects/list/projects-form/r&d-form/R&DWrapper.jsx'
import StandardProjectWrapper from '@/pages/projects/list/projects-form/standard-projects-form/StandardProjectWrapper.jsx'
import { LABELS_TABLES } from '@/utilities/constants/index.js'

const ProjectsForm = ({
    dataForm,
    openDialogDelete,
    setOpenDialogToConfirm,
    onClose,
    applicants,
    customers,
    teams,
    successManagers,
    responsibilities,
}) => {
    const [isLoading, setIsLoading] = useState(false)

    const closeModal = isDirty => {
        if (isDirty) {
            setOpenDialogToConfirm(true)
        } else {
            onClose()
        }
    }

    const customersFiltered = customers.filter(
        customer => customer.data.name !== 'Humans.tech'
    )

    const props = {
        dataForm,
        onClose,
        setIsLoading,
        isLoading,
        openDialogDelete,
        closeModal,
        applicants,
        customers: customersFiltered,
        teams,
        successManagers,
        responsibilities,
    }

    switch (dataForm.typeForm) {
        case LABELS_TABLES.projects:
            return <StandardProjectWrapper {...props} />
        case LABELS_TABLES.final_balances:
            return <FinalBalanceWrapper {...props} />
        case LABELS_TABLES.rd:
            return <RAndDWrapper {...props} />
    }
}

export default ProjectsForm

ProjectsForm.propTypes = {
    dataForm: PropTypes.object,
    openDialogDelete: PropTypes.func,
    setOpenDialogToConfirm: PropTypes.func,
    onClose: PropTypes.func,
    applicants: PropTypes.array,
    customers: PropTypes.array,
    teams: PropTypes.array,
    successManagers: PropTypes.array,
    responsibilities: PropTypes.array,
}
