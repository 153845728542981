import { css } from 'styled-components'
import '@fontsource/mulish/200.css'
import '@fontsource/mulish/300.css'
import '@fontsource/mulish/400.css'
import '@fontsource/mulish/500.css'
import '@fontsource/mulish/600.css'
import '@fontsource/mulish/700.css'
import '@fontsource/mulish/800.css'
import '@fontsource/mulish/900.css'

export const defaultFontFamily = `'Mulish', sans-serif`

export const typography = {
    heading1: css`
        font-family: ${defaultFontFamily};
        font-weight: 800;
        font-size: 2rem; //32px
        line-height: 2.5rem; //40px
    `,
    heading2: css`
        font-family: ${defaultFontFamily};
        font-weight: 700;
        font-size: 2rem; //32px
        line-height: 2.5rem; //40px
    `,
    heading3: css`
        font-family: ${defaultFontFamily};
        font-weight: 400;
        font-size: 1.25rem; //20px
        line-height: 1.5rem; //24px
    `,
    heading3Bold: css`
        font-family: ${defaultFontFamily};
        font-weight: 700;
        font-size: 1.25rem; //20px
        line-height: 1.5rem; //24px
    `,
    heading4: css`
        font-family: ${defaultFontFamily};
        font-weight: 700;
        font-size: 0.875rem; //14px
        line-height: 1.125rem; //18px
    `,

    paragraph: {
        p24: css`
            font-family: ${defaultFontFamily};
            font-weight: 700;
            font-size: 1.5rem; //24px
            line-height: 1.875rem; //30px
        `,
        p16: {
            regular: css`
                font-family: ${defaultFontFamily};
                font-weight: 600;
                font-size: 1rem; //16px
                line-height: 1.25rem; //20px
            `,
            medium: css`
                font-family: ${defaultFontFamily};
                font-weight: 700;
                font-size: 1rem; //16px
                line-height: 1.25rem; //20px
            `,
            bold: css`
                font-family: ${defaultFontFamily};
                font-weight: 800;
                font-size: 1rem; //16px
                line-height: 1.25rem; //20px
            `,
        },
        p14: {
            regular: css`
                font-family: ${defaultFontFamily};
                font-weight: 600;
                font-size: 0.875rem; //14px
                line-height: 1.125rem; //18px
            `,
            medium: css`
                font-family: ${defaultFontFamily};
                font-weight: 700;
                font-size: 0.875rem; //14px
                line-height: 1.125rem; //18px
            `,
            bold: css`
                font-family: ${defaultFontFamily};
                font-weight: 800;
                font-size: 0.875rem; //14px
                line-height: 1.375rem; //22px
            `,
        },
        p12: {
            regular: css`
                font-family: ${defaultFontFamily};
                font-weight: 400;
                font-size: 0.75rem; //12px
                line-height: 1.25rem; //20px
            `,
            bold: css`
                font-family: ${defaultFontFamily};
                font-weight: 700;
                font-size: 0.75rem; //12px
                line-height: 1.25rem; //20px
            `,
        },
        p10: {
            regular: css`
                font-family: ${defaultFontFamily};
                font-weight: 400;
                font-size: 0.625rem; //10px
                line-height: 1.25rem; //20px
            `,
            bold: css`
                font-family: ${defaultFontFamily};
                font-weight: 700;
                font-size: 0.625rem; //10px
                line-height: 1.25rem; //20px
            `,
        },
    },

    buttonText: css`
        font-family: ${defaultFontFamily};
        font-weight: 700;
        font-size: 0.875rem; //14px
        line-height: 1.375rem; //22px
    `,
}
