export const remapRespForThreeCards = (analytics, project) => {
    return [
        {
            id: 1,
            title: 'Budget totale',
            value: project?.budget,
            progress: analytics?.allocated_budget_percentage,
            hours: analytics?.allocated_budget_hours,
            hoursOf: analytics?.remaining_allocated_budget_hours,
            haveProgress: true,
        },
        {
            id: 2,
            title: 'Budget pianificato FLOAT',
            value: analytics?.float_scheduled_hours * project?.rate,
            progress: analytics?.float_schedules_hours_percentage,
            hours: analytics?.float_scheduled_hours,
            hoursOf: analytics?.remaining_float_scheduled_hours,
            haveProgress: true,
        },
        {
            id: 3,
            title: 'Ore consumate',
            value: analytics?.consumed_hours * project?.rate,
            progress: analytics?.consumed_percentage,
            color: '#FFB800',
            hours: analytics?.consumed_hours,
            hoursOf: analytics?.remaining_consumed_hours,
            haveProgress: true,
        },
    ]
}
