import PropTypes, { shape } from 'prop-types'

import Body from '@/components/table/desktop/body/Body.jsx'
import { TABLE_ALIGNMENTS } from '@/utilities/constants'

import Header from './header'

const DesktopTable = ({
    isLoading = false,
    isRefetching = false,
    isError,
    alternatingRowColors = false,
    bodyheight = 'auto',
    columns = [],
    data = [],
    isPaginated = true,
    onChangePageCb,
    onRefresh,
    page,
    sortColumnCb,
    sorter,
    columnsRenderers,
    onClickRow = () => null,
    expandedRowsIndexes = [],
    selectedRow,
}) => {
    const bodyProps = {
        isPaginated,
        columns,
        columnsRenderers,
        isLoading,
        isRefetching,
        isError,
        bodyheight,
        onClickRow,
        onRefresh,
        expandedRowsIndexes,
        alternatingRowColors,
        data,
        page,
        onChangePageCb,
        selectedRow,
    }

    return (
        <div>
            <Header
                columns={columns}
                sorter={sorter}
                sortColumnCb={sortColumnCb}
            />
            <Body {...bodyProps} />
        </div>
    )
}

export default DesktopTable

DesktopTable.propTypes = {
    isLoading: PropTypes.bool,
    isRefetching: PropTypes.bool,
    isError: PropTypes.bool,
    alternatingRowColors: PropTypes.bool,
    bodyheight: PropTypes.string,
    columns: PropTypes.arrayOf(
        shape({
            alignment: PropTypes.oneOf(Object.values(TABLE_ALIGNMENTS)),
            allowOverflow: PropTypes.bool,
            icon: PropTypes.node,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            mobileFullLength: PropTypes.bool,
            name: PropTypes.string.isRequired,
            sortable: PropTypes.bool,
            width: ({ width }, propName, componentName) => {
                if (!/^\d+%$/.test(width)) {
                    return new Error(
                        `Invalid prop '${propName}' supplied to ${componentName} (${width}). The prop must be a positive percentage number.`
                    )
                }
            },
            divider: PropTypes.bool,
            havePadding: PropTypes.bool,
        })
    ).isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
    isPaginated: PropTypes.bool,
    onChangePageCb: PropTypes.func,
    onRefresh: PropTypes.func,
    page: PropTypes.number,
    sortColumnCb: PropTypes.func,
    sorter: PropTypes.object,
    columnsRenderers: PropTypes.object,
    onClickRow: PropTypes.func,
    expandedRowsIndexes: PropTypes.arrayOf(PropTypes.number),
    selectedRow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
