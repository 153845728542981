import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Datepicker from '@/components/date-picker/DatePicker'
import Input from '@/components/input/Input.jsx'
import ScrollSelect from '@/components/scroll-select/ScrollSelect'
import { TOAST_STATUSES } from '@/components/toast/Toast.jsx'
import useAlert from '@/hooks/useAlert.jsx'
import useError from '@/hooks/useError.jsx'
import { handleApiError } from '@/utilities/api/helpers.js'
import { EDIT_CELL_TYPE } from '@/utilities/constants/commons'

import { StyledCellRowContainer, StyledDeliveryButton } from './styles'

const EditCellRow = ({
    data,
    optionsData,
    handleBlur,
    control,
    type,
    date,
    lastPage,
    currentPage,
    disabled,
    onSubmit,

    index,
    readOnly,
    outOfDate,
    isSuccess,
    errors,
}) => {
    const [value, setValue] = useState('')
    const [options, setOptions] = useState([])
    const [currentDate, setCurrentDate] = useState(new Date())
    const { t } = useTranslation()
    const { triggerAlert } = useAlert()
    const showError = useError()

    useEffect(() => {
        setValue(data)
    }, [data])

    useEffect(() => {
        setOptions(optionsData)
    }, [optionsData])

    useEffect(() => {
        setCurrentDate(new Date(date))
    }, [date])

    useEffect(() => {
        if (isSuccess) {
            triggerAlert({
                variant: TOAST_STATUSES.SUCCESS,
                message: t('commons:toast.updated_success'),
            })
        }
        if (errors !== undefined) {
            if (errors?.data && errors?.data?.message) {
                handleApiError({
                    error: {
                        data: errors?.data,
                    },
                    handleGeneralError: showError,
                })
            } else {
                handleApiError({
                    error: {
                        data: {
                            message: t('commons:toast.generic_error'),
                        },
                    },

                    handleGeneralError: showError,
                })
            }
        }
        return
    }, [isSuccess, errors])

    const handleChange = e => {
        setValue(e.target.value)
    }

    const handleOptionChange = e => {
        setValue(e.label)
        handleBlur(e.value)
    }

    const handleDateChange = e => {
        setCurrentDate(e)
        handleBlur(e)
    }

    return (
        <StyledCellRowContainer>
            {type === EDIT_CELL_TYPE.text && (
                <Input
                    className="sal_input"
                    type="text"
                    value={value || ''}
                    size={'small'}
                    onChange={handleChange}
                    onBlur={e => handleBlur(e)}
                    name={`sal${index}`}
                    disabled={disabled}
                    inputProps={{
                        onKeyPress: e => {
                            if (e.key === 'Enter') {
                                e.preventDefault()
                                handleBlur(e)
                            }
                        },
                    }}
                />
            )}
            {type === EDIT_CELL_TYPE.currency && (
                <Input
                    width="100%"
                    size={'small'}
                    className="sal_input"
                    value={value === null || value === undefined ? '0' : value}
                    control={control}
                    onChange={handleChange}
                    onBlur={e => handleBlur(e)}
                    name={`sal${index}`}
                    disabled={disabled}
                    type={'number'}
                    inputProps={{
                        onKeyPress: e => {
                            if (e.key === '0' && e.target.value.length === 0) {
                                e.preventDefault()
                            }
                        },
                        onKeyDown: e => {
                            if (e.keyCode === 189 || e.keyCode === 109) {
                                e.preventDefault()
                            }
                            if (e.key === 'Enter') {
                                e.preventDefault()
                                handleBlur(e)
                            }
                        },
                        onFocus: e => {
                            if (e.target.value === '0') {
                                e.target.value = ''
                            }
                        },
                    }}
                    readOnly={readOnly}
                />
            )}
            {type === EDIT_CELL_TYPE.select && (
                <ScrollSelect
                    value={{ label: value, value: value }}
                    onChange={handleOptionChange}
                    size={'medium'}
                    options={options}
                    menuPosition={() => 'fixed'}
                    lastPage={lastPage}
                    currentPage={currentPage}
                    disabled={disabled}
                    isOptionDisabled={options => !options?.data?.is_active}
                />
            )}
            {type === EDIT_CELL_TYPE.date && (
                <Datepicker
                    name={`date${index}`}
                    control={control}
                    showMonthDropdown
                    showYearDropdown
                    dateFormat="dd/MM/yyyy"
                    pickerType={'days'}
                    selected={currentDate}
                    onChange={e => handleDateChange(e)}
                    suffixIcon={null}
                    disabled={disabled}
                    outOfDate={outOfDate}
                />
            )}

            {type === EDIT_CELL_TYPE.delivery_button && (
                <StyledDeliveryButton
                    disabled={disabled}
                    onClick={() => !disabled && onSubmit()}
                >
                    {value}
                </StyledDeliveryButton>
            )}
        </StyledCellRowContainer>
    )
}

export default EditCellRow

EditCellRow.propTypes = {
    handleBlur: PropTypes.func,
    data: PropTypes.any,
    date: PropTypes.any,
    optionsData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    control: PropTypes.any,
    type: PropTypes.any,
    lastPage: PropTypes.number,
    currentPage: PropTypes.number,
    disabled: PropTypes.bool,
    onSubmit: PropTypes.func,
    index: PropTypes.number,
    readOnly: PropTypes.bool,
    outOfDate: PropTypes.bool,
    isSuccess: PropTypes.bool,
    errors: PropTypes.object,
}
