import dayjs from 'dayjs'

function getDatesInRange(daysAllocated, selectedMonth) {
    const end = dayjs()
        .month(selectedMonth - 1)
        .endOf('month')
        .date()

    const d = Array.from({ length: end }, (_, i) => {
        const month = selectedMonth - 1
        const currentDay = dayjs()
            .month(month)
            .startOf('month')
            .add(i, 'day')
            .format('YYYY-MM-DD')
        const dayAll = daysAllocated ? daysAllocated[currentDay] : false

        return [
            currentDay,
            dayAll
                ? dayAll
                : {
                      allocated: null,
                      confirmed: null,
                      logged: null,
                  },
        ]
    })

    return Object.fromEntries(d)
}

export const remappedData = (allocationItem, userAllocated, selectedMonth) => {
    const data = []

    userAllocated?.forEach(item => {
        const { user } = item
        const userData = {
            id: user.id,
            fullName: `${user.first_name} ${user.last_name}`,
            allocations: [],
        }
        data.push(userData)
    })

    const allocatedDays = {}

    allocationItem?.forEach(userState => {
        const daysOfSelectedMonth = getDatesInRange(
            userState?.allocations,
            selectedMonth
        )
        const userIndex = data.findIndex(
            userData => userData.id === userState.user.id
        )
        if (userIndex !== -1) {
            data[userIndex].allocations.push(daysOfSelectedMonth)

            for (const day in daysOfSelectedMonth) {
                if (daysOfSelectedMonth[day].allocated_hours > 0) {
                    allocatedDays[day] = true
                }
            }
        }
    })

    data.forEach(userData => {
        if (userData.allocations.length === 0) {
            const emptyAllocation = getDatesInRange(null, selectedMonth)
            userData.allocations.push(emptyAllocation)
        }

        const userState = allocationItem?.find(
            item => item.user.id === userData.id
        )

        if (userState) {
            let allocatedHours = 0
            let confirmedHours = 0
            for (const day in userState.allocations) {
                const allocation = userState.allocations[day]
                if (allocation.allocated_hours) {
                    allocatedHours += allocation.allocated_hours
                }
                if (allocation.confirmed_hours) {
                    confirmedHours += allocation.confirmed_hours
                }
            }

            userData.allocated_hours = allocatedHours
            userData.confirmed_hours = confirmedHours
        } else {
            userData.allocated_hours = 0
            userData.confirmed_hours = 0
        }

        userData.allocations.forEach(allocation => {
            for (const day in allocation) {
                allocation[day] = {
                    ...allocation[day],
                    isLarge: allocatedDays[day] === true,
                    userId: userData.id,
                }
            }
        })
    })

    return data
}
