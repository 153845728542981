import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import FinalBalanceTable from '@/components/final-balance-table/FinalBalanceTable.jsx'
import Spinner from '@/components/spinner/Spinner.jsx'
import Table from '@/components/table/index.jsx'
import Tabs from '@/components/tabs/Tabs.jsx'
import {
    columnsInvoices,
    columnsRenderersInvoices,
} from '@/pages/customers/detail/customer-tables/table-billing/billing-columns/columnsBilling.jsx'
import {
    columnsCosts,
    columnsRenderersCosts,
} from '@/pages/customers/detail/customer-tables/table-costs/costs-columns/columnsCosts.jsx'
import {
    columnsProjects,
    columnsRenderersProjects,
} from '@/pages/customers/detail/customer-tables/table-projects/projects-columns/columnsProject.jsx'
import { remappedDataFinalBalance } from '@/pages/customers/detail/remappedDataFinalBalance.js'
import {
    useGetCustomerByIdProjectsQuery,
    useGetCustomerCostsQuery,
    useGetFinalBalanceCustomerQuery,
    useGetInvoicesCustomersQuery,
} from '@/services/customers/customersApiSlice.js'
import { tableHeight, handleSortFilter } from '@/utilities/helpers'

const CustomerTabs = ({
    onSelectedTab,
    openDialogDelete,
    openEditSidebar,
    filters,
    setDataForm,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { id } = useParams()

    const [sortDirections, setSortDirections] = useState({
        projects: 'asc',
        billing: 'asc',
        costs: 'asc',
    })

    const { data: customerDetails, isFetching } =
        useGetCustomerByIdProjectsQuery(
            {
                ID: id,
                sortDirection: sortDirections?.projects,
                ...filters,
            },
            { skip: !id }
        )

    const { data: customerInvoices } = useGetInvoicesCustomersQuery({
        customerID: id,
        sortDirection: sortDirections?.billing,
    })

    const { data: customerCosts } = useGetCustomerCostsQuery({
        customerID: id,
        sortDirection: sortDirections?.costs,
    })
    const [year, setYear] = useState(new Date().getFullYear())
    const { data } = useGetFinalBalanceCustomerQuery({
        year: year,
        ...filters,
        customerId: id,
    })

    const onSectionClick = section => {
        const searchParams = new URLSearchParams(location.search)
        searchParams.set('section', section)
        onSelectedTab(section)
        navigate(`?${searchParams.toString()}`)
    }

    //it is used to not render the columns every time but only when it actually changes the language
    const tableColumns = useMemo(() => {
        return {
            projects: columnsProjects(),
            billing: columnsInvoices(),
            costs: columnsCosts(),
        }
    }, [t]) // eslint-disable-line react-hooks/exhaustive-deps

    const finalBalanceData = remappedDataFinalBalance(data)

    const tabsComponent = [
        {
            id: 1,
            value: t('general.projects'),
            label: t('general.projects'),
            component: (
                <>
                    <Table
                        columns={tableColumns.projects}
                        columnsRenderers={columnsRenderersProjects(
                            openDialogDelete,
                            openEditSidebar
                        )}
                        data={customerDetails?.data}
                        isLoading={isFetching}
                        isPaginated
                        bodyheight={tableHeight(customerDetails?.data)}
                        sortColumnCb={() =>
                            handleSortFilter('projects', setSortDirections)
                        }
                    />
                </>
            ),
        },
        {
            id: 2,
            value: t('customer.tabs.billing'),
            label: t('customer.tabs.billing'),
            component: (
                <>
                    <Table
                        columns={tableColumns.billing}
                        columnsRenderers={columnsRenderersInvoices(
                            openDialogDelete,
                            openEditSidebar
                        )}
                        data={customerInvoices}
                        isLoading={isFetching}
                        isPaginated
                        bodyheight={tableHeight(customerInvoices)}
                        sortColumnCb={() =>
                            handleSortFilter('billing', setSortDirections)
                        }
                    />
                </>
            ),
        },
        {
            id: 3,
            value: t('customer.tabs.costs'),
            label: t('customer.tabs.costs'),
            component: (
                <>
                    <Table
                        columns={tableColumns.costs}
                        columnsRenderers={columnsRenderersCosts(
                            openDialogDelete,
                            openEditSidebar
                        )}
                        data={customerCosts?.data}
                        isLoading={isFetching}
                        isPaginated
                        bodyheight={tableHeight(customerCosts?.data)}
                        sortColumnCb={() =>
                            handleSortFilter('costs', setSortDirections)
                        }
                    />
                </>
            ),
        },
        {
            id: 4,
            value: t('customer.tabs.final_balances'),
            label: t('customer.tabs.final_balances'),
            component: (
                <FinalBalanceTable
                    parentRows={finalBalanceData}
                    setDataForm={setDataForm}
                    setYear={setYear}
                />
            ),
        },
    ]

    return (
        <>
            {isFetching && <Spinner />}
            <Tabs
                onValueChange={e => onSectionClick(e)}
                triggers={tabsComponent}
                defaultValue={t('general.projects')}
                haveDivider
            />
        </>
    )
}

export default CustomerTabs

CustomerTabs.propTypes = {
    onSelectedTab: PropTypes.func,
    setDataForm: PropTypes.func,
    openDialogDelete: PropTypes.func,
    openEditSidebar: PropTypes.func,
    filters: PropTypes.object,
}
