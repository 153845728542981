import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'

import Table from '@/components/table/index.jsx'
import {
    columnsRenderersTeams,
    columnsTeams,
} from '@/pages/settings/tables-settings/columnsTeams.jsx'
import { useGetTeamsQuery } from '@/services/settings/settingApiSlice.js'
import { selectPanelId } from '@/services/settings/settingsSlice'
import { tableHeight } from '@/utilities/helpers'

const TableTeams = () => {
    const { dataProps } = useOutletContext()
    const { openPanel, openDeleteDialog, setOpenForm } = dataProps

    const [currentPage, setCurrentPage] = useState(1)

    const { data: teams, isFetching } = useGetTeamsQuery({
        currentPage: currentPage,
        sortDirection: 'asc',
    })

    const panelId = useSelector(selectPanelId)

    const handleNextPage = () => {
        if (!isFetching) {
            if (
                teams?.pagination?.nextPage !== null &&
                currentPage !== teams?.pagination?.lastPage
            ) {
                setCurrentPage(currentPage + 1)
            }
        }
    }

    return (
        <Table
            columns={columnsTeams()}
            columnsRenderers={columnsRenderersTeams(
                openPanel,
                openDeleteDialog,
                setOpenForm
            )}
            data={teams?.data}
            isLoading={isFetching}
            isPaginated
            bodyheight={tableHeight(teams?.data)}
            selectedRow={panelId}
            onChangePageCb={handleNextPage}
        />
    )
}

export default TableTeams

TableTeams.propTypes = {
    openPanel: PropTypes.func,
    openDeleteDialog: PropTypes.func,
    setOpenForm: PropTypes.func,
}
