import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { PlusIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import Search from '@/components/search/Search.jsx'
import Select from '@/components/select/Select.jsx'
import {
    StyledCustomerBilling,
    StyledCustomerFilters,
} from '@/pages/customers/detail/customer-filters/styles.js'
import { LABELS_TABLES, MODAL_TYPE } from '@/utilities/constants/index.js'
import { STATUS_OPTIONS_PROJECTS } from '@/utilities/constants/list.js'
import { translateOptions } from '@/utilities/helpers.js'

export const CustomerFilters = ({
    selectedTab,
    setOpenSidebar,
    setFilters,
    filters,
}) => {
    const { t } = useTranslation()

    switch (selectedTab) {
        case t('general.projects'):
            return (
                <StyledCustomerFilters>
                    <Select
                        isControlled={false}
                        size={'medium'}
                        placeholder={t('general.status')}
                        options={translateOptions(STATUS_OPTIONS_PROJECTS)}
                        onChange={value =>
                            setFilters({
                                ...filters,
                                is_active: value?.value,
                            })
                        }
                    />
                </StyledCustomerFilters>
            )

        case t('customer.tabs.billing'):
            return (
                <StyledCustomerFilters>
                    <Button
                        size={'small'}
                        type={'button'}
                        onClick={() =>
                            setOpenSidebar({
                                open: true,
                                formType: LABELS_TABLES.billing,
                                type: MODAL_TYPE.create,
                            })
                        }
                    >
                        <PlusIcon />
                        {t('customer.actions.add_billing')}
                    </Button>
                </StyledCustomerFilters>
            )

        case t('customer.tabs.final_balances'):
            return (
                <StyledCustomerBilling>
                    <Search
                        placeholder={t('settings.filters.placeholder.search')}
                        handleSearch={value => {
                            setFilters({ search: value })
                        }}
                    />
                    <Button
                        type={'button'}
                        size={'small'}
                        onClick={() =>
                            setOpenSidebar({
                                open: true,
                                formType: LABELS_TABLES.final_invoice,
                                isEdit: false,
                            })
                        }
                    >
                        <PlusIcon />
                        {t('customer.actions.add_billing')}
                    </Button>
                </StyledCustomerBilling>
            )
        case t('customer.tabs.costs'):
            return (
                <StyledCustomerFilters>
                    <Button
                        size={'small'}
                        type={'button'}
                        onClick={() =>
                            setOpenSidebar({
                                open: true,
                                formType: LABELS_TABLES.costs,
                                type: MODAL_TYPE.create,
                            })
                        }
                    >
                        <PlusIcon />
                        {t('customer.actions.add_cost')}
                    </Button>
                </StyledCustomerFilters>
            )
        default:
            return (
                <StyledCustomerFilters>
                    <Select
                        isControlled={false}
                        size={'medium'}
                        placeholder={t('general.status')}
                        options={translateOptions(STATUS_OPTIONS_PROJECTS)}
                        onChange={value =>
                            setFilters({
                                ...filters,
                                is_active: value?.value,
                            })
                        }
                    />
                </StyledCustomerFilters>
            )
    }
}

export default CustomerFilters

CustomerFilters.propTypes = {
    selectedTab: PropTypes.string,
    setOpenSidebar: PropTypes.func,
    setFilters: PropTypes.func,
    filters: PropTypes.object,
}
