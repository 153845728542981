import styled, { css } from 'styled-components'

export const StyledContainerFluid = styled.div`
    ${({ theme }) => css`
        padding: ${theme.spacing * 4}px ${theme.spacing * 3}px;
    `}
`

export const StyledContainerFilter = styled.div`
    ${({ theme, isMembers }) => css`
        display: grid;
        grid-template-columns: ${isMembers
            ? ' 130px 174px 160px'
            : `170px 140px`};
        grid-gap: ${theme.spacing}px;
        grid-template-rows: 1fr;
        color: ${theme.palette.primary[0]};
    `}
`

export const StyledLink = styled.a`
    ${({ theme, nothingAssociated }) => css`
        color: ${nothingAssociated
            ? theme.palette.danger.base.active
            : 'inherit'};

        &:hover {
            color: ${nothingAssociated
                ? theme.palette.danger.base.hover
                : theme.palette.primary.base.active};
        }
    `}
`

export const StyledContainerDropdownItems = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: ${theme.spacing}px;
        height: 20px;

        & > p {
            ${theme.typography.paragraph.p12.regular};
            text-decoration: none;
        }

        & > svg {
            width: 16px;
            height: 16px;

            & g,
            & path {
                fill: ${theme.palette.danger.base.active};
            }
        }
    `}
`
