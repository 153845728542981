import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { InfoIcon, PlusIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import Tooltip from '@/components/tooltip/Tooltip.jsx'
import { StyledFiltersWrapper } from '@/pages/projects/detail/detail-standard-projects/project-sub-header/styles.js'
import { LABELS_TABLES } from '@/utilities/constants/index.js'

const ProjectSubHeader = ({
    selectedTab,
    onClickHandler,
    notHavePhase,
    onClickToUpdate,
}) => {
    const { t } = useTranslation()

    if (selectedTab === LABELS_TABLES.billing) {
        return (
            <StyledFiltersWrapper width={150} notHavePhase={notHavePhase}>
                {notHavePhase && (
                    <Tooltip
                        text={t('validation:general_form.log_activity')}
                        showPosition={'bottom'}
                    >
                        <Button
                            size={'small'}
                            variant={'ghost'}
                            onClick={onClickToUpdate}
                        >
                            <InfoIcon />
                            {t('general.update')}
                        </Button>
                    </Tooltip>
                )}
                <Button type={'button'} size={'small'} onClick={onClickHandler}>
                    <PlusIcon />
                    {t('projects.actions.add_billing')}
                </Button>
            </StyledFiltersWrapper>
        )
    } else if (selectedTab === LABELS_TABLES.costs) {
        return (
            <StyledFiltersWrapper width={140} notHavePhase={notHavePhase}>
                {notHavePhase && (
                    <Tooltip
                        text={t('validation:general_form.log_activity')}
                        showPosition={'bottom'}
                    >
                        <Button
                            size={'small'}
                            variant={'ghost'}
                            onClick={onClickToUpdate}
                        >
                            <InfoIcon />
                            {t('general.update')}
                        </Button>
                    </Tooltip>
                )}
                <Button type={'button'} size={'small'} onClick={onClickHandler}>
                    <PlusIcon />
                    {t('projects.actions.add_cost')}
                </Button>
            </StyledFiltersWrapper>
        )
    } else {
        return null
    }
}

export default ProjectSubHeader

ProjectSubHeader.propTypes = {
    selectedTab: PropTypes.string,
    onClickHandler: PropTypes.func,
    onClickToUpdate: PropTypes.func,
    notHavePhase: PropTypes.bool,
}
