import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import PropTypes from 'prop-types'

import {
    StyledDropdownContent,
    StyledDropdownItem,
    StyledDropdownTrigger,
    StyledDropdownItemChild,
} from './styles'

// referece: https://www.radix-ui.com/docs/primitives/components/dropdown-menu

const Dropdown = ({
    trigger,
    items,
    defaultOpen,
    side,
    align = 'end',
    minwidth = 122,
    haveHover = false,
    title,
}) => {
    return (
        <DropdownMenu.Root defaultOpen={defaultOpen}>
            <StyledDropdownTrigger asChild>
                <button tabIndex={'-1'}>{trigger}</button>
            </StyledDropdownTrigger>

            <DropdownMenu.Portal>
                <StyledDropdownContent
                    sideOffset={5}
                    side={side}
                    align={align}
                    minwidth={minwidth}
                >
                    {title && <h3>{title}</h3>}
                    {items.map(
                        ({ label, onSelect, icon, isToDelete, disabled }) => (
                            <StyledDropdownItem
                                onSelect={onSelect}
                                key={label}
                                asChild
                                haveHover={haveHover}
                            >
                                <StyledDropdownItemChild
                                    isToDelete={isToDelete}
                                    disabled={disabled}
                                >
                                    {icon}
                                    <span>{label}</span>
                                </StyledDropdownItemChild>
                            </StyledDropdownItem>
                        )
                    )}
                </StyledDropdownContent>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    )
}

export default Dropdown

Dropdown.propTypes = {
    minwidth: PropTypes.number,
    defaultOpen: PropTypes.bool,
    side: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
    trigger: PropTypes.element.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            onSelect: PropTypes.func,
            icon: PropTypes.node,
            isToDelete: PropTypes.bool,
        }).isRequired
    ).isRequired,
    align: PropTypes.oneOf(['start', 'end', 'center']),
    haveHover: PropTypes.bool,
    title: PropTypes.string,
}
