import styled, { css } from 'styled-components'

export const StyledFormBilling = styled.form`
    ${({ isEdit }) => css`
        grid-template-rows: ${!isEdit
            ? ' repeat(3, max-content) auto'
            : ' repeat(4, max-content) auto'};
        height: 100%;
    `}
`

export const StyledContainerFormActions = styled.div`
    height: 100%;
    align-items: end;
`
