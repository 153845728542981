import { createSelector, createSlice } from '@reduxjs/toolkit'

const sliceName = 'theme'

const appConfig = createSlice({
    name: sliceName,
    initialState: {
        themeMode: 'light',
    },
    reducers: {
        setThemeMode: (state, payload) => {
            state.themeMode = payload
        },
    },
})

const selectSelf = state => state[sliceName]

export const selectThemeMode = createSelector(selectSelf, state => state.mode)

export const { setThemeMode } = appConfig.actions

export default appConfig.reducer
