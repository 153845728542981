import PropTypes from 'prop-types'

import { StyledProgressRoot, StyledProgressIndicator } from './style'

const Progress = ({ progressValue, className, width, height, color }) => {
    return (
        <StyledProgressRoot
            className={className}
            value={progressValue}
            width={width}
            height={height}
        >
            <StyledProgressIndicator
                className={className}
                progress={progressValue}
                color={color}
            />
        </StyledProgressRoot>
    )
}

export default Progress

Progress.propTypes = {
    progressValue: PropTypes.number,
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.number,
    color: PropTypes.string,
}
