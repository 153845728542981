import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import SidePopover from '@/components/side-popover/SidePopover.jsx'
import AddMembers from '@/pages/customers/log/LogForm/AddMembers/AddMembers.jsx'
import {
    validationSchema,
    DEFAULT_ADD_MEMBERS_MOCK,
} from '@/pages/customers/log/LogForm/AddMembers/addMembersForm.schema.js'

const AddMembersWrapper = ({
    dataForm,
    closeModal,
    openDialogDelete,
    isEdit,
    members,
    finalBalance,
    allocations,
    userAllocated,
}) => {
    const { t } = useTranslation()

    const methods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'onChange',
        nativeValidation: false,
        shouldFocusError: true,
        resolver: yupResolver(validationSchema),
        defaultValues: DEFAULT_ADD_MEMBERS_MOCK,
    })

    const {
        formState: { isDirty },
    } = methods

    return (
        <FormProvider {...methods}>
            <SidePopover
                onClose={() => closeModal(isDirty)}
                isDirty={isDirty}
                title={t('activity.actions.modify_members')}
            >
                <AddMembers
                    isEdit={isEdit}
                    closeModal={closeModal}
                    dataForm={dataForm}
                    openDialogDelete={openDialogDelete}
                    members={members}
                    finalBalance={finalBalance}
                    allocations={allocations}
                    userAllocated={userAllocated}
                />
            </SidePopover>
        </FormProvider>
    )
}

export default AddMembersWrapper

AddMembersWrapper.propTypes = {
    dataForm: PropTypes.object,
    onClose: PropTypes.func,
    closeModal: PropTypes.func,
    openDialogDelete: PropTypes.func,
    isEdit: PropTypes.bool,
    members: PropTypes.array,
    finalBalance: PropTypes.object,
    allocations: PropTypes.array,
    userAllocated: PropTypes.array,
}
