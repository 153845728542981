import PropTypes from 'prop-types'

import { truncateText } from '@/utilities/helpers.js'

import { StyledChipContainer, StyledContainer } from './styles'

const Chip = ({
    className,
    variant,
    outlined,
    textOnly,
    size = 'medium',
    rounded,
    text,
    textUppercase,
    icon,
    isStrokeIcon,
    isPathFilledIcon,
    iconPosition = 'left',
    isClickable,
    fullWidth = false,
    disabled,
    ...rest
}) => {
    const maxTextLength = fullWidth ? 15 : 8
    return (
        <StyledContainer fullWidth={fullWidth}>
            <StyledChipContainer
                className={className}
                title={text}
                {...rest}
                rounded={rounded}
                size={size}
                fullWidth={fullWidth}
                outlined={outlined}
                iconPosition={iconPosition}
                textOnly={textOnly}
                isStrokeIcon={isStrokeIcon}
                isPathFilledIcon={isPathFilledIcon}
                textUppercase={textUppercase}
                isClickable={isClickable}
                disabled={disabled}
                variant={variant}
            >
                {!!icon && <>{icon}</>}
                {!!text && <span>{truncateText(text, maxTextLength)}</span>}
            </StyledChipContainer>
        </StyledContainer>
    )
}

export default Chip

Chip.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(['success', 'warning', 'danger', '']),
    outlined: PropTypes.bool,
    textOnly: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    rounded: PropTypes.bool,
    text: PropTypes.string,
    textUppercase: PropTypes.bool,
    icon: PropTypes.element,
    isStrokeIcon: PropTypes.bool,
    isPathFilledIcon: PropTypes.bool,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    isClickable: PropTypes.bool,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
}
