import { Virtuoso } from 'react-virtuoso'
import styled, { css } from 'styled-components'

import { TABLE_HEIGHT } from '@/utilities/constants/commons'

const BodyStyle = css`
    ${({ theme: { palette }, bodyheight }) => css`
        height: ${bodyheight} !important;
        position: relative;
        max-height: ${TABLE_HEIGHT};
        background: ${palette.neutral.base[0]};
        overflow-y: auto !important;
    `}
`

export const StyledTbody = styled.div`
    ${BodyStyle};
`

export const StyledPaginatedTbody = styled(Virtuoso)`
    ${BodyStyle};
`

export const StyledNoResultsMessage = styled.span`
    ${({ theme: { spacing, palette } }) => css`
        display: block;
        text-align: center;
        padding-top: ${spacing * 3}px;
        color: ${palette.neutral.base[200]};
    `}
`
