import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { EditIcon } from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import SectionItem from '@/components/sidebar-tab-item/section-item/SectionItem'
import SectionTitle from '@/components/sidebar-tab-item/section-title/SectionTitle'
import {
    StyledSliderSectionContainer,
    StyledTabInfoContainer,
    StyledSidebarSubHeader,
    StyledTabCtaContainer,
} from '@/components/sidebar-tab-item/styles'
import { LABELS_TABLES } from '@/utilities/constants/index.js'
import { STATUS_OPTIONS } from '@/utilities/constants/list.js'
import { translateOptions } from '@/utilities/helpers.js'

import { customerFields } from './customerFields'

const CustomerSidebar = ({ data, setDataForm }) => {
    const { t } = useTranslation()
    const theme = useTheme()

    const { customer, success_manager } = data

    const customerData = customerFields(customer, success_manager)

    return (
        <>
            <StyledSidebarSubHeader>
                {
                    <h3>
                        {data?.customer?.name} #{data?.customer?.id}
                    </h3>
                }
                <StyledTabCtaContainer>
                    <Button
                        onClick={() =>
                            setDataForm({
                                isOpen: true,
                                typeForm: LABELS_TABLES.customer,
                                id: data?.id,
                                isEdit: true,
                                data: {
                                    ...data?.customer,
                                    success_manager: data?.success_manager,
                                },
                            })
                        }
                        collapsed
                    >
                        <EditIcon
                            width={10}
                            height={10}
                            fill={theme.palette.neutral.base[0]}
                        />
                    </Button>
                </StyledTabCtaContainer>
            </StyledSidebarSubHeader>
            <StyledSliderSectionContainer>
                <StyledTabInfoContainer>
                    <SectionItem
                        label={t('customer.sidebar_left.labels.business_name')}
                        value={customer.name}
                        width={100}
                    />
                </StyledTabInfoContainer>
            </StyledSliderSectionContainer>

            <StyledSliderSectionContainer>
                <StyledTabInfoContainer>
                    <SectionItem
                        label={t('general.status')}
                        value={translateOptions(STATUS_OPTIONS).map(item => {
                            if (item.value === customer.is_active) {
                                return item.label
                            }
                        })}
                        width={100}
                    />
                </StyledTabInfoContainer>
            </StyledSliderSectionContainer>

            <StyledSliderSectionContainer>
                <SectionTitle
                    title={t('customer.sidebar_left.subtitles.billing_data')}
                />
                <StyledTabInfoContainer>
                    {customerData.billing_data.map((item, index) => {
                        return (
                            <SectionItem
                                label={item.label}
                                value={item.value}
                                width={item.width}
                                key={index}
                            />
                        )
                    })}
                </StyledTabInfoContainer>
            </StyledSliderSectionContainer>

            <StyledSliderSectionContainer>
                <SectionTitle
                    title={t('customer.sidebar_left.subtitles.link')}
                />

                <StyledTabInfoContainer>
                    <SectionItem
                        label={t('customer.sidebar_left.labels.link_drive')}
                        value={customer.drive_url}
                        link={true}
                        width={100}
                    />
                </StyledTabInfoContainer>
            </StyledSliderSectionContainer>

            <StyledSliderSectionContainer>
                <SectionTitle
                    title={t('customer.sidebar_left.subtitles.billing_address')}
                />

                <StyledTabInfoContainer>
                    {customerData.customer_address.map((item, index) => {
                        return (
                            <SectionItem
                                label={item.label}
                                value={item.value}
                                width={item.width}
                                key={index}
                            />
                        )
                    })}
                </StyledTabInfoContainer>
            </StyledSliderSectionContainer>

            <StyledSliderSectionContainer>
                <SectionTitle
                    title={t('customer.sidebar_left.subtitles.success_data')}
                />

                <StyledTabInfoContainer>
                    {customerData.account_data.map((item, index) => {
                        return (
                            <SectionItem
                                label={item.label}
                                value={item.value}
                                width={item.width}
                                key={index}
                            />
                        )
                    })}
                </StyledTabInfoContainer>
            </StyledSliderSectionContainer>

            <div>
                <SectionTitle title={t('customer.sidebar_left.labels.note')} />

                <StyledTabInfoContainer>
                    <SectionItem label="" value={customer.note} width={100} />
                </StyledTabInfoContainer>
            </div>
        </>
    )
}

export default CustomerSidebar

CustomerSidebar.propTypes = {
    data: PropTypes.any,
    setDataForm: PropTypes.func,
}
