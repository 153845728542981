import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import resources from './translations'

const i18nextDetectorOptions = {
    order: ['localStorage', 'navigator'],
    lookupLocalStorage: 'i18nextLng',
    //nonExplicitWhitelist: true
}

i18n
    // load translation using http
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend) //
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        ns: ['pages', 'commons'],
        defaultNS: 'pages',
        fallbackLng: 'it',
        debug: true,
        react: {
            useSuspense: false,
            wait: false,
        },
        supportedLngs: ['it'], // only detect languages that are in the whitelist
        nonExplicitWhitelist: true,
        detection: i18nextDetectorOptions,
    })

export default i18n
