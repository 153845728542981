import { createBrowserRouter } from 'react-router-dom'

import PrivateLayout from '@/layouts/private-layout/PrivateLayout.jsx'
import PublicLayout from '@/layouts/public-layout/PublicLayout.jsx'
import * as pages from '@/pages'
import FinalBalanceTables from '@/pages/projects/list/projects-tables/final-balances-table/FinalBalanceTables.jsx'
import StandardProjectsTables from '@/pages/projects/list/projects-tables/projects-table/StandardProjectsTables.jsx'
import RDTables from '@/pages/projects/list/projects-tables/r&d-table/RDTables.jsx'
import TableMembers from '@/pages/settings/tables-settings/table-members/TableMembers.jsx'
import TableTeams from '@/pages/settings/tables-settings/table-teams/TableTeams.jsx'
import PrivateRoute from '@/route-guards/private-route/PrivateRoute.jsx'
import PublicRoute from '@/route-guards/public-route/PublicRoute.jsx'
import SharedRoute from '@/route-guards/shared-route/SharedRoute.jsx'
import * as routeNames from '@/utilities/constants'

const router = createBrowserRouter(
    [
        {
            element: <PrivateRoute />, // authenticated user layout route
            children: [
                {
                    element: <PrivateLayout withHeader />,
                    children: [
                        {
                            exact: true,
                            path: routeNames.ROUTE_DASHBOARD,
                            element: <pages.Dashboard />,
                        },

                        {
                            exact: true,
                            path: routeNames.ROUTE_PROJECTS,
                            element: <pages.ProjectsList />,
                            children: [
                                {
                                    exact: true,
                                    path: routeNames.ROUTE_STANDARD_PROJECTS,
                                    element: <StandardProjectsTables />,
                                },
                                {
                                    exact: true,
                                    path: routeNames.ROUTE_FINAL_BALANCE,
                                    element: <FinalBalanceTables />,
                                },
                                {
                                    exact: true,
                                    path: routeNames.ROUTE_RD,
                                    element: <RDTables />,
                                },
                            ],
                        },

                        {
                            exact: true,
                            path: routeNames.ROUTE_PROJECT_DETAIL,
                            element: <pages.ProjectDetail />,
                        },
                        {
                            exact: true,
                            path: routeNames.ROUTE_RD_DETAIL,
                            element: <pages.RddDetail />,
                        },

                        {
                            exact: true,
                            path: routeNames.ROUTE_CUSTOMERS,
                            element: <pages.CustomersList />,
                        },
                        {
                            exact: true,
                            path: routeNames.ROUTE_FINANCE,
                            element: <pages.Finance />,
                        },
                        {
                            exact: true,
                            path: routeNames.ROUTE_SETTINGS,
                            element: <pages.Settings />,
                            children: [
                                {
                                    index: true,
                                    exact: true,
                                    path: routeNames.ROUTE_SETTINGS_MEMBERS,
                                    element: <TableMembers />,
                                },
                                {
                                    exact: true,
                                    path: routeNames.ROUTE_SETTINGS_TEAMS,
                                    element: <TableTeams />,
                                },
                            ],
                        },

                        {
                            exact: true,
                            path: routeNames.ROUTE_CUSTOMER_DETAIL,
                            element: <pages.CustomerDetail />,
                        },
                        {
                            exact: true,
                            path: routeNames.ROUTE_LOG_DETAIL,
                            element: <pages.LogDetail />,
                        },
                    ],
                },
            ],
        },
        {
            element: <PublicRoute />, // anonymous user layout route
            children: [
                {
                    element: <PublicLayout />,
                    children: [
                        {
                            exact: true,
                            path: routeNames.ROUTE_LOGIN,
                            element: <pages.Login />,
                        },
                    ],
                },
            ],
        },
        {
            element: <SharedRoute />,
            children: [],
        },
        {
            path: '*', // Not found route
            element: <pages.NotFound />,
        },
    ],
    {
        basename: '/',
    }
)

export default router
