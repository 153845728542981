import styled, { css } from 'styled-components'

export const StyledAvatar = styled.div`
    ${({ theme, uniquecolor, size }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-transform: uppercase;
        background-color: ${uniquecolor};
        color: ${theme.palette.neutral.base[0]};
        ${theme.typography.paragraph.p10.bold}
        width: ${size}px;
        height: ${size}px;
        border-radius: 50%;
        cursor: default;
    `}
`
