import { createSlice } from '@reduxjs/toolkit'

const projectsSlice = createSlice({
    name: 'projects',
    initialState: {
        expandedRows: [],
    },
    reducers: {
        handlExpandedRows: (state, action) => {
            const id = action.payload
            if (state.expandedRows.includes(id)) {
                state.expandedRows = state.expandedRows.filter(e => e !== id)
            } else {
                state.expandedRows = [...state.expandedRows, id]
            }
        },
        setExpandedRows: (state, action) => {
            state.expandedRows = action.payload
        },
    },
})

export const currentExpandedRows = state => state?.projects?.expandedRows

export const { handlExpandedRows, setExpandedRows } = projectsSlice.actions

export default projectsSlice.reducer
