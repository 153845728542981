import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import Tabs from '@/components/tabs/Tabs.jsx'
import { columnsFinalBalance } from '@/pages/projects/list/projects-tables/final-balances-table/columnsFinalBalance.jsx'
import { columnsStandardProjects } from '@/pages/projects/list/projects-tables/projects-table/columnsStandardProjects.jsx'
import { columnsRD } from '@/pages/projects/list/projects-tables/r&d-table/columnsR&D.jsx'
import {
    LABELS_TABLES,
    ROUTE_CUSTOMERS,
    ROUTE_FINAL_BALANCE,
    ROUTE_LOG_DETAIL_NO_ID,
    ROUTE_PROJECTS,
    ROUTE_RD,
    ROUTE_SETTINGS,
    ROUTE_STANDARD_PROJECTS,
} from '@/utilities/constants/index.js'

const ProjectTabs = ({
    projects,
    finalBalance,
    rdData,
    setDataForm,
    openDialogDelete,
    setSelectedTab,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const standardProjectsPath = `${ROUTE_PROJECTS}/${ROUTE_STANDARD_PROJECTS}`
    const finalBalancePath = `${ROUTE_PROJECTS}/${ROUTE_FINAL_BALANCE}`
    const rdPath = `${ROUTE_PROJECTS}/${ROUTE_RD}`

    const onSectionClick = section => {
        setDataForm({})
        setSelectedTab(section)

        switch (section) {
            case LABELS_TABLES.projects:
                return navigate(`${ROUTE_PROJECTS}/${ROUTE_STANDARD_PROJECTS}`)
            case LABELS_TABLES.final_balances:
                return navigate(`${ROUTE_PROJECTS}/${ROUTE_FINAL_BALANCE}`)
            case LABELS_TABLES.rd:
                return navigate(`${ROUTE_PROJECTS}/${ROUTE_RD}`)
        }
    }

    const tableColumns = useMemo(() => {
        return {
            projects: columnsStandardProjects(),
            finalBalance: columnsFinalBalance(),
            rd: columnsRD(),
        }
    }, [t])

    const openEditSidebar = (id, modalType, typeForm) => {
        return setDataForm({
            isOpen: true,
            typeForm: typeForm,
            id: id,
            isEdit: true,
        })
    }

    const goToDetails = (id, global_project_id) => {
        switch (location.pathname) {
            case finalBalancePath:
                return navigate(
                    `${ROUTE_LOG_DETAIL_NO_ID}/${global_project_id}`
                )
            case rdPath:
                return navigate(`/${ROUTE_RD}/${global_project_id}`)
            case standardProjectsPath:
                return navigate(
                    `/${ROUTE_STANDARD_PROJECTS}/${global_project_id}`
                )
        }
    }

    const goToCustomer = id => {
        switch (location.pathname) {
            case rdPath:
                return navigate(`${ROUTE_SETTINGS}?id=${id}`)
            default:
                return navigate(`${ROUTE_CUSTOMERS}/${id}`)
        }
    }

    const props = {
        projects,
        finalBalance,
        rdData,
        tableColumns,
        openDialogDelete,
        openEditSidebar,
        goToDetails,
        goToCustomer,
    }

    const projectsTabs = [
        {
            id: 1,
            value: t('general.projects'),
            label: t('general.projects'),
        },
        {
            id: 2,
            value: t('projects.tabs.final_balances'),
            label: t('projects.tabs.final_balances'),
        },
        {
            id: 3,
            value: t('projects.tabs.r&d'),
            label: t('projects.tabs.r&d'),
        },
    ]
    return (
        <>
            <Tabs
                onValueChange={e => onSectionClick(e)}
                triggers={projectsTabs}
                defaultValue={t('general.projects')}
                haveDivider
            />

            <Outlet context={{ props }} />
        </>
    )
}

export default ProjectTabs

ProjectTabs.propTypes = {
    projects: PropTypes.array,
    finalBalance: PropTypes.array,
    setDataForm: PropTypes.func,
    openDialogDelete: PropTypes.func,
    setSelectedTab: PropTypes.func,
    rdData: PropTypes.array,
}
