import * as RadixTooltip from '@radix-ui/react-tooltip'
import styled, { css } from 'styled-components'

export const StyledTooltipContent = styled(RadixTooltip.Content)`
    ${({ theme, margincontent, customcolor }) => css`
        border-radius: ${theme.spacing - 2}px;

        border: ${customcolor
            ? `1px solid ${customcolor}`
            : `1px solid ${theme.palette.neutral.base[200]}`};
        padding: ${theme.spacing}px ${theme.spacing}px;
        margin-left: ${margincontent ? margincontent : theme.spacing * 2}px;
        max-width: 340px;
        white-space: pre-wrap;
        background-color: ${theme.palette.neutral.base[0]};
        color: ${theme.palette.neutral.base[1000]};
        box-shadow: ${theme.shadows[50]};
        ${theme.typography.paragraph.p14.regular};
    `}
`

export const StyledTooltipContainer = styled(RadixTooltip.Trigger)`
    position: relative;
    display: inline-block;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`

export const StyledContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: ${({ theme }) => theme.spacing * 2.5}px;
    width: 100%;
`
