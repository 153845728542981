import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const StyledHeaderRoot = styled.header`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-template-columns: 1fr auto;
        padding: 0 ${theme.spacing * 3}px;
        height: ${theme.headerHeight}px;
        background-color: ${theme.palette.neutral.base[0]};
        border-bottom: 1px solid ${theme.palette.neutral.base[200]};
    `}
`

export const StyledHeaderLogo = styled.div`
    ${({ theme }) => css`
        cursor: pointer;
        color: ${theme.palette.primary.text};
    `}
`

export const StyledHeaderInfo = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${theme.palette.neutral.base[500]};
        & svg {
            cursor: pointer;
        }
    `}
`

export const StyledHeaderRoutes = styled.div`
    ${({ theme }) => css`
        display: flex;
        gap: ${theme.spacing * 7}px;
        margin-right: ${theme.spacing * 4}px;
        a {
            text-decoration: none;
        }
    `}
`

export const StyledHeaderNav = styled(NavLink)`
    ${({ theme, active }) => css`
        ${theme.typography.paragraph.p14.regular}
        color: ${theme.palette.neutral.base[300]};
        pointer-events: ${active ? 'none' : 'auto'};
        &:hover {
            color: ${theme.palette.neutral.base[1000]};
        }
        &.active {
            color: ${theme.palette.neutral.base[1000]};
            ${theme.typography.paragraph.p14.medium}
        }
    `}
`

export const StyledHeaderDivider = styled.div`
    ${({ theme }) => css`
        background: ${theme.palette.neutral.base[200]};
        height: 32px;
        width: 1px;
        margin-right: ${theme.spacing * 4}px;
    `}
`

export const StyledHeaderUserInfo = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        gap: ${theme.spacing * 2}px;
    `}
`
