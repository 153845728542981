import i18next from 'i18next'
import { Link } from 'react-router-dom'

import {
    ActionsIcon,
    CostsProjectIcon,
    EditIcon,
    FinalBalanceIcon,
    ServiceIcon,
    TrashIcon,
} from '@/assets/icons/icons.jsx'
import Chip from '@/components/chip/Chip.jsx'
import Dropdown from '@/components/dropdown/Dropdown.jsx'
import StageStatus from '@/components/stage-status/StageStatus.jsx'
import Tooltip from '@/components/tooltip/Tooltip.jsx'
import { StyledContainerCell } from '@/pages/customers/detail/styles.js'
import {
    COMMON_DATE_FORMAT,
    COSTS_STATUS,
    COSTS_TYPE,
    GENERAL_STATUSES,
    LABELS_TABLES,
    ROUTE_CUSTOMERS,
} from '@/utilities/constants/index.js'
import {
    COSTS_STATUS_OPTIONS,
    COSTS_TYPE_OPTIONS,
} from '@/utilities/constants/list.js'
import {
    getHumanReadableDate,
    translateOptions,
    truncateText,
} from '@/utilities/helpers.js'
/* eslint-disable */

export const COLUMNS_PASSIVE_SCHEDULE_NAMES = {
    id: 'id',
    customer_name: 'customer_name',
    team_lead: 'team_lead',
    typology: 'typology',
    date: 'date',
    taxable: 'taxable',
    status: 'status',
    notes: 'notes',
    stage: 'stage',
    actions: 'actions',
}

export const columnsPassiveSchedule = () => {
    return [
        {
            alignment: 'left',
            label: i18next.t('general.id'),
            name: COLUMNS_PASSIVE_SCHEDULE_NAMES.id,
            width: '5%',
            sortable: false,
            divider: false,
        },
        {
            alignment: 'left',
            label: i18next.t('finance.table.customer_name'),
            name: COLUMNS_PASSIVE_SCHEDULE_NAMES.customer_name,
            width: '14%',
            sortable: false,
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('finance.table.team_lead'),
            name: COLUMNS_PASSIVE_SCHEDULE_NAMES.team_lead,
            sortable: false,
            width: '14%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.typology'),
            name: COLUMNS_PASSIVE_SCHEDULE_NAMES.typology,
            sortable: false,
            width: '11%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('finance.table.taxable'),
            name: COLUMNS_PASSIVE_SCHEDULE_NAMES.taxable,
            sortable: true,
            width: '8%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('finance.table.date'),
            name: COLUMNS_PASSIVE_SCHEDULE_NAMES.date,
            sortable: false,
            width: '8%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('general.status'),
            name: COLUMNS_PASSIVE_SCHEDULE_NAMES.status,
            sortable: false,
            width: '6%',
            divider: true,
        },

        {
            alignment: 'left',
            label: i18next.t('finance.table.notes'),
            name: COLUMNS_PASSIVE_SCHEDULE_NAMES.notes,
            sortable: false,
            width: '17%',
            divider: true,
        },
        {
            alignment: 'left',
            label: i18next.t('finance.table.stage'),
            name: COLUMNS_PASSIVE_SCHEDULE_NAMES.stage,
            sortable: false,
            width: '15%',
            divider: true,
        },
        {
            alignment: 'space-evenly',
            label: ' ',
            name: COLUMNS_PASSIVE_SCHEDULE_NAMES.actions,
            sortable: false,
            width: '2%',
            divider: true,
        },
    ]
}

export const columnsRenderersPassiveSchedule = (
    openDialogDelete,
    openEditSidebar
) => ({
    [COLUMNS_PASSIVE_SCHEDULE_NAMES.id]: ({ id }) => {
        return <p>#{id}</p>
    },
    [COLUMNS_PASSIVE_SCHEDULE_NAMES.customer_name]: ({ customer }) => (
        <Link to={`/${ROUTE_CUSTOMERS}/${customer?.id}`}>
            <p>{customer?.name}</p>
        </Link>
    ),
    [COLUMNS_PASSIVE_SCHEDULE_NAMES.team_lead]: ({ project }) => {
        return <p>{project?.team?.name ?? '-'}</p>
    },
    [COLUMNS_PASSIVE_SCHEDULE_NAMES.typology]: ({ type, project }) => {
        const renderTypology = () => {
            switch (type) {
                case COSTS_TYPE.project:
                    return <CostsProjectIcon />
                case COSTS_TYPE.service:
                    return <ServiceIcon />
                default:
                    return <FinalBalanceIcon />
            }
        }

        const textToRender = translateOptions(COSTS_TYPE_OPTIONS)?.find(
            item => {
                return item.value === type
            }
        )
        return (
            <Tooltip
                text={`${project?.name}`}
                icon={renderTypology()}
                showPosition="bottom"
                visibilityCondition={type === COSTS_TYPE.project}
            >
                <StyledContainerCell>
                    {renderTypology()}
                    <p>{textToRender?.label}</p>
                </StyledContainerCell>
            </Tooltip>
        )
    },
    [COLUMNS_PASSIVE_SCHEDULE_NAMES.date]: ({ date }) => (
        <p>{getHumanReadableDate(date, COMMON_DATE_FORMAT)}</p>
    ),
    [COLUMNS_PASSIVE_SCHEDULE_NAMES.taxable]: ({ amount }) => <p>€ {amount}</p>,

    [COLUMNS_PASSIVE_SCHEDULE_NAMES.status]: ({ status }) => {
        const statusToRender = () => {
            switch (status) {
                case COSTS_STATUS.paid:
                    return GENERAL_STATUSES.success
                case COSTS_STATUS.received:
                    return GENERAL_STATUSES.warning
                default:
                    return
            }
        }

        const textToRender = () => {
            const enumStatus = translateOptions(COSTS_STATUS_OPTIONS)
            return enumStatus.find(item => item.value === status)?.label
        }
        return (
            <Chip
                text={textToRender()}
                size={'medium'}
                variant={statusToRender()}
                fullWidth={false}
            />
        )
    },
    [COLUMNS_PASSIVE_SCHEDULE_NAMES.notes]: ({ description }) => (
        <Tooltip text={description} showPosition={'bottom'}>
            <p>{description ? truncateText(description, 26) : '-'}</p>
        </Tooltip>
    ),
    [COLUMNS_PASSIVE_SCHEDULE_NAMES.stage]: ({ sal }) => {
        return (
            <>
                {sal ? (
                    <StageStatus
                        status={
                            sal?.is_completed === false
                                ? GENERAL_STATUSES.danger
                                : GENERAL_STATUSES.success
                        }
                        text={truncateText(
                            `#${sal?.global_sal_id} - ${sal?.name}`,
                            15
                        )}
                    />
                ) : (
                    '-'
                )}
            </>
        )
    },
    [COLUMNS_PASSIVE_SCHEDULE_NAMES.actions]: data => (
        <Dropdown
            trigger={<ActionsIcon />}
            items={[
                {
                    label: i18next.t('finance.actions.edit_billing'),
                    onSelect: () => {
                        openEditSidebar(
                            data?.project?.id,
                            LABELS_TABLES.costs,
                            data
                        )
                    },
                    icon: <EditIcon />,
                    isToDelete: false,
                },
                {
                    label: i18next.t('finance.actions.delete_billing'),
                    onSelect: () => {
                        openDialogDelete(data?.id, LABELS_TABLES.billing)
                    },
                    icon: <TrashIcon />,
                    isToDelete: true,
                },
            ]}
        />
    ),
})
