import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { CalendarIcon } from '@/assets/icons/icons.jsx'
import Input from '@/components/input/Input.jsx'
import Search from '@/components/search/Search.jsx'
import Select from '@/components/select/Select.jsx'
import { StyledContainerActiveScheduleFinance } from '@/pages/finance/finance-filters/styles.js'
import { CONTRACT_TYPE_OPTIONS } from '@/utilities/constants/list.js'
import { translateOptions } from '@/utilities/helpers.js'

const ActiveScheduleFilters = ({ setFilters, filters }) => {
    const { t } = useTranslation()

    return (
        <StyledContainerActiveScheduleFinance>
            <Select
                isControlled={false}
                size={'medium'}
                placeholder={t('general.typology')}
                options={translateOptions(CONTRACT_TYPE_OPTIONS)}
                onChange={value =>
                    setFilters({
                        ...filters,
                        contractType: value?.value,
                    })
                }
            />

            {/*TODO: ADD DATEPICKER WHEN IS READY THE SCHEMA IS READY*/}
            <Input
                icon={<CalendarIcon />}
                iconPosition={'right'}
                placeholder={t('billing_project.fields.placeholder.date')}
                size={'medium'}
                width={'150px'}
            />

            <Search
                placeholder={t('settings.filters.placeholder.search')}
                handleSearch={value => {
                    setFilters({ search: value })
                }}
            />
        </StyledContainerActiveScheduleFinance>
    )
}

export default ActiveScheduleFilters

ActiveScheduleFilters.propTypes = {
    setFilters: PropTypes.func,
    filters: PropTypes.object,
}
