/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import Select from '@/components/select/Select.jsx'

import { StyledQueryButton } from './styles'

const QuerySelect = ({
    getData,
    required,
    name,
    label,
    placeholder,
    control,
    options,
    touched,
    errors,
    cta,
    size,
    isClearable,
    onClickHandler,
    isOptionDisabled,
}) => {
    const QueryCta = props => {
        return (
            <div>
                {props.children}
                <StyledQueryButton
                    size="medium"
                    color={'neutral'}
                    variant={'borderless'}
                    type={'button'}
                    onClick={() => onClickHandler()}
                >
                    {cta}
                </StyledQueryButton>
            </div>
        )
    }

    const [searchValue, setSearchValue] = useState(undefined)

    useEffect(() => {
        if (typeof getData === 'function') {
            if (searchValue) {
                const timeout = setTimeout(() => {
                    getData(1, searchValue)
                }, 500)
                return () => clearTimeout(timeout)
            } else {
                getData(1)
            }
        }
    }, [searchValue])

    return (
        <Select
            required={required}
            name={name}
            label={label}
            placeholder={placeholder}
            control={control}
            options={options}
            touched={touched}
            errors={errors}
            size={size}
            customComponents={{ MenuList: QueryCta }}
            onInputChange={e => setSearchValue(e)}
            isSearchable
            isClearable={isClearable}
            isOptionDisabled={isOptionDisabled}
        />
    )
}

export default QuerySelect

QuerySelect.propTypes = {
    getData: PropTypes.func,
    required: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    control: PropTypes.object,
    options: PropTypes.array,
    touched: PropTypes.any,
    errors: PropTypes.object,
    cta: PropTypes.string,
    size: PropTypes.string,
    isClearable: PropTypes.bool,
    onClickHandler: PropTypes.func,
}
