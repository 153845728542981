import * as yup from 'yup'

const ALLOCATION_MODEL = {
    id: 'id',
    name: 'name',
    start_date: 'start_date',
    end_date: 'end_date',
    hours: 'hours',
    allocation: 'allocation',
}

const DEFAULT_ALLOCATION_MOCK = {
    [ALLOCATION_MODEL.name]: null,
    [ALLOCATION_MODEL.allocation]: null,
}

const RequestValidationSchema = yup.object().shape({
    [ALLOCATION_MODEL.name]: yup.object().nullable(),
    [ALLOCATION_MODEL.allocation]: yup.array(),
})

export {
    ALLOCATION_MODEL,
    DEFAULT_ALLOCATION_MOCK,
    RequestValidationSchema as validationSchema,
}
